<div class="container view">
    <h2>{{lang.text('alumnos')}}</h2>

    <div class="row">
      <div class="col-sm-6">
        <div class="botones-opciones mb-3">
            <button class="app-btn" *ngIf="auth.rolId == 2" (click)="nuevo()" title="{{lang.text('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
            <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>
            <button class="app-btn" *ngIf="auth.rolId == 2" (click)="excel()" title="{{lang.text('btn.add')}}"><fa-icon icon="file"></fa-icon> .xls</button>
            <button class="app-btn" *ngIf="auth.rolId == 2" (click)="seleccionarFlojos()" title="{{lang.text('btn.add')}}"><fa-icon icon="check"></fa-icon>{{lang.text('btn.seleccionar_sin_periodo')}}</button>
        </div>
      </div>
      <div class="col-sm-6">
        <input type="search" class="app-input mb-3" placeholder="{{lang.text('buscar')}}" (keyup.enter)="buscar($event)">
      </div>
  </div>

    <div *ngIf="!this.loading" class="table-container">
        <table class="app-table">
              <thead>
                    <th>
                        <input *ngIf="auth.rolId == 2" style="margin-left: 8px;" type="checkbox" (click)="checkChange($event)">

                    </th>
                    <th>

                    </th>
                    <th>
                          {{lang.text('rut')}}
                    </th>
                    <th>
                      {{lang.text('correo')}}
                    </th>
                    <th *ngIf="auth.rolId == 2" >
                        {{lang.text('plan')}}
                    </th>
                    <th>
                        {{lang.text('telefono')}}
                    </th>
                    <th *ngIf="auth.rolId == 2" >
                        {{lang.text('al.periodos_activos')}}
                    </th>
                    <th>

                    </th>
              </thead>
              <tbody>
                    <tr *ngFor="let u of users" [class.selected]="u.selected">
                        <td>
                            <input *ngIf="auth.rolId == 2" type="checkbox" class="app-check" [(ngModel)]="u.selected" (change)="updateMasivos()">
                        </td>
                      <td>
                          <img [src]="u.userImagen" alt="" height="32">
                          {{u.userNombre}} {{u.userApellido}}

                      </td>
                      <td>
                          {{u.userRut}}
                      </td>
                      <td>
                          {{u.userEmail}}
                      </td>
                      <td *ngIf="auth.rolId == 2" >
                          {{u.planDesc}}
                      </td>
                      <td>
                          {{u.userTelefono}}
                      </td>
                      <td *ngIf="auth.rolId == 2" > 
                          <fa-icon icon="check" *ngIf="u.evaluacionesActivas > 0"></fa-icon>
                      </td>
                      <td>
                            <ng-container *ngIf="!u.selected">
                                <button class="app-btn small" (click)="verDocs(u)" title="{{lang.t('al.ver_doc')}}"><fa-icon icon="book"></fa-icon></button>
                                <button class="app-btn small" *ngIf="auth.rolId == 2" (click)="modificar(u)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                                <button class="app-btn small" *ngIf="auth.rolId == 2" (click)="eliminar(u)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                                <button class="app-btn small" *ngIf="auth.rolId == 2" (click)="evaluar(u)" title="{{lang.t('al.evaluar')}}"><fa-icon icon="comment"></fa-icon></button>
                                <button class="app-btn small" *ngIf="auth.rolId == 2" [disabled]="u.evaluacionesActivas > 0" (click)="crearEva(u)" title="{{lang.t('btn.crearEva')}}"><fa-icon icon="futbol"></fa-icon></button>
                            
                            </ng-container>
                        </td>
                    </tr>
              </tbody>
        </table>
        <div class="masivo" *ngIf="seleccionados > 0">
            <span>{{lang.text('al.masivos_counter')}} {{seleccionados}}  </span>
            <button class="app-btn small" (click)="eliminarMultiple()" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon> {{lang.t('btn.dele')}}</button>
            <button class="app-btn small" (click)="crearEvaMultiple()" title="{{lang.t('btn.crearEva')}}"><fa-icon icon="futbol"></fa-icon> {{lang.t('btn.crearEva')}}</button>
        </div>

    </div>

    <div *ngIf="this.loading" class="loader">

    </div>
    <h6 *ngIf="this.users.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>

</div>
