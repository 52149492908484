import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { CategoriaService } from 'src/app/services/categoria.service';
import { Sesion } from 'src/app/models/sesion';
import { ModalService } from 'src/app/services/modal-service';
import { SesionNuevoComponent } from './sesion-nuevo/sesion-nuevo.component';
import { CopyPasteService } from 'src/app/services/copypaste.service';
import { SesionInfoComponent } from './sesion-info/sesion-info.component';
import { LangService } from 'src/app/services/lang.service';


@Component({
  selector: 'app-sesiones',
  templateUrl: './sesiones.component.html',
  styleUrls: ['./sesiones.component.css']
})
export class SesionesComponent implements OnInit {

  categorias:Sesion[] = [];
  categoriasFiltered:Sesion[] = [];
  loading= true;

  pageSize = 10;
  page = 0;
  lastPage = 0;
  searchStr="";


  constructor(private categoriaService:CategoriaService,
              private modalService:ModalService,
              public clipService:CopyPasteService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    const req = await this.categoriaService.getAllSesion();
    this.categorias = req.data;
    this.categoriasFiltered = this.categorias;
    this.resetPaged();
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(SesionNuevoComponent,{windowClass:'app-modal top-modal full-w',size:'xl',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async modificar(sesion:Sesion)
  {
    let modalRef = this.modalService.open(SesionNuevoComponent,{windowClass:'app-modal top-modal full-w',size:'xl',backdropClass:'no-backdrop'});
    modalRef.componentInstance.sesionParam= sesion;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async info(sesion:Sesion)
  {
    let modalRef = this.modalService.open(SesionInfoComponent,{windowClass:'app-modal top-modal ',size:'md',backdropClass:'no-backdrop'});
    modalRef.componentInstance.sesion= sesion;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async duplicar(sesion:Sesion)
  {
    let modalRef = this.modalService.open(SesionNuevoComponent,{windowClass:'app-modal top-modal full-screen',size:'xl',backdropClass:'no-backdrop'});
    modalRef.componentInstance.sesionParam= sesion;
    modalRef.componentInstance.duplicate = true;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(sesion:Sesion)
  {
    let confirmar = await this.modalService.confirm('ses.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.categoriaService.deleteSesion(sesion);
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
  }


  resetPaged(){
    this.page = 0;
    this.lastPage = Math.ceil(this.categoriasFiltered.length/this.pageSize);
  }

  prevPage(){
    if(this.page > 0){
      this.page--;
    }
  }

  nextPage(){
    if(this.page < this.lastPage -1){
      this.page++;
    }
  }

  getPaged(page){
    var array = this.categoriasFiltered.slice(page*this.pageSize,page*this.pageSize+this.pageSize);
    return array;
  }

  async refresh()
  {
    this.loading=true;
    await this.onLoad();
    await this.search();
    this.loading=false;
  }

  async search()
  {
    if(this.searchStr.length > 1)
    {
      this.categoriasFiltered = this.categorias.filter((x) =>{
        return x.cacuNombre.toLowerCase().includes(this.searchStr.toLowerCase());
      });
      
    }
    else{
      this.categoriasFiltered = this.categorias;
    }

    this.resetPaged();
  }
}
