import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { Mesociclo } from 'src/app/models/mesociclo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-macrociclos-nuevo',
  templateUrl: './macrociclos-nuevo.component.html',
  styleUrls: ['./macrociclos-nuevo.component.css']
})
export class MacrociclosNuevoComponent implements OnInit {

  loading = true;
  mesos:Mesociclo[] = [];
  mesosSel:Mesociclo[] = [];
  uploading = false;
  caunNombre:string;
  errores:any[] = [];

  @Input() macrocicloParam:Macrociclo;

  constructor(public activeModal:NgbActiveModal,
              private categoriaService:CategoriaService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad(){
    try {
      const req = await this.categoriaService.getAllMesos();
      this.mesos = req.data;

      if(this.macrocicloParam)
      {
        const req2 = await this.categoriaService.getMesoByMacro(this.macrocicloParam.caunId);
        this.mesosSel = req2.data;
        this.caunNombre = this.macrocicloParam.caunNombre;
      }      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregar(obj:Mesociclo)
  {
    let find = this.mesosSel.find(el => el.cadoId == obj.cadoId);
    if(find == null)
    {
      this.mesosSel.push(obj);
    }
  }

  async remover(obj:Mesociclo)
  {
    let ind = this.mesosSel.findIndex(el => el.cadoId == obj.cadoId);
    this.mesosSel.splice(ind,1);
  }

  async guardar()
  {
    this.uploading= true;
    try {
      let nuevo = new Macrociclo();
      if(this.macrocicloParam){nuevo.caunId = this.macrocicloParam.caunId;}//modificar    
      nuevo.caunNombre = this.caunNombre;

      for (const iterator of this.mesosSel) {
        nuevo.mesociclos.push(iterator.cadoId);
      }

      const req = await this.categoriaService.postMacro(nuevo);
      this.activeModal.close('creado');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

}
