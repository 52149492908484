<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!leccionParam ">{{lang.text('lecc.agregar')}}</h4>
        <h4 *ngIf="leccionParam ">{{lang.text('lecc.editar')}}</h4>
        <form>
            <label class="app-label">{{lang.text('lecc.nombre')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="leccion.leccNombre" name="leccNombre">

            <label class="app-label">{{lang.text('lecc.desc')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="leccion.leccDesc" name="leccDesc">
        </form>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar">

    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn red" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn green" [disabled]="uploading" (click)="agregar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check" ></fa-icon></button>
    </div>
</div>
