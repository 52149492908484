<div class="container-view">
    <div class="botones-opciones full ciclos">
        <button class="app-btn" [routerLink]="['/cursos']">{{lang.text('menu.curs')}}</button>
        <button class="app-btn" [routerLink]="['/modulos']">{{lang.text('menu.modu')}}</button>
        <button class="app-btn" [routerLink]="['/lecciones']">{{lang.text('menu.lecc')}}</button>
        <button class="app-btn" [routerLink]="['/quizes']">{{lang.text('menu.quiz')}}</button>
        <button class="app-btn active" [routerLink]="['/preguntas']">{{lang.text('menu.preg')}}</button>

    </div>
    <div class="ciclos-border">
        <div class="botones-opciones mb-3">
            <button class="app-btn" (click)="agregarNuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus" ></fa-icon></button>
            <button class="app-btn" (click)="refresh()" title="{{lang.t('btn.actu')}}"><fa-icon icon="retweet" ></fa-icon></button>

        </div>
        <div *ngIf="!this.loading" class="table-container">
            <table class="app-table">
                <thead>
                    <th>
                        #
                    </th>
                    <th>
                        {{lang.text('preg.pregunta')}}
                    </th>

                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let preg of preguntas">
                        <td>{{preg.pregId}}</td>
                        <td>{{preg.pregDesc}}</td>

                        <td>
                            <button class="app-btn small" (click)="modificar(preg)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit" ></fa-icon></button>
                            <button class="app-btn small red" (click)="eliminar(preg)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt" ></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="this.loading" class="loader">

        </div>
        <h6 *ngIf="preguntas.length == 0 && !this.loading">{{lang.t('nada_por_aqui')}}</h6>

    </div>

</div>
