import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Clase } from '../models/clase';





@Injectable({
  providedIn: 'root'
})

export class DocumentoService {

  constructor(private http:HttpClient) {
  }

  public async getAll(idAlumno:number)
  {
    return this.http.get<ApiResponse<Documento>>(`${environment.urlAPI}documento/${idAlumno}`).toPromise();
  }

  public async GetModuloDocumento(moduId:number)
  {
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}documento/documento/${moduId}`).toPromise();
  }

  public async post(idAlumno:number,file:any)
  {
    var body = new FormData();
    body.append('alumnoId',idAlumno.toString());
    body.append('file',file,file.name);
    return this.http.post<ApiResponse<Documento>>(`${environment.urlAPI}documento`,body).toPromise();
  }


  public async enviarDoc(idAlumno:number,file_id:string)
  {
    var body = {
      alumnoId:idAlumno,
      docuId:file_id
    }
    return this.http.post<ApiResponse<Documento>>(`${environment.urlAPI}documento/alumno-enviar-documento`,body).toPromise();
  }

  public async delete(id:string)
  {
    return this.http.post<ApiResponse<Documento>>(`${environment.urlAPI}documento/eliminar/${id}`,{}).toPromise();
  }

  //CAMPUS

  public async getAllCampus()
  {
    return this.http.get<ApiResponse<Documento>>(`${environment.urlAPI}documento/getDocCampus`).toPromise();
  }



  public async postCampus(tipoDocuId = 1,docuCursId:number,file:any)
  {
    var body = new FormData();
    body.append('tipoDocuId',tipoDocuId.toString());
    body.append('docuCursId',docuCursId.toString());
    body.append('file',file,file.name);
    return this.http.post<ApiResponse<Documento>>(`${environment.urlAPI}documento/postDocuCampus`,body).toPromise();
  }





  public async getModuloQuiz(docuId:number,leccId?:number)
  {
    const body = Params.toHttpParams({docuId:docuId,leccId:leccId});
    return this.http.get<ApiResponse<Documento>>(`${environment.urlAPI}campus/quiz/modulo`,{params:body}).toPromise();
  }


  public async postModuloQuiz(req:DocumentoLeccion)
  {
    return this.http.put<ApiResponse<DocumentoLeccion>>(`${environment.urlAPI}campus/quiz/modulo`,req).toPromise();
  }


  
  public async DeleteModulo(moduId?:number)
  {
    const body = Params.toHttpParams({moduId:moduId});
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}campus/modulo/curso`,{params:body}).toPromise();
  }









}

export interface Documento{
  docuId?:string;
  docuNombre:string;
  docuArchivo:string;
  alumnoId:number;
} 


export class DocumentoLeccion{
  leccId:number;
  documentos:number[];
}

export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}