import { Component, OnInit } from '@angular/core';
import { Sponsor } from 'src/app/models/sponsor';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { AgregarModificarSponsorComponent } from './agregar-modificar-sponsor/agregar-modificar-sponsor.component';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.css']
})
export class SponsorComponent implements OnInit {

  loading = true;
  sponsors:Sponsor[] = [];

  constructor(private sponsorService:SponsorService,private helper:ModalService,public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    try {
      const req = await this.sponsorService.getAll();
      this.sponsors = req.data;
    } catch (error) {
      
    }
    this.loading = false;
  }

  async refresh(){
    this.loading = false;
    await this.load();
  }

  async agregarNuevo(){
    let modalRef = this.helper.open(AgregarModificarSponsorComponent,{windowClass:'app-modal top-modal ',size:'md',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      this.refresh();
    } catch (error) {
      
    }
  }

  async editar(i:Sponsor){
    let modalRef = this.helper.open(AgregarModificarSponsorComponent,{windowClass:'app-modal top-modal ',size:'md',backdropClass:'no-backdrop'});
    modalRef.componentInstance.model = i;
    try {
      await modalRef.result;
      this.refresh();
    } catch (error) {
      
    }
  }
  async eliminar(i:Sponsor){
    const question = await this.helper.confirm('Confirmar eliminación','Eliminar','Cancelar');
    if(question){
      try {
        const req = await this.sponsorService.delete(i.sponId);
        this.refresh();
      } catch (error) {
        this.helper.showError(error.message,'ok');
      }
    }
  }
}
