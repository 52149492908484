import { Component, OnInit } from '@angular/core';
import { Caracteristica } from 'src/app/models/caracteristica';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { AgregarCaracteristicaComponent } from './agregar-caracteristica/agregar-caracteristica.component';

@Component({
  selector: 'app-caracteristica',
  templateUrl: './caracteristica.component.html',
  styleUrls: ['./caracteristica.component.css']
})
export class CaracteristicaComponent implements OnInit {
  caracteristicas:Caracteristica[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  constructor(private modalService:ModalService,private videoService:VideoService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(AgregarCaracteristicaComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async modificar(g:Caracteristica)
  {
    let modalRef = this.modalService.open(AgregarCaracteristicaComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    modalRef.componentInstance.caracteristica = g;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async eliminar(g:Caracteristica)
  {
    let confirmar = await this.modalService.confirm('car.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.videoService.deleteCaracteristica(g.caracteristicaId);
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }



  async onLoad()
  {
    try {
      const req = await this.videoService.getAllCaracteristicas();
      this.caracteristicas = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

}
