import { Component, OnInit } from '@angular/core';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { MacrociclosDetalleComponent } from './macrociclos-detalle/macrociclos-detalle.component';
import { MacrociclosNuevoComponent } from './macrociclos-nuevo/macrociclos-nuevo.component';

@Component({
  selector: 'app-macrociclos',
  templateUrl: './macrociclos.component.html',
  styleUrls: ['./macrociclos.component.css']
})
export class MacrociclosComponent implements OnInit {

  loading = true;
  categorias:Macrociclo[] = [];

  constructor(private categoriaService:CategoriaService,private modalService:ModalService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    const req = await this.categoriaService.getAllMacros();
    this.categorias = req.data;
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(MacrociclosNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async detalle(obj:Macrociclo)
  {
    let modalRef = this.modalService.open(MacrociclosDetalleComponent,{windowClass:'app-modal message-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.macrociclo = obj;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async modificar(obj:Macrociclo)
  {
    let modalRef = this.modalService.open(MacrociclosNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.macrocicloParam= obj;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(obj:Macrociclo)
  {
    let confirmar = await this.modalService.confirm('macro.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.categoriaService.deleteMacro(obj);
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
  }

  async refresh()
  {
    this.loading=true;
    await this.onLoad();
    this.loading=false;
  }
}
