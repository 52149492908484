import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Caracteristica } from 'src/app/models/caracteristica';
import { TipoVideo } from 'src/app/models/tipovideo';
import { Video } from 'src/app/models/video';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-video-editar',
  templateUrl: './video-editar.component.html',
  styleUrls: ['./video-editar.component.css']
})
export class VideoEditarComponent implements OnInit {
  videoNombre:string='';
  videoDesc:string='';
  videoFile:File;
  videoDuracion:string='';
  progress:number = 0;

  @Input() videoId:number;
  @Input() lc:Caracteristica[];

  errores:string[] = [];

  videoPremium;
  uploading = false;
  loading = true;

  caracteristicas:Caracteristica[] = [];
  tipoVideoId: number = null;
  tiposVideo:TipoVideo[] = [];
  videoDescEjercicio: string = '';
  
  constructor(public activeModal: NgbActiveModal,
              private videoService: VideoService,
              private modalService: ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading= false;
  }

  async onLoad()
  {
    try {
      const req = await this.videoService.get(this.videoId);
      const video = req.data[0];
      this.videoNombre= video.videoNombre;
      this.videoDesc = video.videoDescripcion;
      this.videoDuracion = video.videoTiempo;
      this.tipoVideoId = video.tipoVideoId;
      this.videoDescEjercicio = video.videoDescEjercicio;
      const req2 = await this.videoService.getAllCaracteristicas();
      this.caracteristicas = req2.data;
      const req3 = await this.videoService.getTiposVideo();
      this.tiposVideo = req3.data;

      for (const i of this.lc) {
        for (const j of this.caracteristicas) {
          if(j.caracteristicaId == i.caracteristicaId){
            j.selected = true;
          }
        }
      }
    } catch (error) {
      await this.modalService.showError(error.message,'Ok');
      this.activeModal.dismiss();
    }
  }

  async modificarVideo()
  {
    let video = new Video();
    video.videoId=this.videoId;
    video.videoDescripcion = this.videoDesc;
    video.videoNombre = this.videoNombre;
    video.videoTiempo = this.videoDuracion;
    video.tipoVideoId = this.tipoVideoId;
    video.videoDescEjercicio = this.videoDescEjercicio;
    for (const iterator of this.caracteristicas) {
      if(iterator.selected)
      {
        video.categorias.push(iterator.caracteristicaId);
      }
    }
    
    //video.Archivo = this.videoFile;
    const file = (<HTMLInputElement>document.getElementById('archivo')).files.item(0); 
    video.Archivo = file;
    
    this.videoService.post(video).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Enviado');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Recibido el header');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Subiendo ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('Terminado!', event.body);
          setTimeout(() => {
            this.progress = 0;
            this.activeModal.close();
          }, 1500);

      }
    }, (error) =>
    {
      this.modalService.showError(error.message,'Ok');
      this.errores= error.data;
    });
    
    
  }

}
