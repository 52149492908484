import { Component, OnInit } from '@angular/core';
import { Asistencia } from 'src/app/models/asistencia';
import { AsistenciaService } from 'src/app/services/Asistencias.service';
import { ModalService } from 'src/app/services/modal-service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-asistencias',
  templateUrl: './asistencias.component.html',
  styleUrls: ['./asistencias.component.css']
})
export class AsistenciasComponent implements OnInit {

  loading = true;
  lista:Asistencia[] = [];
  soloHoy:number = 1;

  constructor(private modalService:ModalService,private asistenciaService:AsistenciaService, public lang:LangService) { }

  ngOnInit() {
    this.refresh(1);
  }

  async onLoad()
  {
    try {
      if(this.soloHoy == 1)
      {
        const request = await this.asistenciaService.getHoy();
        this.lista =request.data;
      }
      else
      {
        const request = await this.asistenciaService.getAll();
        this.lista =request.data;
      }

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async habilitarTodas()
  {
    try {

      let obj:number[] = [];

      for (const iterator of this.lista) {
        if(iterator.selected)
        {
          obj.push(iterator.asistenciaId);
        }
      }

      const req = await this.asistenciaService.activarVarias(obj);
      await this.refresh(this.soloHoy);

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar')
    }
  }



  async refresh(opt){
    this.loading = true;
    this.soloHoy = opt;
    await this.onLoad();
    this.loading = false;
  }

}
