<div class="container view">
      <h4 lang>{{lang.text('patrocinadores')}}</h4>
      <div class="botones-opciones mb-3">
          <button class="app-btn" (click)="agregarNuevo()" title="{{lang.text('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
          <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>

      </div>
      <div *ngIf="!this.loading" class="table-container">
          <table class="app-table">
                <thead>
                      <th>
                            #
                      </th>
                      <th>
                            {{lang.text('descripcion')}}
                      </th>
                      <th>
                            {{lang.text('prioridad')}}
                      </th>
                      <th>

                      </th>
                </thead>
                <tbody>
                      <tr *ngFor="let iter of this.sponsors;index as idx">
                            <td>{{idx+1}}</td>
                            <td>
                                <img [src]="iter.sponImg" width="48" height="48" alt="" srcset="">
                                {{iter.sponDesc}}
                            </td>
                            <td>
                                  {{iter.sponPrioridad}}
                            </td>
                            <td>
                                  <button class="app-btn small" (click)="editar(iter)" title="{{lang.text('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>

                                  <button class="app-btn small red" (click)="eliminar(iter)" title="{{lang.text('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                            </td>
                      </tr>
                </tbody>
          </table>

      </div>

      <div *ngIf="this.loading" class="loader">

      </div>
      <h6 *ngIf="this.sponsors.length == 0 && !this.loading">Nada por aqui...</h6>

  </div>
