<div class="modal-alert" >
    <div class="modal-body left" *ngIf="!loading">
        <h3>{{lang.text('calendar.agregarh1')}}</h3>
        <div class="row">
            <div class="col-sm-6">
                <label class="app-label" for="fechaDesde">{{lang.text('fecha_desde')}}</label>
                <input [(ngModel)]="model.fechaDesde"  class="app-input" type="date" id="fechaDesde" name="fechaDesde">
            </div>
            <div class="col-sm-6">
                <label class="app-label" for="fechaHasta">{{lang.text('fecha_hasta')}}</label>
                <input [(ngModel)]="model.fechaHasta"  class="app-input" type="date" id="fechaHasta" name="fechaHasta">
            </div>
        </div>
        <div class="row mt-1">
            <div class="col-sm-12">
                <label for="">{{lang.text('calendar.dias')}}</label>
                <div class="dias-stack">
                    <div *ngFor="let i of model.dias;index as idx">
                        <input [(ngModel)]="i.checked" style="margin-right: 4px;"  type="checkbox" name="dia{{idx}}" id="dia{{idx}}">
                        <label  for="dia{{idx}}">{{nombresDias[idx+1]}}</label>
                    </div>
                </div>
        
                
                <label class="app-label" for="">{{lang.text('calendar.hora')}}</label>
                <input class="app-input" type="time" name="hora" id="hora" [(ngModel)]="model.hora">
            </div>
        </div>
        
        
        
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn"  (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn"  (click)="save()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
  </div>
  
  
  