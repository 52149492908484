<div class="modal-alert">
      <div class="modal-body left">
            <h4 *ngIf="userModel.id == 0">{{lang.text('prof.crear')}}</h4>
            <h4 *ngIf="userModel.id > 0">{{lang.text('prof.editar')}}</h4>
          <div class="row">
                <div class="col-sm-7">
                  <form>
                        <label class="app-label">{{lang.text('nombre')}}</label>
                        <input type="text" class="app-input" [(ngModel)]="userModel.userNombre" name="userNombre">

                        <label class="app-label">{{lang.text('apellido')}}</label>
                        <input type="text" class="app-input" [(ngModel)]="userModel.userApellido" name="userApellido">

                        <label class="app-label">{{lang.text('rut')}}</label>
                        <input type="search" class="app-input" [(ngModel)]="userModel.userRut" (keyup)="updateRut()" name="userRut">

                        <label class="app-label">{{lang.text('telefono')}}</label>
                        <input type="text" class="app-input" [(ngModel)]="userModel.userTelefono" name="userTelefono">


                        <div class="app-checkbox mt-3" >
                              <input type="checkbox" [(ngModel)]="userModel.esEmpresa" name="esEmpresa" id="esEmpresa"> <label for="esEmpresa">{{lang.text('es_empresa')}}</label>
                        </div>

                        <label class="app-label">{{lang.text('sexo')}}</label>
                        <div class="row">
                              <div class="col-sm-4">
                                    <input type="radio" name="gen" value="1" id="m" [(ngModel)]="userModel.userSexo" [value]="1">
                                    <label for="m">{{lang.text('Masculino')}}</label>
                              </div>
                              <div class="col-sm-4">
                                    <input type="radio" name="gen" value="2" id="f" [(ngModel)]="userModel.userSexo" [value]="2">
                                    <label for="f">{{lang.text('Femenino')}}</label>
                              </div>
                              <div class="col-sm-4">
                                    <input type="radio" name="gen" value="0" id="ne" [(ngModel)]="userModel.userSexo" [value]="0">
                                    <label for="ne">{{lang.text('otro')}}</label>
                              </div>
                        </div>

                        


                        <label class="app-label">{{lang.text('email')}}</label>
                        <input type="email" class="app-input" [(ngModel)]="userModel.userEmail" name="userEmail">

                        <label class="app-label">{{lang.text('password')}}</label>
                        <input type="password" class="app-input" [(ngModel)]="userModel.userPassword" name="userPassword">




                      </form>
                </div>
                <div class="col-sm-5">
                      <div >


                        <label class="app-label">{{lang.text('imagen')}}</label>
                        <div class="loader" *ngIf="imageUpload"></div>
                        <img [src]="userModel.userImagen" alt="">
                        <button class="app-btn w-100" (click)="filePicker.click()">{{lang.text('subir_imagen')}}</button>
                        <input #filePicker type="file" (change)="subirImagen($event)" style="display: none;">
                        

                        <!-- <label class="app-label">{{lang.text('pais')}}</label>
                        <select (change)="actualizarRegiones($event)"  class="app-combo">
                              <option value="null" >{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let c of paises" value="{{c.paisId}}">{{c.paisNombre}}</option>
                        </select>

                        <label class="app-label">{{lang.text('region')}}</label>
                        <select  (change)="actualizarComunas($event)" class="app-combo">
                              <option value="null" >{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let c of regiones" value="{{c.regionId}}">{{c.regionNombre}}</option>
                        </select>

                        <label class="app-label">{{lang.text('comuna')}}</label>
                        <select [(ngModel)]="userModel.comunaId" name="userComuna" class="app-combo">
                              <option value="null" disabled>{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let c of comunas" value="{{c.comunaId}}">{{c.comunaNombre}}</option>
                        </select> -->

                        <label class="app-label">{{lang.text('inputComuna.label')}}</label>
                        <app-input-comuna [(ngModel)]="userModel.comunaId"></app-input-comuna>


                        <label class="app-label">{{lang.text('membresia')}}</label>
                        <select (change)="cambiarMembresia()" [(ngModel)]="userModel.membresiaId" name="userMembresia" class="app-combo">
                              <option value="null" disabled>{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let n of membresias" value="{{n.membresiaId}}">{{n.membresiaDesc}}</option>
                        </select>

                        <label class="app-label">{{lang.text('categoria')}}</label>
                        <select  [(ngModel)]="userModel.nivelId" name="userNivel" class="app-combo">
                              <option value="null" disabled>{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let n of niveles" value="{{n.nivelId}}">{{n.nivelNombre}}</option>
                        </select>

                        <label class="app-label">{{lang.text('fecha_inicio')}}</label>
                        <input type="date" class="app-input" [(ngModel)]="userModel.fechaInicio" name="userFechaInicio">

                      </div>



                </div>
          </div>




          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div class="loading-bar" *ngIf="uploading"></div>
      <div class="modal-footer">
          <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
      </div>
</div>


