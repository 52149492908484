import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Mesociclo } from 'src/app/models/mesociclo';
import { Microciclo } from 'src/app/models/microciclo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-mesociclo-nuevo',
  templateUrl: './mesociclo-nuevo.component.html',
  styleUrls: ['./mesociclo-nuevo.component.css']
})
export class MesocicloNuevoComponent implements OnInit {
  loading = true;
  micros:Microciclo[] = [];
  microsSel:Microciclo[] = [];
  uploading = false;
  cadoNombre:string;
  cadoDetalle:string;
  errores:any[] = [];

  @Input() mesocicloParam:Mesociclo;

  constructor(public activeModal:NgbActiveModal,
              private categoriaService:CategoriaService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad(){
    try {
      const req = await this.categoriaService.getAllMicros();
      this.micros = req.data;

      if(this.mesocicloParam)
      {
        const req2 = await this.categoriaService.getMicroByMeso(this.mesocicloParam.cadoId);
        this.microsSel = req2.data;
        this.cadoNombre = this.mesocicloParam.cadoNombre;
        this.cadoDetalle = this.mesocicloParam.cadoDetalle;
      }      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregar(obj:Microciclo)
  {
    let find = this.microsSel.find(el => el.catrId == obj.catrId);
    if(find == null)
    {
      this.microsSel.push(obj);
    }
  }

  async remover(obj:Microciclo)
  {
    let ind = this.microsSel.findIndex(el => el.catrId == obj.catrId);
    this.microsSel.splice(ind,1);
  }

  async guardar()
  {
    this.uploading= true;
    try {
      let nuevo = new Mesociclo();
      if(this.mesocicloParam){nuevo.cadoId = this.mesocicloParam.cadoId;}//modificar    
      nuevo.cadoNombre = this.cadoNombre;      
      nuevo.cadoDetalle = this.cadoDetalle;

      for (const iterator of this.microsSel) {
        nuevo.microciclos.push(iterator.catrId);
      }

      const req = await this.categoriaService.postMeso(nuevo);
      this.activeModal.close('creado');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

}
