<div class="paginator mt-2" >
    <div>
        <button class="app-btn small outline" [disabled]="tabla.getAttribute('currentPage') <= 1" (click)="tabla.setAttribute('currentPage',tabla.getAttribute('currentPage')-1)"><fa-icon icon="arrow-left"></fa-icon></button>
        <button class="app-btn small outline" disabled > {{tabla.getAttribute('currentPage')}} / {{tabla.getAttribute('lastPage')}} </button>
        <button class="app-btn small outline" [disabled]="isDisabled()" (click)="tabla.setAttribute('currentPage',n(tabla.getAttribute('currentPage'))+1)"><fa-icon icon="arrow-right"></fa-icon></button>
        <button *ngIf="tabla.dataSet" class="app-btn small outline" (click)="excel()"><fa-icon icon="file-excel"></fa-icon> {{lang.text('excel.export')}}</button>
    
    </div>

    <span *ngIf="tabla.dataSet">{{tabla.dataSet.length}} {{lang.text('pagi.registers')}}</span>
</div>