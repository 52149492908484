import { Video } from "./video";

export class Sesion{
      cacuId:number = 0;
      cacuNombre:string;
      cacuNombreApp:string;

      cacuObj:string;
      cacuComunicacion:string;
      cacuImplementos:string;
      cacuEntradaTiempo:number;
      cacuEntradaDesc:string;
      cacuPrincipalTiempo:number;
      cacuPrincipalDesc:string;
      cacuVueltaTiempo:number;
      cacuVueltaDesc:string;

      /* ViewModel */
      selected:boolean = false;
      
      /* API */
      grupos:any[] = [];
      videos:any[] = [];
      conteos:any[] = [];
      partes:string[] = [];
      esSerie:number[] = [];
}