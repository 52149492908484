import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Curso } from 'src/app/models/campus/curso';
import { Idioma } from 'src/app/models/campus/idioma';
import { CursoService } from 'src/app/services/campus/curso.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-nuevo-curso',
  templateUrl: './nuevo-curso.component.html',
  styleUrls: ['./nuevo-curso.component.css']
})
export class NuevoCursoComponent implements OnInit {


  cursos:Curso;
  errores:any[] = [];
  uploading:boolean= false;
  idioma:Idioma[] = [];

  constructor(public activeModal:NgbActiveModal,
              private modalService:ModalService,
              public lang:LangService,
              private cursoService:CursoService
              ) {

    this.cursos = new Curso();

   }

   @Input() cursParam:Curso;

  async ngOnInit(): Promise<void> {
    if (this.cursParam) {
      this.cursos=this.cursParam;
    }
    console.log('param',this.cursParam);
    
    const req = await this.cursoService.getIdioma();
    this.idioma = req.data
    
    
  }

  async agregar(){
    this.uploading = true;
    if(this.cursParam)
    {
      try {
        const req = await this.cursoService.post(this.cursos);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }else{
      try {
        const req = await this.cursoService.put(this.cursos);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }

    this.uploading=false;
  }
}



