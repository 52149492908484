import { Component, OnInit } from '@angular/core';
import { Curso } from 'src/app/models/campus/curso';
import { User } from 'src/app/models/User';
import { CursoService } from 'src/app/services/campus/curso.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { AuthService } from 'src/app/services/auth-service.service';
import { Utils } from 'src/app/helpers/Utils';
import { CursoDetalles } from 'src/app/models/campus/cursodetalles';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { Documento, DocumentoService } from 'src/app/services/documento.service';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { leccion } from 'src/app/models/campus/leccion';
import { environment } from 'src/environments/environment';
import { PdfMakeWrapper, Txt, Img } from 'pdfmake-wrapper'
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Component({
  selector: 'app-campus-aprobado',
  templateUrl: './campus-aprobado.component.html',
  styleUrls: ['./campus-aprobado.component.css']
})
export class CampusAprobadoComponent implements OnInit {

  loading:boolean = true;
  user:User;
  cursos:Curso[] = [];
  lecciones:CursoDetalles[][]=[];
  docs:Documento[][] = [];
  leccion:leccion[][]=[];
  
  constructor(private modalService:ModalService, private cursoService:CursoService
  , private moduloService:ModuloService,private leccionService:LeccionService,public lang:LangService, private userService:UserService
  ,private authService:AuthService
  ,private docuService:DocumentoService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {      
      const req = await this.userService.me();
      this.user = req.data[0];
      

      const req2 = await this.cursoService.getCursos(this.user?.id!);
      this.cursos = req2.data;

      const req3 = await this.cursoService.getCursoDetalles(this.user?.id!,this.cursos[0].cursId);
      const lecciones = req3.data;
      console.log('lecciones', lecciones)
      
      

      for (const i of Utils.groupBy(lecciones,i => i.moduId) ) {
        this.lecciones.push(i);
        console.log('lecciones', this.lecciones)
        console.log('i[0].leccId', i[0].moduId)
        const req4 = await this.docuService.GetModuloDocumento(i[0].moduId);
        console.log('req4',  req4)
        i[0].documentos = req4.data;
        console.log('docs',  i[0].documentos)
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  

  async createPdf() {
     const user = this.authService.currentUserValue!;
     const fecha = new Date();
     const fecha1 = fecha.toLocaleDateString();
     const pdfDefinition: any = {
      pageOrientation: 'landscape',
      pageSize: 'LETTER',
      content: [
        
         {
          pageOrientation: 'landscape',
          image: await this.getBase64ImageFromURL("/assets/imgbk/certificado/Certificado.png"),
          width: 800,
          absolutePosition: {x: 0, y: 0},
          
        },
        
         {
        text: user.userNombre,
        fontSize: 15,
        italics: 'Open Sans',
        absolutePosition: {x: 400, y: 230},
        },
        {
          text: fecha1,
          fontSize: 15,
          italics: 'Helvetica-Oblique',
          absolutePosition: {x: 440, y: 345},
        },
        {
          text: 'Firma',
          fontSize: 15,
          italics: 'Helvetica-Oblique',
          absolutePosition: {x: 390, y: 430},
        }   
         
        
                 
    ],
    
    
    }
    const pdf = pdfMake.createPdf(pdfDefinition);
    pdf.open();
    console.log(pdfDefinition);
  }

  async generatePDF(){
    const pdf = new PdfMakeWrapper();
    const user = this.authService.currentUserValue!;
    const fecha = new Date();
    const fecha1 = fecha.toLocaleDateString();
    const nombre = user.userNombre + ' ' + user.userApellido
    pdf.pageOrientation('landscape');
     pdf.pageSize('LETTER'); 
    /*  pdf.pageSize({
      width: 590,
      height: 460
  }); */
     pdf.add(
      new Txt(nombre).fontSize(55).absolutePosition(330,200).end
    ); 
    pdf.add(
      new Txt(fecha1).fontSize(29).absolutePosition(425,333).end
    ); 
    /* pdf.add( await new Img('/assets/imgbk/certificado/firma.jpg').build() ); */
    pdf.images({
      picture1: await new Img('/assets/imgbk/certificado/firma.jpg').build(),
      
      
  });
    pdf.background( await new Img('/assets/imgbk/certificado/Certificado.png').width(800).alignment('justify').absolutePosition(-3,-3).build() );

   /*  pdf.images(
      { picture1: await new Img('/assets/img/Certificado.png').build() }
      
    ) */

    pdf.create().open();
  }

  async download(doc:Documento){
    window.open(environment.urlAPI+'documento/descargar/'+doc.docuId+'?token='+this.authService.currentUserValue?.token,'_system');
  } 

  

}
