import { Component, OnInit } from '@angular/core';
import { Grupo } from 'src/app/models/grupo';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { AdministrarGrupoComponent } from '../administrar-grupo/administrar-grupo.component';
import { NuevoGrupoComponent } from '../nuevo-grupo/nuevo-grupo.component';

@Component({
  selector: 'app-grupo',
  templateUrl: './grupo.component.html',
  styleUrls: ['./grupo.component.css']
})
export class GrupoComponent implements OnInit {

  grupos:Grupo[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  constructor(private modalService:ModalService, private grupoService:GrupoService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(NuevoGrupoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async administrar(grupo:Grupo){
    let modalRef = this.modalService.open(AdministrarGrupoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.grupo = grupo;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(g:Grupo)
  {
    let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.grupoService.delete(g.grupoId);
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }



  async onLoad()
  {
    try {
      const req = await this.grupoService.getAll();
      this.grupos = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

}
