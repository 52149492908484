<div class="container view">

    <div class="botones-opciones full ciclos">

        <button class="app-btn" [routerLink]="['/promocion']">{{lang.t('menu.popup')}}</button>
    </div>
    <div class="ciclos-border">
        <div class="botones-opciones mb-3">
            <button class="app-btn" title="{{lang.text('btn.add')}}" (click)="agregarNuevo()"><fa-icon icon="plus"></fa-icon></button>
            <button class="app-btn" title="{{lang.text('btn.actu')}}" (click)="refresh()"><fa-icon icon="retweet"></fa-icon></button>

            



        </div>
        <div  class="table-container">
            <table class="app-table">
                <thead>
                    <th>
                        #
                    </th>
                    <th>
                        {{lang.text('pop.horario')}}
                    </th>
                    <th>
                        {{lang.text('pop.nom_direccion')}}
                    </th>
                    <th>
                        {{lang.text('pop.direccion')}}
                    </th>
                    <th>
                        {{lang.text('pop.fecha_fin')}}
                    </th>
                    <th>
                        {{lang.text('pop.activo')}}
                    </th>
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let pop of this.popUp;index as idx">
                         <td>{{idx+1}}</td>
                        <td>{{pop.horario}}</td>
                        <td>{{pop.nombreDireccion}}</td>
                        <td>{{pop.direccion}}</td>
                        <td>{{pop.fechaFin | date :'d/M/yy'}}</td>
                        <td *ngIf="this.pop.activo == 1">{{lang.text('pop.si')}}</td>
                        <td *ngIf="this.pop.activo == 0">{{lang.text('pop.no')}}</td>

                        <td>
                             <button class="app-btn small" title="{{lang.text('lecc.modificar')}}" (click)="modificar(pop)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                            
                            
                             <button class="app-btn small red" (click)="eliminar(pop)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt" ></fa-icon></button> 
                             <button class="app-btn small red" (click)="copyText(pop.codigo)" title="{{lang.t('btn.copiarLink')}}"><fa-icon icon="copy" ></fa-icon></button> 
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
          
        <!-- <div  class="loader">

        </div>
         -->
    </div>
</div>
