import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Sesion } from '../models/sesion';

import { Grupo } from '../models/grupo';
import { Evaluacion, PeriodoEvaluacion } from '../models/evaluacion';




@Injectable({
  providedIn: 'root'
})

export class EvaluacionService {

  constructor(private http:HttpClient) {
  }

  post(e:Evaluacion){
    return this.http.post<ApiResponse<Evaluacion>>(`${environment.urlAPI}evaluacion`,e).toPromise();
  }


  crearSemestre(e:PeriodoEvaluacion){
    return this.http.post<ApiResponse<Evaluacion>>(`${environment.urlAPI}evaluacion/crear-semestre`,e).toPromise();
  }
  
  getPeriodoSesiones(user_id:number)
  {
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}evaluacion/actual/${user_id}/sesiones`).toPromise();
  }

}
