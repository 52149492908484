<div class="container-md" style="max-width: 960px;">
    <div class="botones-opciones full ciclos">
        <button class="app-btn " [routerLink]="['/account']" >{{lang.text('acco.h1')}}</button>
        <button class="app-btn" [routerLink]="['/calendar']" >{{lang.text('acco.calendar')}}</button>
        <button class="app-btn " [routerLink]="['/prices']" >{{lang.text('acco.payments')}}</button>
        <button class="app-btn active" [routerLink]="['/sells']" >{{lang.text('acco.sells')}}</button>

    </div>
    <div class="header g-border p-3">
        <h2>{{lang.text('acco.header_ventas')}}</h2>

        <ng-container *ngIf="this.user && !user.stripeConfirmado">
            <p>{{lang.text('acco.stripe_info')}}</p>

            <button class="app-btn" (click)="linkear()">{{lang.text('acco.stripe_button')}}</button>
        </ng-container>

        <ng-container *ngIf="this.user && user.stripeConfirmado && user.stripeAccountUrl">
            <p>{{lang.text('acco.stripe_info2')}}</p>

            <button class="app-btn" (click)="update()">{{lang.text('acco.stripe_button_re')}}</button>
        </ng-container>
        

        <div class="table-container" style="margin-top: 16px;max-height: 50vh;">
            <table #tabla class="app-table ktable" kTable [pageSize]="15">
                <thead>
                    <th>

                    </th>
                    <th>
                        {{lang.text('fecha')}}
                    </th>
                    <th>
                        {{lang.text('estado')}}
                    </th>
                    <th>
                        {{lang.text('valor_venta')}}
                    </th>
                    <th>
                        {{lang.text('valor_apagar')}}
                    </th>
                    <th>
                        {{lang.text('valor_pagado')}}
                    </th>
                    <th>
                        {{lang.text('alumno')}}
                    </th>
                    
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of ventas">
                        <td>
                            #{{i.compId}}
                        </td>
                        <td>
                            {{i.compFh |date:'medium'}}
                        </td>
                        <td>
                            {{lang.text(i.compraEstado)}}
                        </td>
                        <td>
                            $ {{i.compValor / 100 | currency:'usd'}} 
                        </td>

                        <td>
                            $ {{i.totalPagar / 100 | currency:'usd'}} 
                        </td>
                        <td>
                            $ {{i.totalAbonos / 100 | currency:'usd'}} 
                        </td>
                        <td>
                            <p class="m-0">{{i.alumnoNombre}}</p>
                            <small><a href="mailto:{{i.alumnoEmail}}">{{i.alumnoEmail}}</a></small>
                            
                        </td>
                        
                        <td>
                            <button *ngIf="i.totalPagar > 0" class="app-btn small"  routerLink="/ver-venta/{{i.compId}}" ><fa-icon icon="arrow-right"></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
        
        <app-paginator [tableRef]="tabla">
        </app-paginator>
        
    </div>    
</div>
