import { Location } from '@angular/common';
import { Component } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { NavService } from 'src/app/services/nav.service';
import { PushStatus, WebSocketService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-logs-campanas',
  templateUrl: './logs-campanas.component.html',
  styleUrls: ['./logs-campanas.component.css']
})
export class LogsCampanasComponent {
  dataSet:any[] = [];
  status:PushStatus = {
    notificacionesPendientes: 0,
    lastUpdate: new Date(),
    notificacionesEnviadas: 0,
    mailPendientes: 0
  };
  constructor(public lang:LangService,private navSrv:NavService,private wsSrv:WebSocketService) { }

  ngOnInit(): void {
    this.load();
    this.wsSrv.onPushStatusUpdated.subscribe(i => {
      this.status = i;
    });
  }

  async load(){

  }
  
  async back(){
    this.navSrv.back();
  }
}
