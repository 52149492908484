<div class="container view">
    <h3>{{lang.text('total_users')}} {{total}}</h3>
    <div *ngIf="!this.loading" class="table-container">
        <table class="app-table">
              <thead>
                    <th>
                          #
                    </th>
                    <th>
                          {{lang.text('nombre')}}
                    </th>
                    <th>
                          {{lang.text('correo')}}
                    </th>
                    <th>
                        {{lang.text('tipo')}}
                    </th>
              </thead>
              <tbody>
                <tr *ngFor="let i of usuarios">
                    <td>
                        {{i.id}}
                    </td>

                    <td>
                        {{i.userNombre}} {{i.userApellido}}
                    </td>
                    <td>
                        {{i.userEmail}}
                    </td>
                    <td>
                        {{lang.text(i.rolDescripcion)}}
                    </td>
                </tr>
              </tbody>
        </table>

    </div>
    <div class="mt-2" *ngIf="!this.loading">
        <button class="app-btn small outline" (click)="prevPage()"> < </button>
        <button class="app-btn small outline" disabled > {{pagina}} / {{lastPage}} </button>
        <button class="app-btn small outline"  (click)="nextPage()"> > </button>
    </div>
    <div *ngIf="this.loading" class="loader">

    </div>
    <h6 *ngIf="this.usuarios.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>

</div>
