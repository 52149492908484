import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

import { leccion } from '../../../models/campus/leccion';
@Component({
  selector: 'app-nuevo-leccion',
  templateUrl: './nuevo-leccion.component.html',
  styleUrls: ['./nuevo-leccion.component.css']
})
export class NuevoLeccionComponent implements OnInit {

  leccion:leccion;
  errores:any[] = [];
  uploading:boolean= false;

  constructor(public activeModal:NgbActiveModal,
              private quizService:LeccionService,
              private modalService:ModalService,
              public lang:LangService) {
    this.leccion = new leccion();
   }

   @Input() leccionParam:leccion;

  async ngOnInit(): Promise<void> {
    if (this.leccionParam) {
      this.leccion=this.leccionParam;
    }
  }

  async agregar(){
    this.uploading = true;
    if(this.leccionParam)
    {
      try {
        const req = await this.quizService.post(this.leccion);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }else{
      try {
        const req = await this.quizService.put(this.leccion);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }

    this.uploading=false;
  }
}
