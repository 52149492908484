import { environment } from 'src/environments/environment';
import { Caracteristica } from './caracteristica';

export class Video
{
    videoId:number = 0;
    videoDescripcion:string;
    videoNombre:string;
    videoTiempo:string;
    videoPreview:string;
    VideogifPreview:string;
    videoPremium:number;
    Archivo: any;
    tipoVideoId: number;
    videoDescEjercicio:string;
    paseId?:string;

    /* ViewModel */
    listaCaracteristicas:Caracteristica[] = [];
    loadingCaracteristicas = false;
    selected:boolean;
    hovered: boolean;
    isdragged: boolean;
    

    mostrarContador = false;
    esSerie = false;

    /*API*/
    categorias:any[] = [];
    videoProcesado:number;

    
    
    

    public get previewUrl():string{
        return `${environment.urlAPI}user/imagenes/${this.videoPreview}`;
    }

}