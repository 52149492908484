import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-resetear-password',
  templateUrl: './resetear-password.component.html',
  styleUrls: ['./resetear-password.component.css']
})
export class ResetearPasswordComponent implements OnInit {


  loading = true;
  uploading = false;
  step = 1;
  code:string = '';
  pass:string = '';
  pass2:string = '';
  email:string = '';

  constructor(private authService:AuthService,private userService:UserService,public lang:LangService,private helper:ModalService,private router:Router) { }

  ngOnInit(): void {
    this.load();
  }


  async load(){
    this.loading = true;

    try {
      
    } catch (error) {
      
    }

    this.loading = false;
  }


  async goStep2(){
    const loader = await this.helper.showLoader();
    try {
      const req = await this.userService.sendPasswordReset(this.email);
      this.step = 2;
    } catch (error) {
      
    }
    loader.dismiss();
  }


  async resetPassword(){
    if(this.pass.length < 6){
      this.helper.showError('pr.password_no_valida','aceptar');
      return;
    }
    if(this.pass != this.pass2){
      this.helper.showError('pr.password_no_confirmada','aceptar');
      return;
    }
    const loader = await this.helper.showLoader();
    try {
      const req = await this.userService.passwordReset({userEmail:this.email,code:this.code,newPassword:this.pass,newPasswordConfirm:this.pass2});
      await loader.dismiss();
      await this.helper.showError('pr.password_reset_sucess','aceptar');
      this.router.navigateByUrl('/login');
    } catch (error) {
      await loader.dismiss();
      await this.helper.showError(error.message,'aceptar');
    }
  }
}
