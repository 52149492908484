import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { AlumnoCrearEvaluacionComponent } from './alumno-crear-evaluacion/alumno-crear-evaluacion.component';
import { AlumnoDetalleComponent } from './alumno-detalle/alumno-detalle.component';
import { AlumnoEditarComponent } from './alumno-editar/alumno-editar.component';
import { AlumnoListarDocumentosComponent } from './alumno-listar-documentos/alumno-listar-documentos.component';
import { AlumnoNuevoComponent } from './alumno-nuevo/alumno-nuevo.component';
import { SubirExcelComponent } from './subir-excel/subir-excel.component';

@Component({
  selector: 'app-alumnos',
  templateUrl: './alumnos.component.html',
  styleUrls: ['./alumnos.component.css']
})
export class AlumnosComponent implements OnInit {


  loading = true;
  users:User[] = [];

  solicitudes: any[] = [];
  seleccionados = 0;

  
  
  constructor(private userService:UserService,public auth:AuthService,private modalService:ModalService,public lang:LangService) { }

  async ngOnInit() {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad()
  {
    try {
      const req = await this.userService.getByRol(3);
      this.users = req.data;
      console.log(this.users)
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.updateMasivos();
  }

  async checkChange(event){
    const check:boolean = event.target.checked;
    for (const i of this.users) {
      i.selected = check;
    }
    this.updateMasivos();
  }

  async eliminarMultiple(){
    let confirmar = await this.modalService.confirm('confirmar_quitar_alumnos','Si, borralo','No');
    if(confirmar)
    {
      try {
        const loader = await this.modalService.showLoader();
        for (const i of this.users.filter(i => i.selected)) {
          const req = await this.userService.delete(i);
        }
        loader.dismiss();
        await this.onLoad();
        this.updateMasivos();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async crearEvaMultiple(){
    const users = this.users.filter(i => i.selected);
    for (const i of users) {
      if(i.evaluacionesActivas > 0){
        this.modalService.showError('al.error_alumno_con_eva','ok')
        return ;
      }
    }
    let modalRef = this.modalService.open(AlumnoCrearEvaluacionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.users = users;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async updateMasivos(){
    this.seleccionados = this.users.filter(i => i.selected).length;
  }

  async cargarSolicitudes(){
    const req2 = await this.userService.getSolicitudes();
    this.solicitudes = req2.data;
  }

  async nuevo()
  {
    let modalRef = this.modalService.open(AlumnoNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async verDocs(user:User)
  {
    let modalRef = this.modalService.open(AlumnoListarDocumentosComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.user = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async seleccionarFlojos(){
    for (const i of this.users) {
      if(i.evaluacionesActivas == 0){
        i.selected = true;
      }
    }
    this.updateMasivos();
  }

  async excel()
  {
    let modalRef = this.modalService.open(SubirExcelComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async verSesiones(user:User)
  {
    let modalRef = this.modalService.open(AlumnoDetalleComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.user = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async modificar(user:User){
    
    let modalRef = this.modalService.open(AlumnoNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(user:User)
  {
    let confirmar = await this.modalService.confirm('confirmar_quitar_alumno','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.userService.delete(user);
        await this.onLoad();
        this.updateMasivos();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async refresh()
  {
    this.loading = true;
    await this.onLoad();
    this.loading = false;
  }

  async crearEva(user:User)
  {
    let modalRef = this.modalService.open(AlumnoCrearEvaluacionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async evaluar(user:User)
  {
    let modalRef = this.modalService.open(AlumnoEditarComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  

  async buscar(event)
  {
    var value= event.srcElement.value;
    if(!value) return;
    
    this.loading = true;
    try {
      const req = await this.userService.buscar(3,value);
      this.users = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.loading = false;
  }

}
