


<div class="modal-alert">
    <div class="modal-body left">

        <input *ngIf="showSearch" class="app-input" type="search" placeholder="{{lang.t('buscar')}}" [(ngModel)]="searchStr" name="buscar" (keyup)="filter()">
            <div class="app-lista-checkbox">
                  <div class="app-checkbox" *ngFor="let i of itemsFiltered" (click)="select(i)">
                    <label>{{i.name}}</label>
                  </div>
            </div>
    </div>
</div>
