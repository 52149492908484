import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { quiz } from 'src/app/models/campus/quiz';
import { ModalService } from 'src/app/services/modal-service';
import { PreguntaService } from '../../../services/campus/pregunta.service';
import { QuizService } from '../../../services/campus/quiz.service';
import { Pregunta } from '../../../models/campus/Pregunta';
import { quizPreguntas } from '../../../models/campus/quiz';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-quizes-editar',
  templateUrl: './quizes-editar.component.html',
  styleUrls: ['./quizes-editar.component.css']
})
export class QuizesEditarComponent implements OnInit {

  @Input() quizParam:quiz;

  //grupo:Grupo;
  preguntas:Pregunta[] = [];
  preguntasSel:Pregunta[] = [];
  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];
/*   filtro:Caracteristica = null; 
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public preguntaService:PreguntaService,
              public quizService:QuizService, 
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
    console.log(this.quizParam);
  }

  async onLoad()
  {
    try {

      //const req = await this.videoService.ge
      const request = await this.preguntaService.get();
      this.preguntas = request.data;
      const request2 = await this.quizService.getQuizPregunta(this.quizParam.quizId);
      this.preguntasSel = request2.data;
      
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    }
  }

  async agregar(pregunta:Pregunta)
  {
    let find = this.preguntasSel.find(el => el.pregId == pregunta.pregId);
    if(!find)
    {
      this.preguntasSel.push(pregunta);
    }
  }

/*   async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    
    this.loadingVideos = false;
  } */

  async remover(pregunta:Pregunta)
  {
    let p = this.preguntasSel.findIndex(el => el.pregId == pregunta.pregId);
    this.preguntasSel.splice(p,1);
  }

  async guardar(){
    this.uploading= true;
     try {
      let v:quizPreguntas = new quizPreguntas();
      v.quizId=this.quizParam.quizId;
      v.preguntas = [];
      for (const iterator of this.preguntasSel) {
        v.preguntas.push(iterator.pregId);
      }
      const req = await this.quizService.postQuizPregunta(v);
      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    } 
    this.uploading = false;
  }

}
