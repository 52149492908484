

<div class="container view">
    <div  *ngIf="tabMode != 'faltas'">

        <div class="row">
            <div class="col-sm-6">
                <div class="player-info">
                    <img [src]="alumno?.userImagen?? './assets/account.png'" alt="player-pic" >
                    <div class="datos">
                        <div class="data-row">
                            <span class="material-symbols-rounded">badge</span>
                            <span *ngIf="alumno">{{alumno.userNombre + ' ' + alumno.userApellido}}</span>
                            <span *ngIf="!alumno">--- ---</span>
                        </div>
                        <div class="data-row">
                            <span class="material-symbols-rounded">mail</span>
                            <span>{{alumno?.userEmail ?? '---@---'}}</span>
                        </div>
                        <div class="data-row">
                            <span class="material-symbols-rounded">call</span>
                            <span>{{alumno?.userTelefono ?? '---- ----'}}</span>
                        </div>
                    </div>
                    <div></div>
                    <button  class="app-btn small btn-change-al" (click)="selectAlumno.click()">
                        <span class="material-symbols-rounded">manage_accounts</span>
                        {{lang.text('seleccionar_alumno')}}</button>

                </div>
            </div>
            <div class="col-sm-6">
                <div>
                    <label class="app-label">{{lang.text('evaluacion_seleccionada')}}</label>
                    <select [disabled]="!alumno" [(ngModel)]="evaluacion" (change)="changeEv($event)" name="evaluacion" class="app-combo">
                            <option value="null" disabled>{{lang.text('seleccionar_evaluacion')}}</option>
                            <option *ngFor="let e of evaluaciones" [ngValue]="e">{{lang.text('evaluacion')}} {{e.caunNombre}} #{{e.evaluacionNumero}}</option>
                    </select>
                </div>
                <div class="acciones-alumno">
                    <button [disabled]="!alumno" (click)="helper.open(suspensionscontent)" class="app-btn">
                        <span class="material-symbols-rounded">autopause</span>
                        {{lang.text('suspensions')}}
                    </button>

                    <button [disabled]="!alumno" class="app-btn" (click)="verDocs(alumno)">
                        <span class="material-symbols-rounded">article</span>
                        {{lang.t('al.ver_doc')}}
                    </button>
                </div>
            </div>
        </div>
        <app-search-select #selectAlumno style="display: none;" [(model)]="alumno" (modelChange)="changeAlumno(null)" [items]="alumnosBox">
                    
        </app-search-select>


    </div>
    <ng-container *ngIf="alumno!=null && evaluacion != null">
        <div class="botones-opciones mb-3 mt-3">
            <button class="app-btn" [class.active]="tabMode=='alumno'" (click)="changeMode('alumno')">{{lang.text('proximas_clases')}}</button>
            <button class="app-btn" [class.active]="tabMode=='hist'" (click)="changeMode('hist')">{{lang.text('historico')}}</button>
            <button class="app-btn" [class.active]="tabMode=='faltas'" (click)="changeMode('faltas')">{{lang.text('avisos_inasistencias')}}</button>
    
        </div>
    
        <div *ngIf="this.tableLoading" class="loader">
        </div>
        <div class="table-container" style="height: calc(100vh - 360px);">
            <table class="app-table">
                <thead>
    
                      <ng-container *ngIf="tabMode == 'faltas'">
                          <th>
                              {{lang.text('alumno')}}
                          </th>
                          <th>
                              {{lang.text('rut')}}
                          </th>
                      </ng-container>
    
                      <th>
                        {{lang.text('clase')}}
                      </th>
                      <th>
                          {{lang.text('fecha_programada')}}
                      </th>
                      <th *ngIf="tabMode=='hist'">
                        {{lang.text('fecha_real')}}
                      </th>
                      <th>
                          {{lang.text('estado')}}
                      </th>
                      <th>
                          {{lang.text('sesion')}}
                      </th>
                      <th >
                        {{lang.text('fecha_avisa')}}
                      </th>
                      <th>
                          {{lang.text('observacion')}}
                      </th>
                      <th *ngIf="tabMode=='hist'">
                        Coach
                    </th>
                      <th></th>
                </thead>
                <tbody>
                    <ng-container *ngFor="let i of clases; let idx = index">
                      <tr *ngIf="idx < 5 || tabMode != 'alumno'" >
                            <ng-container *ngIf="tabMode == 'faltas'">
                                <td>
                                    {{i.alumnoNombre}} {{i.alumnoApellido}}
                                </td>
                                <td>
                                    {{i.alumnoRut}}
                                </td>
                            </ng-container>
                            <td>
                                {{i.clasNumero}}
                            </td>
                            <td>
                                {{i.clasFhProgramada}}
                            </td>
                            <td *ngIf="tabMode=='hist'">
                                {{i.clasFhReal}}
                            </td>
                            <td>
    
                                {{i.clesNombre}}
                            </td>
                            <td>
    
                                {{i.cacuNombre}}
    
                            </td>
                            <td>
                                {{i.clasFhAvisoInasistencia}}
                            </td>
                            <td>
                                {{i.clasObservacion}}
                            </td>
                            <td *ngIf="tabMode=='hist'">
                                {{i.profesorNombre}}
                            </td>
                            <td>
                                <button class="app-btn icon-only" title="Información" *ngIf="tabMode=='alumno'" (click)="verInfo(i)"><fa-icon icon="info"></fa-icon></button>
                                <button class="app-btn icon-only" title="Marcar asistencia" *ngIf="tabMode=='alumno'" (click)="marcarAsistidoRapido(i)" title="{{lang.t('btn.marcar_asistencia')}}"><fa-icon icon="check"></fa-icon></button>
                                <button class="app-btn icon-only" title="Ver Videos de la sesión" (click)="verVideos(i)"><fa-icon icon="video"></fa-icon></button>
                            </td>
    
                      </tr>
                    </ng-container>
                </tbody>
          </table>
        </div>
    </ng-container>

</div>


<ng-template #suspensionscontent let-modal>
	<div class="modal-header ">
		<h4 class="modal-title" id="modal-basic-title"><h6>{{lang.text('suspensiones_clases')}}</h6></h4>
		<button  style="color:white;" type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
	</div>
	<div class="modal-body ">
		<div class="table-container-feo" >
            <table class="mini app-table">
                <thead>
                    <th>{{lang.text('fecha_inicio')}}</th>
                    <th>{{lang.text('fecha_fin')}}</th>
                    <th>{{lang.text('observacion')}}</th>
                    <th></th>
                </thead>
                <tbody>
                    <tr *ngFor="let s of suspensiones">
                        <td>
                            {{s.sucaFechaInicio}}
                        </td>
                        <td>{{s.sucaFechaFin}}</td>
                        <td>{{s.sucaObservacion}}</td>
                        <td>
                            <button class="app-btn" (click)="eliminarSuspension(s)"><fa-icon icon="trash-alt"></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
	</div>
	<div class="modal-footer">
        <button *ngIf="alumno" class="app-btn" style="margin-bottom: 16px;" (click)="agregarSuspension()">{{lang.text('agregar_suspension')}}</button>
	</div>
</ng-template>