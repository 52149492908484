import { Component, OnInit } from '@angular/core';
import { Clase } from 'src/app/models/clase';
import { CustomSelectOption } from 'src/app/models/customselectoption';
import { Evaluacion } from 'src/app/models/evaluacion';
import { User } from 'src/app/models/User';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ControlClaseService } from 'src/app/services/controlclase';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { SuspensionClases, SuspensionClasesService } from 'src/app/services/suspension-clases.service';
import { UserService } from 'src/app/services/user.service';
import { AgregarSuspensionClasesComponent } from './agregar-suspension-clases/agregar-suspension-clases.component';
import { MarcarAsistenciaComponent } from './marcar-asistencia/marcar-asistencia.component';
import { VerInfoComponent } from './ver-info/ver-info.component';
import { VideosClaseComponent } from './videos-clase/videos-clase.component';
import { AlumnoListarDocumentosComponent } from '../alumnos/alumno-listar-documentos/alumno-listar-documentos.component';

@Component({
  selector: 'app-control-asistencia',
  templateUrl: './control-asistencia.component.html',
  styleUrls: ['./control-asistencia.component.scss']
})
export class ControlAsistenciaComponent implements OnInit {


  alumno:User = null;
  alumnos:User[] = [];
  alumnosBox:CustomSelectOption[] = [];
  loading = true;
  evaluacion:Evaluacion = null;
  evaluaciones:Evaluacion[] = [];
  clases:Clase[] = [];
  searchStr:string='';
  boxShow= false;
  suspensiones:SuspensionClases[] = [];

  tableLoading = false;

  tabMode:'alumno'|'faltas'|'hist' = 'alumno';

  constructor(private userSrv:UserService
    ,private catSrv:CategoriaService
    ,private evSrv:EvaluacionService
    ,public helper:ModalService
    ,private claseSrv:ControlClaseService,
    private suspensionService:SuspensionClasesService,
    public lang:LangService) { }

  ngOnInit(): void {
    this.refresh();
  }

  async refresh(){
    this.loading= true;
    this.alumno = null;
    this.clases = [];
    await this.load();
    this.loading = false;
  }


  async verDocs(user:User)
  {
    let modalRef = this.helper.open(AlumnoListarDocumentosComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.user = user;
    try {
      await modalRef.result;
    } catch (error) {
      
    }
  }

  async load(){
    const loader = await this.helper.showLoader();
    try {
      const request = await this.userSrv.getByRol(3);
      this.alumnos = request.data;
      this.alumnosBox = this.alumnos.map<CustomSelectOption>(i => {return {name:i.userNombre+' '+i.userApellido,value:i}});
      loader.dismiss();
    } catch (error) {
      loader.dismiss();
      this.helper.showError(error.message,'aceptar');
    }
  }

  async changeAlumno(event){
    if(this.alumno != null)
    {
      const loader = await this.helper.showLoader();
      try {
        
        const req = await this.userSrv.getEvaluaciones(this.alumno.id);
        this.evaluacion = null;
        this.evaluaciones = req.data;
        if(this.evaluaciones.length > 0){
          this.evaluacion = this.evaluaciones[0];
          this.changeEv(null);
        }
        const req2 = await this.suspensionService.getAll(this.alumno.id);
        this.suspensiones = req2.data;
        loader.dismiss();
      } catch (error) {
        loader.dismiss();
        this.helper.showError(error.message,'aceptar');
      }
    }
  }


  

  async eliminarSuspension(suspension:SuspensionClases)
  {
    if(await this.helper.confirm('confirmar_eliminacion_suspecion','eliminar','cancelar')){
      try {
        const req = await this.suspensionService.delete(suspension.sucaId);
        const req2 = await this.suspensionService.getAll(this.alumno.id);
        this.suspensiones = req2.data;
      } catch (error) {
        this.helper.showError(error.message,'Ok');
      }
    }
  }

  async changeEv(event){
    this.tableLoading = true;
    this.clases = [];
    if(this.evaluacion != null && this.alumno != null)
    {
      try {
        if(this.tabMode == 'alumno'){
          const req = await this.claseSrv.getClases(this.alumno.id,this.evaluacion.evaluacionId);
          this.clases = req.data;
        }
        else if(this.tabMode == 'hist'){
          const req = await this.claseSrv.getClasesHist(this.alumno.id,this.evaluacion.evaluacionId);
          this.clases = req.data;
        }
        
      } catch (error) {
        this.helper.showError(error.message,'aceptar');
      }
    }
    this.tableLoading = false;
  }

  async changeMode(mode){
    this.tabMode = mode;
    
    if(this.tabMode == 'faltas')
    {
      this.loadInasistencias();
    }
    else{
      this.changeEv(null);
    }
  }

  async loadInasistencias(){
    this.tableLoading = true;
    try {
      const req = await this.claseSrv.getClasesInasistencias();
      this.clases = req.data;
    } catch (error) {
      this.helper.showError(error.message,'aceptar');
    }
    this.tableLoading = false;
  }

  async habilitarVirtual(clase:Clase)
  {
    const resp = await this.helper.confirm('confirmar_habilitar_clase','si','cancelar');
    if(resp)
    {
      try {
        const req = await this.claseSrv.postCambiarEstado(clase.clasId,2,new Date(),'habilitado_virtual');
        await this.changeEv(null);
      } catch (error) {
        this.helper.showError(error.message,'aceptar');
      }
    }
  }


  async agregarSuspension(){

    let modalRef = this.helper.open(AgregarSuspensionClasesComponent,{windowClass:'app-modal top-modal ',size:'md',backdropClass:'no-backdrop'});
    modalRef.componentInstance.alumno = this.alumno;
    try {
      await modalRef.result;
    } catch (error) {
      
    }
    
  }


  async marcarAsistidoRapido(clase:Clase){
    const loader = await this.helper.showLoader();
    try {
      const req = await this.claseSrv.postCambiarEstado(clase.clasId,4,new Date(),'');
      loader.dismiss();
      this.changeEv(null);
    } catch (error) {
      loader.dismiss();
      this.helper.showError(error.message,'Ok');
    }
  }

  async marcarAsistido(clase:Clase){
    let modalRef = this.helper.open(MarcarAsistenciaComponent,{windowClass:'app-modal top-modal ',size:'md',backdropClass:'no-backdrop'});
    modalRef.componentInstance.clase = clase;
    try {
      await modalRef.result;
      await this.changeEv(null);
    } catch (error) {
      
    }
  }

  async verVideos(clase:Clase){
    let modalRef = this.helper.open(VideosClaseComponent,{windowClass:'app-modal top-modal ',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.clase = clase;
    try {
      await modalRef.result;
    } catch (error) {
      
    }
  }

  async verInfo(clase:Clase){
    let modalRef = this.helper.open(VerInfoComponent,{windowClass:'app-modal top-modal ',size:'xl',backdropClass:'no-backdrop'});
    modalRef.componentInstance.clase = clase;
    try {
      await modalRef.result;
    } catch (error) {
      
    }
  }

  async showAlumnos(){
    this.boxShow = !this.boxShow;
  }

  

  async selectAlumno(alumno:User)
  {
    this.boxShow = false;
    this.searchStr = '';
    this.alumno = alumno;
    this.changeAlumno(null)
  }
}
