import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Clase } from '../models/clase';





@Injectable({
  providedIn: 'root'
})

export class SuspensionClasesService {

  constructor(private http:HttpClient) {
  }

  public async getAll(idAlumno:number)
  {
    return this.http.get<ApiResponse<SuspensionClases>>(`${environment.urlAPI}suspensionclase/${idAlumno}`).toPromise();
  }

  public async post(alumnoId:number,razon:string,fechaini:string,fechafin:string)
  {
    const body = {
      alumnoId:alumnoId,
      sucaObservacion:razon,
      sucaFechaInicio:fechaini,
      sucaFechaFin:fechafin
    }
    return this.http.post<ApiResponse<SuspensionClases>>(`${environment.urlAPI}suspensionclase`,body).toPromise();
  }


  public async delete(id:number)
  {
    return this.http.post<ApiResponse<SuspensionClases>>(`${environment.urlAPI}suspensionclase/eliminar/${id}`,{}).toPromise();
  }
}

export interface SuspensionClases{
  sucaId:number,
  sucaObservacion:string,
  sucaFechaInicio:string,
  sucaFechaFin:string,
  alumnoId:number
} 
