import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from 'src/app/models/video';
import { AuthService } from 'src/app/services/auth-service.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.css']
})
export class VideoPlayerComponent implements OnInit {

  @Input() video:Video;
  token:string;
  videoUrl:string;
  @ViewChild("videoEl") videoElement: ElementRef;
  playing = false;
  videoPlayer:any;

  progressBar:number = 0;

  constructor(public activeModal:NgbActiveModal,private authService:AuthService) { }

  async ngOnInit(): Promise<void> {
    
  }

  ngAfterViewInit()
  {
    this.videoPlayer = this.videoElement.nativeElement;
    this.videoPlayer.addEventListener('timeupdate', (x) => {this.update(x);});
    this.setUpPlayer();
  }


  async update(event){
    const video = event.target;
    const progress = video.currentTime * 100 / video.duration;
    this.progressBar = progress;
  }

  async setUpPlayer()
  {
    this.token = this.authService.currentUserValue.token;
    this.videoUrl = environment.urlAPI+'video/'+this.video.videoId+'/video/'+this.token;
    
  }

  async playPause()
  {
    if (this.videoPlayer.paused) 
      this.videoPlayer.play(); 
    else 
      this.videoPlayer.pause(); 
  }
}
