import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Microciclo } from 'src/app/models/microciclo';
import { Sesion } from 'src/app/models/sesion';
import { CategoriaService } from 'src/app/services/categoria.service';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-microciclo-nuevo',
  templateUrl: './microciclo-nuevo.component.html',
  styleUrls: ['./microciclo-nuevo.component.css']
})
export class MicrocicloNuevoComponent implements OnInit {

  loading = true;
  sesiones:Sesion[] = [];
  allSesiones:Sesion[] = [];
  sesionesSel:Sesion[] = [];
  uploading = false;
  catrNombre:string;
  catrNombreApp:string;
  errores:any[] = [];

  searchStr = '';

  @Input() microcicloParam:Microciclo;

  constructor(public activeModal:NgbActiveModal,
              private categoriaService:CategoriaService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad(){
    try {
      const req = await this.categoriaService.getAllSesion();
      this.allSesiones = req.data;
      this.sesiones = req.data;

      if(this.microcicloParam)
      {
        const req2 = await this.categoriaService.getSesionByMicro(this.microcicloParam.catrId);
        this.sesionesSel = req2.data;
        this.catrNombre = this.microcicloParam.catrNombre;
        this.catrNombreApp = this.microcicloParam.catrNombreApp;
      }      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregar(obj:Sesion)
  {
    let find = this.sesionesSel.find(el => el.cacuId == obj.cacuId);
    if(find == null)
    {
      this.sesionesSel.push(obj);
    }
  }

  async remover(obj:Sesion)
  {
    let ind = this.sesionesSel.findIndex(el => el.cacuId == obj.cacuId);
    this.sesionesSel.splice(ind,1);
  }

  async guardar()
  {
    this.uploading= true;
    try {
      let nuevo = new Microciclo();
      if(this.microcicloParam){nuevo.catrId = this.microcicloParam.catrId;}//modificar    
      nuevo.catrNombre = this.catrNombre;
      nuevo.catrNombreApp = this.catrNombreApp;

      for (const iterator of this.sesionesSel) {
        nuevo.sesiones.push(iterator.cacuId);
      }

      const req = await this.categoriaService.postMicro(nuevo);
      this.activeModal.close('creado');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }


  async filter(){
    const str= this.searchStr.toLowerCase();
    if(this.searchStr!= null && this.searchStr.length > 0){
      this.sesiones = this.allSesiones.filter(
        i => i.cacuNombre.toLowerCase().includes(str) || i.cacuNombreApp.toLowerCase().includes(str)
      );
    }
    else{
      this.sesiones = this.allSesiones;
    }
  }
}
