import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Suscripcion } from 'src/app/models/suscripcion';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { WebService } from 'src/app/services/web.service';
import { environment } from 'src/environments/environment';
declare var MercadoPago : any;

@Component({
  selector: 'app-pago-mercadopago',
  templateUrl: './pago-mercadopago.component.html',
  styleUrls: ['./pago-mercadopago.component.css']
})
export class PagoMercadopagoComponent implements OnInit {

  user:User|null = null;
  card:CardModel = {holderName:'',number:'',expMonth:'',expYear:'',cvc:''};
  uploading = false;

  email:string;


  planId:number;
  planes:Suscripcion[] = [];

  showForm = false;

  numberFormatted:string;
  esperandoPago = false;
  mp:any;

  docTypes:DocType[] = [];

  constructor(private webService:WebService,public lang:LangService,private authService:AuthService,private modalService:ModalService,private userService:UserService,private router:Router) { }

  ngOnInit(): void {
    
    
    this.uploading = false;
    this.loadPlanes();
    this.mp = new MercadoPago(environment.mercadoPagoToken);
    const user = this.authService.currentUserValue;
    if(user.rolId != 4){
      this.router.navigateByUrl('/inicio');
    }
  }

  async loadPlanes(){
    
    try {
      const req = await this.webService.planTypes();
      const req2 = await this.userService.getPagos();

      this.planId = req.data[0].suscId;

      this.docTypes = await this.mp.getIdentificationTypes();
      
      this.card.docType = this.docTypes[0].id;

      if(req2.data.length > 0){
        if(req2.data[0].repaVigente){
          this.router.navigateByUrl("/");
        }
        else{
          this.showForm = true;
        }

        

      }
      else{
        this.showForm = true;
      }

      this.planes = req.data;
    } catch (error) {
      this.modalService.showError('error_carga_form_pago','aceptar');
      console.error(error);
      this.router.navigateByUrl('/');
    }
  }



  async sendRegister(token:any){
    try {
      const request = await this.webService.payRegister({
        email:this.email,
        cardToken:token.token,
        planId:this.planId
      });

      await this.modalService.showError(request.message,'Ok');
      if(request.redirect == 'campus'){
        location.href="https://campus.golazoft.com";
      }else{
        this.router.navigateByUrl('/inicio');
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }

  }

  async suscripcionPagadaCorreo(){
    const loader = await this.modalService.showLoader()
      try {
        const req = await this.webService.enviarCorreoSuscripcionPagada(0);
        loader.dismiss();
        
      } catch (error) {
        loader.dismiss();
        this.modalService.showError(error.message,'Ok')
      }
  }

  async pagar(){

    if(this.email==null || !this.email.includes('@')){
      await this.modalService.showError('correo_invalido','aceptar');
      return;
    }

    if(this.card.holderName==null || this.card.holderName.length < 1
      || this.card.number==null || this.card.number.length < 1
      || this.card.cvc==null || this.card.cvc.length < 1
      || this.card.expMonth==null || this.card.expMonth.length < 1
      || this.card.expYear==null || this.card.expYear.length < 1
      || this.card.docNumber==null || this.card.docNumber.length < 1){
      await this.modalService.showError('faltan_campos','aceptar');
      return;
    }

    var realDoc= this.card.docNumber;

    if(this.card.docType == 'RUT'){
      realDoc = realDoc.replace('-', "");
      realDoc = realDoc.replace('.', "");
      realDoc = realDoc.replace('.', "");
      realDoc = realDoc.replace('.', "");
    }

    const loader = await this.modalService.showLoader();

    try{
      const token = await this.mp.createCardToken({
        cardNumber: this.card.number ,
        cardholderName: this.card.holderName,
        cardExpirationMonth: this.card.expMonth,
        cardExpirationYear: this.card.expYear,
        securityCode: this.card.cvc,
        identificationType: this.card.docType,
        identificationNumber: realDoc
      });

      const request = await this.webService.payRegister({cardToken:token.id,email:this.email,planId:this.planId});
      loader.close();
      await this.modalService.showError(request.message,'aceptar');
      if(request.redirect == 'campus'){
        await this.suscripcionPagadaCorreo();
        location.href="https://campus.golazoft.com";
      }else{
        location.href="/inicio";
      }
      
    }
    catch(error){
      console.log(error);
      loader.close();
      if(error.message && error.message.includes('Invalid cardholder.identification.number')){
        error.message = 'invalid parameter identificationNumber';
      }

      if(error.message && error.message.includes('Cannot read properties of undefined')){
        error.message = 'datos_invalido';
      }

      
      if(Array.isArray(error)){
        error = {message : error[0].message};
      }

      this.modalService.showError(error.message,'aceptar',error.errorDetail);
    }
    

  }


  fixValues(){
    if(this.card.number){
      this.formatCreditCard();
    }
    this.card.expMonth = this.card.expMonth.replace(/\D/g, '').substring(0,2);
    this.card.expYear = this.card.expYear.replace(/\D/g, '').substring(0,2);

    const cvc = Number(this.card.cvc);
    if(cvc >0 && cvc < 1000){}else{
      this.card.cvc = this.card.cvc = '';
    }

  }

  formatCreditCard() {
    var realNumber = this.card.number.replace(/\D/g,'');
    this.card.number = realNumber;
    var newNumber = "";
    for(var x = 1; x <= realNumber.length; x++){
        newNumber += realNumber.charAt(x-1);
        if(x % 4 == 0 && x > 0 && x < 15)
            newNumber += " ";
    }
    this.numberFormatted = newNumber;
  }
}

interface DocType{
  id:string;
  name:string;
  type:string;
}

interface CardModel{
  holderName:string;
  number:string;
  expMonth:string;
  expYear:string;
  cvc:string;
  docType?:string;
  docNumber?:string;
}