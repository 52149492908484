import { HttpClient } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Utils } from '../helpers/Utils';
import { ApiResponse } from '../models/ApiResponse';
import { LangService } from '../services/lang.service';

@Component({
  selector: 'app-read-only-table',
  templateUrl: './read-only-table.component.html',
  styleUrls: ['./read-only-table.component.css']
})
export class ReadOnlyTableComponent implements OnInit {

  @Input() pageSize = 10;
  @Input() source:string;
  dataSet:any[] = [];
  errorMsg:string = null;

  loading = false;

  constructor(public lang:LangService,private http:HttpClient) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    this.loading = true;
    try {
      const req = await this.http.get<ApiResponse<any>>(environment.urlAPI+this.source).toPromise();
      this.dataSet = req.data;
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.errorMsg = error.message;
    }
  }


  getLabels(){
    return Object.keys(this.dataSet[0]);
  }

  getValues(obj:any){
    return Object.values(obj);
  }


  isDate(_date){
    const _regExp  = new RegExp('^(-?(?:[1-9][0-9]*)?[0-9]{4})-(1[0-2]|0[1-9])-(3[01]|0[1-9]|[12][0-9])T(2[0-3]|[01][0-9]):([0-5][0-9]):([0-5][0-9])(.[0-9]+)?(Z)?$');
    return _regExp.test(_date);
  }

  isUrl(str:any){
    let stringObj = new String(str);
    return stringObj.startsWith('https://') || stringObj.startsWith('http://');
  }
}
