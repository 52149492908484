import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Output, EventEmitter, forwardRef, Input, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/services/user.service';
import { Comunav2 } from 'src/app/models/comunav2';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-comuna',
  templateUrl: './input-comuna.component.html',
  styleUrls: ['./input-comuna.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComunaComponent),
      multi: true
    }
  ]
})

export class InputComunaComponent implements OnInit,OnDestroy {

  optionList: string[] = [];
  optionListSave:Comunav2[];
  selectedComuna?: string;
  isLoading = false;

  onChange: any = () => {};
  onTouch: any = () => {};

  comunaNombre: string;

  private searchSub = new Subject<string>();
  private unsub = new Subject<void>();

  async onSearch(value: string): Promise<void> {
    this.searchSub.next(value);
  }

  async getCityData(search:string){
    if(search.length > 2){
      this.isLoading = true;
      this.optionListSave = [];
      this.optionList = [];
      const req = await this.userService.getComunas2(search);
      this.optionListSave = req.data.map(item => ({ comunaId: item.comunaId, nombreConcact: item.nombreConcact }));
      this.optionList = req.data.map(item => item.nombreConcact);
      this.isLoading = false;
    }else{
      this.optionListSave = [];
      this.optionList = [];
    }
  }

  onUserSelect(value: string) {
    this.selectedComuna = value;
    const selectedComuna = this.optionListSave.find(item => item.nombreConcact === value);
    const comunaId = selectedComuna?.comunaId;
    this.onChange(comunaId);
    this.onTouch();
  
  }

  constructor(private http: HttpClient, private userService: UserService) {}

  ngOnDestroy(): void {
    this.unsub.next();
    this.unsub.complete();
  }

  ngOnInit(): void {
    this.searchSub.pipe(
      debounceTime(500),
      takeUntil(this.unsub)
    )
    .subscribe( (search:string) =>{
      this.getCityData(search);
    });
  }

  async writeValue(value: any): Promise<void> {
    this.selectedComuna = value;
    this.comunaNombre = await this.userService.getComunaId(Number(value));
    this.selectedComuna = this.comunaNombre;
    this.optionList = [];
    this.optionList.push(this.comunaNombre);
    
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }


  onKeyDown(event: KeyboardEvent): void {
    const allowedKeys = ['Backspace', 'Delete']; // Allow Backspace and Delete keys
    if (!this.isAllowedKey(event.key) && !allowedKeys.includes(event.key)) {
      event.preventDefault();
    }
  }
  
  onPaste(event: ClipboardEvent): void {
    const pastedText = event.clipboardData?.getData('text');
    if (!this.isAllowedText(pastedText)) {
      event.preventDefault();
    }
  }
  
  
  isAllowedKey(key: string): boolean {
    return /^[a-zA-Z' ]$/.test(key);
  }
  
  isAllowedText(text: string): boolean {
    return /^[a-zA-Z' ]*$/.test(text);
  }
  

}