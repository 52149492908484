<div class="modal-alert">
      <div class="modal-header">
            <button class="app-btn circle" (click)="activeModal.dismiss('cerrar')" title="{{lang.text('cerrar')}}"><fa-icon icon="times"></fa-icon></button><h4>{{macrociclo.caunNombre}}</h4>
      </div>
      <div class="modal-body full">
          <h3>{{lang.t('macro.macrociclos')}}: </h3>
          <table class="esquema">
            <tbody>
                  <tr *ngFor="let iter of this.table">
                        <td *ngIf="iter[0].rowSpan > 0" [attr.rowspan]="iter[0].rowSpan" >{{iter[0].nombre}} </td>
                        <td *ngIf="iter[1].rowSpan > 0" [attr.rowspan]="iter[1].rowSpan" >{{iter[1].nombre}}</td>
                        <td *ngIf="iter[2].rowSpan > 0" [attr.rowspan]="iter[2].rowSpan" >{{iter[2].nombre}}</td>
                        <td *ngIf="iter[3].rowSpan > 0" [attr.rowspan]="iter[3].rowSpan" >{{iter[3].nombre}}</td>
                  </tr>
            </tbody>
      </table>
      </div>
  </div>
