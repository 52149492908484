import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Abono } from "../models/abono";
import { ApiResponse } from "../models/ApiResponse";
import { Compra } from "../models/compra";
import { PaqueteEntrenamiento } from "../models/paquete";
import { AbonoInfo } from "../models/abono.info";
import { Payout } from "../models/payout";

@Injectable({
    providedIn: 'root'
  })
export class CompraService{

    constructor(private http:HttpClient){

    }



    refund(opts:{compId:number,reason:string}){
        return this.http.post<ApiResponse<any>>(`${environment.urlAPI}compra/refund`,opts).toPromise();
    }

    getMisVentas(){
        return this.http.get<ApiResponse<Compra>>(`${environment.urlAPI}compra/mis-ventas`).toPromise();
    }


    getMisPagos(compId:number){
        return this.http.get<ApiResponse<Abono>>(`${environment.urlAPI}compra/mis-abonos?compId=${compId}`).toPromise();
    }

    getAbono(abonoId:number){
        return this.http.get<AbonoInfo>(`${environment.urlAPI}compra/abono/${abonoId}`).toPromise();
    }

    getPayouts(){
        return this.http.get<ApiResponse<Payout>>(`${environment.urlAPI}compra/mis-payouts`).toPromise();
    }
}