<div class="container view">
    <app-adm-btns>
      
    </app-adm-btns>
    <h1>{{lang.text('admhome.header')}}</h1>

    <div class="stats" *ngIf="info">

        <div class="stat-box">
            <span class="material-symbols-rounded">
                account_box
            </span>
            <div class="info">
                <h6>{{info.coachesTotal}}</h6>
                <p>{{lang.text('adm.total_coaches')}}</p>
            </div>
            
        </div>

        <div class="stat-box">
            <span class="material-symbols-rounded">
                school
            </span>
            <div class="info">
                <h6>{{info.totalDaddies}}</h6>
                <p>{{lang.text('adm.total_daddies')}}</p>
            </div>
            
        </div>

        <div class="stat-box">
            <span class="material-symbols-rounded">
                groups
            </span>
            <div class="info">
                <h6>{{info.totalClases}}</h6>
                <p>{{lang.text('adm.total_clases')}}</p>
            </div>
            
        </div>

        <div class="stat-box">
            <span class="material-symbols-rounded">
                boy
            </span>
            <div class="info">
                <h6>{{info.totalAlumnos}}</h6>
                <p>{{lang.text('adm.total_alumnos')}}</p>
            </div>
            
        </div>

        



        <div class="stat-box">
            <span class="material-symbols-rounded">
                sell
            </span>
            <div class="info">
                <h6>${{info.ventasTotales/100 | currency:'usd'}}</h6>
                <p>{{lang.text('adm.ventas_coach')}}</p>
            </div>
            
        </div>

        <div class="stat-box">
            <span class="material-symbols-rounded">
                paid
            </span>
            <div class="info">
                <h6>${{info.pagado/100 | currency:'usd'}}</h6>
                <p>{{lang.text('adm.pagado_coaches')}}</p>
            </div>
            
        </div>


        <div class="stat-box">
            <span class="material-symbols-rounded">
                shopping_bag
            </span>
            <div class="info">
                <h6>{{info.cantVentas}}</h6>
                <p>{{lang.text('adm.ventas_totales')}}</p>
            </div>
            
        </div>

        

    </div>


    <div class="graficos" *ngIf="charts.length > 0">
        <div class="grafico-cont" *ngFor="let g of charts">
            <chart [url]="g.dataSet">

            </chart>
        </div>
    </div>

</div>