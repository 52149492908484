import { EventEmitter, Injectable } from "@angular/core";
import * as signalR from "@microsoft/signalr"
import { environment } from "src/environments/environment";
import { AuthService } from "./auth-service.service";
import { User } from "../models/User";

@Injectable({
    providedIn: 'root'
  })
export class WebSocketService{


    private hubConnection: signalR.HubConnection

    onVideoProcessed: EventEmitter<number> = new EventEmitter();
    onPushStatusUpdated: EventEmitter<PushStatus> = new EventEmitter();

    constructor(private authSrv:AuthService) {
        this.startConnection();
        this.authSrv.currentUser.subscribe(u => {
          this.authUser(u);
        });
    }
    


    private async authUser(u:User){
      if(u != null && this.hubConnection.state == signalR.HubConnectionState.Connected){
        this.hubConnection.invoke('authme',u.token);
      }
    }


    public async startConnection() {
      this.hubConnection = new signalR.HubConnectionBuilder()
                              .withUrl(environment.urlServicio+'listen',{skipNegotiation: true,
                                transport: signalR.HttpTransportType.WebSockets,})
                              .withAutomaticReconnect()
                              .build();
      try {
        await this.hubConnection.start();
        console.log('Connected to GolazoFT!');
        this.authUser(this.authSrv.currentUserValue);
      } catch (error) {
        console.log('Error while starting connection: ' + error);
      }
       
      this.hubConnection.on("ev_3", (param:number) =>{
        this.onVideoProcessed.emit(param);
      });

      this.hubConnection.on("PushStatus",(param:PushStatus) =>{
        this.onPushStatusUpdated.emit(param);
      });
    }
}

export interface PushStatus{
  notificacionesPendientes:number,
  lastUpdate:Date,
  notificacionesEnviadas:number,
  mailPendientes:number,
}