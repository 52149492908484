<div class="modal-alert">
      
      <div class="modal-body">
          <div class="video-container">
            <video #videoEl [src]="videoUrl" muted autoplay playsinline>

            </video>
            <div class="phone-overlay"></div>
          </div>
          <div class="right-container">
                  <button class="app-btn" id="cerrar" (click)="activeModal.dismiss()">
                        <fa-icon icon="times"></fa-icon>
                  </button>
                  <div class="box" *ngIf="videoPlayer" >
                        <h4>{{video.videoDescripcion}}</h4>
                        <h4>{{video.videoDescEjercicio}}</h4>
                        <h6>{{video.videoTiempo}}</h6>
                        <div class="flex">
                          <button class="app-btn" (click)="playPause()">
                              <fa-icon *ngIf="videoPlayer.paused" icon="play"></fa-icon>
                              <fa-icon *ngIf="!videoPlayer.paused" icon="pause"></fa-icon>
                          </button>
                          <div class="bar">
                                <div class="bar-inner" [style.width.%] ="progressBar">

                                </div>
                          </div>
                        </div>
                  </div>
          </div>
      </div>      
  </div>
  