<div class="container view">
      <div class="botones-opciones full ciclos">
          <button class="app-btn active" [routerLink]="['/macrociclos']" >{{lang.t('macro.macrociclos')}}</button>
          <button class="app-btn" [routerLink]="['/mesociclos']">{{lang.t('mesociclos')}}</button>
          <button class="app-btn" [routerLink]="['/microciclos']">{{lang.t('microciclos')}}</button>
          <button class="app-btn" [routerLink]="['/sesiones']">{{lang.t('sesiones')}}</button>
      </div>
      <div class="ciclos-border">
          <div class="botones-opciones mb-3">
                <button class="app-btn" (click)="agregarNuevo()" title="{{lang.text('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
                <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>

            </div>
            <div *ngIf="!this.loading" class="table-container">
                <table class="app-table">
                      <thead>
                            <th>
                                  #
                            </th>
                            <th>
                                  {{lang.t('descripcion')}}
                            </th>
                            <th>

                            </th>
                      </thead>
                      <tbody>
                            <tr *ngFor="let iter of this.categorias">
                                  <td>{{iter.caunId}}</td>
                                  <td>{{iter.caunNombre}}</td>
                                  <td>
                                        <button class="app-btn small" (click)="detalle(iter)" title="{{lang.text('btn.detalle')}}"><fa-icon icon="eye"></fa-icon></button>
                                        <button class="app-btn small" (click)="modificar(iter)" title="{{lang.text('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                                        <button class="app-btn small red" (click)="eliminar(iter)" title="{{lang.text('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                                  </td>
                            </tr>
                      </tbody>
                </table>

            </div>

            <div *ngIf="this.loading" class="loader">

            </div>
            <h6 *ngIf="this.categorias.length == 0 && !this.loading" >{{lang.t('nada_por_aqui')}}</h6>

      </div>

  </div>
