<div class="container-md" style="max-width: 960px;">
    <div style="padding-bottom: 16px;">
        <button  class="app-btn small"  routerLink="/sells" ><fa-icon icon="arrow-left"></fa-icon> {{lang.text('go_back')}}</button>
        <button  class="app-btn small"  (click)="refund()"><fa-icon icon="money-bill-transfer"></fa-icon> {{lang.text('refund')}}</button>
    </div>

    <div class="header g-border p-3" *ngIf="!loading">
        <h4>{{lang.text('sell')}}</h4>
        <div class="row">
            <div class="col-sm-4">
                <p><strong>ID </strong> #{{ID}}</p>
            </div>
            <div class="col-sm-4">
                <p><strong>{{lang.text('email')}} </strong> {{model.alumnoEmail}}</p>
            </div>
            <div class="col-sm-4">
                <p><strong>{{lang.text('fecha')}} </strong> {{model.compFh | date:'medium'}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-6">
                <p><strong>{{lang.text('alumno')}}</strong> {{model.alumnoNombre}}</p>
            </div>
            <div class="col-sm-6">
                <p><strong>{{lang.text('estado_compra')}}</strong> {{lang.text(model.compraEstado)}}</p>
            </div>
        </div>

        <div class="row">
            <div class="col-sm-4">
                <p><strong>{{lang.text('paquete_cobrado')}}</strong> {{lang.text(model.paenDesc)}}</p>
            </div>

            <div class="col-sm-4">
                <p><strong>{{lang.text('compra_cobrado')}}</strong> ${{model.compValor / 100 | currency:'usd'}}</p>
            </div>
            
            <div class="col-sm-4">
                <p><strong>{{lang.text('n_clases')}}</strong> {{model.paenDias}}</p>
            </div>

        </div>

        <div class="table-container">
            <table class="app-table">
                <thead>

                    <th>
                        {{lang.text('Id')}}
                    </th>

                    <th>
                        {{lang.text('estado')}}
                    </th>

                    <th>
                        {{lang.text('fecha')}}
                    </th>
                    <th>
                        {{lang.text('valor')}}
                    </th>
                    
                   
                    
                </thead>
                <tbody>
                    <tr style="cursor:pointer;" *ngFor="let i of abonos" (click)="open(content,i)">
                        <td>
                            {{i.abonoId | id:4}}
                        </td>
                        <td>
                            {{i.abonoEstado}}
                        </td>
                        <td>
                            <span *ngIf="i.abonoFhPago">{{i.abonoFhPago | date}}</span>
                        </td>
                        <td>
                            <span *ngIf="i.abonoValor">${{i.abonoValor / 100 | currency:'usd'}}</span>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
 
        
    </div>
</div>

<ng-template #content let-modal>
    <div class="loader" *ngIf="modalLoading">

    </div>

	<div class="modal-body" *ngIf="!modalLoading">
        <div class="row">
            <div class="col-sm-4">
                <p><strong>ID </strong> #{{abono.abonoId |id}}</p>
            </div>
            <div class="col-sm-4">
                <p><strong>{{lang.text('estado_stripe')}} </strong> {{abono.paymentStatus}}</p>
            </div>
            <div class="col-sm-4">
                <p><strong>{{lang.text('fecha')}} </strong> {{model.compFh | date:'medium'}}</p>
            </div>
        </div>
		<div class="table-container">
            <table class="app-table">
                <thead>

                    <th>
                        {{lang.text('clase')}}
                    </th>

                    <th></th>

                    <th>
                        {{lang.text('fecha')}}
                    </th>
                    <th>
                        {{lang.text('estado')}}
                    </th>
                    <th>
                        {{lang.text('has_feedback')}}
                    </th>
                    
                </thead>
                <tbody>
                    <tr  *ngFor="let i of abono.clases" >
                        <td>
                            #{{i.clasId |id:7}}
                        </td>

                        <td>
                            {{i.sesion}}
                        </td>

                        <td>
                            {{i.claseFh | date:'shortDate'}}
                        </td>
                        <td>
                            {{lang.text(i.claseEstado)}}
                        </td>
                        <td>
                            {{lang.text(i.clasFeedback)}}
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
	</div>

    <div class="modal-footer">
        <button type="button" class="app-btn"  (click)="modal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
    </div>
</ng-template>