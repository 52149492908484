<ng-container *ngIf="dataSet.length > 0"> 
    <div class="table-containers">
        <table #tabla class="app-table"  kTable [dataSet]="dataSet" pageSize="{{pageSize}}" >
              <thead>
                <th *ngFor="let i of getLabels()">
                    {{lang.text(i)}}
                </th>
              </thead>
              <tbody>
                <tr *ngFor="let i of dataSet">
                    <td *ngFor="let j of getValues(i)">
                        <ng-container *ngIf="isDate(j);else elseBlock">
                            {{j | date:'medium'}}
                        </ng-container>
                        <ng-template #elseBlock>
                            <ng-container *ngIf="isUrl(j);else otherElse">
                                <a [href]="j">
                                    Link
                                </a>
                            </ng-container>
                        </ng-template>
                        <ng-template #otherElse>
                            {{j}}
                        </ng-template>
                    </td>
                </tr>
              </tbody>
        </table>
    
    </div>
    
    <app-paginator [tableRef]="tabla">
    </app-paginator>
</ng-container>

<div class="loader" *ngIf="loading">

</div>



<div *ngIf="errorMsg" style="display: flex;justify-content: center;">
    <span>{{lang.text(errorMsg)}}</span>
</div>