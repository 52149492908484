import { Component, OnInit } from '@angular/core';
import { Microciclo } from 'src/app/models/microciclo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { MicrocicloNuevoComponent } from '../microciclo-nuevo/microciclo-nuevo.component';

@Component({
  selector: 'app-microciclo-listar',
  templateUrl: './microciclo-listar.component.html',
  styleUrls: ['./microciclo-listar.component.css']
})
export class MicrocicloListarComponent implements OnInit {

  loading = true;
  categorias:Microciclo[] = [];

  constructor(private categoriaService:CategoriaService,private modalService:ModalService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    const req = await this.categoriaService.getAllMicros();
    this.categorias = req.data;
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(MicrocicloNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async modificar(obj:Microciclo)
  {
    let modalRef = this.modalService.open(MicrocicloNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.microcicloParam= obj;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async cambiarTipo(obj:Microciclo,stat:boolean){
    try {
      const req = await this.categoriaService.cambiarTipoMicro(obj,stat);
      await this.onLoad();
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async eliminar(obj:Microciclo)
  {
    let confirmar = await this.modalService.confirm('micro.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.categoriaService.deleteMicro(obj);
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
  }

  async refresh()
  {
    this.loading=true;
    await this.onLoad();
    this.loading=false;
  }

}
