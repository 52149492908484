
import { Component, ElementRef, NgZone, OnInit, ViewChild } from '@angular/core';
import { PopUp } from 'src/app/models/popup';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { NuevoPromocionComponent } from './nuevo-promocion/nuevo-promocion.component';
import { MapsAPILoader } from '@ng-maps/core';

@Component({
  selector: 'app-promocion',
  templateUrl: './promocion.component.html',
  styleUrls: ['./promocion.component.css']
})
export class PromocionComponent implements OnInit {
markerDragEnd($event: any) {
throw new Error('Method not implemented.');
}
  title: string = 'AGM project';
  latitude: number;
  longitude: number;
  zoom: number;
  address: string;
  private geoCoder;

  @ViewChild('search')
  public searchElementRef: ElementRef;

  loading:boolean = true;
  popUp:PopUp[]=[]
  constructor(private modalService:ModalService, public lang:LangService,private userService:UserService,
    private ngZone: NgZone) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    
  }


  async onLoad()
  {
    try {
      const req = await this.userService.getPopUp();
      this.popUp = req.data
      console.log('popUp',this.popUp);
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }
  async agregarNuevo()
  {
    
     let modalRef = this.modalService.open(NuevoPromocionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async modificar(pop?:PopUp)
  {
     let modalRef = this.modalService.open(NuevoPromocionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
     modalRef.componentInstance.popupparam= pop;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async eliminar(pop:PopUp)
  {
    let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
          const req = await this.userService.deletePopUp(pop);  
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }

  copyText(codigo:string): Promise<void> {

    return navigator.clipboard.writeText('https://golazoft.com/promocion/' + codigo).then(
      () => alert('Texto copiado al portapapeles')
    );
  }
  
}
