import { Documento } from "src/app/services/documento.service";

export class leccion{
    leccId?:number;
    leccNombre:string;
    leccDesc:string;
    leccVideId:number;
    leccDocuId:number;
    videoId?:number;
    videoNombre?:string;
    videoDescripcion?:string;
    docuId?:number;
    docuNombre?:string;
    docuArchivo?:string;
    documentos?: Documento[];
    }