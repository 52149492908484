import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from './user.service';

const USER_KEY = 'userGolazoWeb';

@Injectable({
  providedIn: 'root'
})


export class AuthService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  

  constructor(private http: HttpClient,private router:Router,private userService:UserService,private activeRoute:ActivatedRoute) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem(USER_KEY)));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public get rolId():number{
    return this.currentUserValue.rolId;
  }

  async login(username: string, password: string) {
    const body = {
      correo:username,
      password:password
    }
    const req = await this.http.post<ApiResponse<User>>(`${environment.urlAPI}auth/token`,body).toPromise();

    localStorage.setItem(USER_KEY,JSON.stringify(req.data[0]));
    this.currentUserSubject.next(req.data[0]);
    return req.data[0];
    
  }

  loginGuard(){
    const currentUser = this.currentUserValue;
    if (currentUser) {
        return true;
    }

    console.log('Ruta protegida, redireccionando al login.');
    this.router.navigateByUrl('/login');
    return false;
  }

  async loginWithJwt(jwt:string){
    console.log(jwt);
    const req = await this.http.post<ApiResponse<User>>(`${environment.urlAPI}auth/token-wtoken`,null,{
      headers: {'Authorization':'Bearer '+jwt}
    }).toPromise();
    localStorage.setItem(USER_KEY,JSON.stringify(req.data[0]));
    this.currentUserSubject.next(req.data[0]);
    return req.data[0];
  }

  registro(body:RegistroViewModel){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/registrar-web`,body).toPromise();
  }


  async checkRole(){
    if(this.currentUserValue){
      try {
        const req = await this.userService.me();
        
        const rol = req.data[0].rolId;
        if(this.currentUserValue.rolId != rol){
          this.updateRole(rol);
          console.info("Rol del user cambio!");
        }
      } catch (error) {
        
      }
    }
    
  }

  updateRole(rolId:number){
    const value = this.currentUserSubject.value;
    value.rolId = rolId;
    localStorage.setItem(USER_KEY,JSON.stringify(value));
    this.currentUserSubject.next(value);
  }

  logout(extraUrl:string = '') {
    // remove user from local storage to log user out
    localStorage.removeItem(USER_KEY);
    this.currentUserSubject.next(null);
    //location.replace('/');
    this.router.navigateByUrl('/login'+extraUrl,{replaceUrl:true});
  }


  
}

export interface RegistroViewModel{
  nombre?:string,
  apellido?:string,
  password?:string,
  password2?:string,
  comunaId?:number,
  telefono?:string,
  rut?:string,
  email?:string
}
