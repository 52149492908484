<div class="modal-alert">
    <div class="modal-body left">
        <div class="loader" *ngIf="loading"></div>
        <div class="talbe" *ngIf="!loading">
            <div class="dropdown" [class.uploading]="uploading" appDnd (fileDropped)="onFileDropped($event)" (click)="fileDropRef.click()">
                <input type="file" #fileDropRef style="display: none;" id="fileDropRef" multiple (change)="uploadFiles($event.target.files)" />
                <fa-icon icon="plus"></fa-icon>
                <h6>{{lang.text('subir_archivo_aqui')}}</h6>
            </div>
            <table class="app-table">
                <thead>
                      <th>
                            {{lang.text('nombre')}}
                      </th>

                      <th>
                          {{lang.text('fecha_subida')}}
                      </th>

                      <th>

                      </th>

                </thead>
                <tbody>
                      <tr *ngFor="let i of docs">
                        <td>
                            {{i.docuNombre}}
                        </td>
                        <td>
                            {{i.docuFhInsert}}
                        </td>
                        <td>
                            <button (click)="download(i)" class="app-btn" title="{{lang.t('btn.download')}}"><fa-icon icon="file"></fa-icon></button>
                            <button (click)="enviar(i)" class="app-btn" title="{{lang.t('btn.enviar')}}"><fa-icon icon="envelope"></fa-icon></button>
                            <button (click)="delete(i)" class="app-btn" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash"></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
          </table>
        </div>

        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon>{{lang.t('cancelar')}}</button>
    </div>
</div>
