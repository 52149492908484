
<div class="centered g-border">
    <div class="login">
        <img style="width: 100%;" src="./assets/logo_blanco.png">
        <div class="blurred form">
            <div *ngIf="loading" class="loader">

            </div>
            <div *ngIf="!loading">
                <input [(ngModel)]="userInput" type="email" id="user" placeholder="{{lang.text('login.username')}}">

                <input [(ngModel)]="userPass" type="password" id="pass" placeholder="{{lang.text('login.password')}}">
                <div class="botonera">
                    <button autofocus *ngIf="!loading" [disabled]="(!this.userInput || !this.userPass)" (click)="doLogin()" class="app-btn w-100" title="{{lang.t('login.entrar')}}">{{lang.text('login.entrar')}}</button>
                </div>
            </div>



        </div>
        <div style="display: flex;justify-content: center;">
            <a routerLink="/resetear-password" style="text-align: center;">{{lang.text('login.reset_password')}}</a>

        </div>
    </div>
</div>

