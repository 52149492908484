import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AgendaService } from 'src/app/services/agenda.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-agregar-agenda',
  templateUrl: './agregar-agenda.component.html',
  styleUrls: ['./agregar-agenda.component.css']
})
export class AgregarAgendaComponent implements OnInit {


  loading = false;
  uploading = false;
  nombresDias:string[]; 

  model:AgregarAgendaVM = {fechaDesde:null,fechaHasta:null,dias : [],hora:null}; 
  constructor(public lang:LangService,private modalService:ModalService,public activeModal:NgbActiveModal,private agendaService:AgendaService) { }

  ngOnInit(): void {
    this.nombresDias = this.lang.text('dias').split(',');
    console.log('asd',this.nombresDias);
    for (let i = 0; i < 7; i++) {
      this.model.dias.push({checked:false});
    }
  }


  async save(){
    this.uploading = true;
    try {
      const req = await this.agendaService.agregar(this.model);
      this.activeModal.close();
    } catch (error) {
      this.modalService.showError(error.message,'ok');
    }
    this.uploading = false;
  }
}


export interface AgregarAgendaVM{
  fechaDesde:string;
  fechaHasta:string;
  dias:Dia[] ;
  hora:string;
}
interface Dia{
  checked?:boolean;
}
