import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Curso, CursoModulo } from 'src/app/models/campus/curso';
import { Modulo } from 'src/app/models/campus/modulo';
import { CursoService } from 'src/app/services/campus/curso.service';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-editar-curso',
  templateUrl: './editar-curso.component.html',
  styleUrls: ['./editar-curso.component.css']
})
export class EditarCursoComponent implements OnInit {


  @Input() moduParam:Curso;

  //grupo:Grupo;
  modulos:Modulo[] = [];
  moduloSel:Modulo[] = [];


  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];

/*   filtro:Caracteristica = null;
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public moduloService:ModuloService,
              public cursoService:CursoService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
    //console.log(this.quizParam);
  }

  async onLoad()
  {
     try {
      const request = await this.moduloService.get();
      this.modulos = request.data;
      //console.table(this.modulos);
      const request2 = await this.cursoService.getCursoModulo(this.moduParam.cursId);
      this.moduloSel = request2.data;
      console.table(this.moduloSel);
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    }
  }

  async agregar(modulo:Modulo)
  {
    let find = this.moduloSel.find(el => el.moduId == modulo.moduId);
    if(!find)
    {
      this.moduloSel.push(modulo);
    }
  }

/*   async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }

    this.loadingVideos = false;
  } */

  async remover(modulo:Modulo)
  {
    let p = this.moduloSel.findIndex(el => el.moduId == modulo.moduId);
    this.moduloSel.splice(p,1);
  }

  async guardar(){
     this.uploading= true;
     try {
      let v:CursoModulo = new CursoModulo();
      v.cursId=this.moduParam.cursId;
      v.modulos = [];
      for (const iterator of this.moduloSel) {
        v.modulos.push(iterator.moduId);
      }
      const req = await this.cursoService.postCursoModulo(v);
      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
      console.log(error.data)
    }
    this.uploading = false;
  }
}
