import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Clase } from '../models/clase';





@Injectable({
  providedIn: 'root'
})

export class ControlClaseService {

  constructor(private http:HttpClient) {
  }

  public async getClases(idAlumno:number,idEvaluacion:number)
  {
    return this.http.get<ApiResponse<Clase>>(`${environment.urlAPI}clase/${idAlumno}/${idEvaluacion}`).toPromise();
  }


  public async getClasesHist(idAlumno:number,idEvaluacion:number)
  {
    return this.http.get<ApiResponse<Clase>>(`${environment.urlAPI}clase/${idAlumno}/${idEvaluacion}/hist`).toPromise();
  }

  public async getClasesInasistencias()
  {
    return this.http.get<ApiResponse<Clase>>(`${environment.urlAPI}clase/inasistencias`).toPromise();
  }
  
  public async postCambiarEstado(idClase:number,idEstado:number,fecha:Date,obs:string)
  {
    const body = {
      clasId:idClase,
      clasEstado:idEstado,
      clasFhReal:fecha,
      clasObservacion:obs
    }
    return this.http.post<ApiResponse<Clase>>(`${environment.urlAPI}clase`,body).toPromise();
  }

}
