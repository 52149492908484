import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Respuesta } from 'src/app/models/campus/respuesta';
import { Pregunta } from 'src/app/models/campus/Pregunta';
import { quiz } from 'src/app/models/campus/quiz';
import { quizPreguntas } from '../../models/campus/quiz';


@Injectable({
  providedIn: 'root'
})
export class QuizService {

  constructor(private http:HttpClient) {
  }

  public async get(quizId?:number)
  {
    const body = Params.toHttpParams({quizId:quizId});
    return this.http.get<ApiResponse<quiz>>(`${environment.urlAPI}campus/quiz`,{params:body}).toPromise();
  }

  public async put(req:quiz)
  {

    return this.http.put<ApiResponse<quiz>>(`${environment.urlAPI}campus/quiz`,req).toPromise();
  }

  public async post(req:quiz)
  {

    return this.http.post<ApiResponse<quiz>>(`${environment.urlAPI}campus/quiz`,req).toPromise();
  }

  public async getQuizPregunta(quizId:number,pregId?:number)
  {
    const body = Params.toHttpParams({quizId:quizId,pregId:pregId});
    return this.http.get<ApiResponse<Pregunta>>(`${environment.urlAPI}campus/pregunta/quiz`,{params:body}).toPromise();
  }
  public async postQuizPregunta(req:quizPreguntas)
  {

    return this.http.post<ApiResponse<quizPreguntas>>(`${environment.urlAPI}campus/pregunta/quiz`,req).toPromise();
  }
  
  public async DeleteQuiz(quizId?:number)
  {
    const body = Params.toHttpParams({quizId:quizId});
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}campus/quiz/delete`,{params:body}).toPromise();
  }

}


export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}