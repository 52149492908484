export class quiz{
    quizId?:number;
    quizNombre:string;
    quizDesc:string;
    moduId: number;
    moduNombre: string;
    cursId: number;
    aprobado: number;
}

export class quizPreguntas{
    quizId:number;
    preguntas:number[];
    }
