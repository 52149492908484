<div class="modal-alert">
    <div class="modal-body left">
        <h4>
            <fa-icon icon="edit"></fa-icon> {{lang.text('curs.edit')}} {{moduParam.cursNombre}}  {{moduParam.cursDesc}}  #{{moduParam.cursCodigo}}</h4>
        <div class="pt-3">

        </div>

        <div class="row">
            <div class="col-sm-5">
                <h5>{{lang.text('curs.moduDisp')}}</h5>
            </div>
            <div class="col-sm-2">

            </div>
            <div class="col-sm-5">
                <h5>{{lang.text('curs.moduCurs')}}</h5>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="loader"></div>
        </div>
        <div class="row" *ngIf="!loading">
            <div class="col-sm-5">

                <div class="lista">
                    <h6 *ngFor="let m of modulos" (click)="agregar(m)">
                        <fa-icon icon="plus"></fa-icon> {{m.moduDesc}}</h6>
                </div>

            </div>
            <div class="col-sm-2">
                <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
            </div>
            <div class="col-sm-5">

                <div class="lista">
                    <h6 *ngFor="let m of moduloSel" (click)="remover(m)">
                        <fa-icon icon="minus"></fa-icon> {{m.moduDesc}}</h6>
                </div>
            </div>
        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
            {{error.message}}
        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar"></div>
    <div class="modal-footer right">

        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" ><fa-icon icon="undo-alt"></fa-icon> {{lang.text('cancelar')}}</button>
        <button autofocus type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" ><fa-icon icon="save" ></fa-icon> {{lang.text('guardarCambios')}}</button>
    </div>
</div>
