import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Sesion } from '../models/sesion';

import { Grupo } from '../models/grupo';




@Injectable({
  providedIn: 'root'
})

export class CopyPasteService {
  clip:ClipBoardContent = {};



  constructor(private http:HttpClient) {
    this.clear()
  }


  public get isEmpty(){
    if(this.clip.content.length == 0) return true;

    return false;
  }

  copy(content:any[]){
    this.clip.content = content;
  }

  clear(){
    this.clip.content = [];
  }

  copyItem(content:any){
    this.clip.content = [];
    this.clip.content.push(content);
  }
  
}

interface ClipBoardContent{
  content?:any[];
}
