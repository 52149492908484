import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Modulo } from 'src/app/models/campus/modulo';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-nuevo-modulo',
  templateUrl: './nuevo-modulo.component.html',
  styleUrls: ['./nuevo-modulo.component.css']
})
export class NuevoModuloComponent implements OnInit {


  modulos:Modulo;
  errores:any[] = [];
  uploading:boolean= false;

  constructor(public activeModal:NgbActiveModal,
              private moduloService:ModuloService,
              private modalService:ModalService,
              public lang:LangService) {

    this.modulos = new Modulo();

   }

   @Input() moduloParam:Modulo;

  async ngOnInit(): Promise<void> {
    if (this.moduloParam) {
      this.modulos=this.moduloParam;
    }
  }

  async agregar(){
    this.uploading = true;
    if(this.moduloParam)
    {
      try {
        const req = await this.moduloService.post(this.modulos);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }else{
      try {
        const req = await this.moduloService.put(this.modulos);
        this.activeModal.close('creado');
      } catch (error) {
        this.errores = error.data;
        this.modalService.showError(error.message,'Aceptar');
      }
    }

    this.uploading=false;
  }
}



