<div class="container" *ngIf="showForm">
    <h1>{{lang.text('mp_actualizar_pago_header')}}</h1>
    <div class="doble-row">
        <div class="card-container">
            <div class="credit-card">
                <div><img src="/assets/logo_rosa.png" height="70"></div>
                <div style="padding-left:16px ;margin-bottom: 12px;"><img src="/assets/ccchip.png" height="40"></div>
                <h1 class="c-number">{{card.number? numberFormatted:'xxxx xxxx xxxx xxxx'}}</h1>
                <div style="width: 70%;display: flex;justify-content: space-evenly;">
                    <h1 class="exp"><small>exp</small>{{card.expMonth? card.expMonth:'xx'}}/{{card.expYear? card.expYear:'xx'}}</h1>
                    <h1 class="exp"><small>cvc</small>***</h1>
                </div>
                
                <h1 class="holder">{{card.holderName}}</h1>
            </div>
            <div class="mt-3">
                <h5>{{lang.text('mp.texto1')}} <a href="https://www.mercadopago.cl">Mercado Pago</a></h5>
                <p>{{lang.text('mp.texto2')}}</p>
                <p><small><a href="https://golazoft.com/assets/tos.pdf">{{lang.text('mp.texto3')}}</a></small></p>
            </div>
            
        </div>
        <div class="form-container">
            <div class="row">
                <div class="col-sm-12">
                    <label for="number">{{lang.text('numero')}}</label>
                    <input type="text" inputmode="numeric" id="number" name="number" (keyup)="fixValues()" [(ngModel)]="card.number">
                </div>
            </div>

            <div class="row">
                
                <div class="col-sm-6">
                    <label for="month">{{lang.text('fecha_exp')}}</label>
                    <div style="display: flex;">
                        <input type="text" inputmode="numeric" id="month" name="month" (keyup)="fixValues()" [(ngModel)]="card.expMonth">

                        <input type="text" inputmode="numeric" id="year" name="year" (keyup)="fixValues()" [(ngModel)]="card.expYear">
                    </div>
                    
                </div>

                <div class="col-sm-6">
                    <label for="cvc">{{lang.text('cvc_code')}}</label>
                    <input type="password" inputmode="numeric" id="cvc" name="cvc" (keyup)="fixValues()" [(ngModel)]="card.cvc">
                </div>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <label for="holder">{{lang.text('card_holder')}}</label>
                    <input type="text"  id="holder" name="holder" [(ngModel)]="card.holderName">
                </div>
            </div>

            <div class="row">
                <div class="col-sm-12">
                    <label for="email">{{lang.text('email')}}</label>
                    <input type="email"  id="email" name="email" [(ngModel)]="email">
                </div>
            </div>

            
            
            <div class="row" >
                <div class="col-sm-6">
                    <label for="tdoc">{{lang.text('tipo_documento')}}</label>
                    <select name="tdoc" id="tdoc" [(ngModel)]="card.docType">
                        <option [value]="null" disabled selected>{{lang.text('seleccionar')}}</option>
                        <option *ngFor="let d of docTypes" [value]="d.id">{{d.name}}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="doc">{{lang.text('documento')}}</label>
                    <input type="text"  id="doc" name="doc" [(ngModel)]="card.docNumber">
                </div>
            </div>
            

            <div style="margin-top: 16px;">
                <button class="app-btn" style="width: 100%;" (click)="pagar()">{{lang.text('pagar')}}</button>

            </div>
            
        </div>
    </div>
</div>

<div style="display: flex;width:100%;justify-content:center;flex-direction: column;align-items: center;" *ngIf="esperandoPago">
    <div class="spin-loader"></div>
    <h2>{{lang.text('esperando_comprobacion_pago')}}</h2>
</div>