<div class="container view">
    <div class="videos">
        <div class="botones-opciones mb-3">
            <button class="app-btn" *ngIf="userModel.rolId == 1" (click)="agregarNuevo()"><fa-icon icon="plus"></fa-icon> {{lang.text('videos.nuevo')}}</button>
            <button class="app-btn" *ngIf="userModel.rolId == 1" routerLink="/caracteristica"><fa-icon icon="cogs"></fa-icon> {{lang.text('videos.adm_caracteristicas')}}</button>
            <button class="app-btn" (click)="currentPage=1;refresh()"><fa-icon icon="retweet"></fa-icon> {{lang.text('videos.refresh')}}</button>

        </div>

        <div class="botones-opciones mb-2" style="align-items: center;">
            <input class="app-input" type="search"  name="searchBox" placeholder="{{lang.text('videos.buscar')}}" [(ngModel)]="searchStr" (change)="searchChange()">
            &nbsp;
            <button class="app-btn" (click)="searchChange()" title="{{lang.text('videos.buscar2')}}"><fa-icon icon="search"></fa-icon></button>
            <label for="tipoVideoId" style="margin:0px;">{{lang.text('videos.fil_caracteristicas')}} </label>
            <select [(ngModel)]="filtroTag" name="tipoVideoId" class="app-combo pink" (change)="searchChange()">
                <option [value]="null" selected>{{lang.text('videos.todos')}}</option>
                <option *ngFor="let tv of tags" value="{{tv.caracteristicaId}}">{{tv.caracteristicaNombre}}</option>
            </select>
            <label for="grupoId" style="margin:0px;">{{lang.text('videos.fil_grupo')}} </label>
            <select [(ngModel)]="filtroGrupo" name="grupoId" class="app-combo pink" (change)="searchChange()">
                <option [value]="null" selected>{{lang.text('videos.todos')}}</option>
                <option *ngFor="let g of grupos" value="{{g.grupoId}}">{{g.grupoNombre}}</option>
            </select>

        </div>


        <div *ngIf="!this.loading" class="videos-container">
            <div class="video-el" *ngFor="let iter of this.videos">
                <div class="video-preview" [style.background-image]="'url('+previewUrl+iter.videoPreview+')'">
                    <button class="app-btn secondary" (click)="verVideo(iter)"><fa-icon icon="play"></fa-icon></button>
                </div>
                <div class="text">
                    <p>{{iter.videoNombre}}</p>
                    <p style="color:#bbbbbb;">{{iter.videoDescripcion}}</p>
                    <p style="color:#999999;"><i>{{iter.calidades}}</i></p>
                    <p ><small style="color:var(--secondary-color);">{{iter.videoTiempo}}</small></p>
                    <div *ngIf="iter.loadingCaracteristicas"  style="display: flex;">
                        <div class="loader inline"></div><p> {{lang.text('videos.cargando')}}</p>
                    </div>
                    <p><span *ngFor="let cat of iter.listaCaracteristicas" class="badge badge-pill badge-info">{{cat.caracteristicaNombre}}</span></p>
                </div>
                <div class="h-stack" style="margin-top:4px" *ngIf="userModel.rolId == 1">
                    
                    <button class="app-btn small" style="margin-right: 2px;" (click)="modificarVideo(iter)" title="{{lang.text('btn.edit')}}"> <fa-icon icon="edit"></fa-icon></button>
                    <button class="app-btn small" style="margin-right: 2px;" (click)="eliminarVideo(iter)" title="{{lang.text('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                </div>
            </div>
            <!--
            <div  class="app-card" *ngFor="let iter of this.videos">
                <div class="icon">
                    <img *ngIf="!iter.videoPreview" src="./assets/img/icon_video.png" alt="">
                    <img *ngIf="iter.videoPreview" [src]="previewUrl+iter.videoPreview" alt="">
                </div>
                <div class="content">
                    <h5>{{iter.videoNombre}} <small>#{{iter.videoId}}</small></h5>
                    <h6>{{iter.videoDescripcion}}</h6>
                    <p *ngIf="userModel.rolId == 1">Descripción: {{iter.videoDescEjercicio}}</p>
                    <small>{{iter.videoTiempo}}</small>
                    <div *ngIf="iter.loadingCaracteristicas"  style="display: flex;">
                        <div class="loader inline"></div><span> Cargando...</span>
                    </div>
                    <div></div>
                    <span *ngFor="let cat of iter.listaCaracteristicas" class="badge badge-pill badge-info">{{cat.caracteristicaNombre}}</span>

                </div>
                <div class="botones">

                    <button class="app-btn" (click)="verVideo(iter)"><fa-icon icon="eye"></fa-icon> Ver</button>
                    <div *ngIf="userModel.rolId == 1">
                        <button class="app-btn" (click)="modificarVideo(iter)"><fa-icon icon="edit"></fa-icon> Editar</button>
                        <button class="app-btn" (click)="eliminarVideo(iter)"><fa-icon icon="trash-alt"></fa-icon> Eliminar</button>
                    </div>


                </div>
            </div>
            -->
        </div>

        <div *ngIf="this.loading" class="loader">

        </div>
        <h6 *ngIf="this.videos.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>
        <div class="botones-opciones mb-3" style="margin-top: 1rem;">

            <button class="app-btn secondary outline" [disabled]="currentPage<=1"  (click)="prev()"> < </button>
            <button class="app-btn secondary outline" style="border-color: transparent;">{{currentPage}}</button>
            <button class="app-btn secondary outline" [disabled]="this.videos.length < 1"  (click)="next()"> > </button>
        </div>
    </div>
</div>
