import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth-service.service';
import { User } from '../models/User';
import { NzNotificationService } from 'ng-zorro-antd/notification';

@Injectable({providedIn: 'root'})
export class PushService {

    public static pushRegisterEndpoint = environment.urlAPI+'auth/push';
    private pushSubs:string|null;

    constructor(private httpClient: HttpClient,private sw:SwPush,private authSrv:AuthService) 
    { 
        
    }


    async init(){
        await this.getPushSubscription();
        this.authSrv.currentUser.subscribe(async i => {
            if(this.pushSubs == null){
                await this.getPushSubscription();
            }
            await this.userChange(i);
        });
    }

    async getPushSubscription(){
        try {
            const subs = await this.sw.requestSubscription({serverPublicKey:environment.publicVAPID});
            this.pushSubs = JSON.stringify(subs);
            return this.pushSubs;
        } catch (error) {
            console.log(error);
        }
    }

    private async registerToken(){
        if(this.authSrv.currentUserValue == null) return;
        if(this.pushSubs){
            try {
                let body = {
                    platform:'web',
                    token:this.pushSubs
                }
                await this.httpClient.patch<any>(PushService.pushRegisterEndpoint,body).toPromise();
            } catch (error) {
                console.log('error push api send:',error.message);
            }
        }
    }

    private async unregisterToken(){
        if(this.authSrv.currentUserValue != null) return;
        if(this.pushSubs){
            try {
                await this.httpClient.delete<any>(PushService.pushRegisterEndpoint+'?platform=web&token='+this.pushSubs).toPromise();
            } catch (error) {
                console.log('error push api send:',error.message);
            }
        }   
    }

    async userChange(i: User) {
        

        if(i == null){
            this.unregisterToken();
        }else{
            this.registerToken();
        }
    }
    
}