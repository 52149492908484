<div class="page mt-4">
    <div class="container">

        <div class="container view">
            <div>
                <h2>{{lang.text('campus.certificado_documentos')}}</h2>
            </div>

            <div class="cursos">
                <div class="curso" >
                    <img src="https://golazoft.com/api/assets/iso_rosa.png" alt="" srcset="">
                    <div style="margin-right: 10px;">
                        <h6>Certificado Golaço Coach</h6>
                    </div>
                    <div>
                        <button (click)="generatePDF()" class="app-btn">{{lang.text('btn.certificado')}}</button>
                    </div>
                </div>
            </div>
            <div class="table-container">
                <table class="app-table" *ngFor="let l of lecciones">
                    <thead>
                        <th>

                            <h4 class="mt-5"><b> Documentos {{l[0].moduNombre}}</b></h4>
                        </th>

                        <th>

                        </th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let d of l[0].documentos">
                            <td>{{d.docuNombre}}</td>

                            <td>

                                <button (click)="download(d)" class="app-btn small red" title="{{lang.text('al.download')}}"> <fa-icon icon="download"></fa-icon></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

            </div>

            <!--   <div class="table-container">
            <table class="app-table" *ngFor="let l of lecciones">
                <thead>

                    <th>
                        {{l[0].leccNombre}}
                    </th>

                    <th>

                    </th>
                </thead>
                <tbody *ngFor="let d of l[0].documentos">
                    <tr >
                        <td>{{d.docuNombre}}</td>
                        

                        <td>                            
                            <button (click)="download(d)" class="g-btn secondary"> {{lang.text('btn.descargar')}}</button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div> -->

            <!-- <h4>{{lang.text('leccion.recursos_apoyo')}}</h4>
                    <table class="documento-table mt-4" *ngFor="let l of lecciones">
                        <h6>{{l[0].leccNombre}}</h6>
                        <thead>
                              <th>
                                    {{lang.text('nombre')}}
                              </th>
                                            
                              <th>
        
                              </th>
        
                        </thead>
                        <tbody  *ngFor="let d of l[0].documentos">
                              <tr >
                                <td>
                                     {{d.docuNombre}}
                                </td>
                               
                                <td>
                                     <button (click)="download()" class="app-btn"></button> 
                                     <button (click)="download(d)" class="g-btn secondary"> {{lang.text('btn.descargar')}}</button>
                                </td>
                            </tr>
                            
                        </tbody>
                        
                  </table>  -->

            <!-- <table class="app-tabla mt-4" *ngFor="let l of lecciones">
            <h6>{{l[0].leccNombre}}</h6>
            <tbody *ngFor="let d of l[0].documentos">
                <tr>
                    <td>
                        {{d.docuNombre}}
                    </td>
                </tr>

            </tbody>
        </table> -->

            <!-- <div *ngFor="let l of lecciones">
            <h6>{{l[0].leccNombre}}</h6>
            <div *ngFor="let d of l[0].documentos">
                {{d.docuNombre}}
            </div>
        </div>  -->

        </div>
    </div>