export class Mesogrupo{
    megrId:number;
    megrNombre:string;
    megrDescripcion:string;
    mesociclos:number[] = [];
}

export class MesogrupoCado{
    megrId:number;
    megrNombre:string;
    megrDescripcion:string;
    cadoId:number;
    cadoNombre:string;
}