export class Modulo{
    moduId?:number;
    moduNombre:string;
    moduDesc:string;
    moduCodigo:string;
}

export class ModuloLeccion{
    moduId:number;
    lecciones:number[];
}


export class ModuloQuiz{
    moduId:number;
    quizes:number[];
}