import { Component } from '@angular/core';
import { Payout } from 'src/app/models/payout';
import { CompraService } from 'src/app/services/compra.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-payouts',
  templateUrl: './payouts.component.html',
  styleUrls: ['./payouts.component.css']
})
export class PayoutsComponent {

  
  payouts:Payout[] = [];
  constructor(public lang:LangService,private helper:ModalService,private userService:UserService,private ventaService:CompraService) { }

  ngOnInit(){
    this.loadPayouts();
  }

  async loadPayouts(){
    try {
      this.payouts = (await this.ventaService.getPayouts()).data;
    } catch (error) {
      
    }
  }
}
