import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/helpers/Utils';
import { Comuna } from 'src/app/models/comuna';
import { Mesociclo } from 'src/app/models/mesociclo';
import { Mesogrupo } from 'src/app/models/Mesogrupo';
import { Plan } from 'src/app/models/plan';
import { User } from 'src/app/models/User';
import { CategoriaService } from 'src/app/services/categoria.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alumno-nuevo',
  templateUrl: './alumno-nuevo.component.html',
  styleUrls: ['./alumno-nuevo.component.css']
})
export class AlumnoNuevoComponent implements OnInit {

  loading = true;
  uploading = false;
  comunas:Comuna[] = [];
  paises:Comuna[] = [];
  regiones:Comuna[] = [];
  all:Comuna[] = [];
  allRegions:Comuna[] = [];
  errores:any[] = [];
  imageUpload = false;


  ciclos:Mesogrupo[] = [];


  @Input() userModel:User;

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
    private periodoService:PeriodoService, private evaluacionService:EvaluacionService
    ,private catService:CategoriaService, public lang:LangService) { }

  async ngOnInit() {
    if(!this.userModel){
      this.userModel = new User();
      this.userModel.id = 0;
      this.userModel.comunaId = 0;
      
    }
    else{
      this.revisarEvaluacion();
      console.log(this.userModel.userComuna)
      console.log(this.userModel)
    }
    await this.onLoad();
    this.loading= false;
  }

  async revisarEvaluacion(){

  }

  async guardar()
  {
    
    this.uploading=true;
    try {
      this.userModel.rolId = 3;
      

      if(this.userModel.id == 0)
      {
        const req = await this.userService.post(this.userModel);
      }
      else
      {
        const req = await this.userService.put(this.userModel);
      }
      this.activeModal.close('creado');
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

  async onLoad()
  {
    try {
      //const req = await this.userService.getComunas();
      //this.setupDireccion(req.data)

      const req4 = await this.catService.getAllMesoGrupos();
      this.ciclos = req4.data;
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }
  }



  actualizarRegiones(event){
    this.regiones = this.allRegions.filter(x => x.paisId == event.target.value);
  }

  actualizarComunas(event){
    this.comunas = this.all.filter(x => x.regionId == event.target.value);
  }

  async setupDireccion(list:Comuna[]){
    this.all = list;
    for (const i of Utils.groupBy(list,x => x.paisId)) {
      this.paises.push(i[0]);
    }

    for (const i of Utils.groupBy(list,x => x.regionId)) {
      this.allRegions.push(i[0]);
    }
    
    this.comunas = this.all;
  }

  async subirImagen(event){
    let file = event.target.files[0];
    console.log(file);
    this.imageUpload= true;
    try {
      const req = await this.userService.postImagen(file);
      this.userModel.userImagen = `${environment.urlAPI}user/imagen/${req.message}`;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.imageUpload= false;
  }


  

  updateRut()
  {
    this.userModel.userRut = this.userService.fixRut(this.userModel.userRut);
  }

}
