import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hs',
    standalone:true
})

export class HSPipe implements PipeTransform {
    transform(totalSegundos: number): string {
        const minutos = Math.floor(totalSegundos / 60);
        const segundos = totalSegundos % 60;

        const minutosFormateados = minutos < 10 ? `0${minutos}` : minutos;
        const segundosFormateados = segundos < 10 ? `0${segundos}` : segundos;

        return `${minutosFormateados}:${segundosFormateados}`;
    }
}