import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/helpers/Utils';
import { Clase } from 'src/app/models/clase';
import { ProgramaDetalle } from 'src/app/models/programa.detalle';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { VideoPlayerComponent } from '../../video-player/video-player.component';

@Component({
  selector: 'app-ver-info',
  templateUrl: './ver-info.component.html',
  styleUrls: ['./ver-info.component.css']
})
export class VerInfoComponent implements OnInit {

  @Input() clase:Clase;

  normalizedData:any[] = [];
  newMode = false;

  programaHeader:ProgramaDetalle;
  programa:ProgramaDetalle[] = [];
  partes: { paseId: string; paseDesc:string; duracion: number; ejercicios: { ejercicio: string; duracion: number; videos: { videoId: number; duracion: number; }[]; }[]; }[];
  duracionTotal: number;

  constructor(private modalSrv:NgbModal,public activeModal:NgbActiveModal,public lang:LangService,private sesionSrv:CategoriaService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    try {
      const req = await this.sesionSrv.getPrograma(this.clase.cacuId);
      if(req.data.length > 0){
        this.newMode = true;
        this.programaHeader = req.data[0];
        this.programa  = req.data;
        this.duracionTotal = req.data.reduce((a,obj)=>{return a+obj.duracion},0);

        this.partes = Utils.groupBy(this.programa,i => i.paseId).map(m => {
          
          return {
            paseId:m[0].paseId,
            paseDesc:m[0].paseDesc,
            duracion: m.reduce((a,obj) => { return a+obj.duracion},0),
            ejercicios: this.groupEjercicios(m)
          }
        });
        console.log(this.partes)
      }
    } catch (error) {
      console.log(error);
    }
  }


  groupEjercicios(m:ProgramaDetalle[]){
    let arr:{idx:number,ejercicio:string,header:string,duracion:number,videos:{videoId:number,duracion:number}[]}[] = [];
    let last = null;
    let serieN = 1;
    for (const k of m) {
      let newObj;
      if(last!= null){

        if(last.ejercicio == k.ejercicio){
          last.duracion+=k.duracion;
          last.videos.push({videoId:k.videoId,duracion:k.duracion});
          newObj = last;
        }else{
          let vs =  [];
          vs.push({videoId:k.videoId,duracion:k.duracion});
          newObj = {
            idx:k.idx,
            ejercicio:k.ejercicio,
            duracion:k.duracion,
            videos:vs
          }
          arr.push(newObj);
        }

      }else{
        let vs =  [];
        vs.push({videoId:k.videoId,duracion:k.duracion});
        newObj = {
          idx:k.idx,
          ejercicio:k.ejercicio,
          duracion:k.duracion,
          videos:vs
        }
        arr.push(newObj);
      }
      last = newObj;
      if(k.esSerie){
        let t = serieN+'th Series';
        if(serieN == 1){
          t = '1st Series'
        }
        if(serieN == 2){
          t = '2nd Series'
        }
        if(serieN == 3){
          t = '3rd Series'
        }
        newObj.header = t;
        serieN++;
      }
    }
    return arr;
  }


  verVideo(video:any){
    let modalRef = this.modalSrv.open(VideoPlayerComponent,{windowClass:'app-modal message-modal video-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.video = video;
  }
}
