import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluacion-nuevo',
  templateUrl: './evaluacion-nuevo.component.html',
  styleUrls: ['./evaluacion-nuevo.component.css']
})
export class EvaluacionNuevoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
