import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Sesion } from '../models/sesion';

import { Grupo } from '../models/grupo';
import { Asistencia } from '../models/asistencia';




@Injectable({
  providedIn: 'root'
})

export class AsistenciaService {

  constructor(private http:HttpClient) {
  }

  public getAll()
  {
    return this.http.get<ApiResponse<Asistencia>>(`${environment.urlAPI}asistencia`).toPromise();
  }

  public getHoy()
  {
    return this.http.get<ApiResponse<Asistencia>>(`${environment.urlAPI}asistencia/hoy`).toPromise();
  }

  public getByAlumno(alumno:number,periodo:number)
  {
    return this.http.get<ApiResponse<Asistencia>>(`${environment.urlAPI}asistencia/alumno/${alumno}/${periodo}`).toPromise();
  }

  public put(asis:Asistencia)
  {
    return this.http.post<ApiResponse<Asistencia>>(`${environment.urlAPI}asistencia`,asis).toPromise();
  }
  
  public activarVarias(lista:number[])
  {
    let body = {
      asistencias:lista,
    }
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}asistencia/activar`,body).toPromise();
  }
}
