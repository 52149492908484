import { HttpClient } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { lastValueFrom, timer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-usub-mailisting',
  templateUrl: './usub-mailisting.component.html',
  styleUrls: ['./usub-mailisting.component.css']
})
export class UsubMailistingComponent implements  OnInit {


  load = false;
  router = inject(Router);
  http = inject(HttpClient);
  async ngOnInit() {
    try {
      await lastValueFrom(this.http.get(environment.urlAPI+'mailinglist/unsub/promos'));
    } catch (error) {
      
    }
    this.load = true;
    await lastValueFrom(timer(5000));
    this.router.navigateByUrl('/account',{replaceUrl:true});
  }
}
