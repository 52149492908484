import { Comunav2 } from './../models/comunav2';
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Comuna } from '../models/comuna';
import { Evaluacion } from '../models/evaluacion';
import { Nivel } from '../models/nivel';
import { Plan } from '../models/plan';
import { RegistroPago, TipoPago } from '../models/pagos';
import { Pago } from '../models/pago';
import { ActualizarDatosViewModel } from 'src/app/models/actualizardatosvm';
import { LugarClases, LugarClaseViewModel } from '../models/lugarclases';
import { UsuarioCrearMasivo } from '../models/crearmasivo';
import { HorarioProfesor } from '../models/HorarioProfesor';
import { PopUp } from '../models/popup';
import { Time } from '@angular/common';
import { AccountLink } from '../models/account-link';
import { map } from 'rxjs/operators';




@Injectable({
  providedIn: 'root'
})

export class UserService {


  constructor(private http:HttpClient) {
  }

  async getComunas()
  {
    return this.http.get<ApiResponse<Comuna>>(`${environment.urlAPI}user/comunas`).toPromise();
  }

  async getComunaId(comunaId:number){
    if(comunaId == 0){return}
    const params = {comunaId : comunaId}
    const response = await this.http.get<any>(`${environment.urlAPI}user/comunasv2`, { params }).toPromise();
    return response.data[0].nombreConcact;
  }

  async getComunas2(hint: string){
    const params = { hint: hint };
    return await this.http.get<ApiResponse<Comunav2>>(`${environment.urlAPI}user/comunasv2`, { params }).toPromise();
  }

  async getPopUp()
  {
    return this.http.get<ApiResponse<PopUp>>(`${environment.urlAPI}user/popup`).toPromise();
  }

  async linkear(){
    return this.http.post<AccountLink>(`${environment.urlAPI}pagos/stripe/linkear`,{}).toPromise();
  }

  async postPopUp(direccion:string, horario:Date, fechafin:Date, latitude:number, longitud: number, activo:number, nomDireccion:string)
  {
    const body = {
      Direccion:direccion,
      Horario:horario,
      FechaFin:fechafin,
      Latitud:latitude,
      Longitud:longitud,
      Activo:activo,
      NomDireccion:nomDireccion
    }
    return this.http.post<ApiResponse<PopUp>>(`${environment.urlAPI}user/popup-agregar`,body).toPromise();
  }


  async putPopUp(popupId: number,direccion:string, horario:Date, fechafin:Date, latitude:number, longitud: number, activo:number, nomDireccion:string){
    const body = {
      PopupId:popupId,
      Direccion:direccion,
      Horario:horario,
      FechaFin:fechafin,
      Latitud:latitude,
      Longitud:longitud,
      Activo:activo,
      NomDireccion:nomDireccion
    }
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/popup-modificar`,body).toPromise();
  }

  deletePopUp(p:PopUp)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/popup-eliminar/${p.popupId}`,{}).toPromise();
  }
  

  async getTipoEntrenamiento()
  {
    return this.http.get<ApiResponse<LugarClases>>(`${environment.urlAPI}user/entre-tipo`).toPromise();
  }

  //prueba
  async getNivel()
  {
    return this.http.get<ApiResponse<Nivel>>(`${environment.urlAPI}user/nivel`).toPromise(); // ruta
  }
  //

  async getNiveles()
  {
    return this.http.get<ApiResponse<Nivel>>(`${environment.urlAPI}nivel`).toPromise();
  }

  async getMembresias()
  {
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}user/membresias`).toPromise();
  }

  async getByRol(id:number)
  {
    return this.http.get<ApiResponse<User>>(`${environment.urlAPI}user/rol/${id}`).toPromise();
  }

  async getProfesHorario(comunaId:number)
  {
    return this.http.get<ApiResponse<HorarioProfesor>>(`${environment.urlAPI}user/profesor-horario/${comunaId}`).toPromise();
  }

  buscar(id:number,str:string)
  {
    return this.http.get<ApiResponse<User>>(`${environment.urlAPI}user/buscar/${id}/${str}`).toPromise();
  }

  async post(user:User)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/crear`,user).toPromise();
  }

  getActividad(id:number)
  {
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}user/${id}/actividad`).toPromise();
  }

  getEvaluaciones(id:number)
  {
    return this.http.get<ApiResponse<Evaluacion>>(`${environment.urlAPI}user/${id}/actividad`).toPromise();
  }


  getPlanes()
  {
    return this.http.get<ApiResponse<Plan>>(`${environment.urlAPI}plan`).toPromise();
  }

  getEvaluacionesPendientes(id:number,evaluacionId:number)
  {
    return this.http.get<ApiResponse<Evaluacion>>(`${environment.urlAPI}user/actividadPendiente/${id}/${evaluacionId}`).toPromise();
  }

  me()
  {
    return this.http.get<ApiResponse<User>>(`${environment.urlAPI}user`).toPromise();
  }

  put(user:User)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/modificar`,user).toPromise();
  }

  delete(u:User)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/eliminar/${u.id}`,{}).toPromise();
  }

  async postImagen(file)
  {
    var formData: any = new FormData();
    formData.append('archivo',file);
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/imagen`,formData).toPromise();
  }

  async getSolicitudes()
  {
    return this.http.get<ApiResponse<User>>(`${environment.urlAPI}clase/profesores/mis-solicitudes`).toPromise();
  }

  async responderSolicitud(id:number,resp:boolean)
  {
    const body = {
      soprId:id,
      respuesta:resp
    }
    return this.http.post<ApiResponse<User>>(`${environment.urlAPI}clase/profesores/mis-solicitudes`,body).toPromise();
  }


  async getPagos()
  {
    return this.http.get<ApiResponse<Pago>>(`${environment.urlAPI}user/pagos`).toPromise();
  }


  async getPagosByUseR(user:number){
    return this.http.get<ApiResponse<RegistroPago>>(`${environment.urlAPI}pago/${user}`).toPromise();
  }

  async getTiposPagos(){
    return this.http.get<ApiResponse<TipoPago>>(`${environment.urlAPI}pago/tipos`).toPromise();
  }

  async postPago(body:RegistroPago){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}pago`,body).toPromise();
  }

  async deletePago(idPago:number){
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}pago/${idPago}`).toPromise();
  }



  async getLugaresClases(idUser:number){
    return this.http.get<ApiResponse<LugarClases>>(`${environment.urlAPI}user/lugares-clases/${idUser}`).toPromise();
  }


  async agregarLugarClase(lugar:LugarClaseViewModel){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/lugares-clases/`,lugar).toPromise();
  }

  async eliminarLugarClase(idUser:number,luclId:number){
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}user/lugares-clases/${idUser}/lugar/${luclId}`).toPromise();
  }


  sendPasswordReset(email:string){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/reset-password/send`,{email:email}).toPromise();
  }

  passwordReset(body:PasswordResetBody){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/reset-password/activate`,body).toPromise();
  }

  async actualizarDatos(obj:ActualizarDatosViewModel){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/actualizar`,obj).toPromise();
}


public crearUsuarioMasivo(users:UsuarioCrearMasivo[]){
  const body = {
    usuarios:users
  }
  return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/crear-masivo`,body).toPromise();
}

  async putRol(user_id:number,rol_id:number){
    const body = {
      idUser:user_id,
      rolId:rol_id
    };
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/rol`,body).toPromise();
  }

  getMaster(pagina:number, searchStr:string = null, rolId:number= null){
    var str = `${environment.urlAPI}user/listar-maestro?page=${pagina}`;
    if(searchStr != null)
    {
      str = str+`&search=${searchStr}`;
    }
    if(rolId != null)
    {
      str = str+`&rolId=${rolId}`;
    }
    return this.http.get<ApiResponse<User>>(str).toPromise();
  }


  fixRut(rut:string)
  {
    if(rut.length < 2) return rut;
    var ret:string;

    ret = rut.replace(/^0+|[^0-9kK]+/g, '').toUpperCase();

    var result = ret.slice(-4, -1) + '-' + ret.substr(ret.length - 1)
    for (var i = 4; i < ret.length; i += 3) {
      result = ret.slice(-3 - i, -i) + '.' + result
    }


    return result;
  }
}


interface PasswordResetBody{
  code:string,
  userEmail:string,
  newPassword:string,
  newPasswordConfirm:string
}


