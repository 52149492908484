import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/helpers/Utils';
import { ActualizarDatosViewModel } from 'src/app/models/actualizardatosvm';
import { HorarioProfesor } from 'src/app/models/HorarioProfesor';
import { LugarClases, LugarClaseViewModel } from 'src/app/models/lugarclases';
import { Pago } from 'src/app/models/pago';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { WebService } from 'src/app/services/web.service';
import { ActualizarDatosComponent } from './actualizar-datos/actualizar-datos.component';
import { ActualizarLugarClasesComponent } from './actualizar-lugar-clases/actualizar-lugar-clases.component';

@Component({
  selector: 'app-mi-cuenta',
  templateUrl: './mi-cuenta.component.html',
  styleUrls: ['./mi-cuenta.component.css']
})
export class MiCuentaComponent implements OnInit {

  genders= ['-','Masculino','Femenino','Otro'];
  loading = true;
  user:User;
  pagos:Pago[] = [];
  diasSemana:string[] = [];

  horarios:HorarioProfesor[] = [];

  suscripcion:Pago = null;

  userModel:User;

  lugares:LugarClases[] = [];

  linkAdmPago:string = null;

  comunaNombre:string;

  constructor(public authService:AuthService,
    private modalService:ModalService,
    private userService:UserService,
    public lang:LangService,
    private webService:WebService) { }

  ngOnInit(): void {

    this.load();
    this.diasSemana = this.lang.text('dias').split(',')
  }

  async load(){
    this.loading = true;

    try {
      const req = await this.userService.me();
      this.user = req.data[0];
      console.log('user',req.data[0])
      this.comunaNombre = `${req.data[0]['comunaNombre']}, ${req.data[0]['regionNombre']}`;

      const req2 = await this.userService.getPagos();
      this.pagos = req2.data;

      const req3 = await this.userService.getProfesHorario(185);
      this.horarios = req3.data;
      

      this.suscripcion = this.pagos.find(i => i.repaVigente);

      if(this.suscripcion?.tipaId == -1){
        const req = await this.webService.stripePortal();
        this.linkAdmPago = req.message;
        this.suscripcion.repaFhExp = req.data[0].fhFin;
        console.log(this.linkAdmPago);
      }

    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }

    try {
      const req = await this.userService.getLugaresClases(this.user.id);
      this.lugares = [];
      console.log('horarios',this.lugares)

      
      for (const i of Utils.groupBy(req.data, i => i.luclId)) {
        i[0].dias=[]
        console.log('dias',i[0].dias)
        for (const j of i) {
          i[0].dias.push(j.luclDia)
          
        }
        this.lugares.push(i[0])
      }
      
    } catch (error) {
      
    }
    this.loading = false;
  }



  async modificarDatos()
  {
    let modalRef = this.modalService.open(ActualizarDatosComponent,{windowClass:'app-modal top-modal',size:'md',backdropClass:'no-backdrop',backdrop:'static'});
    console.log(this.user.comunaId)
    const model:ActualizarDatosViewModel = {
      userNombre:this.user.userNombre,
      userApellido:this.user.userApellido,
      userTelefono:this.user.userTelefono,
      userImagen:this.user.userImagen,
      comunaId:this.user.comunaId,
      userComuna:this.comunaNombre

    };

    modalRef.componentInstance.viewModel = model;
    try {
      await modalRef.result;
      await this.load();
    } catch (error) {

    }
  }


  async editarLugarClases(lugar:LugarClases){
    console.log('lugar', lugar);

    const vm:LugarClaseViewModel = {
      luclId: lugar.luclId, luclDesc: lugar.luclDesc, luclPrecioRef: lugar.luclPrecioRef, comunaId: lugar.comunaId,
      luclDia: '',
      luclEntre: lugar.entreId,
      luclHora: lugar.horario,
      luclPrecioRefMen: 0,
      luclDia2: undefined,
      dias: [] = lugar.dias
    };
    
    this.modalLugarClases(vm);
  }

  async eliminarLugarClases(lugar:LugarClases){
    const question = await this.modalService.confirm('Confirmar eliminación','Eliminar','Cancelar');
    if(question){
      try {
        const req = await this.userService.eliminarLugarClase(this.user.id,lugar.luclId);
        this.load();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }


  async nuevoLugarClases(){
    
    
    const vm:LugarClaseViewModel = {
      luclDesc: '', luclPrecioRef: 0, comunaId: this.user.comunaId,
      luclDia: '',
      luclHora: undefined,
      luclEntre: 0,
      luclPrecioRefMen: 0,
      luclDia2: undefined,
      dias: []
    };
    
    this.modalLugarClases(vm);
    
  }

  async modalLugarClases(vm:LugarClaseViewModel){
    const loader = await this.modalService.showLoader();
    let modalRef = this.modalService.open(ActualizarLugarClasesComponent,{windowClass:'app-modal top-modal',size:'md',backdropClass:'no-backdrop',backdrop:'static'});

    modalRef.componentInstance.viewModel = vm;
    try {
      await modalRef.result;
      await this.load();
    } catch (error) {

    }
    loader.dismiss();
  }

  async cerrarSesion(){
    this.authService.logout();
  }
}
