<div class="modal-alert">
      <div class="modal-body left">
          <h4>{{lang.text('videos.nuevo_video')}}:</h4>
          <form>
            <label class="app-label">{{lang.text('videos.nombre')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="videoNombre" name="videoNombre">
            <label class="app-label">{{lang.text('videos.nombre_app')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="videoDesc" name="videoDescripcion">
            <label class="app-label">{{lang.text('videos.desc')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="videoDescEjercicio" name="videoDescripcionEjercicio">

            <label class="app-label">{{lang.text('videos.duracion')}}:</label>
            <input class="app-input" type="time" [(ngModel)]="videoDuracion" name="videoTiempo">
            <div class="app-checkbox mt-2" >
                  <input type="checkbox" [(ngModel)]="videoPremium" name="videoPremium" id="videoPremium"> <label for="videoPremium">{{lang.text('videos.premium')}}</label>
            </div>
            <label class="app-label">{{lang.text('videos.tipo')}}:</label>

            <select [(ngModel)]="tipoVideoId" name="tipoVideoId" class="app-combo">
                  <option value="null" disabled>{{lang.text('seleccionar')}}</option>
                  <option *ngFor="let tv of tiposVideo" value="{{tv.tipoVideoId}}">{{tv.tipoVideoDesc}}</option>
            </select>

            <label class="app-label">{{lang.text('videos.caracteristicas')}}:</label>
            <div class="app-lista-checkbox">
                  <div class="app-checkbox" *ngFor="let cts of caracteristicas">
                        <input type="checkbox" [(ngModel)]="cts.selected" name="{{cts.caracteristicaNombre}}-{{cts.caracteristicaId}}" id="{{cts.caracteristicaNombre}}-{{cts.caracteristicaId}}"> <label for="{{cts.caracteristicaNombre}}-{{cts.caracteristicaId}}">{{cts.caracteristicaNombre}}</label>
                  </div>
            </div>
            <label class="app-label">{{lang.text('videos.archivo')}}:</label>
            <div class="progress mb-3" *ngIf="progress > 0">
                  <div class="progress-bar  bg-success" role="progressbar" [style.width.%]="progress">
                  </div>
            </div>
            <h6 *ngIf="videoFile" class="text-center">{{videoFile}}</h6>
            <button type="button" class="app-btn w-100 outline secondary" (click)="fileChooser.click()">{{lang.text('videos.seleccionar_archivo')}}</button>
            <input #fileChooser style="display: none;" type="file" [(ngModel)]="videoFile" id="archivo" name="archivo">
          </form>
          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div *ngIf="uploading" class="loading-bar" >

      </div>
      <div class="modal-footer">
          <button type="button" class="app-btn outline" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" ><fa-icon icon="times"></fa-icon> {{lang.text('cancelar')}}</button>
          <button type="button" class="app-btn outline" [disabled]="uploading" (click)="agregarVideo()" ><fa-icon icon="save"></fa-icon> {{lang.text('agregar')}}</button>
      </div>
</div>
