import { Component } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-logusuarios',
  templateUrl: './logusuarios.component.html',
  styleUrls: ['./logusuarios.component.css']
})
export class LogusuariosComponent {

  constructor(public lang:LangService){
    
  }
}
