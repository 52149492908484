import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from 'src/app/alert-modal-component/alert-modal-component.component';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {


  userInput:string;
  userPass:string;

  loading= false;

  constructor(private authService:AuthService,
              private modalService:ModalService,
              private router:Router,
              private activeRoute:ActivatedRoute,
              private userService:UserService,
              @Inject(DOCUMENT) private _document,
              public lang:LangService) { }

  ngOnInit(): void {
    this._document.body.classList.add('login-background');
  }
  ngOnDestroy(){
    this._document.body.classList.remove('login-background');
  }


  async doLogin()
  {
    //if(!this.userInput || !this.userPass) return;

    this.loading = true;
    try {
      let req = await this.authService.login(this.userInput,this.userPass);
      //let redirect = (this.activeRoute.snapshot.queryParams.returnUrl? this.activeRoute.snapshot.queryParams.returnUrl : '/inicio');
      await this.router.navigateByUrl('/inicio');
    } catch (error) {
      console.log(error);
      this.modalService.showError(error.message,'Aceptar');
    }
    this.loading = false;
  }
}

