import { Component, HostListener, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { Caracteristica } from 'src/app/models/caracteristica';
import { Grupo } from 'src/app/models/grupo';
import { User } from 'src/app/models/User';
import { Video } from 'src/app/models/video';
import { AuthService } from 'src/app/services/auth-service.service';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { WebSocketService } from 'src/app/services/ws.service';
import { environment } from 'src/environments/environment';
import { VideoPlayerComponent } from '../video-player/video-player.component';
import { VideoEditarComponent } from './video-editar/video-editar.component';
import { VideoNuevoComponent } from './video-nuevo/video-nuevo.component';

@Component({
  selector: 'app-videos',
  templateUrl: './videos.component.html',
  styleUrls: ['./videos.component.css']
})
export class VideosComponent implements OnInit {


  loading = true;
  userModel:User;

  videos:Video[] = [];

  previewUrl:string = `${environment.urlAPI}user/imagen/`;

  currentPage = 1;
  searchStr:string = null;


  filtroTag:number = null;
  filtroGrupo:number = null;

  tags:Caracteristica[] = [];
  grupos:Grupo[] = [];
 

  constructor(private videoService:VideoService,
              private modalService:ModalService,
              private authService:AuthService,
              private grupoService:GrupoService,
              public lang:LangService,
              private wsService:WebSocketService) { }

  ngOnInit(): void {
    this.onLoad();

  }

  async onLoad()
  {
    this.loading=true;
    this.searchStr = null;
    this.userModel = this.authService.currentUserValue;
    await this.loadVideos();
    await this.loadOther();
    this.loading=false;
    this.currentPage = 1 ;
  }


  async loadOther(){
    try {
      const req1 = await this.grupoService.getAll();
      this.grupos = req1.data;

      const req2 = await this.videoService.getAllCaracteristicas();
      this.tags = req2.data;
    } catch (error) {
      
    }
  }
  
  async refresh()
  {
    this.loading = true;
    await this.loadVideos();
    this.loading = false;
  }


  async agregarNuevo()
  {
    let modalRef = this.modalService.open(VideoNuevoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop',backdrop:'static'});
    try {
      await modalRef.result;
      this.refresh();
    } catch (error) {
      
    }
  }


  async optimizarVideo(video:Video)
  {
    const resp = await this.modalService.confirm('¿Esta seguro? Esto podria tomar un poco...','Ok','No');
    if(!resp) return;
    
    try{
      let resp = await this.videoService.reProcesarVideo(video.videoId);
      this.modalService.showError('El video será optimizado... puede tardar un poco.','OK')
    }
    catch(error)
    {
      this.modalService.showError(error.message,'ok');
    }
  }

  async modificarVideo(video:Video)
  {
    let modalRef = this.modalService.open(VideoEditarComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.videoId = video.videoId;
    modalRef.componentInstance.lc = video.listaCaracteristicas;
    try {
      await modalRef.result;
      this.refresh();
    } catch (error) {
      
    }
  }

  async loadVideos()
  {
    try {
      let request = await this.videoService.getAllEx(this.currentPage, this.searchStr,this.filtroTag,this.filtroGrupo);
      this.videos = request.data;
      for (const iterator of this.videos) {
        this.loadCaracteristicas(iterator);
      }
      return this.videos.length;
    } catch (error) {
      this.modalService.showError(error.message,'ok');
    }
  }

  async loadCaracteristicas(video:Video)
  {
    video.loadingCaracteristicas = true;
    try {
      const req = await this.videoService.getCaracteristicas(video.videoId);
      video.listaCaracteristicas = req.data;
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }
    
    video.loadingCaracteristicas = false;
  }

  async verVideo(video:Video)
  {
    let modalRef = this.modalService.open(VideoPlayerComponent,{windowClass:'app-modal message-modal video-modal' ,size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.video = video;
    try {
      await modalRef.result;
    } catch (error) {
    }
  }

  async eliminarVideo(video:Video)
  {
    let confirmar = await this.modalService.confirm('videos.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.videoService.delete(video);
        await this.loadVideos();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }


  async prev(){
    if(this.currentPage > 1)
    {
      this.currentPage --;
      this.loading=true;
      await this.loadVideos();
      this.loading=false;
    }
  }
  async next(){
    this.currentPage++;
    this.loading=true;
    let total = await this.loadVideos();
    this.loading=false;
  }

  async searchChange(){
    this.currentPage = 1;
    this.loading = true;
    await this.loadVideos();
    this.loading = false;
  }
}
