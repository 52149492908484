import { Component, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Caracteristica } from 'src/app/models/caracteristica';
import { Grupo } from 'src/app/models/grupo';
import { Video } from 'src/app/models/video';
import { GrupoService } from 'src/app/services/grupo.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { LangService } from 'src/app/services/lang.service';


@Component({
  selector: 'app-administrar-grupo',
  templateUrl: './administrar-grupo.component.html',
  styleUrls: ['./administrar-grupo.component.css']
})
export class AdministrarGrupoComponent implements OnInit {

  @Input() grupo:Grupo;

  //grupo:Grupo;
  videos:Video[] = [];
  videosSel:Video[] = [];
  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];
  filtro:Caracteristica = null;
  caracteristicas:Caracteristica[] = [];

  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,private grupoService:GrupoService,private videoService:VideoService, private modalService:ModalService, public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad()
  {
    try {

      //const req = await this.videoService.ge
      const request = await this.videoService.getAll();
      this.videos = request.data;
      const request2 = await this.videoService.getByGrupo(this.grupo.grupoId);
      this.videosSel = request2.data;
      const request3 = await this.videoService.getAllCaracteristicas();
      this.caracteristicas = request3.data;

    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    }
  }

  async agregar(video:Video)
  {
    let find = this.videosSel.find(el => el.videoId == video.videoId);
    if(!find)
    {
      this.videosSel.push(video);
    }
  }

  async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }

    this.loadingVideos = false;
  }

  async remover(video:Video)
  {
    let v = this.videosSel.findIndex(el => el.videoId == video.videoId);
    this.videosSel.splice(v,1);
  }

  async guardar(){
    this.uploading= true;
    try {
      let v = this.grupo;
      v.videos = [];
      for (const iterator of this.videosSel) {
        v.videos.push(iterator.videoId);
      }
      const req = await this.grupoService.update(v);

      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

}
