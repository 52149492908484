<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{lang.t('ses.copiar')}} # {{sesion.cacuId}}:</h4>

        <label class="app-label">{{lang.t('ses.copiar_info_a_sesiones')}}</label>
        <input  class="app-input" type="search" placeholder="{{lang.t('buscar')}}" [(ngModel)]="searchStr" name="buscar" (keyup)="filter()">
            <div class="app-lista-checkbox">
                  <div class="app-checkbox" *ngFor="let cts of sesiones">
                        <input type="checkbox" (change)="refreshing()" [(ngModel)]="cts.selected" name="{{cts.cacuNombre}}-{{cts.cacuId}}"> <label for="{{cts.cacuNombre}}-{{cts.cacuId}}">{{cts.cacuNombre}}</label>
                  </div>
            </div>

        <label for="segment" class="app-label">{{lang.t('ses.copiar_a')}} {{aCopiar.length}}:</label>
        <div class="segment">
            <span *ngFor="let i of aCopiar" class="badge badge-pill badge-info">{{i.cacuNombre}}</span>
        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
      <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
      <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()"><fa-icon icon="check"></fa-icon> {{lang.t('ses.copiar_save')}}</button>
    </div>
</div>
