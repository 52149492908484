<div class="container view">

    <div class="botones-opciones full ciclos">
        <button class="app-btn" [routerLink]="['/cursos']">{{lang.t('menu.curs')}}</button>
        <button class="app-btn" [routerLink]="['/modulos']">{{lang.t('menu.modu')}}</button>
        <button class="app-btn active" [routerLink]="['/lecciones']">{{lang.t('menu.lecc')}}</button>
        <button class="app-btn" [routerLink]="['/quizes']">{{lang.t('menu.quiz')}}</button>
        <button class="app-btn" [routerLink]="['/preguntas']">{{lang.t('menu.preg')}}</button>
    </div>
    <div class="ciclos-border">
        <div class="botones-opciones mb-3">
            <button class="app-btn" title="{{lang.text('btn.add')}}" (click)="agregarNuevo()"><fa-icon icon="plus"></fa-icon></button>
            <button class="app-btn" title="{{lang.text('btn.actu')}}" (click)="refresh()"><fa-icon icon="retweet"></fa-icon></button>

            



        </div>
        <div *ngIf="!this.loading" class="table-container">
            <table class="app-table">
                <thead>
                    <th>
                        #
                    </th>
                    <th>
                        {{lang.text('lecc.nombre')}}
                    </th>
                    <th>
                        {{lang.text('lecc.desc')}}
                    </th>
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let lecc of this.lecciones;index as idx">
                        <td>{{idx+1}}</td>
                        <td>{{lecc.leccNombre}}</td>
                        <td>{{lecc.leccDesc}}</td>

                        <td>
                            <button class="app-btn small" title="{{lang.text('lecc.modificar')}}" (click)="modificar(lecc)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                            <button class="app-btn small" title="{{lang.text('lecc.administrarVideo')}}" (click)="agregarVideo(lecc)" title="{{lang.t('btn.addVideo')}}"><fa-icon icon="video"></fa-icon></button>
                            <button class="app-btn small" title="{{lang.text('lecc.administrarDocumento')}}" (click)="agregarDocumento(lecc)" title="{{lang.t('btn.addDocu')}}"><fa-icon icon="file-import"></fa-icon></button>
                             <button class="app-btn small red" (click)="eliminar(lecc)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt" ></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="this.loading" class="loader">

        </div>
        <h6 *ngIf="this.lecciones.length == 0 && !this.loading">{{lang.t('nada_por_aqui')}}</h6>
    </div>
</div>
