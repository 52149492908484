import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/models/ApiResponse';
import { leccion } from 'src/app/models/campus/leccion';
import { Modulo, ModuloLeccion, ModuloQuiz } from 'src/app/models/campus/modulo';
import { quiz } from 'src/app/models/campus/quiz';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ModuloService {

  constructor(private http:HttpClient) { }


  public async get(quizId?:number)
  {
    const body = Params.toHttpParams({quizId:quizId});
    return this.http.get<ApiResponse<Modulo>>(`${environment.urlAPI}campus/Modulo`,{params:body}).toPromise();
  }
  //no espera nada de vuelta
  public async put(req:Modulo)
  {
    return this.http.put<ApiResponse<Modulo>>(`${environment.urlAPI}campus/Modulo`,req).toPromise();
  }

//este si puede llegar algo de vuelta
  public async post(req:Modulo)
  {
    return this.http.post<ApiResponse<Modulo>>(`${environment.urlAPI}campus/Modulo`,req).toPromise();
  }



  public async getModuloLeccion(moduId:number,LeccId?:number)
  {
    const body = Params.toHttpParams({moduId:moduId,pregId:LeccId});
    return this.http.get<ApiResponse<leccion>>(`${environment.urlAPI}campus/Leccion/modulo`,{params:body}).toPromise();
  }



  public async postModuloLeccion(req:ModuloLeccion)
  {
    return this.http.put<ApiResponse<ModuloLeccion>>(`${environment.urlAPI}campus/Leccion/modulo`,req).toPromise();
  }


  public async getModuloQuiz(moduId:number,quizId?:number)
  {
    const body = Params.toHttpParams({moduId:moduId,quizId:quizId});
    return this.http.get<ApiResponse<quiz>>(`${environment.urlAPI}campus/quiz/modulo`,{params:body}).toPromise();
  }


  public async postModuloQuiz(req:ModuloQuiz)
  {
    return this.http.put<ApiResponse<ModuloQuiz>>(`${environment.urlAPI}campus/quiz/modulo`,req).toPromise();
  }


  
  public async DeleteModulo(moduId?:number)
  {
    const body = Params.toHttpParams({moduId:moduId});
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}campus/modulo/curso`,{params:body}).toPromise();
  }





}
export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}