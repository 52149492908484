import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth-service.service';

@Component({
  selector: 'app-re-login',
  templateUrl: './re-login.component.html',
  styleUrls: ['./re-login.component.css']
})
export class ReLoginComponent implements OnInit {

  constructor(private activatedRoute:ActivatedRoute,private router:Router,private auth:AuthService) {
    this.loginAndRedirect();
  }


  async loginAndRedirect(){
    await this.load();
    await this.redirect();
  }

  ngOnInit(): void {
  }

  async load(){
    try {
      const token = this.activatedRoute.snapshot.params.token as string;
      if(this.auth.currentUserValue != null) throw {msg:'ya_user_login'};
      const login = await this.auth.loginWithJwt(token);
    } catch (error) {
      console.error(error);
    }
    
  }

  async redirect(){
    const redirect = this.activatedRoute.snapshot.params.redirectUrl;
    if(redirect){
      this.router.navigateByUrl('/'+redirect);
    }else{
      await this.router.navigateByUrl('/');
    }
    
  }
}
