import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import {  NavigationEnd, Router } from '@angular/router';
import {  NavigationStart, Event as NavigationEvent } from '@angular/router';
import { User } from 'src/app/models/User';

import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
  
})
export class MenuComponent implements OnInit {


  isAuth: boolean = false;
  userModel:User;
  isInWeb:boolean;

  showMenu = false;

  constructor(private authService:AuthService,private router:Router,private modalService:ModalService,public lang:LangService) { 
    this.authService.currentUser.subscribe( val => {
      this.isAuth = (val? true:false);
      this.userModel = val;
    });
  }

  

  ngOnInit(): void {

  }

  cerrarSesion()
  {
    this.authService.logout();
  }

  toggleMenu(){
    this.showMenu = !this.showMenu;
  }

}
