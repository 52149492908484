import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomSelectOption } from 'src/app/models/customselectoption';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-options-alert',
  templateUrl: './options-alert.component.html',
  styleUrls: ['./options-alert.component.scss']
})
export class OptionsAlertComponent {

  @Input() items:CustomSelectOption[];

  constructor(private modalRef:NgbActiveModal,public lang:LangService){

  }

  select(item:CustomSelectOption){
    this.modalRef.close(item);
  }
}
