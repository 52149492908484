<div class="container-md" style="max-width: 960px;">
    <div class="botones-opciones full ciclos">
        <button class="app-btn " [routerLink]="['/account']" >{{lang.text('acco.h1')}}</button>
        <button class="app-btn" [routerLink]="['/calendar']" >{{lang.text('acco.calendar')}}</button>
        <button class="app-btn active" [routerLink]="['/prices']" >{{lang.text('acco.payments')}}</button>
        <button class="app-btn " [routerLink]="['/sells']" >{{lang.text('acco.sells')}}</button>

    </div>
    <div class="header g-border p-3">
        <h2>{{lang.text('acco.price.paquetes')}}</h2>
        <p>{{lang.text('acco.price.ex')}}</p>
        <div class="table-container">
            <table class="app-table">
                <thead>
                    <th>

                    </th>
                    <th>
                        {{lang.text('numero_clases')}}
                    </th>
                    <th>
                        {{lang.text('precio')}}
                    </th>
                    <th>
                        {{lang.text('precio_por_clase')}}
                    </th>
                    <th>
                        {{lang.text('comision_golazo')}} 
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of paquetes">
                        <td>
                            {{i.paenDesc}}
                        </td>
                        <td>
                            {{i.paenDias}}
                        </td>
                        <td>
                            $ {{i.price / 100}} {{i.currency}}
                        </td>
                        <td>
                            $ {{round(i.price / 100 / i.paenDias)}} {{i.currency}}
                        </td>
                        <td>
                            ${{i.comision /100}} {{i.currency}}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>    
</div>
