import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Clase } from 'src/app/models/clase';
import { Video } from 'src/app/models/video';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { environment } from 'src/environments/environment';
import { VideoPlayerComponent } from '../../video-player/video-player.component';

@Component({
  selector: 'app-videos-clase',
  templateUrl: './videos-clase.component.html',
  styleUrls: ['./videos-clase.component.css']
})
export class VideosClaseComponent implements OnInit {

  @Input() clase:Clase;
  loading = true;
  videos:Video[] = [];
  previewUrl:string = `${environment.urlAPI}user/imagen/`;


  constructor(public activeModal:NgbActiveModal,
    private modalSrv:ModalService,
    private catSrv:CategoriaService,
    private videoSrv:VideoService,
    public lang:LangService) { }

  ngOnInit(): void {
  
    this.load();
  }

  async load(){
    try {
      const req = await this.videoSrv.getBySesion(this.clase.cacuId);
      this.videos = req.data.filter(i=> i.mostrarContador);
    } catch (error) {
      await this.activeModal.dismiss();
      this.modalSrv.showError(error.message,'aceptar');
    }
    this.loading = false;
  }


  async verVideo(video:Video){
    let modalRef = this.modalSrv.open(VideoPlayerComponent,{windowClass:'app-modal message-modal video-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.video = video;
    try {
      await modalRef.result;
    } catch (error) {
    }
  }

}
