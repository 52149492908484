import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Evaluacion } from 'src/app/models/evaluacion';
import { User } from 'src/app/models/User';
import { AsistenciaService } from 'src/app/services/Asistencias.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-alumno-editar',
  templateUrl: './alumno-editar.component.html',
  styleUrls: ['./alumno-editar.component.css']
})
export class AlumnoEditarComponent implements OnInit {

  loading = true;
  uploading = false;

  errores:any[] = [];
  @Input() userModel:User;

  periodos:Evaluacion[]=[];

  asistencias:any[] = [];
  niveles:any[] = [];

  evaluacion:Evaluacion;

  observacion:string;
  nivel:number;

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
              private asistenciaService:AsistenciaService, private evaluacionService:EvaluacionService,public lang:LangService) { }

  ngOnInit(): void {
    this.refresh();
  }

  async refresh(){
    this.loading= true;
    await this.onLoad();
    this.loading = false;
  }

  async onLoad(){
      console.log("Evaluaciones:" + this.evaluacion);
      try {
        //const req = await this.userService.getEvaluacionesPendientes(this.userModel.id, this.evaluacion.evaluacionId);
        const req = await this.userService.getEvaluaciones(this.userModel.id);
        this.periodos = req.data;
        const req2 = await this.userService.getNiveles();
        this.niveles = req2.data;
        console.log(req.data);
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.activeModal.dismiss('error');
      }
  }

  

  async guardar(){

    if(!this.evaluacion) return;
    if(!this.observacion  || this.observacion.length < 1) return;
    if(this.nivel < 1) return;

    console.log(this.evaluacion);

    this.uploading=true;
    try {
      this.evaluacion.nivelId = this.nivel;
      this.evaluacion.evaluacionObservacion = this.observacion;
      this.evaluacion.alumnoId = this.evaluacion.id;
      
      const req = await this.evaluacionService.post(this.evaluacion);
      this.activeModal.close('ok');
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.uploading = false;
  }



}
