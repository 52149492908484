import { Component, OnInit } from '@angular/core';
import { Curso } from 'src/app/models/campus/curso';
import { User } from 'src/app/models/User';
import { CursoService } from 'src/app/services/campus/curso.service';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { AlumnoListarDocumentosComponent } from '../alumnos/alumno-listar-documentos/alumno-listar-documentos.component';
import { EditarCursoComponent } from './editar-curso/editar-curso.component';
import { ListarDocumentosCursoComponent } from './listar-documentos-curso/listar-documentos-curso.component';
import { NuevoCursoComponent } from './nuevo-curso/nuevo-curso.component';

@Component({
  selector: 'app-cursos',
  templateUrl: './cursos.component.html',
  styleUrls: ['./cursos.component.css']
})
export class CursosComponent implements OnInit {



  cursos:Curso[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  users:User[] = [];

  constructor(private modalService:ModalService, private cursoService:CursoService,public lang:LangService, private userService:UserService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
    await this.onLoadUser();
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {
      const req = await this.cursoService.get();
      this.cursos = req.data;
      
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregarNuevo()
  {
     let modalRef = this.modalService.open(NuevoCursoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {

    }
  }

  async modificar(cursoId?:Curso)
  {
     let modalRef = this.modalService.open(NuevoCursoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
     modalRef.componentInstance.cursParam = cursoId;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {

    }
  }

   async agregarModulo(curso:Curso){
      let modalRef = this.modalService.open(EditarCursoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.moduParam = curso;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {

    }
  }


/*   async agregarQuiz(modulo:Modulo){
    let modalRef = this.modalService.open(AgregarQuizComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
  modalRef.componentInstance.moduParam = modulo;
  try {
    await modalRef.result;
    await this.refresh();
  } catch (error) {

  }
}
 */
  async eliminar(curso:Curso)
  {
    let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.cursoService.DeleteCurso(curso.cursId);
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }


  async verDocs(curso:Curso)
  {
    let modalRef = this.modalService.open(ListarDocumentosCursoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.curso = curso;

    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {

    }
  }



  async onLoadUser()
  {
    try {
      /* const req = await this.userService.getByRol(1);
      this.users = req.data; */

      //await this.cargarSolicitudes();
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }


}
