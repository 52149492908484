import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { Documento, DocumentoService } from 'src/app/services/documento.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-alumno-listar-documentos',
  templateUrl: './alumno-listar-documentos.component.html',
  styleUrls: ['./alumno-listar-documentos.component.css']
})
export class AlumnoListarDocumentosComponent implements OnInit {

  @Input() user:User;
  docs:Documento[] = [];
  uploading = false;
  loading = false;
  errores: any[] = [];
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
    private periodoService:PeriodoService, private evaluacionService:EvaluacionService
    ,private catService:CategoriaService, private docService:DocumentoService,private authService:AuthService,public lang:LangService) { }

  ngOnInit(): void {
    this.init();
  }

  init(){
    this.load()
  }


  async refresh(){
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  async uploadFiles(files:any[]){
    if(!this.uploading){
      for (const i of files) {
        console.log(i);
        this.uploading = true;
        try {
          const req = await this.docService.post(this.user.id,i);
        } catch (error) {
          this.modalService.showError(error.message,'OK');
        }
      }
      this.uploading = false;
      this.refresh();
    }
  }

  async onFileDropped(event) {
    console.log(event);
    this.uploadFiles(event);
  }

  async load(){
    try {
      const req = await this.docService.getAll(this.user.id);
      this.docs = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
    this.loading = false;
  }


  async download(doc:Documento){
    window.open(environment.urlAPI+'documento/descargar/'+doc.docuId+'?token='+this.authService.currentUserValue.token,'_system');
  }

  async enviar(doc:Documento){
    const resp = await this.modalService.confirm('doc.enviar_confirmar','Ok','Cancelar');
    if(resp){
      const loader = await this.modalService.showLoader()
      try {
        const req = await this.docService.enviarDoc(this.user.id,doc.docuId);
        loader.dismiss();
        this.modalService.showError('doc.enviado','ok');
      } catch (error) {
        loader.dismiss();
        this.modalService.showError(error.message,'Ok')
      }

    }
  }

  async delete(doc:Documento){
    try {
      const req = await this.docService.delete(doc.docuId);
      this.refresh();
    } catch (error) {
      this.modalService.showError(error.message,'ok');
    }
  }
}
