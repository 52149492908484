import { Component } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-adm-paquetes',
  templateUrl: './adm-paquetes.component.html',
  styleUrls: ['./adm-paquetes.component.css']
})
export class AdmPaquetesComponent {

  /**
   *
   */
  constructor(public lang:LangService) {
    
  }
}
