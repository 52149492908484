<div class="modal-alert">
    <div class="modal-body left">
        <table class="app-table" *ngIf="!newMode">
            <tr>
                <td>{{clase.cacuNombreApp}}</td>
                <td>{{lang.text('tiempo')}} {{clase.cacuEntradaTiempo + clase.cacuPrincipalTiempo+clase.cacuVueltaTiempo}}m</td>
                <td>

                </td>
            </tr>
            <tr>
                <td>{{lang.text('objetivo')}}</td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{clase.cacuObj}}</p></td>
            </tr>
            <tr>
                <td>{{lang.text('comunicacion')}}</td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{clase.cacuComunicacion}}</p></td>
            </tr>
            <tr>
                <td>{{lang.text('implementos')}}</td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{clase.cacuImplementos}}</p></td>
            </tr>
            <tr>
                <td>{{lang.text('entrada_calor')}}</td>
                <td>{{clase.cacuEntradaTiempo}}</td>
                <td><p style="white-space: pre-wrap;">{{clase.cacuEntradaDesc}}</p></td>
            </tr>
            <tr>
                <td>{{lang.text('principal')}}</td>
                <td>{{clase.cacuPrincipalTiempo}}</td>
                <td><p style="white-space: pre-wrap">{{clase.cacuPrincipalDesc}}</p></td>
            </tr>
            <tr>
                <td>{{lang.text('vuelta')}}</td>
                <td>{{clase.cacuVueltaTiempo}}</td>
                <td><p style="white-space: pre-wrap;">{{clase.cacuVueltaDesc}}</p></td>
            </tr>
        </table>



        <table class="app-table" *ngIf="newMode">
            <thead>
                <th></th>
                <th></th>
                <th></th>
                <th style="width: 50%;"></th>
            </thead>
            <tr>
                <td>{{programaHeader.cacuNombreApp}}</td>
                <td>{{lang.text('tiempo')}} {{duracionTotal|hs}}m</td>
                <td>
                </td>
                <td>
                    
                </td>
            </tr>
            <tr>
                <td>{{lang.text('objetivo')}}</td>
                <td></td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{programaHeader.cacuObj}}</p></td>
                
            </tr>
            <tr>
                <td>{{lang.text('comunicacion')}}</td>
                <td></td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{programaHeader.cacuComunicacion}}</p></td>
                
            </tr>
            <tr>
                <td>{{lang.text('implementos')}}</td>
                <td></td>
                <td></td>
                <td><p style="white-space: pre-wrap;">{{programaHeader.cacuImplementos}}</p></td>
                
            </tr>
            <tr *ngFor="let p of partes">
                <td>{{lang.text(p.paseId)}}</td>
                <td>{{p.duracion |hs}}</td>
                <td><p *ngIf="p.ejercicios.length < 1" style="white-space: pre-wrap;">{{p.paseDesc}}</p></td>
                <td style="white-space: pre-wrap;">
                    <p>
                        <span *ngFor="let e of p.ejercicios">
                            <strong *ngIf="e.header"><hr>{{e.header}}<br></strong>
                            {{e.duracion |hs}} {{e.ejercicio}} 
                            
                            <span (click)="verVideo(e.videos[0])" class="material-symbols-rounded video-span"  >video_library</span>

                            <br>
                        </span>
                        
                    </p>
                </td>
                
            </tr>
            
        </table>

    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
    </div>
</div>
