<div class="container view">
  <app-adm-btns>
    
  </app-adm-btns>
  <h1>{{lang.text('log.header')}}</h1>
    <ng-container *ngIf="!loading">
        <div class="table-containers">
            <table #tabla class="app-table"  kTable pageSize="15" >
                  <thead>
                    <th>{{lang.text('fecha')}}</th>
                    <th>{{lang.text('tipo_log')}}</th>
                    <th></th>
                  </thead>
                  <tbody>
                    <tr *ngFor="let i of registros">
                        <td>{{i.logeFh | date:'medium'}}</td>
                        <td>{{lang.text(i.logeType)}}</td>
                        <td>{{i.logeText}}</td>
                    </tr>
                  </tbody>
            </table>
    
        </div>
    
        <app-paginator [tableRef]="tabla">
        </app-paginator>
    </ng-container>
    
    
    <div *ngIf="this.loading" class="loader">

    </div>


</div>
