import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { TipoVideo } from '../models/tipovideo';




@Injectable({
  providedIn: 'root'
})

export class VideoService {
  

  constructor(private http:HttpClient) {
  }

  public getAll(){
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}video`).toPromise();
  }

  public getAllEx(page = 1, search = null, filtroTag = null, filtroGrupo = null){
    var str = `${environment.urlAPI}video?page=${page}`;
    if(search != null)
    {
      str = str+`&search=${search}`;
    }
    if(filtroTag != null){
      str = str+`&filtroTag=${filtroTag}`;
    }
    if(filtroGrupo != null){
      str = str+`&filtroGrupo=${filtroGrupo}`;
    }
    return this.http.get<ApiResponse<Video>>(str).toPromise();
  }

  public getBySesion(id:number)
  {
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}categorias/sesion/${id}`).toPromise();
  }

  public get(id:number)
  {
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}video/${id}`).toPromise();
  }

  public getCampus(id?:number)
  {
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}campus/leccion/campus/${id}`).toPromise();
  }

  public getByGrupo(id:number)
  {
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}video/grupo/${id}`).toPromise();
  }


  public reProcesarVideo(id:number)
  {
    return this.http.post<ApiResponse<Video>>(`${environment.urlAPI}video/optimizar/${id}`,{videoId:id}).toPromise();
  }

  public getByCaracteristica(id:number)
  {
    return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}video/caracteristica/${id}`).toPromise();
  }

  public post(video:Video): Observable<any> {
    var formData: any = new FormData();

    if(video.videoId > 0)
    {
      formData.append("videoId", video.videoId);
    }

    formData.append("videoNombre", video.videoNombre);
    formData.append("videoDescripcion", video.videoDescripcion);
    formData.append("videoDescEjercicio", video.videoDescEjercicio);
    formData.append("videoTiempo", video.videoTiempo);
    formData.append("tipoVideoId", video.tipoVideoId);
    if(video.videoPremium)
    {
      formData.append("videoPremium", 1);
    }
    else
    {
      formData.append("videoPremium", 0);
    }
    
    formData.append("Archivo", video.Archivo);
    
    for (let index = 0; index < video.categorias.length; index++) {
      const element = video.categorias[index];
      formData.append(`categorias[${index}]`,element);
    }
    

    return this.http.post(`${environment.urlAPI}video`, formData, {
      reportProgress: true,
      observe: 'events'
    }).pipe();
  }

  public getAllCaracteristicas()
  {
    return this.http.get<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/caracteristica`).toPromise();
  }

  public agregarCaracteristica(name:string){
    return this.http.post<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/caracteristica`,{caracteristicaNombre:name}).toPromise();
  }

  public postCaracteristica(c:Caracteristica){
    return this.http.post<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/caracteristica`,c).toPromise();
  }

  public deleteCaracteristica(id:number){
    return this.http.post<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/caracteristica/eliminar/${id}`,{}).toPromise();
  }

  public getTiposVideo()
  {
    return this.http.get<ApiResponse<TipoVideo>>(`${environment.urlAPI}video/tipos`).toPromise();
  }

  public delete(video:Video)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}video/eliminar/${video.videoId}`,{}).toPromise();
  }

  public getCaracteristicas(videoId: number) {
    return this.http.get<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/${videoId}/caracteristicas`).toPromise();
  }

  public getVideoUrl(videoId:number,token:string)
  {
    return this.http.get<ApiResponse<Caracteristica>>(`${environment.urlAPI}video/${videoId}/video/${token}`).toPromise();
  }
}
