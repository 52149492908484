<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{lang.text('ses.editar_info')}} # {{sesion.cacuId}}:</h4>
        <form>
            <label class="app-label">{{lang.t('objetivo')}}</label>
            <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuObj" name="cacuObj">
            </textarea>

            <label class="app-label">{{lang.t('comunicacion')}}</label>
            <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuComunicacion" name="cacuComunicacion">
            </textarea>

              <label class="app-label">{{lang.t('implementos')}}</label>
              <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuImplementos" name="cacuImplementos">
              </textarea>

            <label class="app-label">{{lang.t('t_entrada_calor')}}</label>
            <input rows="3" class="app-input" type="number" inputmode="numeric" [(ngModel)]="sesion.cacuEntradaTiempo" name="cacuEntradaTiempo">
            <label class="app-label">{{lang.t('entrada_calor')}}</label>
            <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuEntradaDesc" name="cacuEntradaDesc">
            </textarea>

            <label class="app-label">{{lang.t('t_principal')}}</label>
            <input rows="3" class="app-input" type="number" inputmode="numeric" [(ngModel)]="sesion.cacuPrincipalTiempo" name="cacuPrincipalTiempo">
            <label class="app-label">{{lang.t('principal')}}</label>
            <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuPrincipalDesc" name="cacuPrincipalDesc">
            </textarea>

            <label class="app-label">{{lang.t('t_vuelta')}}</label>
            <input rows="3" class="app-input" type="number" inputmode="numeric" [(ngModel)]="sesion.cacuVueltaTiempo" name="cacuVueltaTiempo">
            <label class="app-label">{{lang.t('vuelta')}}</label>
            <textarea rows="3" class="app-input" type="text" [(ngModel)]="sesion.cacuVueltaDesc" name="cacuVueltaDesc">
            </textarea>

        </form>

        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
      <button type="button" class="app-btn" [disabled]="uploading" (click)="copiar()"><fa-icon icon="copy"></fa-icon> {{lang.t('ses.copiar')}}</button>
      <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
      <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
