import { Component, OnInit } from '@angular/core';
import { Utils } from 'src/app/helpers/Utils';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-log-ventas',
  templateUrl: './log-ventas.component.html',
  styleUrls: ['./log-ventas.component.css']
})
export class LogVentasComponent implements OnInit {

  dataSet:any[] = [];
  constructor(public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){

  }

}
