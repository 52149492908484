import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Clase, ClaseEstado } from 'src/app/models/clase';
import { ControlClaseService } from 'src/app/services/controlclase';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-marcar-asistencia',
  templateUrl: './marcar-asistencia.component.html',
  styleUrls: ['./marcar-asistencia.component.css']
})
export class MarcarAsistenciaComponent implements OnInit {

  @Input() clase:Clase;
  errores:any[] = [];
  uploading = false;

  observacion:string = '';
  fecha:Date = new Date();
  estado:number = null;

  estados:ClaseEstado[] = [{clesId:4,clesNombre:'Asistido presencial'},{clesId:5,clesNombre:'Ausente'}];

  constructor(private modalSrv:ModalService,public activeModal:NgbActiveModal,
    private claseSrv:ControlClaseService,public lang:LangService) { }

  ngOnInit(): void {
  }


  async guardar(){
    this.uploading = true;
    try {
      const req = await this.claseSrv.postCambiarEstado(this.clase.clasId,this.estado,this.fecha,this.observacion);
      this.activeModal.close();
    } catch (error) {
      this.modalSrv.showError(error.message,'Aceptar');
    }
    this.uploading = false;
  }

}
