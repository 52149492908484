import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/helpers/Utils';
import { Comuna, DireccionCompleta } from 'src/app/models/comuna';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { Nivel } from 'src/app/models/nivel';
import { SemestreViewModel } from 'src/app/models/SemestreViewModel';
import { User } from 'src/app/models/User';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';





@Component({
  selector: 'app-profesor-nuevo',
  templateUrl: './profesor-nuevo.component.html',
  styleUrls: ['./profesor-nuevo.component.css']
})



export class ProfesorNuevoComponent implements OnInit {

  loading = true;
  uploading = false;

  niveles:Nivel[] = [];
  errores:any[] = [];
  imageUpload = false;
  membresias:any[] = [];

  comunas:Comuna[] = [];
  regiones:Comuna[] = [];

  paises:Comuna[] = [];
  all:Comuna[]=[];
  allRegions:Comuna[] = [];

  @Input() userModel:User;

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,private categoriaService:CategoriaService,
              private periodoService:PeriodoService,public lang:LangService ) {

  }

  async ngOnInit() {
    if(!this.userModel){
      this.userModel = new User();
      this.userModel.id = 0;
      this.userModel.comunaId = 0;
    }
    else
    {
      this.checkActidad()
    }
    await this.onLoad();
    this.loading= false;
  }

  async checkActidad()
  {

  }

  async cambiarMembresia()
  {

  }

  handleSelectedUserChange(selectedUser: string) {
    console.log('Hola')
  }

  async guardar()
  {
    this.uploading=true;
    try {
      this.userModel.rolId = 2;


      if(this.userModel.id == 0)
      {
        const req = await this.userService.post(this.userModel);
      }
      else
      {
        const req = await this.userService.put(this.userModel);
      }
      this.activeModal.close('creado');
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

  async onLoad()
  {
    try {
      //const req = await this.userService.getComunas();
      //this.setupDireccion(req.data);

      const req2 = await this.userService.getMembresias();
      this.membresias = req2.data;

      const req3 = await this.userService.getNiveles();
      this.niveles = req3.data;

    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }
  }


  actualizarRegiones(event){
    this.regiones = this.allRegions.filter(x => x.paisId == event.target.value);
  }

  actualizarComunas(event){
    this.comunas = this.all.filter(x => x.regionId == event.target.value);
  }

  async setupDireccion(list:Comuna[]){
    this.all = list;
    for (const i of Utils.groupBy(list,x => x.paisId)) {
      this.paises.push(i[0]);
    }

    for (const i of Utils.groupBy(list,x => x.regionId)) {
      this.allRegions.push(i[0]);
    }

    this.comunas = this.all;
  }





  async subirImagen(event){
     let file = event.target.files[0];
    console.log(file);
    this.imageUpload= true;
    try {
      const req = await this.userService.postImagen(file);
      this.userModel.userImagen = `${environment.urlAPI}user/imagen/${req.message}`;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.imageUpload= false; 
  }


  async changeDate(sem:SemestreViewModel){
    try {
      const req = await this.periodoService.getFechaTermino(sem.id,sem.fecha.toString());
      sem.fechaFin = req.data[0].fechaFin;
    } catch (error) {

    }
  }

  updateRut()
  {
    this.userModel.userRut = this.userService.fixRut(this.userModel.userRut);
  }
}
