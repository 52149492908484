import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Curso } from 'src/app/models/campus/curso';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { Documento, DocumentoService } from 'src/app/services/documento.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-listar-documentos-curso',
  templateUrl: './listar-documentos-curso.component.html',
  styleUrls: ['./listar-documentos-curso.component.css']
})
export class ListarDocumentosCursoComponent implements OnInit {

  @Input() user:User;
  @Input() curso:Curso;
  docs:Documento[] = [];
  uploading = false;
  loading = false;
  errores: any[] = [];
  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef;

  
  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
    private periodoService:PeriodoService, private evaluacionService:EvaluacionService
    ,private catService:CategoriaService, private docService:DocumentoService,private authService:AuthService,public lang:LangService) { }

    ngOnInit(): void {
      this.init();
    }

    
  init(){
    //this.load()
    this.loadDocCampus();
  }


  async refresh(){
    this.loading = true;
    await this.loadDocCampus();
    this.loading = false;
  }



  async onFileDropped(event) {
    console.log(event);
    this.uploadFilesCampus(event);
  }


  async download(doc:Documento){
    window.open(environment.urlAPI+'documento/descargar/'+doc.docuId+'?token='+this.authService.currentUserValue.token,'_system');
  }




  async delete(doc:Documento){
    let confirmar = await this.modalService.confirm('curs.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    try {
      const req = await this.docService.delete(doc.docuId);
      this.refresh();
    } catch (error) {
      this.modalService.showError(error.message,'ok');
    }
  }


  async loadDocCampus(){
    try {
      const req = await this.docService.getAllCampus();
      this.docs = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
    this.loading = false;
  }




  async uploadFilesCampus(files:any[]){
    if(!this.uploading){
      for (const i of files) {
        console.log(i);
        this.uploading = true;
        try {
          console.log('jajajajajaa', this.curso.cursId);
          const req = await this.docService.postCampus(1,this.curso.cursId,i);
        } catch (error) {
          this.modalService.showError(error.message,'OK');
        }
      }
      this.uploading = false;
      this.refresh();
    }
  }

}
