import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Chart from 'chart.js/auto';

import { environment } from 'src/environments/environment';
import { ApiResponse } from '../models/ApiResponse';
import { HttpClient } from '@angular/common/http';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'chart',
  templateUrl: './chart-component.component.html',
  styleUrls: ['./chart-component.component.css']
})
export class ChartComponent implements OnInit,AfterViewInit {


  @ViewChild('canvas') chartCanvas!: ElementRef;
  private chartReference;
  private canvas:HTMLCanvasElement;
  private type:any;
  private init = false;
  private _url:string;
  chartData:ChartQueryResult[];
  constructor(private http:HttpClient) { }


  @Output() typeChange = new EventEmitter<'bar'|'line'>();


  public get url(){
    return this._url;
  }

  @Input() public set url(d:string){
    this._url = d;
    this.loadData();
  }


  async loadData(){
    try {
      const req = await this.http.get<ApiResponse<ChartQueryResult>>(environment.urlAPI+'reporte/grafico/'+this.url).toPromise();
      this.chartData = req.data;
      this.type = req.data[0].chartType
      this.drawChart();
    } catch (error) {
      console.error(error);
    }
  }

  
  getColor(c:string){
    if(c.includes(',')){
      console.log(c);
      return c.split(',');
    }
    return c;
  }

  drawChart(){
    if(this.chartData == null) return;
    
    if(this.chartReference == null){
      const labels = this.groupBy(this.chartData,i => i.xAxis).map(i => {return i[0].xAxis});
      const sets = this.groupBy(this.chartData,i => i.dataSet);
  


      let dataSets = [];
      console.log(sets);
      for (const i of sets) {
        dataSets.push({
          label:i[0].dataSet,
          data:i.map(i => {return i.yAxis;}),
          borderWidth:2,
          borderRadius:8,
          pointRadius: 4,
          pointHoverRadius: 8,
          backgroundColor: this.getColor(i[0].color ?? '#FF32BBBB') ,
          borderColor:this.getColor(i[0].color ?? '#FF32BB'),
          title:i[0].title??'Grafico'
        });
      }

      const data = {
        labels: labels,
        datasets: dataSets
      }

      const moneyPipe = new CurrencyPipe('en-US');
      const vType = this.chartData[0].valueType;
      //console.log('Grafico vType', vType)

      this.chartReference = new Chart(this.canvas,{
        options:{
          scales:{
            x: {
              grid: {
                color:'#555555'
              },
              title:{
                color:'#FFFFFF'
              },
              ticks:{
                color:'#ffffff'
              }
            },
            y: {
              grid: {
                color:'#555555'
              },
              ticks:{
                callback: function(value, index, ticks) {
                  if(vType == 'money'){
                    return moneyPipe.transform(value,'USD');
                  }else{
                    return value;
                  }
                },
                color:'#ffffff'
              }
            }
          },
          responsive:true,
          aspectRatio:4/3,
          plugins: {
            title: {
              color:'#ffffff',
              display: true,
              text: dataSets[0].title,
            },
            legend: {
              
              labels: {
                usePointStyle: true,
                color:'#ffffff',
              },
            }
          }
        },
        type:this.type,
        data:data
      });

    }
    
    
  }


  ngOnInit(): void {
  }


  ngAfterViewInit(){
    this.canvas = this.chartCanvas.nativeElement;
    this.init = true;
    this.drawChart();
  }

  groupBy<T, K>(list: T[], getKey: (item: T) => K) {
    const map = new Map<K, T[]>();
    list.forEach((item) => {
        const key = getKey(item);
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Array.from(map.values());
}

}

export interface ChartQueryResult{
  xAxis:string,
  yAxis:string,
  dataSet:string,
  color?:string,
  chartType:string,
  title?:string,
  valueType:string  
}