import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Grupo } from 'src/app/models/grupo';
import { Sponsor } from 'src/app/models/sponsor';
import { ControlClaseService } from 'src/app/services/controlclase';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { SponsorService } from 'src/app/services/sponsor.service';
import { SuspensionClasesService } from 'src/app/services/suspension-clases.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-agregar-modificar-sponsor',
  templateUrl: './agregar-modificar-sponsor.component.html',
  styleUrls: ['./agregar-modificar-sponsor.component.css']
})
export class AgregarModificarSponsorComponent implements OnInit {

  @Input() model:Sponsor = {sponId:null,sponImg:'',sponDesc:'',grupos:[],sponPrioridad:0};

  grupos:Grupo[] = [];
  gruposSel:Grupo[] = [];

  uploading = false;
  loading = false;
  imageUpload = false;
  errores:any[] = [];

  constructor(private modalSrv:ModalService,public activeModal:NgbActiveModal,
    private claseSrv:ControlClaseService,private suspensionService:SuspensionClasesService,
    private userService:UserService,private sponsorService:SponsorService,
    private grupoService:GrupoService,public l:LangService,
    public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    this.loading = true;
    try {
      const req = await this.grupoService.getAll();
      this.grupos = req.data;

      if(this.model.sponId != null){
        const req2 = await this.sponsorService.get(this.model.sponId);
        this.gruposSel = req2.data;
      }

    } catch (error) {
      this.modalSrv.showError(error.message,'ok');
    }
    this.loading = false;
  }

  async subirImagen(event){
    let file = event.target.files[0];
    console.log(file);
    this.imageUpload= true;
    try {
      const req = await this.userService.postImagen(file);
      this.model.sponImg = `${environment.urlAPI}user/imagen/${req.message}`;
    } catch (error) {
      this.modalSrv.showError(error.message,'Aceptar');
    }
    this.imageUpload= false;
  }

  async guardar(){
    this.uploading = true;
    try {
      this.model.grupos = [];
      for (const i of this.gruposSel) {
        this.model.grupos.push(i.grupoId);
      }
      const req = await this.sponsorService.post(this.model);
      this.activeModal.close('ok');
    } catch (error) {
      this.modalSrv.showError(error.message,'ok');
    }
    this.uploading = false;
  }


  async agregar(video:Grupo)
  {
    let find = this.gruposSel.find(el => el.grupoId == video.grupoId);
    if(!find)
    {
      this.gruposSel.push(video);
    }
  }


  async remover(video:Grupo)
  {
    let v = this.gruposSel.findIndex(el => el.grupoId == video.grupoId);
    this.gruposSel.splice(v,1);
  }
}
