import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { InicioComponent } from './components/inicio/inicio.component';
import { LoginComponent } from './components/login/login.component';
import { VideosComponent } from './components/videos/videos.component';
import { VideoNuevoComponent } from './components/videos/video-nuevo/video-nuevo.component';
import { MacrociclosComponent } from './components/macrociclos/macrociclos.component';
import { SesionesComponent } from './components/sesiones/sesiones.component';
import { SesionDetalleComponent } from './components/sesiones/sesion-detalle/sesion-detalle.component';
import { GrupoComponent } from './components/grupos/grupo/grupo.component';
import { MicrocicloListarComponent } from './components/microciclos/microciclo-listar/microciclo-listar.component';
import { MesociclosComponent } from './components/mesociclos/mesociclos.component';
import { AlumnosComponent } from './components/alumnos/alumnos.component';
import { ProfesoresComponent } from './components/profesores/profesores.component';
import { AsistenciasComponent } from './components/asistencias/asistencias.component';

import { MesogrupoComponent } from './mesogrupos/mesogrupo/mesogrupo.component';
import { ControlAsistenciaComponent } from './components/control-asistencia/control-asistencia.component';

import { CaracteristicaComponent } from './components/caracteristica/caracteristica.component';
import { SponsorComponent } from './components/sponsor/sponsor.component';

import { PagoMercadopagoComponent } from './components/pago-mercadopago/pago-mercadopago.component';
import { SolicitudesComponent } from './components/solicitudes/solicitudes.component';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { UsuarioMaestroComponent } from './components/usuario-maestro/usuario-maestro.component';
import { QuizesComponent } from './components/quizes/quizes.component';
import { ResetearPasswordComponent } from './components/resetear-password/resetear-password.component';
import { GuestGuard } from './helpers/guest.guard';
import { ModulosComponent } from './components/modulos/modulos.component';
import { LeccionesComponent } from './components/lecciones/lecciones.component';
import { CursosComponent } from './components/cursos/cursos.component';
import { MiCuentaComponent } from './components/mi-cuenta/mi-cuenta.component';
import { CampusAprobadoComponent } from './components/campus-aprobado/campus-aprobado.component';
import { PromocionComponent } from './components/promocion/promocion.component';
import { PreciosComponent } from './components/mi-cuenta/precios/precios.component';
import { VentasComponent } from './components/mi-cuenta/ventas/ventas.component';
import { AgendaComponent } from './components/mi-cuenta/agenda/agenda.component';
import { VerVentaComponent } from './components/mi-cuenta/ventas/ver-venta/ver-venta.component';

import { ReLoginComponent } from './components/re-login/re-login.component';
import { AuthService } from './services/auth-service.service';
import { AuthGuard } from './helpers/auth.guard';
import { AdminHomeComponent } from './components/adminpanel/admin-home/admin-home.component';
import { LogPagosComponent } from './components/adminpanel/log-pagos/log-pagos.component';
import { LogVentasComponent } from './components/adminpanel/log-ventas/log-ventas.component';
import { LogComponent } from './components/adminpanel/log.component';
import { AdmPaquetesComponent } from './components/adminpanel/adm-paquetes/adm-paquetes.component';
import { LogusuariosComponent } from './components/adminpanel/logusuarios/logusuarios.component';
import { AdminCampanaComponent } from './components/adminpanel/admin-campana/admin-campana.component';
import { PayoutsComponent } from './components/mi-cuenta/payouts/payouts.component';
import { LogsCampanasComponent } from './components/adminpanel/admin-campana/logs-campanas/logs-campanas.component';
import { UsubMailistingComponent } from './components/mi-cuenta/usub-mailisting/usub-mailisting.component';



//Rutas de la web app
const routes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', component: InicioComponent,canActivate:[AuthGuard]},
  { path: 'videos', component: VideosComponent, canActivate: [AuthGuard]},
  { path: 'caracteristica', component: CaracteristicaComponent, canActivate: [AuthGuard]},
  { path: 'grupos', component: GrupoComponent, canActivate: [AuthGuard]},
  { path: 'sponsor', component: SponsorComponent, canActivate: [AuthGuard]},
  { path: 'sesiones', component:SesionesComponent,canActivate:[AuthGuard]},
  { path: 'microciclos', component:MicrocicloListarComponent,canActivate:[AuthGuard]},
  { path: 'mesociclos', component:MesociclosComponent,canActivate:[AuthGuard]},
  { path: 'macrociclos', component:MacrociclosComponent,canActivate:[AuthGuard]},
  { path: 'mesogrupos', component:MesogrupoComponent,canActivate:[AuthGuard]},
  { path: 'alumnos', component:AlumnosComponent,canActivate:[AuthGuard]},
  { path: 'usuarios', component:UsuarioMaestroComponent,canActivate:[AuthGuard]},
  { path: 'profesores', component:ProfesoresComponent,canActivate:[AuthGuard]},
  { path: 'sesiones/:id',component:SesionDetalleComponent ,canActivate:[AuthGuard]},
  { path: 'asistencias',component:AsistenciasComponent,canActivate:[AuthGuard]},
  { path: 'control-asistencia',component:ControlAsistenciaComponent,canActivate:[AuthGuard]},
  { path: 'solicitudes',component:SolicitudesComponent,canActivate:[AuthGuard]},
  { path: 'pago',component:PagoMercadopagoComponent,canActivate:[AuthGuard]},
  { path: 'login', component: LoginComponent,canActivate:[GuestGuard] },
  { path: 'resetear-password', component: ResetearPasswordComponent,canActivate:[GuestGuard] },
  { path: 'campus', redirectTo:'preguntas', pathMatch: 'full'  },
  { path: 'preguntas',component:PreguntasComponent,canActivate:[AuthGuard]},
  { path: 'quizes',component:QuizesComponent,canActivate:[AuthGuard]},
  { path: 'modulos',component:ModulosComponent,canActivate:[AuthGuard]},
  { path: 'lecciones',component:LeccionesComponent,canActivate:[AuthGuard]},
  { path: 'cursos',component:CursosComponent,canActivate:[AuthGuard]},
  { path: 'account',component:MiCuentaComponent,canActivate:[AuthGuard]},
  { path: 'campus-aprobado',component:CampusAprobadoComponent,canActivate:[AuthGuard]},
  { path: 'promocion',component:PromocionComponent,canActivate:[AuthGuard]},
  { path: 'prices',component:PreciosComponent,canActivate:[AuthGuard]},
  { path: 'sells',component:VentasComponent,canActivate:[AuthGuard]},
  { path: 'calendar',component:AgendaComponent,canActivate:[AuthGuard]},
  { path: 'adm',component:AdminHomeComponent,canActivate:[AuthGuard]},
  { path: 'adm/log',component:LogComponent,canActivate:[AuthGuard]},
  { path: 'adm/ventas',component:LogVentasComponent,canActivate:[AuthGuard]},
  { path: 'payouts',component:PayoutsComponent,canActivate:[AuthGuard]},
  { path: 'adm/pagos',component:LogPagosComponent,canActivate:[AuthGuard]},
  { path: 'adm/paquetes',component:AdmPaquetesComponent,canActivate:[AuthGuard]},
  { path: 'adm/usuarios',component:LogusuariosComponent,canActivate:[AuthGuard]},
  { path: 'adm/campana/nueva',component:AdminCampanaComponent,canActivate:[AuthGuard]},
  { path: 'adm/campana/historial',component:LogsCampanasComponent,canActivate:[AuthGuard]},
  { path: 'ver-venta/:id',component:VerVentaComponent,canActivate:[AuthGuard]},

  { path: 'account/mail/unsub',component:UsubMailistingComponent,canActivate:[AuthGuard]},

  { path: 'nuevo-user/:token',component:ReLoginComponent},
  { path: 'redirect',component:ReLoginComponent},
];

export const AppRoutingModule = RouterModule.forRoot(routes);
