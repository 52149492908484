import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Clase } from '../models/clase';
import { Sponsor } from '../models/sponsor';
import { Grupo } from '../models/grupo';





@Injectable({
  providedIn: 'root'
})

export class SponsorService {

  constructor(private http:HttpClient) {
  }
  

  public async getAll()
  {
    return this.http.get<ApiResponse<Sponsor>>(`${environment.urlAPI}sponsor`).toPromise();
  }


  public async get(id:number)
  {
    return this.http.get<ApiResponse<Grupo>>(`${environment.urlAPI}sponsor/${id}`).toPromise();
  }

  public async post(sponsor:Sponsor)
  {
    return this.http.post<ApiResponse<Sponsor>>(`${environment.urlAPI}sponsor`,sponsor).toPromise();
  }


  public async delete(id:number)
  {
    return this.http.post<ApiResponse<Sponsor>>(`${environment.urlAPI}sponsor/eliminar/${id}`,{}).toPromise();
  }
}

