<div class="modal-alert" >
    <div class="modal-body left" *ngIf="!loading">
        <div class="row">
            <div class="col-sm-12">
                <label class="app-label">{{lang.text('descripcion')}}</label>
                <input type="text" class="app-input" [(ngModel)]="viewModel.luclDesc" name="luclDesc">

                <ng-container *ngIf="user.rolId == 2">
                  <label class="app-label">{{lang.text('precio')}}</label>
                  <input type="number" class="app-input" [(ngModel)]="viewModel.luclPrecioRef" name="luclPrecioRef">
                  <small>{{lang.text('acco.precio_expl')}}</small>
                  <label class="app-label">{{lang.text('precio_mensual')}}</label>
                  <input type="number" class="app-input" [(ngModel)]="viewModel.luclPrecioRefMen" name="luclPrecioRef">
                  <small>{{lang.text('acco.precio_expl')}}</small>
                </ng-container>
                

                <!-- <label class="app-label">{{lang.text('pais')}}</label>
                        <select [(ngModel)]="viewModel.paisId" (change)="actualizarRegiones($event)"  class="app-combo">
                              <option value="0" selected >{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let c of paises" value="{{c.paisId}}">{{c.paisNombre}}</option>
                        </select>
                <label class="app-label">{{lang.text('region')}}</label>
                        <select [(ngModel)]="viewModel.regionId"  (change)="actualizarComunas($event)" class="app-combo">
                              <option value="0" selected>{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let c of regiones" value="{{c.regionId}}">{{c.regionNombre}}</option>
                        </select>
                <label class="app-label">{{lang.text('comuna')}}</label>
                <select [(ngModel)]="viewModel.comunaId" name="userComuna" class="app-combo">
                    <option value="0" selected>{{lang.text('seleccionar')}}</option>
                    <option *ngFor="let c of comunas" [value]="c.comunaId">{{c.comunaNombre}}</option>
                </select> -->

  
                <label for="app-label">{{lang.text('inputComuna.label')}}</label>
                <app-input-comuna [(ngModel)]="viewModel.comunaId" ></app-input-comuna>

                <label class="app-label">{{lang.text('entrenamiento')}}</label>
                        <select  [(ngModel)]="viewModel.luclEntre" class="app-combo">
                              <option value="0" selected >{{lang.text('seleccionar')}}</option>
                              <option *ngFor="let e of entrenamientos" value="{{e.entreId}}">{{e.entreTipo}}</option>
                        </select>
                <label class="app-label">{{lang.text('horario')}}</label>

                
                <div class="container">
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="container">{{lang.text('lunes')}}
                                <input [(ngModel)]="lunes" type="checkbox" >
                                <span class="checkmark"></span>
                              </label>
                              <label class="container">{{lang.text('martes')}}
                                <input [(ngModel)]="martes" type="checkbox" value="Martes">
                                <span class="checkmark"></span>
                              </label>
                              <label class="container">{{lang.text('miercoles')}}
                                <input [(ngModel)]="miercoles" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                              <label class="container">{{lang.text('jueves')}}
                                <input [(ngModel)]="jueves" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                        </div>
                        <div class="col-sm-6">
                            <label class="container">{{lang.text('viernes')}}
                                <input [(ngModel)]="viernes" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                              <label class="container">{{lang.text('sabado')}}
                                <input [(ngModel)]="sabado" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                              <label class="container">{{lang.text('domingo')}}
                                <input [(ngModel)]="domingo" type="checkbox">
                                <span class="checkmark"></span>
                              </label>
                        </div>
                    </div>
                </div>
                <input [(ngModel)]="viewModel.luclHora" class="app-input"  type="time" id="horario" name="horario"> 
            </div>
        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn"  (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn"  (click)="save()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
  </div>
  
  
  