import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SelectModalComponentComponent } from '../modals/select-modal-component/select-modal-component.component';
import { CustomSelectOption } from '../models/customselectoption';
import { ModalService } from '../services/modal-service';

@Component({
  selector: 'app-search-select',
  templateUrl: './search-select.component.html',
  styleUrls: ['./search-select.component.css']
})
export class SearchSelectComponent implements OnInit,OnChanges {


  @Input() model:any;
  selectedItem:CustomSelectOption = null;
  @Input() placeholder:string = 'Seleccionar';
  @Output() modelChange = new EventEmitter<any>();
  @Input() items:CustomSelectOption[];

  constructor(private modalService:ModalService,private modalController:NgbModal) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model) {
        console.log('Change detected',changes.model.currentValue);
        this.selectedItem = this.items.find(i => i.value == changes.model.currentValue);
    }
}


  async click(){
    try{
      const modalRef = this.modalController.open(SelectModalComponentComponent,{windowClass:'app-modal top-modal'});
      modalRef.componentInstance.items = this.items;
      const item:CustomSelectOption = await modalRef.result;
      this.selectedItem = item;
      this.model = item.value;
      this.modelChange.emit(this.model);
      console.log('Item seleccionado:',this.selectedItem);
    }
    catch(error){

    }
  }
}

