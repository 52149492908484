import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-inicio',
  templateUrl: './inicio.component.html',
  styleUrls: ['./inicio.component.css']
})
export class InicioComponent implements OnInit {

  user:User;
  isAuth: boolean;
  constructor(private authService:AuthService,private userService:UserService,public lang:LangService,
    private modalService:ModalService,private webService:WebService) { }

  ngOnInit(): void {
  
    this.authService.currentUser.subscribe( val => {
      this.isAuth = (val? true:false);
      this.user = val;
    });
  }


  async irPagar(){
    const loader = await this.modalService.showLoader();
    try {
      const {message} = await this.webService.stripePago();
      location.href = message;
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
    loader.dismiss();
  }



}
