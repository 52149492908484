import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gestionsesionalumno',
  templateUrl: './gestionsesionalumno.component.html',
  styleUrls: ['./gestionsesionalumno.component.css']
})
export class GestionsesionalumnoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
