import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from 'src/app/models/video';
import { ModalService } from 'src/app/services/modal-service';
import { leccion } from '../../../models/campus/leccion';
import { VideoService } from '../../../services/video.service';
import { LeccionService } from '../../../services/campus/leccion.service';
import { LangService } from 'src/app/services/lang.service';



@Component({
  selector: 'app-editar-leccion',
  templateUrl: './editar-leccion.component.html',
  styleUrls: ['./editar-leccion.component.css']
})
export class EditarLeccionComponent implements OnInit {

  @Input() leccionParam:leccion;

  //grupo:Grupo;
  videos:Video[] = [];
  videosSel:Video;
  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];
/*   filtro:Caracteristica = null; 
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public videoService:VideoService,
              public leccionService:LeccionService,
              private modalService:ModalService,
              public lang:LangService
              ) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad()
  {
    try {
      if (this.leccionParam.videoId) {
        const request = await this.videoService.getCampus(this.leccionParam.videoId);
        this.videosSel= request.data[0];
      }else{
        this.videosSel=null;
      }

      //const req = await this.videoService.ge
      const request2 = await this.videoService.getCampus();
      this.videos = request2.data;
      console.log('videos',this.videos);
      

      
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    }
  }

  async agregar(video:Video)
  {
    this.videosSel=video;
  }

/*   async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    
    this.loadingVideos = false;
  } */

  async remover(video:Video)
  {
    this.videosSel = null;
  }

  async guardar(){
    if (this.videosSel == null) {
      await this.modalService.showError('video.eliminar','Aceptar');
      
    }
    this.uploading= true;
      try {
      var leccion= this.leccionParam;
      leccion.videoId=this.videosSel.videoId;

      const req = await this.leccionService.post(leccion);
      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }  
    this.uploading = false;
  }

}
