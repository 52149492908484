<div class="modal-alert">
      <div class="modal-body left">
            <h4>{{lang.text('al.evaluar')}}</h4>

            <div class="section">
                  <h6>{{lang.text('nombre')}}: {{userModel.userNombre}} {{userModel.userApellido}}</h6>
                  <h6>{{lang.text('rut')}}: {{userModel.userRut}}</h6>
                  <h6>{{lang.text('telefono')}}: {{userModel.userTelefono}}</h6>
            </div>
            <div *ngIf="!loading">
                  <label class="app-label">{{lang.text('evaluacion')}}</label>
                  <select [(ngModel)]="evaluacion" name="evaluacion" class="app-combo" (change) = "cargarAsistencias()">
                        <option [ngValue]="null" disabled>{{lang.text('seleccionar')}}</option>
                        <option *ngFor="let e of periodos" [ngValue]="e" >{{lang.text('evaluacion')}} {{e.caunNombre}} #{{e.evaluacionNumero}}</option>
                  </select>

                  <label class="app-label">{{lang.text('observacion')}}:</label>
                  <input [(ngModel)]="observacion" type="text" class="app-input">
                  <label class="app-label">{{lang.text('nivel')}}</label>
                  <select [(ngModel)]="nivel" name="nivel" class="app-combo" >
                        <option value="null" selected disabled>{{lang.text('seleccionar')}}</option>
                        <option *ngFor="let n of niveles" value="{{n.nivelId}}">{{n.nivelNombre}}</option>
                  </select>

            </div>


            <div class="alert alert-danger" *ngFor="let error of errores">
                  {{error.message}}
            </div>
      </div>
      <div class="loading-bar" *ngIf="uploading"></div>
      <div class="modal-footer">
            <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
            <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
      </div>
</div>
