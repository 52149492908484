<div class="container view">
    <div style="padding-bottom: 16px;">
        <button  class="app-btn small"  (click)="back()" ><fa-icon icon="arrow-left"></fa-icon> {{lang.text('go_back')}}</button>
    </div>
    <h1>{{lang.text('adm.campanas')}}</h1>

    
    
    <div class="row">
        
        <div class="col-sm-6">
            <h1> {{lang.text('adm.noti.nueva')}}</h1>
            <div class="form-noti g-border p-4">
                <label class="app-label">{{lang.text('adm.noti.titulo')}}:</label>
                <input [maxlength]="40" class="app-input" type="text" [(ngModel)]="nueva.titulo" name="titulo">
        
                <label class="app-label">{{lang.text('adm.noti.mensaje')}}:</label>
                <textarea [maxlength]="nueva.tipo == 'push' ? 160:8000" [rows]="nueva.tipo == 'push' ? 4:10" class="app-input" type="text" [(ngModel)]="nueva.mensaje" name="titulo"></textarea>
                <small><i>{{lang.text('adm.noti.hint')}}</i></small>
                <label class="app-label">{{lang.text('adm.noti.lang')}}:</label>
                <select [(ngModel)]="nueva.lang" name="lang" class="app-combo">
                    <option *ngFor="let tv of langOpts" value="{{tv.code}}">{{tv.name}}</option>
                </select>
        
                <select [(ngModel)]="nueva.tipo" name="tipo" class="app-combo">
                    <option value="mail">E-Mail</option>
                    <option value="push">Push Notification</option>
                </select>
        
                <label class="app-label">{{lang.text('adm.noti.target')}}:</label>
                <div class="app-lista-checkbox">
                    <div class="app-checkbox" >
                        <input type="checkbox" [(ngModel)]="opSinProfe" name="sinprofe" id="sinprofe"> <label for="sinprofe">{{lang.text('adm.noti.opsinprofe')}}</label>
                    </div>
                    <div class="app-checkbox" >
                        <input type="checkbox" [(ngModel)]="opConProfe" name="opConProfe" id="opConProfe"> <label for="opConProfe">{{lang.text('adm.noti.opconprofe')}}</label>
                    </div>
                    <div class="app-checkbox" >
                        <input type="checkbox" [(ngModel)]="opDaddy" name="opDaddy" id="opDaddy"> <label for="opDaddy">{{lang.text('adm.noti.opDaddy')}}</label>
                    </div>
                    <div class="app-checkbox" >
                        <input type="checkbox" [(ngModel)]="opCoach" name="opCoach" id="opCoach"> <label for="opCoach">{{lang.text('adm.noti.opCoach')}}</label>
                    </div>
                    <div class="app-checkbox" >
                        <input type="checkbox" [(ngModel)]="opCampus" name="opCampus" id="opCampus"> <label for="opCampus">{{lang.text('adm.noti.opCampus')}}</label>
                    </div>
                </div>
        
                
        
                <button type="button" class="app-btn outline" [disabled]="uploading" (click)="enviar()" >
                    {{lang.text('enviar')}}
                </button>
        
            </div>
        </div>
        <div class="col-lg-6">
            <h1>{{lang.text('adm.noti.preview')}}</h1>
            <div class="preview g-border p-4">
                <div class="phone-mockup">
                    <div class="screen">
                        <span class="notch"></span>
                        <span class="right-status-bar" [style.color]="nueva.tipo == 'push'? 'white':'black'">
                            <span class="material-symbols-rounded">signal_cellular_alt</span>
                            <span class="material-symbols-rounded">wifi</span>
                            <span class="material-symbols-rounded">battery_horiz_075</span>
                        </span>

                        <span class="left-status-bar" [style.color]="nueva.tipo == 'push'? 'white':'black'">
                            <span class="material-symbols-rounded">mail</span>
                            <span class="material-symbols-rounded">near_me</span>
                        </span>

                        <div class="noti-area" *ngIf="nueva.tipo=='push'">
                            <p class="fake-clock">
                                {{status?.lastUpdate?? 'Sat Sep 13 275760 00:00:00 GMT+0000' | date:'shortTime'}}
                            </p>
                            <div class="notification-fake">
                                <img src="/assets/mobile_icon.png" alt="noti-icon">
                                <div class="t-area">
                                    <p class="noti-titulo">{{nueva.titulo}}</p>
                                    <p class="noti-body">{{nueva.mensaje}}</p>
                                </div>
                            </div>
                        </div>

                        <div class="mail-area" *ngIf="nueva.tipo=='mail'">
                            <div class="m-header">
                                <p>Mail from Golaço</p>
                            </div>
                            <div class="m-body">
                                <div class="mail">
                                    <a href="https://golazoft.com"><img src="https://golazoft.com/api/assets/logo_rosa.png" alt="Golazo Logo" width="200" height="110" style="margin-bottom: 48px;"></a>
                                    <div class="mail-wrapper">
                                        <div class="mail-body-container">
                                         
                                            <div class="mail-body">
                                                <h1>{{nueva.titulo}}</h1>
                                                <p>{{nueva.mensaje}}</p>
                                            </div>
                            
                                        </div>
                                        <div class="botones-sociales">
                                            <a href="https://www.instagram.com/golazoft/"><img src="https://golazoft.com/api/assets/IG.png" width="40" height="40"></a>
                                            <a href="https://www.facebook.com/Golazoft"><img src="https://golazoft.com/api/assets/FB.png" width="40" height="40"></a>
                                        </div>
                            
                                        <div class="footer">
                                            <p><small>&copy; Golaço FT 2022, <a href="https://golazoft.com/assets/tos.pdf">Terminos y condiciones</a></small></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>
                <ng-container *ngIf="nueva.tipo == 'push'">

                </ng-container>
            </div>
        </div>
    </div>

    
    
</div>