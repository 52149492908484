
<div class="modal-alert">
      <div class="modal-header">
            <button class="app-btn circle" (click)="activeModal.dismiss('cerrar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button><h4>{{lang.t('btn.sesiones_periodo_actual')}} {{user.userNombre}}</h4>
      </div>
      <div class="modal-body full">
          <table class="esquema">
            <tbody>
                  <tr *ngFor="let i of sesiones; let idx = index">
                        <td>{{idx+1}}</td>
                        <td>{{diasNombres[i.diaSemana]}} {{i.fecha}}</td>
                        <td>{{i.cadoNombre}}</td>
                        <td>{{i.catrNombre}}</td>
                        <td>{{i.cacuNombre}}</td>
                  </tr>
            </tbody>
      </table>
      </div>
  </div>
