<div class="container view">
    <h2>{{lang.text('profesores')}}</h2>

    <div class="row">
        <div class="col-sm-6">
          <div class="botones-opciones mb-3">
              <button class="app-btn" (click)="nuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
              <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>

              <button class="app-btn" (click)="nuevoPendiente()"><fa-icon icon="plus"></fa-icon> {{lang.text('prof.pendientes')}}</button>

          </div>
        </div>
        <div class="col-sm-6">
          <input type="search" class="app-input mb-3" placeholder="{{lang.text('buscar')}}" (keyup.enter)="buscar($event)">
        </div>
    </div>


    <div *ngIf="!this.loading" class="table-container" >
        <table class="app-table">
              <thead>
                    <th>

                    </th>

                    <th>
                      {{lang.text('correo')}}
                    </th>
                    <th>
                        {{lang.text('telefono')}}
                    </th>

                    <th>

                    </th>
              </thead>
              <tbody>
                    <tr *ngFor="let u of getPaged(page)">
                      <td>
                          <div style="display: flex;justify-content: space-between;">
                            <img [src]="u.userImagen" alt="" class="profile" style="margin-right: 4px;">

                              <div style="display: flex;flex-direction: column;align-items: flex-start; justify-items: center;flex:auto;">
                                <p style="margin:0px;">{{u.userNombre}} {{u.userApellido}}</p>
                                <small>{{u.rolDescripcion}}</small>
                                <small>{{u.userRut}}</small>
                              </div>
                          </div>



                      </td>

                      <td>
                          {{u.userEmail}}
                      </td>
                      <td>
                          {{u.userTelefono}}
                      </td>
                      <td>
                          <button class="app-btn small" [disabled]="u.rolId != 2" (click)="verPagos(u)" title="{{lang.text('pago.ver')}}"><fa-icon icon="dollar-sign"></fa-icon></button>
                          <button class="app-btn small" [disabled]="u.rolId != 2" (click)="modificar(u)" title="{{lang.text('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                          <button class="app-btn small" [disabled]="u.rolId != 2"  (click)="eliminar(u)" title="{{lang.text('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                      </td>
                    </tr>
              </tbody>
        </table>

    </div>

    <div class="mt-2">
        <button class="app-btn small outline" (click)="prevPage()"> < </button>
        <button class="app-btn small outline" disabled > {{page+1}} / {{lastPage}} </button>
        <button class="app-btn small outline"  (click)="nextPage()"> > </button>
    </div>

    <div *ngIf="this.loading" class="loader">

    </div>
    <h6 *ngIf="this.users.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>

</div>
