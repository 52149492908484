import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Respuesta } from 'src/app/models/campus/respuesta';
import { Pregunta } from 'src/app/models/campus/Pregunta';
import { PregRespReq } from '../../models/campus/respuesta';

@Injectable({
  providedIn: 'root'
})
export class PreguntaService {

  constructor(private http:HttpClient) {
  }


  public async get(pregId?:number)
  {
    const body = Params.toHttpParams({pregId:pregId});
    return this.http.get<ApiResponse<Pregunta>>(`${environment.urlAPI}campus/pregunta`,{params:body}).toPromise();
  }

  public async put(preg:PregRespReq)
  {

    return this.http.put<ApiResponse<Pregunta>>(`${environment.urlAPI}campus/pregunta`,preg).toPromise();
  }

  public async post(preg:PregRespReq)
  {

    return this.http.post<ApiResponse<Pregunta>>(`${environment.urlAPI}campus/pregunta`,preg).toPromise();
  }
  
  public async DeletePregunta(pregId?:number)
  {
    const body = Params.toHttpParams({pregId:pregId});
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}campus/pregunta/delete`,{params:body}).toPromise();
  }


  
}

export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}
