import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-solicitudes',
  templateUrl: './solicitudes.component.html',
  styleUrls: ['./solicitudes.component.css']
})
export class SolicitudesComponent implements OnInit {
  loading = true;


  solicitudes: any[] = [];

  
  constructor(private userService:UserService,private modalService:ModalService,public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async refresh(){
    this.loading = true;
    await this.load();
    this.loading = false;
  }

  async load(){

    try {
      await this.cargarSolicitudes();
    } catch (error) {
      
    }
  }

  async cargarSolicitudes(){
    const req2 = await this.userService.getSolicitudes();
    this.solicitudes = req2.data;
  }


  async aceptarSolicitud(sol:any){
    const resp = await this.modalService.confirm('sol.confimar_aceptar','Si','Cancelar');
    if(resp){
      try {
        const req = await this.userService.responderSolicitud(sol.soprId,true);
        await this.refresh()
      } catch (error) {
        this.modalService.showError(error.message,'Ok');
      }
    }
  }

  async rechazarSolicitud(sol:any){
    const resp = await this.modalService.confirm('sol.confimar_rechazar','Si','Cancelar');
    if(resp){
      try {
        const req = await this.userService.responderSolicitud(sol.soprId,false);
        await this.cargarSolicitudes();
      } catch (error) {
        this.modalService.showError(error.message,'Ok');
      }
    }
  }
}
