import { Component, OnInit } from '@angular/core';
import { Respuesta } from 'src/app/models/campus/respuesta';
import { Mesociclo } from 'src/app/models/mesociclo';
import { RespuestaService } from 'src/app/services/campus/respuesta.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { Pregunta } from '../../models/campus/Pregunta';
import { PreguntaService } from '../../services/campus/pregunta.service';
import { PreguntasNuevoComponent } from './preguntas-nuevo/preguntas-nuevo.component';


@Component({
  selector: 'app-preguntas',
  templateUrl: './preguntas.component.html',
  styleUrls: ['./preguntas.component.css']
})
export class PreguntasComponent implements OnInit {

  loading = true;
  preguntas:Pregunta[] = [];

  constructor(private preguntaService:PreguntaService,private modalService:ModalService,public lang:LangService, private respuestaService:RespuestaService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    const req = await this.preguntaService.get();
    this.preguntas = req.data;
  }

  async agregarNuevo()
  {
     let modalRef = this.modalService.open(PreguntasNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }


  async modificar(obj:Pregunta)
  {
     let modalRef = this.modalService.open(PreguntasNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.preguntaParam= obj;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(preg:Pregunta)
  {
    let confirmar = await this.modalService.confirm('meso.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.preguntaService.DeletePregunta(preg.pregId);
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
  }

  async refresh()
  {
    this.loading=true;
    await this.onLoad();
    this.loading=false;
  }


}
