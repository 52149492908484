<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{l.t('spon.editar')}}</h4>
        <form>
            <label class="app-label">{{l.t('descripcion')}}:</label>
            <input type="text" class="app-combo" [(ngModel)]="model.sponDesc" name="sponDesc" id="sponDesc">

            <label class="app-label">{{l.t('prioridad')}}:</label>
            <input type="number" class="app-combo" [(ngModel)]="model.sponPrioridad" name="sponPrioridad" id="sponPrioridad">

            <label class="app-label">{{l.t('imagen')}}:</label>
            <div class="loader" *ngIf="imageUpload"></div>
            <img [src]="model.sponImg" alt="">
            <button class="app-btn w-100" (click)="filePicker.click()">{{l.t('subir_imagen')}}</button>
            <input #filePicker type="file" (change)="subirImagen($event)" style="display: none;">

        </form>
        <h6>{{l.t('spon.grupos_videos')}}:</h6>
        <div class="row" *ngIf="!loading" >
            <div class="col-sm-5">

              <div class="lista" style="height: 400px;max-height: 400px;">
                <div class="loader" *ngIf="loadingVideos">

                </div>
                    <h6 *ngFor="let i of grupos" (click)="agregar(i)"><fa-icon icon="plus"></fa-icon> {{i.grupoNombre}}</h6>
              </div>

            </div>
            <div class="col-sm-2">
              <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
            </div>
            <div class="col-sm-5">

              <div class="lista" style="height: 400px;max-height: 400px;">
                    <h6 *ngFor="let i of gruposSel" (click)="remover(i)"><fa-icon icon="minus"></fa-icon> {{i.grupoNombre}}</h6>
              </div>
            </div>
        </div>

        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.add')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
