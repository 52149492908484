<div class="modal-alert">
    <div class="modal-body left">
        <h4>
            <fa-icon icon="edit"></fa-icon> {{lang.t('lecc.editLecc')}} {{leccionParam.leccNombre}} #{{leccionParam.leccId}}</h4>
        <div class="pt-3">

        </div>

        <div class="row">
            <div class="col-sm-5">
                <h5>{{lang.t('lecc.videoDisp')}}</h5>
                <!--                 <select (change)="filtrar()" class="app-combo" [(ngModel)]="filtro">
              <option value="null">Todas las caracteristicas</option>
              <option *ngFor="let car of this.caracteristicas" [ngValue]="car">{{car.caracteristicaNombre}}</option>
            </select> -->
            </div>
            <div class="col-sm-2">

            </div>
            <div class="col-sm-5">
                <h5>{{lang.t('lecc.videoLecc')}}</h5>
            </div>
        </div>
        <div class="row" *ngIf="loading">
            <div class="loader"></div>
        </div>
        <div class="row" *ngIf="!loading">
            <div class="col-sm-5">

                <div class="lista">
                    <h6 *ngFor="let v of videos" (click)="agregar(v)">
                        <fa-icon icon="plus"></fa-icon> {{v.videoNombre}}</h6>
                </div>

            </div>
            <div class="col-sm-2">
                <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
            </div>
            <div class="col-sm-5">

                <div class="lista">
                    <h6 *ngIf="videosSel" (click)="remover(v)">
                        <fa-icon icon="minus"></fa-icon> {{videosSel.videoNombre}}</h6>
                </div>
            </div>
        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
            {{error.message}}
        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar"></div>
    <div class="modal-footer right">

        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="undo-alt"></fa-icon> {{lang.t('cancelar')}}</button>
        <button autofocus type="button" class="app-btn" [disabled]="uploading" (click)="guardar()"><fa-icon icon="save" title="{{lang.t('btn.saveChange')}}"></fa-icon> {{lang.t('guardarCambios')}}</button>
    </div>
</div>
