<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!preguntaParam">{{lang.t('preg.nuevo')}}</h4>
        <h4 *ngIf="preguntaParam">{{lang.t('preg.editar')}} # {{preguntaParam.pregId}}:</h4>
        <form>
            <label class="app-label">{{lang.t('preg.pregunta')}}</label>
            <input class="app-input" type="text" [(ngModel)]="pregDesc" name="pregDesc">
        </form>
        <h4>{{lang.t('preg.agregar')}}</h4>
        <div class="ciclos-border">
            <form>
                <label class="app-label">{{lang.t('preg.respuesta')}}</label>
                <input class="app-input" type="text" [(ngModel)]="respDesc" name="respDesc">



                <div class="app-checkbox">
                    <input type="checkbox" [(ngModel)]="respCorrecta" name="respCorrecta" id="respCorrecta"> <label>{{lang.t('preg.escorrecta')}}</label>

                </div>

            </form>

            <button class="app-btn" (click)="agregar()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
        </div>

        <div *ngIf="loading" class="loader mt-2"></div>
        <div *ngIf="!loading">
            <div *ngIf="!this.loading" class="table-container">
                <div class="table-container">
                    <table class="app-table">
                        <thead>
                            <th>
                                {{lang.t('preg.respuesta')}}
                            </th>
                            <th>
                                {{lang.t('preg.escorrecta')}}
                            </th>

                            <th>

                            </th>
                        </thead>
                        <tbody>
                            <tr *ngFor="let resp of pregResp">
                                <td><input class="app-input" type="text" [(ngModel)]="resp.respDesc" name="moduloNombre"></td>
                                
                                <td><input  class="checkbox" type="checkbox" [(ngModel)]="resp.respCorrecta" name="respCorrecta" id="respCorrecta"> </td>
                                
                                <td>
                                    
                                    <button *ngIf="videosSel" class="app-btn small red" (click)="remover(resp)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <h6 *ngIf="pregResp.length == 0 && !this.loading">{{lang.t('nada_por_aqui')}}</h6>
            </div>

        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check" ></fa-icon></button>
    </div>
</div>
