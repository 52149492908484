<div class="modal-alert">
  <div class="modal-body left">
    <div class="row">
      <div class="col-sm-6">
        <form>
          <label class="app-label">{{lang.text('nombre')}}</label>
          <input type="text" class="app-input" [(ngModel)]="viewModel.userNombre" name="userNombre">
          <label class="app-label">{{lang.text('apellido')}}</label>
          <input type="text" class="app-input" [(ngModel)]="viewModel.userApellido" name="userApellido">
        </form>
      </div>
      <div class="col-sm-6">
        <div>
          <form>
            <label class="app-label">{{lang.text('telefono')}}</label>
            <input type="text" class="app-input" [(ngModel)]="viewModel.userTelefono" name="userTelefono">
          </form>

          <!-- <label class="app-label">{{lang.text('pais')}}</label>
<select (change)="actualizarRegiones($event)" class="app-combo">
<option value="null">{{lang.text('seleccionar')}}</option>
<option *ngFor="let c of paises" value="{{c.paisId}}">{{c.paisNombre}}</option>
</select>

<label class="app-label">{{lang.text('region')}}</label>
<select (change)="actualizarComunas($event)" class="app-combo">
<option value="null">{{lang.text('seleccionar')}}</option>
<option *ngFor="let c of regiones" value="{{c.regionId}}">{{c.regionNombre}}</option>
</select>

<label class="app-label">{{lang.text('comuna')}}</label>
<select [(ngModel)]="viewModel.comunaId" name="userComuna" class="app-combo">
<option value="null" disabled>{{lang.text('seleccionar')}}</option>
<option *ngFor="let c of comunas" [value]="c.comunaId">{{c.comunaNombre}}</option>
</select> -->

          

          <div style="width: auto;"><label class="app-label">{{lang.text('imagen')}}</label></div>
          <div class="loader" *ngIf="imageUpload"></div>
          <img alt="" [src]="viewModel.userImagen">
          <button class="app-btn" style="width: 100%; padding: 4.2px;" (click)="filePicker.click()">{{lang.text('subir_imagen')}}</button>
          <input #filePicker type="file" (change)="subirImagen($event)" style="display: none;">


        </div>
      </div>
    </div>
    <div class="row" style="justify-content: center; align-items: center;">
      <label style="margin-top: 0.25rem; width: 100%;" for="app-label">{{lang.text('inputComuna.label')}}</label>
      <div style="width: 100%; height: auto; display: inline-block;">
        <app-input-comuna [(ngModel)]="viewModel.comunaId"></app-input-comuna>
      </div>



    </div>
    <div class="alert alert-danger" *ngFor="let error of errores">
      {{lang.textEx(error.message)}}
    </div>
  </div>
  <div class="loading-bar" *ngIf="uploading"></div>
  <div class="modal-footer">
    <button type="button" class="app-btn" (click)="activeModal.dismiss('cancelar')"
      title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
    <button type="button" class="app-btn" (click)="save()" title="{{lang.text('btn.saveChange')}}"><fa-icon
        icon="check"></fa-icon></button>
  </div>
</div>