<nz-select style="border-radius: 5px;"
  nzMode="default"
  [(nzPlaceHolder)]="comunaNombre"
  nzAllowClear
  nzShowSearch
  nzServerSearch
  [(ngModel)]="selectedComuna"
  (ngModelChange)="onUserSelect($event)"
  (nzOnSearch)="onSearch($event)"
  (keydown)="onKeyDown($event)"
  (paste)="onPaste($event)">
  <nz-option *ngFor="let o of optionList" [nzValue]="o" [nzLabel]="o"></nz-option>
  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
    <i nz-icon nzType="loading" class="loading-icon"></i>
  </nz-option>
</nz-select>
