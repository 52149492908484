import { Component, OnInit } from '@angular/core';
import { quiz } from 'src/app/models/campus/quiz';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { QuizService } from '../../services/campus/quiz.service';
import { QuizesNuevoComponent } from './quizes-nuevo/quizes-nuevo.component';
import { QuizesEditarComponent } from './quizes-editar/quizes-editar.component';



@Component({
  selector: 'app-quizes',
  templateUrl: './quizes.component.html',
  styleUrls: ['./quizes.component.css']
})
export class QuizesComponent implements OnInit {

  quizes:quiz[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  constructor(private modalService:ModalService, private quizService:QuizService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {
      const req = await this.quizService.get();
      this.quizes = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregarNuevo()
  {
     let modalRef = this.modalService.open(QuizesNuevoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async modificar(quiz?:quiz)
  {
     let modalRef = this.modalService.open(QuizesNuevoComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
     modalRef.componentInstance.quizParam= quiz;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async administrar(quiz:quiz){
     let modalRef = this.modalService.open(QuizesEditarComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.quizParam = quiz;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async eliminar(quiz:quiz)
  {
    let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.quizService.DeleteQuiz(quiz.quizId);
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }




}
