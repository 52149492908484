import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sesion-editar',
  templateUrl: './sesion-editar.component.html',
  styleUrls: ['./sesion-editar.component.css']
})
export class SesionEditarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
