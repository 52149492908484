
<div class="centered g-border">
    <div class="login">
        <img style="width: 100%;" src="./assets/logo_blanco.png">
        <div class="blurred form">
            <div *ngIf="loading" class="loader">

            </div>
            <div *ngIf="!loading">
                <h4>{{lang.text('pr.header')}}</h4>
                <ng-container *ngIf="step == 1">

                    <label class="app-label">{{lang.text('correo')}}:</label>
                    <input class="app-input" type="email" [(ngModel)]="email" name="email">
                    <div style="display: flex;justify-content: flex-end;">
                        <button class="app-btn mt-2"  (click)="goStep2()">{{lang.text('pr.send_mail')}}</button>

                    </div>
                </ng-container>

                <ng-container *ngIf="step == 2">
                    <p class="info">{{lang.text('pr.info_msg')}}</p>
                    <label class="app-label">{{lang.text('pr.code')}}:</label>
                    <input class="app-input" type="text" [(ngModel)]="code" name="code">

                    <label class="app-label">{{lang.text('pr.password')}}:</label>
                    <input class="app-input" type="password" [(ngModel)]="pass" name="password">

                    <label class="app-label">{{lang.text('pr.password2')}}:</label>
                    <input class="app-input" type="password" [(ngModel)]="pass2" name="password2">

                    <div style="display: flex;justify-content: flex-end;">
                        <button class="app-btn mt-2"  (click)="resetPassword()">{{lang.text('pr.reset_password')}}</button>

                    </div>
                </ng-container>

            </div>



        </div>

    </div>
</div>

