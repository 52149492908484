// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  urlAPI:'https://testingapi.golazoft.com/intranet/api/',
  urlServicio:'https://testingapi.golazoft.com/notificaciones/',
  //urlWS:'http://localhost:5001/ws/',
  //urlAPI:'https://localhost:5001/api/',
  mercadoPagoToken:'APP_USR-2a85a4bd-70d9-4ee3-aec0-ace9eaa885de',
  publicVAPID:'BIuvp8e3hmSl-xwhqk9rMNWY5fXP0Esj538vpCcNsqZFX2CxdMOhPK0gMtGuhaeJzAW6ibPLRH9U74gX7Ll12x0'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
import 'zone.js/dist/zone-error';  // Included with Angular CLI.
