import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Sesion } from '../models/sesion';

import { Grupo } from '../models/grupo';




@Injectable({
  providedIn: 'root'
})

export class GrupoService {

  constructor(private http:HttpClient) {
  }

  

  public getAll()
  {
    return this.http.get<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo`).toPromise();
  }

  public getBySesion(id:number)
  {
    return this.http.get<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo/sesion/${id}`).toPromise();
    //return this.http.get<ApiResponse<Video>>(`${environment.urlAPI}sesion/${id}`).toPromise();
  }

  public post(grupo:Grupo)
  {
    return this.http.post<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo`,grupo).toPromise();
  }

  public update(grupo:Grupo)
  {
    return this.http.post<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo/actualizar`,grupo).toPromise();
  }

  public delete(id:number)
  {
    //return this.http.delete<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo/${id}`).toPromise();
    return this.http.post<ApiResponse<Grupo>>(`${environment.urlAPI}grupovideo/eliminar/${id}`,{}).toPromise();

  }
  
}
