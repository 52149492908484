export class User{
  token:string;
  id:number;
  membresiaId:number;
  rolId:number;
  rolDescripcion?:string;
  userNombre:string;
  userApellido:string;
  userSexo:number;
  userEmail:string;
  userImagen:string;
  userComuna:string;
  rememberToken:string;
  comunaId:number;
  userTelefono:string;
  userLenguaje:string;
  userPassword:string;
  userRut:string;
  userActivo:number;
  userCertificado:boolean;
  esEmpresa:boolean;

  paginationLastPage?:number;
  paginationTotalResults?:number;
  /*  */

  stripeId:string;
  stripeConfirmado:boolean;
  stripeAccountUrl?:string;

  periodoId:number;
  fechaInicio:string;
  nivelId:number;

  contactado:boolean;
  regionId?: number;

  selected:boolean = false;
  evaluacionesActivas?: number;

}
