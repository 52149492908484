import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AgregarAgendaVM } from "../components/mi-cuenta/agenda/agregar-agenda/agregar-agenda.component";
import { Abono } from "../models/abono";
import { Agenda } from "../models/agenda";
import { ApiResponse } from "../models/ApiResponse";
import { Compra } from "../models/compra";
import { PaqueteEntrenamiento } from "../models/paquete";

@Injectable({
    providedIn: 'root'
  })
export class AgendaService{



    delete(ind: Agenda) {
        return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}agenda/${ind.agenId}`).toPromise();
    }

    constructor(private http:HttpClient){

    }

    get(){
        return this.http.get<ApiResponse<Agenda>>(`${environment.urlAPI}agenda`).toPromise();
    }

    agregar(vm:AgregarAgendaVM){

        const body = {
            fechaDesde:vm.fechaDesde,
            fechaHasta:vm.fechaHasta,
            hora:vm.hora,
            dias: vm.dias.map(i => {return i.checked})
        };
        return this.http.post<ApiResponse<any>>(`${environment.urlAPI}agenda`,body).toPromise();
    }


}