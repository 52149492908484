<div class="modal-alert">
    <div class="modal-body left">
        <h2>{{lang.text('profesores_sin_subs')}}</h2>
        <p>{{total}} {{lang.text('registros')}}</p>
        <table class="app-table">
            <thead>
                  <th>
                      {{lang.text('nombre')}}
                  </th>

                  <th>
                      {{lang.text('correo')}}
                  </th>
                  <th>

                  </th>
            </thead>
            <tbody>
                  <tr *ngFor="let i of users">
                    <td>
                        {{i.userNombre}} {{i.userApellido}}
                    </td>
                    <td>
                        {{i.userEmail}}
                    </td>

                    <td>
                        <button (click)="agregar(i)" class="app-btn small" title="{{lang.text('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button class="app-btn small outline" (click)="prevPage()"> < </button>
        <button class="app-btn small outline" disabled > {{currentPage}} / {{lastPage}} </button>
        <button class="app-btn small outline"  (click)="nextPage()"> > </button>

        <button type="button" class="app-btn"  (click)="activeModal.dismiss('cancelar')"><fa-icon icon="times"></fa-icon> {{lang.text('cerrar')}}</button>

    </div>
</div>


