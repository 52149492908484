import { AfterViewInit, Directive, ElementRef, HostListener, Input, NgZone, OnInit, Renderer2 } from '@angular/core';
import { take } from 'rxjs/operators';

@Directive({
  selector: '[kTable]'
})
export class KtableDirective implements AfterViewInit,OnInit{


  @Input() pageSize = 5;
  table:HTMLTableElement;
  columns:string[] = [];




  constructor(private element: ElementRef<HTMLTableElement>,private zone:NgZone) {
    this.table = element.nativeElement;
    this.table.setAttribute('currentPage','1');

    

    this.zone.onMicrotaskEmpty.subscribe(i =>{
      this.update();
    });
  }


  ngOnInit(): void {
    
  }


  ngAfterViewInit(): void {
    
    this.update();
  }

  

  

  update(){

    const ths = this.table.querySelectorAll('th');


    this.columns = Array.from(ths).map( i => { return i.innerText});
    

    let rows = Array.from(this.table.querySelectorAll('tr'));

    rows.forEach(row => {
      row.style.display = 'none';
    });

    const rowsVisible = this.getRows(rows);


    rowsVisible.forEach(row => {
      row.style.display = 'table-row';
    });

    
  }

  get currentPage(){
    let p = Number(this.table.getAttribute('currentPage'));
    if(p < 1) p = 1;
    return p;
  }


  set lastPage(n:number){
    this.table.setAttribute('lastPage',n.toString());
  }

  getRows(rows:HTMLTableRowElement[]){
    let array =  rows.slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
    this.lastPage = Math.ceil(rows.length / this.pageSize);
    return array;
  }

}
