import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistroPago, TipoPago } from 'src/app/models/pagos';
import { User } from 'src/app/models/User';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-agregar-pago',
  templateUrl: './agregar-pago.component.html',
  styleUrls: ['./agregar-pago.component.css']
})
export class AgregarPagoComponent implements OnInit {

  @Input() userModel:User;

  uploading = false;

  tiposPago:TipoPago[] = [];

  @Input() model:RegistroPago = {repaFh : null,repaMeses: 6};

  constructor(public activeModal:NgbActiveModal,private userService:UserService,private modalService:ModalService,public lang:LangService) { }

  ngOnInit(): void {
  
    this.load();
  }


  async load(){
    try {
      const req = await this.userService.getTiposPagos();
      this.tiposPago = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
  }

  async save(){
    if(this.model.tipaId == null || this.model.tipaId == 0){
      return;
    }
    this.model.idUser = this.userModel.id;
    this.uploading = true;
    try {
      const req = await this.userService.postPago(this.model);
      this.activeModal.close('ok');
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
    this.uploading = false;
  }

}
