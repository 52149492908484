<div class="modal-alert">
    <div class="modal-body left">
          <h4>{{lang.text('al.crear_periodo')}}</h4>
        <div class="row">
              <div class="col-sm-12">
                <div class="row" *ngIf="userModel">
                    <div class="col-sm-6">
                        <img [src]="userModel.userImagen" alt="" height="64">
                    </div>
                    <div class="col-sm-6">
                        <h5>{{userModel.userNombre}} {{userModel.userApellido}}</h5>
                    </div>
                </div>
               <form>


                <label class="app-label">{{lang.text('fecha_inicio')}}</label>
                <input type="date" class="app-input" [(ngModel)]="model.evaluacionInicio" name="evaluacionInicio">

                <label class="app-label">{{lang.text('plan')}}</label>
                <select [(ngModel)]="model.planId" name="userPlan" class="app-combo">
                      <option [value]="null" disabled selected>{{lang.text('seleccionar')}}</option>
                      <option *ngFor="let o of planes" value="{{o.planId}}">{{o.planDesc}}</option>
                </select>

                <div *ngIf="model.planId == 4">
                      <label class="app-label">Mesociclos:</label>
                      <select [(ngModel)]="model.cicloFiltro" name="cicloFiltro" class="app-combo">
                            <option [value]="0" disabled selected>{{lang.text('seleccionar')}}</option>
                            <option *ngFor="let o of ciclos" value="{{o.megrId}}">{{o.megrNombre}}</option>
                      </select>
                </div>


                <label class="app-label">{{lang.text('semestre')}}</label>
                <select [(ngModel)]="model.caunId" name="caunId" class="app-combo">
                        <option [value]="null" disabled selected>{{lang.text('seleccionar')}}</option>
                        <option *ngFor="let o of macros" value="{{o.caunId}}">{{o.caunNombre}}</option>
                </select>


               </form>

              </div>
        </div>




        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
