import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/ApiResponse";
import { PaqueteEntrenamiento } from "../models/paquete";

@Injectable({
    providedIn: 'root'
  })
export class PaqueteService{

    constructor(private http:HttpClient){

    }

    getAll(){
        return this.http.get<ApiResponse<PaqueteEntrenamiento>>(`${environment.urlAPI}paqueteentrenamiento`).toPromise();
    }
}