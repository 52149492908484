import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluacion-editar',
  templateUrl: './evaluacion-editar.component.html',
  styleUrls: ['./evaluacion-editar.component.css']
})
export class EvaluacionEditarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
