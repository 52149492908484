import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Video } from 'src/app/models/video';
import { ModalService } from 'src/app/services/modal-service';
import { leccion } from '../../../models/campus/leccion';
import { VideoService } from '../../../services/video.service';
import { Documento, DocumentoLeccion, DocumentoService } from '../../../services/documento.service';
import { LangService } from 'src/app/services/lang.service';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { DocumentoLeccionn } from 'src/app/models/campus/LeccionDocumento';

@Component({
  selector: 'app-editar-documento-leccion',
  templateUrl: './editar-documento-leccion.component.html',
  styleUrls: ['./editar-documento-leccion.component.css']
})
export class EditarDocumentoLeccionComponent implements OnInit {

  @Input() leccionParam:leccion;

  //grupo:Grupo;
  documentos:Documento[] = [];
  documentoSel:Documento[]=[];
  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];
/*   filtro:Caracteristica = null; 
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public documentoService:DocumentoService,
              private modalService:ModalService,
              public lang:LangService,
              private docService:DocumentoService,
              private leccionService:LeccionService
              ) { }

  async ngOnInit(): Promise<void> {
    //await this.onLoad();
    this.loadDocCampus();
    this.loading = false;
    this.cargaDocumentosPorLeccion();
  }



  async cargaDataDocuLecc(){
    console.log('jhadsb', this.leccionParam.leccId);
    console.log('jhadssadfasdab', this.leccionParam.docuId);

    //const req = await this.leccionService.getLeccDocu(this.leccionParam.docuId,this.documentoSel.docuId);
   // const asd = req.data;
  }



  async onLoad()
  {
    try {
      if (this.leccionParam.docuId) {
/*         const request = await this.videoService.getCampus(this.leccionParam.videoId);
        this.documentoSel= request.data[0]; */
      }else{
        this.documentoSel=null;
      }

      //const req = await this.videoService.ge
/*       const request2 = await this.videoService.getCampus();  --por hacer 
      this.documentos = request2.data;*/

      
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    }
  }



  async loadDocCampus(){
    try {
      const req = await this.docService.getAllCampus();
      this.documentos = req.data;


    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
    this.loading = false;
  }


  async cargaDocumentosPorLeccion(){
    const req = await this.leccionService.getLeccDocuPorLeccId(this.leccionParam.leccId);
    this.documentoSel = req.data;
  }

  async agregar(documento:Documento)
  {   
    console.log('ajksdjkashd',this.documentoSel);
    let find = this.documentoSel.find(el => el.docuId == documento.docuId);
    if(!find)
    {
      this.documentoSel.push(documento);
    }
  }


  async remover(documento:Documento)
  {
    console.log('doc', documento);
    let p = this.documentoSel.findIndex(el => el.docuId == documento.docuId);
    this.documentoSel.splice(p,1);
    
    
  }

  async guardar(){
    this.uploading= true;
/*      try {
      let v:quizPreguntas = new quizPreguntas();
      v.quizId=this.quizParam.quizId;
      v.preguntas = [];
      for (const iterator of this.preguntasSel) {
        v.preguntas.push(iterator.pregId);
      }
      const req = await this.quizService.postQuizPregunta(v);
      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }  */
    this.uploading = false;
  }





    async save(){
       this.uploading = true;
      try {
       let v:DocumentoLeccionn = new DocumentoLeccionn();
       v.leccId = this.leccionParam.leccId;
       v.documentos = [];
       for (const iterator of this.documentoSel) {
         v.documentos.push(iterator.docuId);
       }
       console.log(v);
       const req = await this.leccionService.postDocuLecc(v);
       this.activeModal.close('Actualizado!');
     } catch (error) {
       await this.modalService.showError(error.message,'Aceptar');
     }
     this.uploading = false; 
   }
}

