import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ActualizarDatosViewModel } from 'src/app/models/actualizardatosvm';
import { Comuna, DireccionCompleta } from 'src/app/models/comuna';
import { AuthService } from 'src/app/services/auth-service.service';
import { UserService } from 'src/app/services/user.service';
import { environment } from 'src/environments/environment';
import { LangService } from 'src/app/services/lang.service';
import { User } from 'src/app/models/User';
import { ModalService } from 'src/app/services/modal-service';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Utils } from 'src/app/helpers/Utils';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { Nivel } from 'src/app/models/nivel';
import { SemestreViewModel } from 'src/app/models/SemestreViewModel';
import { CategoriaService } from 'src/app/services/categoria.service';
import { PeriodoService } from 'src/app/services/periodo.service';


@Component({
  selector: 'app-actualizar-datos',
  templateUrl: './actualizar-datos.component.html',
  styleUrls: ['./actualizar-datos.component.css']
})
export class ActualizarDatosComponent implements OnInit {
  uploading = false;

  comunas:Comuna[] = [];
  errores:any[] = [];
  imageUpload = false;
  @Input() viewModel:ActualizarDatosViewModel = {};
  regiones:Comuna[] = [];

  paises:Comuna[] = [];
  all:Comuna[]=[];
  allRegions:Comuna[] = [];


  constructor(public activeModal:NgbActiveModal,private userService:UserService,private authService:AuthService,private router:Router, public lang:LangService,private modalService:ModalService,private categoriaService:CategoriaService,
    private periodoService:PeriodoService) { }

  async ngOnInit() {
    await this.onLoad();
    this.uploading = false;
    console.log(this.viewModel)
  }

  handleSelectedUserChange(selectedUser: string) {
    // Do something with the updated selectedUser value
    console.log('Selected User changed:', selectedUser);
    console.log(this.viewModel.comunaId)
  }

  async reLoad(){
    await this.onLoad()

  }
   setupDireccion(list:Comuna[]){
    this.all = list;
    for (const i of Utils.groupBy(list,x => x.paisId)) {
      this.paises.push(i[0]);
    }

    for (const i of Utils.groupBy(list,x => x.regionId)) {
      this.allRegions.push(i[0]);
    }

    this.comunas = this.all;
  }


  async onLoad()
  {



    try {
      //const req = await this.userService.getComunas();
      //this.comunas = req.data;


      //this.setupDireccion(req.data);

    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }

  }

  actualizarRegiones(event){
    this.regiones = this.allRegions.filter(x => x.paisId == event.target.value);
  }

  actualizarComunas(event){
    this.comunas = this.all.filter(x => x.regionId == event.target.value);
  }

  async subirImagen(event){
    let file = event.target.files[0];
    console.log(file);
    this.imageUpload= true;
    try {
      const req = await this.userService.postImagen(file);
      this.viewModel.userImagen = `${environment.urlAPI}user/imagen/${req.message}`;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.imageUpload= false;
  }

  async save(){
     this.uploading= true

    try {
      const req = await this.userService.actualizarDatos(this.viewModel);
      /* loader.message = 'Guardado!'; */
      /* await this.router.navigateByUrl('/home'); */
      /* loader.dismiss(); */
      this.activeModal.close();

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading= false
  }

}
