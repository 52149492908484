<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!moduloParam">{{lang.text('modu.add')}}</h4>
        <h4 *ngIf="moduloParam">{{lang.text('modu.editar')}}</h4>
        <form>
            <label class="app-label">{{lang.text('nombre')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="modulos.moduNombre" name="moduloNombre">

            <label class="app-label">{{lang.text('modu.desc')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="modulos.moduDesc" name="moduloDesc">

            <label class="app-label">{{lang.text('modu.cod')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="modulos.moduCodigo" name="moduloCodigo">
        </form>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar">

    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn red" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn green" [disabled]="uploading" (click)="agregar()" title="{{lang.t('btn.add')}}"><fa-icon icon="check" ></fa-icon></button>
    </div>
</div>
