import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-evaluacion-detalle',
  templateUrl: './evaluacion-detalle.component.html',
  styleUrls: ['./evaluacion-detalle.component.css']
})
export class EvaluacionDetalleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
