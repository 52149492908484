import { Component, OnInit } from '@angular/core';
import { Mesogrupo } from 'src/app/models/Mesogrupo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { MesogrupoEditarComponent } from '../mesogrupo-editar/mesogrupo-editar.component';

@Component({
  selector: 'app-mesogrupo',
  templateUrl: './mesogrupo.component.html',
  styleUrls: ['./mesogrupo.component.css']
})
export class MesogrupoComponent implements OnInit {
  loading = true;
  grupos:Mesogrupo[] = [];

  constructor(private categoriaService:CategoriaService,private modalService:ModalService,public lang:LangService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    const req = await this.categoriaService.getAllMesoGrupos();
    this.grupos = req.data;
  }

  async agregarNuevo()
  {
    let modalRef = this.modalService.open(MesogrupoEditarComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }



  async modificar(obj:Mesogrupo)
  {
    let modalRef = this.modalService.open(MesogrupoEditarComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.mesogrupo= obj;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(obj:Mesogrupo)
  {
    let confirmar = await this.modalService.confirm('mesogrupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.categoriaService.deleteMesogrupo(obj);
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
  }

  async refresh()
  {
    this.loading=true;
    await this.onLoad();
    this.loading=false;
  }
}
