import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Clase } from '../models/clase';
import { Suscripcion } from '../models/suscripcion';





@Injectable({
  providedIn: 'root'
})

export class WebService {

  constructor(private http:HttpClient) {
  }

  

  public async contact(body:ContactForm)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}web/contact-form`,body).toPromise();
  }

  public async planTypes(){
    return this.http.get<ApiResponse<Suscripcion>>(`${environment.urlAPI}pagos/MercadoPago/planes`).toPromise();
  }


  public async payRegister(body:PayForm)
  {
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}pagos/MercadoPago`,body).toPromise();
  }

  public stripePago(){
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}pagos/stripe/pagar`,{}).toPromise();
  }

  public getLog(){
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}log`).toPromise();
  }

  public stripePortal(){
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}pagos/stripe`).toPromise();
  }


  public getResumenAdm(){
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}reporte/resumen`).toPromise();
  }


  public getGrafico(id:string = 'all'){
    return this.http.get<ApiResponse<GraficoData>>(`${environment.urlAPI}reporte/grafico/${id}`).toPromise();
  }
  
  public async enviarCorreoSuscripcionPagada(idAlumno:number)
  {
    
    var body = {
            alumnoId:idAlumno,
          }
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}user/alumno-suscripcion-pagada`,body).toPromise();
  }

}

export interface GraficoData{
  xAxis:string,
  yAxis:string,
  dataSet:string,
  chartType:string
}

export interface PayForm{
  email:string;
  cardToken:string;
  planId:number;
}

export interface ContactForm{
  asunto:string;
  cuerpo:string;
  mail:string;
  nombre:string;
}