<div class="modal-alert">
    <div class="modal-body left">
        <label for="" class="app-label">{{lang.text('subir_excel')}}</label>
        <div class="row">
            <div class="col-sm-6">
                <button class="app-btn" (click)="file.click()">
                    {{lang.text('seleccionar_archivo')}}
                </button>
            </div>
            <div class="col-sm-6">
                {{fileName}}
            </div>
        </div>
        <label for="" class="app-label">{{lang.text('contenido_excel')}}</label>
        <div style="margin-top:12px; border:1px solid var(--secondary-color);padding:5px;border-radius: 5px;">
            <table class="app-table" *ngIf="users.length > 0" >
                
                <tbody>
                      <tr *ngFor="let i of users" [class.error]="i.Correo == correoDup" [id]="'row_'+i.Correo">
                        <td>
                            <input class="input-row" [class.disabled]="!editing" [disabled]="!editing" type="email" name="correo" id="correo" [(ngModel)]="i.Correo">
                        </td>
                        <td>
                            {{i.Nombre}}
                        </td>
                        <td>
                            {{i.Apellido}}
                        </td>
                        <td>
                            {{i.Telefono}}
                        </td>
                        <td *ngIf="editing">
                            <button class="app-btn" (click)="remove(i)"><fa-icon icon="trash"></fa-icon></button>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        
          <input #file style="display:none" type="file" name="" id="" (change)="fileChange($event)">
          <div class="alert alert-danger" *ngFor="let error of errores">
                {{error.message}}
          </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button class="app-btn" (click)="plantilla()" >{{lang.text('descargar_plantilla')}}</button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
