<div class="container-view">
    <h4>{{lang.text('mesogrupos')}}</h4>
    <div class="botones-opciones mb-3">
        <button class="app-btn" (click)="agregarNuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
        <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>

    </div>
    <div *ngIf="!this.loading" class="table-container">
        <table class="app-table">
              <thead>
                    <th>
                          #
                    </th>
                    <th>
                          {{lang.text('descripcion')}}
                    </th>
                    <th>

                    </th>
              </thead>
              <tbody>
                    <tr *ngFor="let iter of this.grupos">
                          <td>{{iter.megrId}}</td>
                          <td>{{iter.megrNombre}}</td>
                          <td>
                                <button class="app-btn small" (click)="modificar(iter)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                                <button class="app-btn small red" (click)="eliminar(iter)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                          </td>
                    </tr>
              </tbody>
        </table>

    </div>

    <div *ngIf="this.loading" class="loader">

    </div>
    <h6 *ngIf="this.grupos.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>

</div>
