<div class="container-md" style="max-width: 960px;">
    <div class="botones-opciones full ciclos">
        <button class="app-btn " [routerLink]="['/account']" >{{lang.text('acco.h1')}}</button>
        <button class="app-btn" [routerLink]="['/calendar']" >{{lang.text('acco.calendar')}}</button>
        <button class="app-btn " [routerLink]="['/prices']" >{{lang.text('acco.payments')}}</button>
        <button class="app-btn active" [routerLink]="['/sells']" >{{lang.text('acco.sells')}}</button>

    </div>
    <div class="header g-border p-3">
        <h2>{{lang.text('acco.header_payouts')}}</h2>

        

        <div class="table-container" style="margin-top: 16px;max-height: 50vh;">
            <table #tabla class="app-table ktable" kTable [pageSize]="15">
                <thead>
                    <th>
                        {{lang.text('fecha_creacion')}}
                    </th>
                    <th>
                        {{lang.text('fecha_arrival')}}
                    </th>
                    <th>
                        {{lang.text('status')}}
                    </th>
                    <th>
                        {{lang.text('amount')}}
                    </th>
                    <th>
                        {{lang.text('error')}}
                    </th>
                    
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of payouts">
                        <td>
                            {{i.created |date:'medium'}}
                        </td>
                        <td>
                            {{i.arrivalDate |date:'medium'}}
                        </td>
                        <td>
                            {{i.status}}
                        </td>

                        <td>
                            $ {{i.amount / 100 | currency:i.currency}} 
                        </td>
                        <td>
                             {{i.failureMessage}} 
                        </td>
                        
                    </tr>
                </tbody>
            </table>
        </div>
        
        <app-paginator [tableRef]="tabla">
        </app-paginator>
        
    </div>    
</div>
