<div class="container view">
      <div class="botones-opciones ciclos full ">
          <button class="app-btn" [routerLink]="['/macrociclos']" >{{lang.t('macro.macrociclos')}}</button>
          <button class="app-btn" [routerLink]="['/mesociclos']">{{lang.t('mesociclos')}}</button>
          <button class="app-btn" [routerLink]="['/microciclos']">{{lang.t('microciclos')}}</button>
          <button class="app-btn active" [routerLink]="['/sesiones']">{{lang.t('sesiones')}}</button>
      </div>
      <div class="ciclos-border">
            <div class="botones-opciones mb-3">
                  <button class="app-btn" (click)="agregarNuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
                  <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>
                  <input type="search" placeholder="{{lang.t('buscar')}}" class="app-btn app-btn-search" name="busqueda" [(ngModel)]="searchStr" (keyup)="search()" (blur)="search()"   id="">
              </div>
              <div *ngIf="!this.loading" class="table-container ciclos">
                  <table class="app-table">
                        <thead>

                              <th style="width: 12px;">
                                    Id
                              </th>
                              <th style="width: 35%;">
                                    {{lang.t('nombre')}}
                              </th>
                              <th style="width: 25%;">
                                    {{lang.t('nombre_visible')}}
                              </th>
                              <th>
                                    {{lang.t('videos')}}
                              </th>
                              <th>

                              </th>
                        </thead>
                        <tbody>
                              <tr *ngFor="let iter of this.getPaged(page); let ind = index">
                                    <td><small style="font-style: italic;">{{iter.cacuId}}</small></td>
                                    <td>{{iter.cacuNombre}}</td>
                                    <td>{{iter.cacuNombreApp}}</td>

                                    <td>
                                          {{iter.videosEnSesion}}
                                    </td>
                                    <td>
                                          <button title="{{lang.text('btn.edit')}}" class="app-btn small" (click)="modificar(iter)">
                                                <fa-icon icon="edit"></fa-icon>
                                          </button>
                                          <button title="{{lang.text('ses.informacion')}}" class="app-btn small" (click)="info(iter)">
                                                <fa-icon icon="info"></fa-icon>
                                          </button>
                                          <button title="{{lang.text('ses.duplicar')}}" class="app-btn small" (click)="duplicar(iter)">
                                                <fa-icon icon="copy"></fa-icon>
                                          </button>
                                          <button title="{{lang.text('btn.dele')}}" class="app-btn small red" (click)="eliminar(iter)">
                                                <fa-icon icon="trash"></fa-icon>
                                          </button>
                                    </td>
                              </tr>
                        </tbody>
                  </table>

              </div>
              <div class="mt-2">
                  <button class="app-btn small outline" (click)="prevPage()"> < </button>
                  <button class="app-btn small outline" disabled > {{page+1}} / {{lastPage}} </button>
                  <button class="app-btn small outline"  (click)="nextPage()"> > </button>
              </div>
              <div *ngIf="this.loading" class="loader">

              </div>
              <h6 *ngIf="this.categorias.length == 0 && !this.loading">{{lang.text('nada_por_aqui')}}</h6>
      </div>

  </div>
  <div class="clip-status" *ngIf="!clipService.isEmpty">
      <h6><fa-icon icon="copy"></fa-icon> {{clipService.clip.content.length}} {{lang.text('ses.portapapeles')}} <a (click)="clipService.clear()"> {{lang.text('ses.vaciar')}}</a></h6>
  </div>
