import { AfterViewInit, Component, Inject, ViewEncapsulation } from '@angular/core';
import { NavigationStart, Router,Event } from '@angular/router';
import { CookieOptions, CookieService } from 'ngx-cookie-service';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { PushService } from 'src/app/services/push.service';
import { UserService } from 'src/app/services/user.service';
import { WebService } from 'src/app/services/web.service';
import { WebSocketService } from 'src/app/services/ws.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements AfterViewInit {
  title = 'golazo';

  isAuth = false;
  userModel:User;
  showMenu= false;

  productionApi = false;
  apiUrl = '';
  prodBuild = environment.production;

  @Inject(Router)private router:Router;
  notifications: Noti[] = [];

  constructor(private authService:AuthService,
    private userService:UserService,
    public lang:LangService,
    private cookieService:CookieService,
    private wsSrv:WebSocketService,
    private pushSrv:PushService){
    this.authService.currentUser.subscribe( val => {
      this.isAuth = (val? true:false);
      this.userModel = val;

    });

    this.updateSession();

    console.log('env:',environment.production);
    this.apiUrl = environment.urlAPI;
    if(environment.urlAPI.toLowerCase().includes('golazoft.com')){
      this.productionApi = true;
    }
    if(environment.urlAPI.toLowerCase().includes('/apit') || environment.urlAPI.toLowerCase().includes('testing')){
      this.productionApi = false;
    }
    if(!environment.production){
      this.productionApi = false;
    }
    this.cookieSetUp();

    this.pushSrv.init();
  }


  /**
   * Trata de identificar el browser, experimental...
   */
  cookieSetUp(){
    try{
      if(!this.cookieService.check('golazo_browser')){
          let uuid = self.crypto.randomUUID();
          console.log('id:',uuid);
          const expirationDate = new Date();
          expirationDate.setFullYear(expirationDate.getFullYear() + 10);
          let options:CookieOptions = {
            expires: expirationDate,
            path: '/',
          };
          if(environment.production){
            options.domain = '.golazoft.com';
          }
          this.cookieService.set('golazo_browser',uuid,options);
      }
    }
    catch(error:any){
      console.error(error);
    }
  }

  ngAfterViewInit(): void {
    this.handleNotifications();
  }

  async updateSession(){
    this.authService.checkRole();   
  }

  async handleNotifications(){
    if(this.authService.currentUserValue != null){
      const req = await this.userService.me();
      const userData = req.data[0];
      if(userData.rolId == 6){
        if(userData.stripeId == null ){
          this.notifications.push({text:'stripe_setup_noti',url:'/sells'});
        }else if(!userData.stripeConfirmado){
          this.notifications.push({text:'stripe_config_noti',url:'/sells'});
        }
      }
      
    }
    
  }

  openNoti(i:Noti){
    this.router.navigateByUrl(i.url);
    const idx = this.notifications.findIndex(j => i.text = j.text);
    this.notifications.splice(idx,1);
  }

  remove(idx){
    this.notifications.splice(idx,1);
  }

  cerrarSesion(){
    this.authService.logout();
  }
}



export interface Noti{
  text:string;
  url?:string;
}