<div class="container-md" style="max-width: 960px;" *ngIf="authService.rolId == 6">
    <div class="botones-opciones full ciclos">
        <button class="app-btn active" [routerLink]="['/account']" >{{lang.text('acco.h1')}}</button>
        <ng-container *ngIf="authService.rolId == 6">
            <button class="app-btn" [routerLink]="['/calendar']" >{{lang.text('acco.calendar')}}</button>
            <button class="app-btn" [routerLink]="['/prices']" >{{lang.text('acco.payments')}}</button>
            <button class="app-btn " [routerLink]="['/sells']" >{{lang.text('acco.sells')}}</button>
        </ng-container>
        
    </div>    
</div>
<div class="container-md" style="max-width: 960px;" *ngIf="!loading">
    <div class="header g-border p-3">
        <div class="row">
            <div class="col-sm-3">
                <img [src]="user.userImagen" class="avatar" alt="">
            </div>
            <div class="col-sm-9">
                <h2>{{user.userNombre}} {{user.userApellido}}</h2>
                <p><fa-icon icon="envelope"></fa-icon> {{user.userEmail}}</p>
            </div>
        </div>
    </div>
    <h2 class="mt-3">{{lang.text('acco.mis-datos')}}</h2>
    <div class="header g-border p-3">
        <div class="row">
            <div class="col-sm-6">
                <h6><fa-icon icon="phone"></fa-icon> {{user.userTelefono}}</h6>
                <h6><fa-icon icon="address-book"></fa-icon> {{user.comunaNombre}}</h6>
            </div>
            <div class="col-sm-6">
                <h6><fa-icon icon="signature"></fa-icon> {{user.userRut}}</h6>
                <h6><fa-icon icon="venus-mars"></fa-icon> {{genders[user.userSexo]}}</h6>
            </div>
        </div>
    </div>

    <ng-container *ngIf="user.rolId==2 || user.rolId == 6">
        <ng-container *ngIf="suscripcion">
            <h2 class="mt-3">{{lang.text('acco.suscripcion_activa')}}</h2>
            <div class="header g-border p-3">
                <div class="row">
                    <div class="col-sm-6">
                        <h6><fa-icon icon="money-bill"></fa-icon> {{suscripcion.tipaNombre}}</h6>
                        <ng-container *ngIf="suscripcion.tipaId == -1" >
                            <h6 *ngIf="suscripcion.repaFhExp == null"><fa-icon icon="calendar"></fa-icon> {{lang.text('acco.valid_susc')}}</h6>
                            <h6 *ngIf="suscripcion.repaFhExp != null" ><fa-icon icon="calendar"></fa-icon> {{lang.text('acco.valid_until')}} {{suscripcion.repaFhExp | date :'d/M/yy'}}</h6>
                        </ng-container>
                        <h6 *ngIf="suscripcion.tipaId != -1" ><fa-icon icon="calendar"></fa-icon> {{lang.text('acco.valid_until')}} {{suscripcion.repaFhExp | date :'d/M/yy'}}</h6>
                    </div>
                    <div class="col-sm-6">
                        <a style="padding: 4px;font-size:12px;" *ngIf="linkAdmPago!=null" target="_blank" class="app-btn" [href]="linkAdmPago" >{{lang.text('acco.adm_pago')}}</a>
                    </div>
                </div>
            </div>
        </ng-container>

        <h2 class="mt-3">{{lang.text('acco.mis_lugares_clases')}}</h2>
        <div class="header g-border p-3">
            <p>{{lang.text('acco.lugares_info')}}</p>
            <button class="app-btn" (click)="nuevoLugarClases()"><fa-icon icon="plus"></fa-icon> {{lang.text('acco.nuevo_lugar')}}</button>
            <div class="table-container">
                <table class="app-table">
                    <thead>
                        <th>{{lang.text('comuna')}}</th>
                        <th>{{lang.text('descripcion')}}</th>
                        <th *ngIf="user.rolId ==2 ">{{lang.text('precio')}}</th>
                        <th>{{lang.text('dia')}}</th>
                        <th>{{lang.text('horario')}}</th>
                        <th>{{lang.text('entrenamiento')}}</th>
                        <th></th>
                    </thead>
                    <tbody>
                        <tr *ngFor="let i of lugares">
                            <td>
                                {{i.comunaNombre}}, {{i.regionNombre}}
                            </td>
                            <td>
                                {{i.luclDesc}}
                            </td>
                            <td *ngIf="user.rolId ==2 ">
                                <ng-container *ngIf="i.luclPrecioRef && i.luclPrecioRef > 0">
                                    {{i.luclPrecioRef | currency}}
                                </ng-container>
                            </td>
                            <td>
                                <span *ngFor="let j of i.dias">
                                    {{diasSemana[j]}}
                                </span>
                                
                                
                            </td>
                            <td>
                                {{i.horario}}
                            </td>
                            <td>
                                {{i.entreTipo}}
                            </td>
                            <td>
                                <button class="app-btn" (click)="editarLugarClases(i)" title="{{lang.text('editar')}}"><fa-icon icon="edit"></fa-icon></button>
                                <button class="app-btn" (click)="eliminarLugarClases(i)" title="{{lang.text('eliminar')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                            </td>
                        </tr>
                        <tr *ngIf="lugares.length == 0">
                            <td>
                                {{user.comunaNombre}}
                            </td>
                            <td>
                                ({{lang.text('por_defecto')}})
                            </td>
                            <td>
                                N/A
                            </td>
                            <td>
                                N/A
                            </td>
                            <td>
                                N/A
                            </td>
                            <td>

                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        

    </ng-container>

    <ng-container *ngIf="user.rolId==3">
        <h2 class="mt-3">{{lang.text('acco.alumno')}}</h2>
        <div class="header g-border p-3">
            <div class="row" *ngIf="user.profesorId">

                <div class="col-sm-6">
                    <h6>{{lang.text('profesor')}}: {{user.profesorNombre}}</h6>
                </div>
                <div class="col-sm-6">
                    <h6>{{lang.text('nivel')}}: {{user.nivelNombre}}</h6>
                </div>
            </div>
            <h2 *ngIf="user.profesorId == null">{{lang.text('acco.mensaje_sin_profesor')}}</h2>
        </div>
    </ng-container>
    <div class="botones-opciones mt-3">
        <button class="app-btn" (click)="modificarDatos()">{{lang.text('acco.editar_datos')}}</button>
        <button class="app-btn" (click)="cerrarSesion()">{{lang.text('logout')}}</button>
    </div>
</div>
