
import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { AuthService } from 'src/app/services/auth-service.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { VerPagosComponent } from './pagos/ver-pagos/ver-pagos.component';
import { ProfesorNuevoComponent } from './profesor-nuevo/profesor-nuevo.component';
import { ProfesoresPendientesComponent } from './profesores-pendientes/profesores-pendientes.component';

@Component({
  selector: 'app-profesores',
  templateUrl: './profesores.component.html',
  styleUrls: ['./profesores.component.css']
})
export class ProfesoresComponent implements OnInit {

  loading = true;
  users:User[] = [];

  page = 0;
  lastPage = 0;
  pageSize = 6;

  constructor(private userService:UserService,public auth:AuthService,private modalService:ModalService,public lang:LangService) { }

  async ngOnInit() {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad()
  {
    try {
      const req = await this.userService.getByRol(2);
      this.users = req.data;
      this.resetPaged();
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async nuevo()
  {
    let modalRef = this.modalService.open(ProfesorNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async modificar(user:User){
    
    let modalRef = this.modalService.open(ProfesorNuevoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }


  async verPagos(user:User)
  {
    let modalRef = this.modalService.open(VerPagosComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = user;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }
  }

  async eliminar(user:User)
  {
    let confirmar = await this.modalService.confirm('prof.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.userService.delete(user);
        
        await this.onLoad();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }


  async nuevoPendiente(){
    let modalRef = this.modalService.open(ProfesoresPendientesComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    try {
      const user = await modalRef.result;
      //console.log(user);
      await this.refresh();
      await this.modalService.showError('prof.adv_pago','Aceptar');
      this.verPagos(user);
    } catch (error) {
      
    }
  }

  async refresh()
  {
    this.loading = true;
    await this.onLoad();
    this.loading = false;
  }

  async buscar(event)
  {
    var value= event.srcElement.value;
    if(!value) return;
    
    this.loading = true;
    try {
      const req = await this.userService.buscar(2,value);
      this.users = req.data;
      this.resetPaged();
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    this.loading = false;
  }


  resetPaged(){
    this.page = 0;
    this.lastPage = Math.ceil(this.users.length/this.pageSize);
  }

  prevPage(){
    if(this.page > 0){
      this.page--;
    }
  }

  nextPage(){
    if(this.page < this.lastPage -1){
      this.page++;
    }
  }

  getPaged(page){
    var array = this.users.slice(page*this.pageSize,page*this.pageSize+this.pageSize);
    return array;
  }
}
