<div class="container-fluid main">
    <div class="row">
        <div class="col-sm-2" >
            <app-menu *ngIf="isAuth" [class.show]="showMenu"></app-menu>
        </div>
        <div class="col-sm-10">
            <div class="header-static">
                <div class="buttons-static-left">
                    <a class="app-btn rd primary outline" (click)="showMenu = !showMenu">{{lang.text('menu')}}</a>
                </div>
                <div class="buttons-static " *ngIf="isAuth">
                    <div class="profile-header">
                        <img [src]="userModel.userImagen" width="32" height="32">
                        <a  routerLink="/account">{{userModel.userNombre}} {{userModel.userApellido}} <fa-icon icon="user"></fa-icon></a>
                    </div>
                    
                </div>
            </div>
            
            <div class="router-container" [class.full-login]="!isAuth">
                <router-outlet ></router-outlet>
            </div>
        </div>
    </div>
</div>

<div class="notification-center">
    <div class="cola">
        <div class="notification g-border" *ngFor="let i of notifications;index as idx" (click)="openNoti(i)">
            <fa-icon icon="bell" style="margin-right: 8px;font-size: 30px;"></fa-icon>
            <fa-icon class="closef" icon="times-circle" (click)="remove(idx)"></fa-icon>
            <p class="m-0">{{lang.text(i.text)}}</p>
        </div>
    </div>
</div>

<h4 class="test-warn" *ngIf="!productionApi">
    Debug:<span>{{prodBuild? '❌':'✅'}}</span> Env: {{apiUrl}}
</h4>