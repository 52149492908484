
import { Time } from '@angular/common';
import { Component, ElementRef, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { MapsAPILoader } from '@ng-maps/core';
import { leccion } from 'src/app/models/campus/leccion';
import { quiz } from 'src/app/models/campus/quiz';
import { PopUp } from 'src/app/models/popup';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-nuevo-promocion',
  templateUrl: './nuevo-promocion.component.html',
  styleUrls: ['./nuevo-promocion.component.css']
})
export class NuevoPromocionComponent implements OnInit {

  latitude: number = 39;
  longitude: number = -99;
  zoom: number = 1;
  address: string;
  private geoCoder;
  
  @ViewChild('search')
  public searchElementRef: ElementRef;
    
  hoy: string;
  popup:PopUp[] = [];
  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];
  quiz:quiz;
  direccion:string;
  horario:Date;
  fecha:Date;
  activo:boolean;
  activobd:number
  nomDireccion:string;
  constructor(private modalService:ModalService, private leccionService:LeccionService,public lang:LangService,public activeModal:NgbActiveModal,
    private userService:UserService,private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone) {
     }
    

  @Input() popupparam:PopUp;
  
  
  async ngOnInit(): Promise<void> {
    
     console.log('activo', this.popupparam); 
     this.hoy = new Date().toISOString().substring(0, 10);
     console.log('hoy', this.hoy); 
    if (this.popupparam) {
      this.horario=this.popupparam.horario;
      this.fecha=this.popupparam.fechaFin;
      this.activo=this.popupparam.activo;
      this.direccion=this.popupparam.direccion;
      this.latitude=this.popupparam.latitud; 
      this.longitude=this.popupparam.longitud;
      this.nomDireccion= this.popupparam.nombreDireccion
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocationParam();
        this.geoCoder = new google.maps.Geocoder;
  
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            let placeShort:string = place.address_components[0].long_name + ', ' + place.address_components[1].long_name
            console.log('placeShort', placeShort);
            
            this.direccion = placeShort;
             
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
          });
        });
      });
    }else{
      this.mapsAPILoader.load().then(() => {
        this.setCurrentLocation();
        this.geoCoder = new google.maps.Geocoder;
  
        let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
        
        autocomplete.addListener("place_changed", () => {
          this.ngZone.run(() => {
            //get the place result
            let place: google.maps.places.PlaceResult = autocomplete.getPlace();
            let placeShort:string = place.address_components[0].long_name + ', ' + place.address_components[1].long_name
            console.log('placeShort', placeShort);
            
            this.direccion = placeShort;
             
            //verify result
            if (place.geometry === undefined || place.geometry === null) {
              return;
            }
  
            //set latitude, longitude and zoom
            this.latitude = place.geometry.location.lat();
            this.longitude = place.geometry.location.lng();
            this.zoom = 18;
          });
        });
      });
    }
    console.log('popupparam',this.popupparam);
    
  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = position.coords.latitude;
        this.longitude = position.coords.longitude;
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  private setCurrentLocationParam() {
    navigator.geolocation.getCurrentPosition((position) => {
        this.latitude = this.popupparam.latitud;
        this.longitude = this.popupparam.longitud;
        this.zoom = 18;
        this.getAddress(this.latitude, this.longitude);
      });
  }

  

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      console.log('results',results);
      console.log('status',status);
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 18;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

  async agregar(){
    this.uploading = true;
    if(this.popupparam)
    {
      console.log('activo', this.activo); 
      try {
        if (this.activo === undefined || this.activo === false) {
          this.activobd = 0
        } else {
          this.activobd = 1
        }
        
         const req = await this.userService.putPopUp(this.popupparam.popupId,this.direccion, this.horario, this.fecha, this.latitude, this.longitude, this.activobd, this.nomDireccion); 
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }else{
      try {
        console.log('direccion', this.direccion);
        console.log('horario', this.horario);
        console.log('fecha', this.fecha);
        console.log('latitude', this.latitude);
        console.log('longitud', this.longitude);
        console.log('activo', this.activo);
        if (this.activo === undefined) {
          this.activobd = 0
        } else {
          this.activobd = 1
        }
        /* const req = await this.quizService.put(this.leccion); */
        const req = await this.userService.postPopUp(this.direccion, this.horario, this.fecha, this.latitude, this.longitude, this.activobd, this.nomDireccion)
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }

    this.uploading=false;
  }

  markerDragEnd(event){

  }
}
