<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!popupparam ">{{lang.text('promo.agregar')}}</h4>
        <h4 *ngIf="popupparam ">{{lang.text('promo.editar')}}</h4>

            <div >
                <div class="form-group">
                  <label>Horario</label>
                  <input [(ngModel)]="horario" class="app-input"  type="time" id="horario" name="horario">
                  <label>Fecha finalizacion</label>
                  <input [(ngModel)]="fecha" [value]="fecha" class="app-input" min="{{ hoy }}"  type="date" id="fechafin" name="fechafin">
                  <label>Activo</label>
                  
  
                    <div class="row">
                        <div class="col-sm-6">
                            <label class="container">Activo
                                <input [(ngModel)]="activo" type="checkbox" >
                                <span class="checkmark"></span>
                              </label>
                             
                        </div>
                        
                    </div>
                    <label>Nombre dirección</label>
                    <input [(ngModel)]="nomDireccion" class="app-input"  type="text" id="direccion" name="direccion">
                  <label>Ingresa dirección</label>
                  <input name="mapa" type="text" class="form-control" (keydown.enter)="$event.preventDefault()" placeholder="Buscar ubicación más cercana" autocorrect="off" autocapitalize="off" spellcheck="off" type="text" #search>
                </div>
              
                 <map-view [latitude]="latitude" [longitude]="longitude" [zoom]="zoom">
                  <map-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"
                    (dragEnd)="markerDragEnd($event)" >
                  </map-marker>
                </map-view> 
          
              </div>  

       
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{lang.textEx(error.message)}}
        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar">

    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn red" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn green" [disabled]="uploading" (click)="agregar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check" ></fa-icon></button>
    </div>
</div>
