import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';




@Injectable({
  providedIn: 'root'
})

export class PeriodoService {
  

  constructor(private http:HttpClient) {
  }

  public getAll(){
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}periodo`).toPromise();
  }

  public getFechaTermino(id:number,fecha:string)
  {
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}periodo/fechatermino/${id}/${fecha}`).toPromise();
  }
}
