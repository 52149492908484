import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {


  loading = true;
  registros:{logeFecha:Date,logeType:string,logeText:string}[] =[];
  constructor(private webService:WebService,public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    try {
      const req = await this.webService.getLog();
      this.registros = req.data;
      this.loading = false;
    } catch (error) {
      
    }
  }

}
