<div class="modal-alert">
    <div class="modal-body left">
        <h2>{{lang.text('pagos_de')}} {{userModel.userNombre+' '+userModel.userApellido}}</h2>
        <table class="app-table">
            <thead>
                  <th>
                      {{lang.text('metodo')}}
                  </th>

                  <th>
                      {{lang.text('fecha')}}
                  </th>

                  <th>
                      {{lang.text('vencimiento')}}
                  </th>

                  <th>
                      {{lang.text('vigencia')}}
                  </th>

                  <th>

                  </th>

            </thead>
            <tbody>
                  <tr *ngFor="let i of pagos">
                    <td>
                        {{i.tipaNombre}}
                    </td>
                    <td>
                        {{i.repaFh}}
                    </td>
                    <td>
                        {{i.repaFhExp}}
                    </td>
                    <td>
                        {{i.repaVigente}}
                    </td>
                    <td>
                        <ng-container *ngIf="i.tipaId > 0">
                            <button (click)="editar(i)" class="app-btn small" title="{{lang.text('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                            <button (click)="delete(i)" class="app-btn small" title="{{lang.text('btn.dele')}}"><fa-icon icon="trash"></fa-icon></button>
                        </ng-container>

                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn"  (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn"  (click)="agregar()" title="{{lang.text('btn.add')}}"><fa-icon icon="plus" ></fa-icon></button>
    </div>
</div>


