import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Sesion } from 'src/app/models/sesion';
import { CategoriaService } from 'src/app/services/categoria.service';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-copiar-info',
  templateUrl: './copiar-info.component.html',
  styleUrls: ['./copiar-info.component.css']
})
export class CopiarInfoComponent implements OnInit {

  errores:any[]= [];
  uploading = false;
  loading = true;

  @Input() sesion:Sesion;

  todasSesiones : Sesion[] = [];
  sesiones :Sesion[] = [];
  aCopiar:Sesion[] = [];

  searchStr = '';

  constructor(public activeModal:NgbActiveModal,
    private categoriaService:CategoriaService,
    private modalService:ModalService,
    private grupoService:GrupoService,
    private videoService:VideoService,
    public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    this.loading = true;
    try {
      const req = await this.categoriaService.getAllSesion();
      this.todasSesiones = req.data;
      this.sesiones = req.data;
      await this.filter;
    } catch (error) {
    }
    this.loading = false;
  }

  async filter(){
    const str= this.searchStr.toLowerCase();
    if(this.searchStr!= null && this.searchStr.length > 0){
      this.sesiones = this.todasSesiones.filter(
        i => i.cacuNombre.toLowerCase().includes(str) || i.cacuNombreApp.toLowerCase().includes(str)
      );
    }
    else{
      this.sesiones = this.todasSesiones;
    }
    this.refreshing();
  }

  async refreshing(){
    this.aCopiar = this.todasSesiones.filter(i=>i.selected);
  }


  async guardar(){
    this.uploading=true;
    await this.refreshing();

    try {
      for (const i of this.aCopiar) {
        const sesionModificar:Sesion = new Sesion();
        sesionModificar.cacuId = i.cacuId;

        sesionModificar.cacuObj = this.sesion.cacuObj;
        sesionModificar.cacuComunicacion = this.sesion.cacuComunicacion;
        sesionModificar.cacuImplementos = this.sesion.cacuImplementos;

        sesionModificar.cacuEntradaTiempo = this.sesion.cacuEntradaTiempo;
        sesionModificar.cacuEntradaDesc = this.sesion.cacuEntradaDesc;

        sesionModificar.cacuPrincipalTiempo = this.sesion.cacuPrincipalTiempo;
        sesionModificar.cacuPrincipalDesc = this.sesion.cacuPrincipalDesc;

        sesionModificar.cacuVueltaTiempo = this.sesion.cacuVueltaTiempo;
        sesionModificar.cacuVueltaDesc = this.sesion.cacuVueltaDesc;

        const req = await this.categoriaService.postSesionInfo(sesionModificar);
      }
      await this.activeModal.close('ok');
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }

    this.uploading = false;
  }
}
