import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Microciclo } from 'src/app/models/microciclo';
import { Sesion } from 'src/app/models/sesion';
import { CategoriaService } from 'src/app/services/categoria.service';
import { CopyPasteService } from 'src/app/services/copypaste.service';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { CopiarInfoComponent } from './copiar-info/copiar-info.component';

@Component({
  selector: 'app-sesion-info',
  templateUrl: './sesion-info.component.html',
  styleUrls: ['./sesion-info.component.css']
})
export class SesionInfoComponent implements OnInit {

  errores:any[]= [];
  uploading = false;
  loading = true;

  @Input() sesion:Sesion;

  constructor(public activeModal:NgbActiveModal,
    private categoriaService:CategoriaService,
    private modalService:ModalService,
    private grupoService:GrupoService,
    private videoService:VideoService,
    public lang:LangService) { }

  ngOnInit(): void {
  }


  async guardar(){
    this.uploading = true;
    try {
      const req = await this.categoriaService.postSesionInfo(this.sesion);
      this.activeModal.close('creado');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }


  async copiar(){
    let modalRef = this.modalService.open(CopiarInfoComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop'});
    modalRef.componentInstance.sesion= this.sesion;
    try {
      await modalRef.result;
    } catch (error) {
      
    }
  }

}
