import { Component } from '@angular/core';
import { CampanaService } from 'src/app/services/campana.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { NavService } from 'src/app/services/nav.service';
import { PushStatus, WebSocketService } from 'src/app/services/ws.service';

@Component({
  selector: 'app-admin-campana',
  templateUrl: './admin-campana.component.html',
  styleUrls: ['./admin-campana.component.scss']
})
export class AdminCampanaComponent {

  
  uploading = false;
  nueva:{
    titulo?:string,
    mensaje?:string,
    options?:string,
    lang?:string,
    tipo?:'mail'|'push'
  } = {lang:'en',tipo:'push'};

  langOpts:{code:string,name:string}[]=[
    {code:'en',name:'English'},
    {code:'es',name:'Español'},
  ]

  opSinProfe:boolean = false;
  opConProfe:boolean = false;
  opDaddy:boolean = false;
  opCoach:boolean = false;
  opCampus:boolean = false;

  constructor(public lang:LangService,private wsSrv:WebSocketService,
    private helper:ModalService,
    private campSrv:CampanaService,
    private navSrv:NavService){
    
  }


  async back(){
    this.navSrv.back();
  }

  async enviar(){
    if(this.nueva.mensaje == null || this.nueva.mensaje.length < 1) return;
    if(this.nueva.titulo == null || this.nueva.titulo.length < 1) return;

    this.uploading = true;
    const loader = await this.helper.showLoader();


    try {
      let opts = {
        tipos:[]
      };
  
      if(this.opConProfe){
        opts.tipos.push('alumno_con_coach');
      }
  
      if(this.opSinProfe){
        opts.tipos.push('alumno_sin_coach');
      }

      if(this.opCoach){
        opts.tipos.push('golazo_coach');
      }

      if(this.opDaddy){
        opts.tipos.push('daddy_coach');
      }

      if(this.opCampus){
        opts.tipos.push('campus');
      }
  
      this.nueva.options = JSON.stringify(opts);
      
      const req = await this.campSrv.enviar(this.nueva);

      loader.dismiss();

      const msg = 'Enviada a '+req.data.length+' usuarios';
      this.helper.showError(msg,'Ok');
      this.navSrv.navigate('/adm/campana/historial',true);
    } catch (error) {
      loader.dismiss();
      this.helper.showError(error.message,'Ok');
    }
    this.uploading = false;
  }
}
