
<aside>
  <a href="/inicio">
    <img src="./assets/logo_blanco.png">

  </a>

  <div class="menu">
    <div class="item" routerLink="/inicio" routerLinkActive="active">
      <a>
        {{lang.text('nav.home')}}
      </a>
    </div>

    <div class="item" routerLink="/videos" routerLinkActive="active" *ngIf="userModel.rolId == 1 || userModel.rolId ==2 || userModel.rolId ==6">
      <a>
        {{lang.text('nav.videos')}}
      </a>
    </div>

    <div class="item" routerLink="/grupos" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.grupos')}}
      </a>
    </div>

    <div class="item" routerLink="/sesiones" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.ciclos')}}
      </a>
    </div>

    <div class="item" routerLink="/profesores" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.profesores')}}
      </a>
    </div>


    <div class="item" routerLink="/cursos" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.campus')}}
      </a>
    </div>

   <div class="item" routerLink="/promocion" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.promocion')}}
      </a>
    </div> 

    <div class="item" routerLink="/adm" routerLinkActive="active" *ngIf="(userModel.rolId == 1)">
      <a>
        {{lang.text('nav.admin')}}
      </a>
    </div> 

    <ng-container *ngIf="userModel.rolId == 2 || userModel.rolId == 6">
      <div class="item" *ngIf="userModel.rolId == 2" routerLink="/alumnos" routerLinkActive="active">
        <a>
          {{lang.text('nav.alumnos')}}
        </a>
      </div>
      <div class="item" routerLink="/control-asistencia" routerLinkActive="active">
        <a>
          {{(userModel.rolId == 2) ? ''+lang.text('nav.asistencias'):''+lang.text('nav.alumnos')}}
        </a>
      </div>
      <div *ngIf="userModel.rolId == 2" class="item" routerLink="/solicitudes" routerLinkActive="active">
        <a>
          {{lang.text('nav.solicitudes')}}
        </a>
      </div>
      <div class="item" routerLink="/campus-aprobado" routerLinkActive="active">
        <a>
          {{lang.text('nav.campus')}}
        </a>
      </div>
    </ng-container>






  </div>

</aside>

