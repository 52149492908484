<div class="container view">
    <h5>{{lang.text('solicitudes')}}</h5>
    <div class="solicitud" *ngFor="let i of solicitudes">
      <h6><small>{{lang.text('fecha')}} {{i.soprFh}}</small></h6>
      <h6>{{i.userNombre}} {{i.userApellido}}</h6>
      <h6><strong>{{lang.text('contacto')}}</strong>
      </h6>
      <h6>{{i.userEmail}} -  {{i.userTelefono}}</h6>
      <h6><input type="checkbox" name="contactado" id="contactado" [(ngModel)]="i.contactado"><label for="contactado">{{lang.text('sol.ya_contactado')}}</label></h6>

      <div class="botones-opciones">
          <ng-container *ngIf="!i.contactado">
              <a class="app-btn" href="mailto:{{i.userEmail}}">{{lang.text('enviar_correo')}}</a>
              <a class="app-btn" href="tel:{{i.userTelefono}}">{{lang.text('llamar')}}</a>
          </ng-container>
          <button class="app-btn" (click)="aceptarSolicitud(i)" *ngIf="i.contactado"> {{lang.text('aceptar')}}</button>
          <button class="app-btn" (click)="rechazarSolicitud(i)"> {{lang.text('rechazar')}}</button>
      </div>
    </div>
</div>
