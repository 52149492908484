import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Abono } from 'src/app/models/abono';
import { AbonoInfo } from 'src/app/models/abono.info';
import { CompraService } from 'src/app/services/compra.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-ver-venta',
  templateUrl: './ver-venta.component.html',
  styleUrls: ['./ver-venta.component.css']
})
export class VerVentaComponent implements OnInit {


  ID:number;
  loading = true;
  model:Abono = null;
  abonos: Abono[] = [];
  abono:AbonoInfo|null;
  modalLoading = false;
  constructor(public lang:LangService,private router:Router,private modalService:NgbModal,private activeRoute:ActivatedRoute,private helper:ModalService,private compraSrv:CompraService) { }

  ngOnInit(): void {
    this.ID = this.activeRoute.snapshot.params.id;
    this.load();
  }


  async load(){
    this.loading = true;
    try {
      const req = await this.compraSrv.getMisPagos(this.ID);
      this.model = req.data[0];
      this.abonos = req.data; 
      this.loading = false;
    } catch (error) {
      this.helper.showError(error.msg,'ok');
    }
    
  }


  async open(content,abono:Abono) {
    this.modalLoading = true;
		const modal = await this.modalService.open(content, { windowClass:'app-modal',size:'lg',ariaLabelledBy: 'modal-basic-title' });
    
    try {
      this.abono = await this.compraSrv.getAbono(abono.abonoId);
      
    } catch (error) {
      
    }
    this.modalLoading = false;
	}


  async refund(){
    const confirm = await this.helper.confirm('confirm_refund','si','no');
    if(confirm){
      const loader = await this.helper.showLoader();
      try {
        const refundRequest = await this.compraSrv.refund({compId:this.ID,reason:'request_by_coach'});
        this.router.navigateByUrl('/sells',{replaceUrl:true});
        loader.dismiss();
      } catch (error:any) {
        this.helper.showError(error.message,'Ok');
        loader.dismiss();
      }
    }
  }
  
}
