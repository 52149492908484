import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomSelectOption } from 'src/app/models/customselectoption';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-select-modal-component',
  templateUrl: './select-modal-component.component.html',
  styleUrls: ['./select-modal-component.component.css']
})
export class SelectModalComponentComponent implements OnInit {

  searchStr:string = '';
  @Input() items:CustomSelectOption[];
  @Input() showSearch = true;
  itemsFiltered:CustomSelectOption[];
  constructor(public activeModal: NgbActiveModal,public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }


  load(){
    this.itemsFiltered = this.items;
  }


  async filter(){
    if(this.searchStr.length > 0){
      const str = this.searchStr.toLowerCase();
      this.itemsFiltered = this.items.filter(i => i.name.toLowerCase().includes(str));
    }else{
      this.itemsFiltered = this.items;
    }
  }


  async select(item:CustomSelectOption){
    this.activeModal.close(item);
  }

}
