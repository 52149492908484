import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.css']
})
export class PaginatorComponent implements OnInit {


  @Input() tableRef:any;

  tabla:any;

  constructor(public lang:LangService) { }

  ngOnInit(): void {
    console.log(this.tableRef);
    this.tabla = this.tableRef;
  }

  n(value:string){
    return Number(value);
  }

  excel(){
    var workbook = XLSX.utils.book_new();
    var sheet = XLSX.utils.json_to_sheet(this.tabla.dataSet);
    XLSX.utils.book_append_sheet(workbook,sheet,"Reporte");
    XLSX.writeFile(workbook, "report.xls");
  }

  isDisabled(){
    return Number(this.tabla.getAttribute('currentPage')) >= Number(this.tabla.getAttribute('lastPage'));
  }
}
