import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Modulo, ModuloQuiz } from 'src/app/models/campus/modulo';
import { quiz } from 'src/app/models/campus/quiz';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { QuizService } from 'src/app/services/campus/quiz.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-agregar-quiz',
  templateUrl: './agregar-quiz.component.html',
  styleUrls: ['./agregar-quiz.component.css']
})
export class AgregarQuizComponent implements OnInit {

  @Input() moduParam:Modulo;

  //grupo:Grupo;
  modulos:Modulo[] = [];
  quizSel:quiz[] = [];
  quizes:quiz[]=[];

  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];

/*   filtro:Caracteristica = null; 
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public moduloService:ModuloService,
              private modalService:ModalService,
              public quizService:QuizService,
              public lang:LangService
              ) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
    //console.log(this.quizParam);
  }

  async onLoad()
  {
     try {
      const request = await this.quizService.get();
      this.quizes = request.data;
      const request2 = await this.moduloService.getModuloQuiz(this.moduParam.moduId);
      this.quizSel = request2.data;
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    } 
  }

  async agregar(quiz:quiz)
  {
    let find = this.quizSel.find(el => el.quizId == quiz.quizId);
    if(!find)
    {
      this.quizSel.push(quiz);
    }
  }

/*   async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    
    this.loadingVideos = false;
  } */

  async remover(quiz:quiz)
  {
    let p = this.quizSel.findIndex(el => el.quizId == quiz.quizId);
    this.quizSel.splice(p,1);
  }

  async guardar(){
     this.uploading= true;
     try {
      let v:ModuloQuiz = new ModuloQuiz();
      v.moduId=this.moduParam.moduId;
      v.quizes = [];
      for (const iterator of this.quizSel) {
        v.quizes.push(iterator.quizId);
      }
      console.log(v);
      const req = await this.moduloService.postModuloQuiz(v);
      this.activeModal.close('Actualizado!');
      console.log('axsd',v);
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    } 
    this.uploading = false; 
  }
}
