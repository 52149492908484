export class Microciclo
{
      catrId:number;
      catrNombre:string;
      catrNombreApp:string;

      

      /*  */
      selected:boolean;
      sesiones:any[] = [];
}