import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'id',
    standalone:true
})

export class IdPipe implements PipeTransform {
    transform(value: any, max = 6): string {
        const val = ""+value;
        return val.padStart(max, '0');
    }
}