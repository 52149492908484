<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{lang.text('agregar_suspension_temporal')}}</h4>
        <form>
            <label class="app-label">{{lang.text('razon')}}</label>
            <input type="text" class="app-combo" [(ngModel)]="razon" name="razon" id="razon">

            <label class="app-label">{{lang.text('fecha_inicio')}}</label>
            <input type="date" class="app-combo" [(ngModel)]="fecha1" name="fecha1" id="fecha1">

            <label class="app-label">{{lang.text('fecha_fin')}}</label>
            <input type="date" class="app-combo" [(ngModel)]="fecha2" name="fecha2" id="fecha2">

        </form>


        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
