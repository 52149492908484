import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, LOCALE_ID, NgModule, isDevMode } from '@angular/core';
//import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AppComponent } from './components/app/app.component';
import { AlumnosComponent } from './components/alumnos/alumnos.component';
import { VideosComponent } from './components/videos/videos.component';
import { VideoDetalleComponent } from './components/videos/video-detalle/video-detalle.component';
import { VideoEditarComponent } from './components/videos/video-editar/video-editar.component';
import { VideoNuevoComponent } from './components/videos/video-nuevo/video-nuevo.component';
import { AlumnoNuevoComponent } from './components/alumnos/alumno-nuevo/alumno-nuevo.component';
import { AlumnoDetalleComponent } from './components/alumnos/alumno-detalle/alumno-detalle.component';
import { ProfesoresComponent } from './components/profesores/profesores.component';
import { ProfesorEditarComponent } from './components/profesores/profesor-editar/profesor-editar.component';
import { ProfesorDetalleComponent } from './components/profesores/profesor-detalle/profesor-detalle.component';
import { ProfesorNuevoComponent } from './components/profesores/profesor-nuevo/profesor-nuevo.component';
import { MacrociclosComponent } from './components/macrociclos/macrociclos.component';
import { MesocicloNuevoComponent } from './components/mesociclos/mesociclo-nuevo/mesociclo-nuevo.component';
import { MesocicloDetalleComponent } from './components/mesociclos/mesociclo-detalle/mesociclo-detalle.component';
import { MesocicloEditarComponent } from './components/mesociclos/mesociclo-editar/mesociclo-editar.component';
import { MicrocicloNuevoComponent } from './components/microciclos/microciclo-nuevo/microciclo-nuevo.component';
import { MicrocicloDetalleComponent } from './components/microciclos/microciclo-detalle/microciclo-detalle.component';
import { MicrocicloEditarComponent } from './components/microciclos/microciclo-editar/microciclo-editar.component';
import { SesionNuevoComponent } from './components/sesiones/sesion-nuevo/sesion-nuevo.component';
import { SesionDetalleComponent } from './components/sesiones/sesion-detalle/sesion-detalle.component';
import { SesionEditarComponent } from './components/sesiones/sesion-editar/sesion-editar.component';
import { EvaluacionEditarComponent } from './components/evaluaciones/evaluacion-editar/evaluacion-editar.component';
import { EvaluacionNuevoComponent } from './components/evaluaciones/evaluacion-nuevo/evaluacion-nuevo.component';
import { EvaluacionDetalleComponent } from './components/evaluaciones/evaluacion-detalle/evaluacion-detalle.component';
import { GestionsesionalumnoComponent } from './components/gestionsesionalumno/gestionsesionalumno.component';
import { AlumnoEditarComponent } from './components/alumnos/alumno-editar/alumno-editar.component';
import { AsistenciasComponent } from './components/asistencias/asistencias.component';
import { MacrociclosNuevoComponent } from './components/macrociclos/macrociclos-nuevo/macrociclos-nuevo.component';
import { MacrociclosDetalleComponent } from './components/macrociclos/macrociclos-detalle/macrociclos-detalle.component';
import { MacrociclosEditarComponent } from './components/macrociclos/macrociclos-editar/macrociclos-editar.component';
import { LoginComponent } from './components/login/login.component';
import { InicioComponent } from './components/inicio/inicio.component';
import { MenuComponent } from './components/inicio/menu/menu.component';
import { FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { AlertModalComponentComponent } from './alert-modal-component/alert-modal-component.component';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { SesionesComponent } from './components/sesiones/sesiones.component';
import { GrupoComponent } from './components/grupos/grupo/grupo.component';
import { NuevoGrupoComponent } from './components/grupos/nuevo-grupo/nuevo-grupo.component';
import { AdministrarGrupoComponent } from './components/grupos/administrar-grupo/administrar-grupo.component';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBaby, fas, faSquare } from '@fortawesome/free-solid-svg-icons';
import { VideoPlayerComponent } from './components/video-player/video-player.component';
import { MicrocicloListarComponent } from './components/microciclos/microciclo-listar/microciclo-listar.component';
import { MesociclosComponent } from './components/mesociclos/mesociclos.component';

import { MesogrupoComponent } from './mesogrupos/mesogrupo/mesogrupo.component';
import { MesogrupoEditarComponent } from './mesogrupos/mesogrupo-editar/mesogrupo-editar.component';
import { ControlAsistenciaComponent } from './components/control-asistencia/control-asistencia.component';
import { MarcarAsistenciaComponent } from './components/control-asistencia/marcar-asistencia/marcar-asistencia.component';
import { VideosClaseComponent } from './components/control-asistencia/videos-clase/videos-clase.component';
import { AgregarCaracteristicaComponent } from './components/caracteristica/agregar-caracteristica/agregar-caracteristica.component';
import { CaracteristicaComponent } from './components/caracteristica/caracteristica.component';
import { AlumnoCrearEvaluacionComponent } from './components/alumnos/alumno-crear-evaluacion/alumno-crear-evaluacion.component';
import { AlumnoListarDocumentosComponent } from './components/alumnos/alumno-listar-documentos/alumno-listar-documentos.component';
import { DndDirective,  } from './dnd.directive';
import { AgregarSuspensionClasesComponent } from './components/control-asistencia/agregar-suspension-clases/agregar-suspension-clases.component';
import { SponsorComponent } from './components/sponsor/sponsor.component';
import { AgregarModificarSponsorComponent } from './components/sponsor/agregar-modificar-sponsor/agregar-modificar-sponsor.component';
import { SesionInfoComponent } from './components/sesiones/sesion-info/sesion-info.component';
import { VerInfoComponent } from './components/control-asistencia/ver-info/ver-info.component';
import { CopiarInfoComponent } from './components/sesiones/sesion-info/copiar-info/copiar-info.component';
import { PagoMercadopagoComponent } from './components/pago-mercadopago/pago-mercadopago.component';
import { VerPagosComponent } from './components/profesores/pagos/ver-pagos/ver-pagos.component';
import { AgregarPagoComponent } from './components/profesores/pagos/agregar-pago/agregar-pago.component';
import { LangService } from './services/lang.service';
import { SolicitudesComponent } from './components/solicitudes/solicitudes.component';
import { PreguntasComponent } from './components/preguntas/preguntas.component';
import { LoaderComponent } from './modals/loader/loader.component';
import { UsuarioMaestroComponent } from './components/usuario-maestro/usuario-maestro.component';
import { PreguntasNuevoComponent } from './components/preguntas/preguntas-nuevo/preguntas-nuevo.component';
import { QuizesComponent } from './components/quizes/quizes.component';
import { QuizesNuevoComponent } from './components/quizes/quizes-nuevo/quizes-nuevo.component';
import { QuizesEditarComponent } from './components/quizes/quizes-editar/quizes-editar.component';
import { ResetearPasswordComponent } from './components/resetear-password/resetear-password.component';
import { ModulosComponent } from './components/modulos/modulos.component';
import { CursosComponent } from './components/cursos/cursos.component';
import { LeccionesComponent } from './components/lecciones/lecciones.component';
import { NuevoModuloComponent } from './components/modulos/nuevo-modulo/nuevo-modulo.component';
import { EditarModuloComponent } from './components/modulos/editar-modulo/editar-modulo.component';
import { NuevoCursoComponent } from './components/cursos/nuevo-curso/nuevo-curso.component';
import { EditarCursoComponent } from './components/cursos/editar-curso/editar-curso.component';
import { NuevoLeccionComponent } from './components/lecciones/nuevo-leccion/nuevo-leccion.component';
import { EditarLeccionComponent } from './components/lecciones/editar-leccion/editar-leccion.component';
import { EditarDocumentoLeccionComponent } from './components/lecciones/editar-documento-leccion/editar-documento-leccion.component';
import { AgregarQuizComponent } from './components/modulos/agregar-quiz/agregar-quiz.component';
import { ProfesoresPendientesComponent } from './components/profesores/profesores-pendientes/profesores-pendientes.component';
import { SelectModalComponentComponent } from './modals/select-modal-component/select-modal-component.component';
import { SearchSelectComponent } from './search-select/search-select.component';
import { MiCuentaComponent } from './components/mi-cuenta/mi-cuenta.component'; 
import { ListarDocumentosCursoComponent } from './components/cursos/listar-documentos-curso/listar-documentos-curso.component';
import { ActualizarDatosComponent } from './components/mi-cuenta/actualizar-datos/actualizar-datos.component';
import { ActualizarLugarClasesComponent } from './components/mi-cuenta/actualizar-lugar-clases/actualizar-lugar-clases.component';
import { SubirExcelComponent } from './components/alumnos/subir-excel/subir-excel.component';
import { CampusAprobadoComponent } from './components/campus-aprobado/campus-aprobado.component';
import { PromocionComponent } from './components/promocion/promocion.component';
import { NuevoPromocionComponent } from './components/promocion/nuevo-promocion/nuevo-promocion.component';

import { PdfMakeWrapper } from 'pdfmake-wrapper'
import pdfFonts from "../assets/custom-fonts"
//import { AgmCoreModule } from '@agm/core';
import { PreciosComponent } from './components/mi-cuenta/precios/precios.component';
import { VentasComponent } from './components/mi-cuenta/ventas/ventas.component';
import { AgendaComponent } from './components/mi-cuenta/agenda/agenda.component';
import { AgregarAgendaComponent } from './components/mi-cuenta/agenda/agregar-agenda/agregar-agenda.component';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { VerVentaComponent } from './components/mi-cuenta/ventas/ver-venta/ver-venta.component';
import { KtableDirective } from './directives/ktable.directive';
import { PaginatorComponent } from './components/app/paginator/paginator.component';

import { ReadOnlyTableComponent } from './read-only-table/read-only-table.component';
import { ReLoginComponent } from './components/re-login/re-login.component';
import { NgMapsCoreModule } from '@ng-maps/core';
import { GOOGLE_MAPS_API_CONFIG, NgMapsGoogleModule } from '@ng-maps/google';
import { NgMapsPlacesModule } from '@ng-maps/places';
import { AdmBtnsComponent } from './components/adminpanel/adm-btns/adm-btns.component';
import { AdminHomeComponent } from './components/adminpanel/admin-home/admin-home.component';
import { LogPagosComponent } from './components/adminpanel/log-pagos/log-pagos.component';
import { LogVentasComponent } from './components/adminpanel/log-ventas/log-ventas.component';
import { LogComponent } from './components/adminpanel/log.component';
import { AdmPaquetesComponent } from './components/adminpanel/adm-paquetes/adm-paquetes.component';
import { LogusuariosComponent } from './components/adminpanel/logusuarios/logusuarios.component';
import {CookieService} from 'ngx-cookie-service';
import { AdminCampanaComponent } from './components/adminpanel/admin-campana/admin-campana.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InputComunaComponent } from './components/input-comuna/input-comuna.component';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { IdPipe } from './_pipes/idpipe';
import { PayoutsComponent } from './components/mi-cuenta/payouts/payouts.component';
import { OptionsAlertComponent } from './components/options-alert/options-alert.component';
import { HSPipe } from './_pipes/hs.pipe';
import { LogsCampanasComponent } from './components/adminpanel/admin-campana/logs-campanas/logs-campanas.component';
import { ChartComponent } from './chart-component/chart-component.component';
import { UsubMailistingComponent } from './components/mi-cuenta/usub-mailisting/usub-mailisting.component';

registerLocaleData(es);

PdfMakeWrapper.setFonts(pdfFonts, {


  HelloWinter:{
    normal : 'Hello Winter.ttf',
    bold: 'Hello Winter.ttf',
    italics: 'Hello Winter.ttf',
    bolditalics: 'Hello Winter.ttf',
  }
});

PdfMakeWrapper.useFont('HelloWinter')




@NgModule({
  declarations: [
    AppComponent,
    AlumnosComponent,
    VideosComponent,
    VideoDetalleComponent,
    VideoEditarComponent,
    VideoNuevoComponent,
    AlumnoNuevoComponent,
    AlumnoDetalleComponent,
    ProfesoresComponent,
    ProfesorEditarComponent,
    ProfesorDetalleComponent,
    ProfesorNuevoComponent,
    MacrociclosComponent,
    MesocicloNuevoComponent,
    MesocicloDetalleComponent,
    MesocicloEditarComponent,
    MicrocicloNuevoComponent,
    MicrocicloDetalleComponent,
    MicrocicloEditarComponent,
    SesionNuevoComponent,
    SesionDetalleComponent,
    SesionEditarComponent,
    EvaluacionEditarComponent,
    EvaluacionNuevoComponent,
    EvaluacionDetalleComponent,
    GestionsesionalumnoComponent,
    AlumnoEditarComponent,
    AsistenciasComponent,
    MacrociclosNuevoComponent,
    MacrociclosDetalleComponent,
    MacrociclosEditarComponent,
    LoginComponent,
    InicioComponent,
    MenuComponent,
    AlertModalComponentComponent,
    SesionesComponent,
    GrupoComponent,
    NuevoGrupoComponent,
    AdministrarGrupoComponent,
    VideoPlayerComponent,
    MicrocicloListarComponent,
    MesociclosComponent,
    MesogrupoComponent,
    MesogrupoEditarComponent,
    ControlAsistenciaComponent,
    MarcarAsistenciaComponent,
    VideosClaseComponent,
    CaracteristicaComponent,
    AgregarCaracteristicaComponent,
    AlumnoCrearEvaluacionComponent,
    AlumnoListarDocumentosComponent,
    DndDirective,
    AgregarSuspensionClasesComponent,
    SponsorComponent,
    AgregarModificarSponsorComponent,
    SesionInfoComponent,
    VerInfoComponent,
    CopiarInfoComponent,

    PagoMercadopagoComponent,
    VerPagosComponent,
    AgregarPagoComponent,
    SolicitudesComponent,
    PreguntasComponent,
    LoaderComponent,
    UsuarioMaestroComponent,
    PreguntasNuevoComponent,
    QuizesComponent,
    QuizesNuevoComponent,
    QuizesEditarComponent,
    ResetearPasswordComponent,
    ModulosComponent,
    CursosComponent,
    LeccionesComponent,
    NuevoModuloComponent,
    EditarModuloComponent,
    NuevoCursoComponent,
    EditarCursoComponent,
    NuevoLeccionComponent,
    EditarLeccionComponent,
    EditarDocumentoLeccionComponent,
    AgregarQuizComponent,
    ProfesoresPendientesComponent,
    SelectModalComponentComponent,
    SearchSelectComponent,
    MiCuentaComponent,
    ActualizarDatosComponent,
    ListarDocumentosCursoComponent,
    ActualizarLugarClasesComponent,
    SubirExcelComponent,
    CampusAprobadoComponent,
    PromocionComponent,
    NuevoPromocionComponent,
    PreciosComponent,
    VentasComponent,
    AgendaComponent,
    AgregarAgendaComponent,
    VerVentaComponent,
    KtableDirective,
    PaginatorComponent,
    LogComponent,
    LogVentasComponent,
    AdminHomeComponent,
    LogPagosComponent,
    AdmBtnsComponent,
    ReadOnlyTableComponent,
    ReLoginComponent,
    AdmPaquetesComponent,
    LogusuariosComponent,
    AdminCampanaComponent,
    InputComunaComponent,
    PayoutsComponent,
    OptionsAlertComponent,
    LogsCampanasComponent,
    ChartComponent,
    UsubMailistingComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FontAwesomeModule,
    NgMapsCoreModule,
    NgMapsPlacesModule,
    NgMapsGoogleModule,
    IdPipe,
    HSPipe,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    NzSelectModule,
    BrowserAnimationsModule,
    NzIconModule

  ],
  providers: [
    CookieService,
    {provide: LOCALE_ID, useValue: navigator.language.startsWith('es') ? navigator.language : 'en-US' },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: NZ_I18N, useValue: en_US },
    LangService,{
      provide:APP_INITIALIZER,
      useFactory:(ls:LangService) => () => {return ls.loadLang()},
      deps:[LangService],
      multi:true
    },
    {
      provide: GOOGLE_MAPS_API_CONFIG,
      useValue: {
        apiKey: 'AIzaSyDIvlaoDMfvZTo_q1Kb2C3_szK59jdoBNQ',
        libraries:['places']
      }
    }
  ],
  bootstrap: [AppComponent]
})





export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIconPacks(fas);
  }
}
