<div class="container view">
      <div class="row">
          <div class="col-sm-3">
              <img src="./assets/img/icon_categories.png" alt="">
          </div>
          <div class="col-sm-9">
              <h2>{{lang.text('inasistencias_avisadas')}}</h2>

              <div class="botones-opciones mb-3">
                  <button class="app-btn" (click)="refresh(1)">{{lang.text('hoy')}}</button>
                  <button class="app-btn" (click)="refresh(0)">{{lang.text('todas')}}</button>
              </div>
              <div *ngIf="!this.loading">
                  <table class="table table-dark app-table">
                        <thead>
                              <th>
                                    #
                              </th>
                              <th>
                                {{lang.text('clase')}}
                              </th>

                              <th>
                                {{lang.text('alumno')}}
                              </th>
                              <th>
                                {{lang.text('rut')}}
                              </th>
                              <th>
                                {{lang.text('semestre')}}
                              </th>
                              <th>{{lang.text('mesociclo')}}</th>
                              <th>{{lang.text('microciclo')}}</th>
                              <th>
                                {{lang.text('sesion')}}
                              </th>
                                    <th>
                                      {{lang.text('activacion')}}
                                    </th>

                              <th>

                              </th>
                        </thead>
                        <tbody>
                              <tr *ngFor="let i of lista">
                                    <td>
                                          {{i.asistenciaId}}
                                    </td>
                                    <td>
                                          {{i.fecha}}
                                    </td>

                                    <td>
                                          {{i.alumnoNombre}}
                                    </td>
                                    <td>
                                          {{i.alumnoRut}}
                                    </td>
                                    <td>
                                          {{i.caunNombre}}
                                    </td>
                                    <td>
                                          {{i.cadoNombre}}
                                    </td>
                                    <td>
                                          {{i.catrNombre}}
                                    </td>
                                    <td>
                                          {{i.cacuNombre}}
                                    </td>
                                    <td>
                                          <fa-icon *ngIf="i.claseActiva == 1" icon="check"></fa-icon>
                                          <fa-icon *ngIf="i.claseActiva == 0" icon="times"></fa-icon>
                                    </td>
                                    <td>
                                          <input [disabled]="i.claseActiva == 1" type="checkbox" name="ch" [(ngModel)]="i.selected">
                                    </td>
                              </tr>
                        </tbody>
                  </table>

              </div>

              <div *ngIf="this.loading" class="loader">

              </div>
              <div *ngIf="this.lista.length > 0 && !this.loading">
                  <button class="app-btn" (click)="habilitarTodas()" title="{{lang.t('btn.habilitar_seleccionados')}}">{{lang.text('habilitar_seleccionados')}}</button>
              </div>
              <h6 *ngIf="this.lista.length == 0 && !this.loading" >{{lang.text('nada_por_aqui')}}</h6>

          </div>
      </div>
  </div>
