<div class="container view">
      <div class="botones-opciones ciclos full ">
          <button class="app-btn" [routerLink]="['/macrociclos']" >{{lang.t('macro.macrociclos')}}</button>
          <button class="app-btn" [routerLink]="['/mesociclos']">{{lang.t('mesociclos')}}</button>
          <button class="app-btn active" [routerLink]="['/microciclos']">{{lang.t('microciclos')}}</button>
          <button class="app-btn" [routerLink]="['/sesiones']">{{lang.t('sesiones')}}</button>
      </div>
      <div class="ciclos-border">
            <div class="botones-opciones mb-3">
                  <button class="app-btn" (click)="agregarNuevo()" title="{{lang.text('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
                  <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>
              </div>
              <div *ngIf="!this.loading" class="table-container">
                  <table class="app-table">
                        <thead>
                              <th>
                                    #
                              </th>
                              <th>
                                    {{lang.t('nombre')}}
                              </th>
                              <th>
                                    {{lang.t('nombre_visible')}}
                              </th>
                              <th>

                              </th>
                              <th>

                              </th>
                        </thead>
                        <tbody>
                              <tr *ngFor="let iter of this.categorias">
                                    <td>{{iter.catrId}}</td>
                                    <td>
                                          {{iter.catrNombre}}

                                    </td>
                                    <td>
                                          {{iter.catrNombreApp}}
                                    </td>
                                    <td>
                                          <span class="badge badge-pill badge-info" *ngIf="iter.catrGratis">{{lang.t('gratis')}}</span>
                                    </td>
                                    <td>
                                          <button class="app-btn small" (click)="modificar(iter)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit" ></fa-icon></button>
                                          <button class="app-btn small" *ngIf="!iter.catrGratis" (click)="cambiarTipo(iter,true)" title="{{lang.t('btn.cambiar')}}"><fa-icon icon="times"></fa-icon><fa-icon icon="dollar-sign"></fa-icon></button>
                                          <button class="app-btn small" *ngIf="iter.catrGratis" (click)="cambiarTipo(iter,false)"><fa-icon icon="check"></fa-icon><fa-icon icon="dollar-sign"></fa-icon></button>
                                          <button class="app-btn small red" (click)="eliminar(iter)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt" ></fa-icon></button>
                                    </td>
                              </tr>
                        </tbody>
                  </table>

              </div>
              <div *ngIf="this.loading" class="loader">

            </div>
            <h6 *ngIf="this.categorias.length == 0 && !this.loading">Nada por aqui...</h6>
      </div>



  </div>
