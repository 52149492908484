import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Grupo } from 'src/app/models/grupo';
import { Sesion } from 'src/app/models/sesion';
import { Video } from 'src/app/models/video';
import { CategoriaService } from 'src/app/services/categoria.service';
import { CopyPasteService } from 'src/app/services/copypaste.service';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';
import { environment } from 'src/environments/environment';
import { OptionsAlertComponent } from '../../options-alert/options-alert.component';
import { CustomSelectOption } from 'src/app/models/customselectoption';

@Component({
  selector: 'app-sesion-nuevo',
  templateUrl: './sesion-nuevo.component.html',
  styleUrls: ['./sesion-nuevo.component.css']
})
export class SesionNuevoComponent implements OnInit {

  @Input() sesionParam:Sesion;
  @Input() duplicate:boolean = false;


  previewUrl:string = `${environment.urlAPI}user/imagen/`;

  cacuNombre:string='';
  cacuNombreApp:string = '';
  errores:any[]= [];
  uploading = false;
  loading = true;
  grupos:Grupo[] = [];
  videosSel:Video[] = [];
  videosDisponibles:Video[] = [];
  dragClass = false;

  coloresPartes = {
    pa_entrada:'#ffffff',
    pa_principal:'#ff0000',
    pa_calma:'#00ff00'
  }

  constructor(public activeModal:NgbActiveModal,
              private categoriaService:CategoriaService,
              private modalService:ModalService,
              private grupoService:GrupoService,
              private videoService:VideoService,
              public clipService:CopyPasteService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }



  async mark(video:Video){
    const loader = await this.modalService.showLoader();
    try {
      const req = await this.categoriaService.getPartesSesion();
      const items:CustomSelectOption[] = req.data.map(i => {return {name:i.paseId,value:i.paseId}});
      const result = await this.modalService.showOptionsAlert(items);
      loader.dismiss();

      let mark = false

      

      for (const i of this.videosSel) {
        if(i == video){
          mark = true;
        }
        if(mark){
          i.paseId = result.value;
        }
      }


    } catch (error) {
      loader.dismiss();
    }
    
  }

  async startSerie(video:Video){
    video.esSerie = !video.esSerie;
  }

  async onLoad(){
    try {
      const req = await this.grupoService.getAll();
      this.grupos = req.data;

      if(this.sesionParam)
      {
        const req2 = await this.videoService.getBySesion(this.sesionParam.cacuId);
        this.videosSel = req2.data;
        this.cacuNombre = this.sesionParam.cacuNombre;
        this.cacuNombreApp = this.sesionParam.cacuNombreApp
        if(this.duplicate)
        {
          this.cacuNombre = 'Copia de '+this.sesionParam.cacuNombre;
          this.sesionParam = null;
        }
      }

    } catch (error) {
      this.modalService.showError('Ocurrio un error al cargar los videos de la sesión: '+error.message,'Aceptar');
    }
  }

  async flipContador(video:Video)
  {
    video.mostrarContador = !video.mostrarContador;
  }

  async agregar(video:Video)
  {
    //clonamos el objeto
    let str = JSON.stringify(video);
    let v:Video = JSON.parse(str);
    this.videosSel.push(v);
  }

  async remove(v:number)
  {
    this.videosSel.splice(v,1);
  }

  async abrir(grupo:Grupo){
    try {
      const videos = await this.videoService.getByGrupo(grupo.grupoId);
      this.videosDisponibles = videos.data;
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    }
  }

  async dragStart(event){
    console.log(event);
  }

  async guardar()
  {
    this.uploading= true;

    for (const i of this.videosSel) {
      if(!i.paseId){
        this.modalService.showError('error_videos_sin_parte','ok');
        this.uploading = false;
        return;
      }
    }

    try {
      let sesion = new Sesion();
      if(this.sesionParam){sesion.cacuId = this.sesionParam.cacuId;}//modificar    
      sesion.cacuNombre = this.cacuNombre;
      sesion.cacuNombreApp = this.cacuNombreApp;

      for (const iterator of this.videosSel) {
        sesion.videos.push(iterator.videoId);
        if(iterator.mostrarContador)
        {
          sesion.conteos.push(1);
        }
        else{
          sesion.conteos.push(0);
        }

        if(iterator.esSerie)
        {
          sesion.esSerie.push(1);
        }
        else{
          sesion.esSerie.push(0);
        }


        sesion.partes.push(iterator.paseId);
      }

      const req = await this.categoriaService.postSesion(sesion);
      this.activeModal.close('creado');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading = false;
  }

  async drop(event,index)
  {

  }

  async dropEnd(event,index:number)
  {
    this.dragClass = false;
    //obtener el indice del mas cercano que arrastro?
    const ele = document.elementFromPoint(event.x,event.y);
    const p = ele.closest('div.video-item');
    const movido:number = Number(p.getAttribute('model'));
    console.log(movido);
    console.log(index);

    var tempArray: Video[] = [];
    for (let i = 0; i < this.videosSel.length; i++) {
      const element = this.videosSel[i];
      if(i == movido){
        tempArray.push(this.videosSel[index]);
      }
      if(i != index){
        tempArray.push(element);
      }
    }

    this.videosSel = tempArray;
  }

  async dragOver(event)
  {
    event.preventDefault();
  }


  async dragStep(event){
    
    for (const i of this.videosSel) {
      i.hovered = false;
    }
    //console.log(event);
    const ele = document.elementFromPoint(event.x,event.y);
    const p = ele.closest('div.video-item');
    const hover:number = Number(p.getAttribute('model'));
    this.videosSel[hover].hovered = true;
     
  }


  async copyMultiple(){

    var tempArray:Video[] = [];
    for (const i of this.videosSel) {
      if(i.selected)
      {
        var newV:Video = JSON.parse(JSON.stringify(i));
        newV.selected = false;
        tempArray.push(newV);
      }
      
    }
    this.clipService.copy(tempArray);
    //tempArray.forEach(i => this.videosSel.push(i));
  }

  async removeMultiple(){
    var tempArray:Video[] = [];
    for (const i of this.videosSel) {
      if(!i.selected)
      {
        tempArray.push(i);
      }
    }
    this.videosSel = tempArray;
  }

  async selectAll(){
    for (const i of this.videosSel) {
      i.selected = true;
    }
  }
  
  async deselectAll(){
    for (const i of this.videosSel) {
      i.selected = false;
    }
  }




  async paste(){
    if(!this.clipService.isEmpty){
      const casting:Video[] = this.clipService.clip.content;
      for (const i of casting) {
        this.videosSel.push(i);
      }
      this.clipService.clear();
    }
  }
}
