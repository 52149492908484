import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';
import { UsuarioCrearMasivo } from 'src/app/models/crearmasivo';
import { AsistenciaService } from 'src/app/services/Asistencias.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import * as XLSX from 'xlsx';

@Component({
  selector: 'app-subir-excel',
  templateUrl: './subir-excel.component.html',
  styleUrls: ['./subir-excel.component.css']
})
export class SubirExcelComponent implements OnInit {

  errores:[] = [];
  fileName= '';
  loading = false;
  uploading = false;
  editing = false;
  users:UsuarioCrearMasivo[] = [];

  correoDup:string = null;

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
    private asistenciaService:AsistenciaService, private evaluacionService:EvaluacionService,public lang:LangService) { }

  ngOnInit(): void {
  }


  async guardar(){
    if(this.users.length > 0){
      this.uploading = true;
      try {
        const req = await this.userService.crearUsuarioMasivo(this.users);
        this.activeModal.close('ok');
      } catch (error) {
        this.editing = true;
        if(error.data.length > 0){
          this.correoDup = error.data[0].usuarioDuplicado;
          this.scrollToError();
        }

        this.modalService.showError(this.lang.textEx(error.message),'Ok');
      }
      this.uploading = false;
    }
  }

  async scrollToError(){
    var el = document.getElementById('row_'+this.correoDup);
    var y = el.offsetTop;
    var div = document.querySelector('.modal-body');
    div.scrollTo({top:y,left:0,behavior:'smooth'});
  }

  async fileChange(event){
    try{
      const target: DataTransfer = <DataTransfer>(event.target);
      if (target.files.length !== 1) {
        throw new Error('multiples_files');
      }
      this.fileName = target.files[0].name;
      const reader: FileReader = new FileReader();
      reader.readAsBinaryString(target.files[0]);
      reader.onload = (e: any) => {
        const binarystr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
  
        const data = XLSX.utils.sheet_to_json(ws);
        for (const i of data) {
          this.users.push(i as UsuarioCrearMasivo);
        }
        console.log(data); //
      };
    }
    catch(err){
      this.modalService.showError(err.message,'ok');
    }
    
  }

  remove(i:UsuarioCrearMasivo){
    this.users.splice(this.users.indexOf(i),1);
  }

  plantilla(){
    window.open('/assets/plantilla_excel.xlsx');
  }
}
