import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { leccion } from 'src/app/models/campus/leccion';
import { Modulo, ModuloLeccion } from 'src/app/models/campus/modulo';
import { Pregunta } from 'src/app/models/campus/Pregunta';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { PreguntaService } from 'src/app/services/campus/pregunta.service';
import { QuizService } from 'src/app/services/campus/quiz.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-editar-modulo',
  templateUrl: './editar-modulo.component.html',
  styleUrls: ['./editar-modulo.component.css']
})
export class EditarModuloComponent implements OnInit {

  @Input() moduParam:Modulo;

  //grupo:Grupo;
  modulos:Modulo[] = [];
  leccionSel:leccion[] = [];
  lecciones:leccion[]=[];

  loading:boolean = true;
  uploading:boolean = false;
  errores:any[] = [];

/*   filtro:Caracteristica = null; 
   caracteristicas:Caracteristica[] = [];
 */
  loadingVideos:boolean = false;


  constructor(public activeModal:NgbActiveModal,
              public moduloService:ModuloService,
              public leccionService:LeccionService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
    //console.log(this.quizParam);
  }

  async onLoad()
  {
     try {

      //const req = await this.videoService.ge
      const request = await this.leccionService.get();
      this.lecciones = request.data;
      console.table(this.lecciones);
      const request2 = await this.moduloService.getModuloLeccion(this.moduParam.moduId);
      this.leccionSel = request2.data;
      //console.table(this.leccionSel);
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.activeModal.dismiss();
    } 
  }

  async agregar(leccion:leccion)
  {
    let find = this.leccionSel.find(el => el.leccId == leccion.leccId);
    if(!find)
    {
      this.leccionSel.push(leccion);
    }
  }

/*   async filtrar()
  {
    this.videos = [];
    this.loadingVideos= true;
    const c = this.filtro;
    try {
      if(c != null){
        const request = await this.videoService.getByCaracteristica(c.caracteristicaId);
        this.videos = request.data;
      }
      else
      {
        const request = await this.videoService.getAll();
        this.videos = request.data;
      }
      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
    
    this.loadingVideos = false;
  } */

  async remover(leccion:leccion)
  {
    let p = this.leccionSel.findIndex(el => el.leccId == leccion.leccId);
    this.leccionSel.splice(p,1);
  }

  async guardar(){
     this.uploading= true;
     try {
      let v:ModuloLeccion = new ModuloLeccion();
      v.moduId=this.moduParam.moduId;
      v.lecciones = [];
      for (const iterator of this.leccionSel) {
        v.lecciones.push(iterator.leccId);
      }
      console.log(v);
      const req = await this.moduloService.postModuloLeccion(v);
      this.activeModal.close('Actualizado!');
    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
    } 
    this.uploading = false; 
  }
}



