import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';

@Injectable({providedIn: 'root'})
export class CampanaService {
    constructor(private httpClient: HttpClient) { }
    
    public enviar(opts:any){
        return this.httpClient.post<ApiResponse<any>>(`${environment.urlAPI}campana`,opts).toPromise();

    }
}