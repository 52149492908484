import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RegistroPago } from 'src/app/models/pagos';
import { User } from 'src/app/models/User';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';
import { AgregarPagoComponent } from '../agregar-pago/agregar-pago.component';

@Component({
  selector: 'app-ver-pagos',
  templateUrl: './ver-pagos.component.html',
  styleUrls: ['./ver-pagos.component.css']
})
export class VerPagosComponent implements OnInit {

  @Input() userModel:User;

  pagos:RegistroPago[] = [];
  constructor(public activeModal:NgbActiveModal,private userService:UserService,private modalService:ModalService,public lang:LangService) { }

  ngOnInit(): void {
  
    this.load();
  }


  async load(){
    try {
      const req = await this.userService.getPagosByUseR(this.userModel.id);
      this.pagos = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
  }

  async agregar(){
    let modalRef = this.modalService.open(AgregarPagoComponent,{windowClass:'app-modal top-modal',size:'md',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = this.userModel;
    try {
      await modalRef.result;
      await this.load();
    } catch (error) {
      
    }
  }


  async editar(pago:RegistroPago){
    let modalRef = this.modalService.open(AgregarPagoComponent,{windowClass:'app-modal top-modal',size:'md',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.userModel = this.userModel;
    modalRef.componentInstance.model = pago;
    try {
      await modalRef.result;
      await this.load();
    } catch (error) {
      console.log(error);
    }
  }

  async delete(pago:RegistroPago){
    const confirm = await this.modalService.confirm('¿Seguro que desea eliminar el pago?','Si','No');
    if(confirm){
      try {
        const req = await this.userService.deletePago(pago.repaId);
        this.load();
      } catch (error) {
        this.modalService.showError(error.message,'Ok');
      }
    }
  }
}
