import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Comuna } from 'src/app/models/comuna';
import { LugarClases, LugarClaseViewModel } from 'src/app/models/lugarclases';
import { AuthService } from 'src/app/services/auth-service.service';
import { CategoriaService } from 'src/app/services/categoria.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';
import { Utils } from 'src/app/helpers/Utils';
import { User } from 'src/app/models/User';

@Component({
  selector: 'app-actualizar-lugar-clases',
  templateUrl: './actualizar-lugar-clases.component.html',
  styleUrls: ['./actualizar-lugar-clases.component.css']
})
export class ActualizarLugarClasesComponent implements OnInit {


  user:User[] = []

  loading = true;
  uploading = false;
  errores:[] = [];
  comunas:Comuna[] = [];
  allRegions:Comuna[] = [];
  regiones:Comuna[] = [];
  all:Comuna[]=[];
  paises:Comuna[] = [];
  entrenamientos:LugarClases[] = [];

  lunes:boolean
  martes:boolean
  miercoles:boolean
  jueves:boolean
  viernes:boolean
  sabado:boolean
  domingo:boolean

  comunaNombre;

  @Input() viewModel:LugarClaseViewModel = {
    luclDesc: '', luclPrecioRef: 0, comunaId: 1,
    luclDia: '',
    luclHora: undefined,
    luclEntre: 0,
    luclPrecioRefMen: 0,
    luclDia2: undefined,
    dias: []
  };

  constructor(public activeModal:NgbActiveModal,private userService:UserService,private authService:AuthService,private router:Router, public lang:LangService,private modalService:ModalService,private categoriaService:CategoriaService,
    private periodoService:PeriodoService) { }

    add(){
      console.log('xd',this.viewModel.luclHora);
      
      let row = document.createElement('input');  
        row.className = 'row';
        row.innerHTML = `
        <br>
        <input type="time">`;
        document.querySelector('.showInputField').appendChild(row);
    }

  ngOnInit(): void {
  console.log('vm',this.viewModel);
  if (this.viewModel) {
    if (this.viewModel.dias.toString().includes('1')) {  
      this.lunes = true
    }

    if (this.viewModel.dias.toString().includes('2')) {  
      this.martes = true
    }

    if (this.viewModel.dias.toString().includes('3')) {  
      this.miercoles = true
    }

    if (this.viewModel.dias.toString().includes('4')) {  
      this.jueves = true
    }
 
    if (this.viewModel.dias.toString().includes('5')) {  
      this.viernes = true
    }

    if (this.viewModel.dias.toString().includes('6')) {  
      this.sabado = true
    }

    if (this.viewModel.dias.toString().includes('7')) {  
      this.domingo = true
    }
  }
    this.load();
  }
  setupDireccion(list:Comuna[]){
    this.all = list;
    for (const i of Utils.groupBy(list,x => x.paisId)) {
      this.paises.push(i[0]);
    }

    for (const i of Utils.groupBy(list,x => x.regionId)) {
      this.allRegions.push(i[0]);
    }

    this.comunas = this.all;
  }
  

  async load(){
    this.loading = true;
    try {
      const userReq = await this.userService.me();
      const user = userReq.data[0];
      this.comunaNombre = user['comunaNombre'];
      //const req = await this.userService.getComunas();

      const req2 = await this.userService.getTipoEntrenamiento();
      this.entrenamientos = req2.data

      
      

      //this.setupDireccion(req.data);

      
    } catch (error) {
      this.modalService.showError(error.message,'ok');
      this.activeModal.dismiss();
    }
    this.loading = false;
  }
  actualizarRegiones(event){
    this.regiones = this.allRegions.filter(x => x.paisId == event.target.value);
  }

  actualizarComunas(event){
    this.comunas = this.all.filter(x => x.regionId == event.target.value);
  }

  async save(){
    this.uploading= true;
    try {


      if (this.viewModel.luclDesc == '') {
        this.modalService.showError('valida.desc','ok');
        return;
      }

      if (this.viewModel.luclEntre == 0 || this.viewModel.luclEntre == null) {
        this.modalService.showError('valida.entre','ok');
        return;
      }

      if (this.viewModel.luclDesc == '') {
        this.modalService.showError('valida.dias','ok');
        return;
      }


      if (this.lunes == undefined && this.martes == undefined && this.miercoles == undefined && this.jueves == undefined && this.viernes == undefined && this.sabado == undefined && this.domingo == undefined) {
        this.modalService.showError('valida.dias','ok');
        return;
      }

      if (this.viewModel.luclHora == undefined) {
        this.modalService.showError('valida.hora','ok');
        return;
      }

      const req1 = await this.userService.me();

      

      

      if (this.lunes == true ) {
        this.viewModel.luclDia = '1,'
      }
      
      if (this.martes == true ) {
        this.viewModel.luclDia += ' 2,'
      }
      if (this.miercoles == true ) {
        this.viewModel.luclDia += ' 3,'
      }
      if (this.jueves == true ) {
        this.viewModel.luclDia += ' 4,'
      }
      if (this.viernes == true ) {
        this.viewModel.luclDia += ' 5,'
      }
      if (this.sabado == true ) {
        this.viewModel.luclDia += ' 6,'
      }
      if (this.domingo == true ) {
        this.viewModel.luclDia += ' 7,'
      }

      


      if (this.lunes == true ) {
        this.viewModel.luclDia2 = 'Lunes'
      }
      
      if (this.martes == true ) {
        this.viewModel.luclDia2 += ' Martes'
      }
      if (this.miercoles == true ) {
        this.viewModel.luclDia2 += ' Miercoles'
      }
      if (this.jueves == true ) {
        this.viewModel.luclDia2 += ' Jueves'
      }
      if (this.viernes == true ) {
        this.viewModel.luclDia2 += ' Viernes'
      }
      if (this.sabado == true ) {
        this.viewModel.luclDia2 += ' Sabado'
      }
      if (this.domingo == true ) {
        this.viewModel.luclDia2 += ' Domingo'
      }
      
      
      const req = await this.userService.agregarLugarClase(this.viewModel);
      this.activeModal.close();
    } catch (error) {
      console.log('entre', this.viewModel.luclEntre);
      console.log('id', this.viewModel.luclId);
      
      this.modalService.showError(error.message,'ok');
    }
    this.uploading = false;
  }

  
}
