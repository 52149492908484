import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Respuesta, preguntaRespuesta } from 'src/app/models/campus/respuesta';
import { Pregunta } from 'src/app/models/campus/Pregunta';


@Injectable({
  providedIn: 'root'
})

export class RespuestaService {

  constructor(private http:HttpClient) {
  }


  public async getPregResp(pregId?:number, respId?:number)
  {
    const body= Params.toHttpParams({pregId:pregId,respId:respId});
    return this.http.get<ApiResponse<preguntaRespuesta>>(`${environment.urlAPI}campus/respuesta/pregunta`,{params:body}).toPromise();
  }




/* 
  public async deleteResp(obj:Pregunta){
    //return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}categorias/microciclo/${obj.catrId}`).toPromise();
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}campus//Respuesta/delete/${obj.pregId}`,{}).toPromise();
  } */
  
}

export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}
 

/* interface bodyDeleteResp{
  RespId?:number;
  PregId?:number;
} */