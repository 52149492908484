import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mesociclo-detalle',
  templateUrl: './mesociclo-detalle.component.html',
  styleUrls: ['./mesociclo-detalle.component.css']
})
export class MesocicloDetalleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
