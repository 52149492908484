<div class="modal-alert">
    <div class="modal-body left">
        <h3 *ngIf="model.repaId">{{lang.text('pago.editar')}}</h3>
        <h3 *ngIf="!model.repaId">{{lang.text('pago.crear')}}</h3>
        <form>
            <label class="app-label">{{lang.text('fecha_inicio')}}</label>
            <input type="datetime-local" class="app-input" [(ngModel)]="model.repaFh" name="repaFh">

            <label class="app-label">{{lang.text('tipo')}}</label>
            <select [(ngModel)]="model.tipaId" name="tipaId" class="app-combo">
                  <option value="null" disabled selected>{{lang.text('seleccionar')}}</option>
                  <option *ngFor="let o of tiposPago" value="{{o.tipaId}}">{{o.tipaNombre}}</option>
            </select>

            <label class="app-label">{{lang.text('duracion_meses')}}</label>
            <input type="number" class="app-input" [(ngModel)]="model.repaMeses" name="repaMeses">
           </form>
    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading"  (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading"  (click)="save()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>


