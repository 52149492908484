<div class="modal-alert">
      <div class="modal-body left">
          <h4>{{lang.text('grupo.editar')}}</h4>
          <form>
            <label class="app-label">{{lang.text('nombre')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="grupo.grupoNombre" name="grupoNombre">

            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="0" name="grupoGratis" id="grupoGratis" [(ngModel)]="grupo.grupoGratis">
              <label class="form-check-label" for="grupoGratis">
                {{lang.text('grupo.gratuito')}}
              </label>
            </div>
          </form>
          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div *ngIf="uploading" class="loading-bar">

      </div>
      <div class="modal-footer">
          <button type="button" class="app-btn red" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
          <button type="button" class="app-btn green" [disabled]="uploading" (click)="agregar()" title="{{lang.text('btn.add')}}"><fa-icon icon="check"></fa-icon></button>
      </div>
</div>
