import { Component, OnInit } from '@angular/core';
import { LeccionService } from 'src/app/services/campus/leccion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { leccion } from '../../models/campus/leccion';
import { NuevoLeccionComponent } from './nuevo-leccion/nuevo-leccion.component';
import { EditarLeccionComponent } from './editar-leccion/editar-leccion.component';
import { EditarDocumentoLeccionComponent } from './editar-documento-leccion/editar-documento-leccion.component';


@Component({
  selector: 'app-lecciones',
  templateUrl: './lecciones.component.html',
  styleUrls: ['./lecciones.component.css']
})
export class LeccionesComponent implements OnInit {

  lecciones:leccion[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  constructor(private modalService:ModalService, private leccionService:LeccionService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {
      const req = await this.leccionService.get();
      this.lecciones = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregarNuevo()
  {
     let modalRef = this.modalService.open(NuevoLeccionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async modificar(leccion:leccion)
  {
     let modalRef = this.modalService.open(NuevoLeccionComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
     modalRef.componentInstance.leccionParam= leccion;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async agregarVideo(leccion:leccion){
     let modalRef = this.modalService.open(EditarLeccionComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.leccionParam = leccion;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
  }

  async agregarDocumento(leccion:leccion){
    let modalRef = this.modalService.open(EditarDocumentoLeccionComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
   modalRef.componentInstance.leccionParam = leccion;
   try {
     await modalRef.result;
     await this.refresh();
   } catch (error) {
     
   } 
 }


 async eliminar(leccion:leccion)
 {
   let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
   if(confirmar)
   {
     try {
       const req = await this.leccionService.DeleteLeccion(leccion.leccId);
       await this.refresh();
     } catch (error) {
       this.modalService.showError(error.message,'ok');
     }
   }
 }





}
