import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { preguntaRespuesta } from 'src/app/models/campus/respuesta';
import { Mesociclo } from 'src/app/models/mesociclo';
import { Microciclo } from 'src/app/models/microciclo';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { Pregunta } from '../../../models/campus/Pregunta';
import { RespuestaService } from '../../../services/campus/respuesta.service';
import { PregRespReq, RespReq } from '../../../models/campus/respuesta';
import { PreguntaService } from '../../../services/campus/pregunta.service';

@Component({
  selector: 'app-preguntas-nuevo',
  templateUrl: './preguntas-nuevo.component.html',
  styleUrls: ['./preguntas-nuevo.component.css']
})
export class PreguntasNuevoComponent implements OnInit {

  loading = true;
  pregResp:preguntaRespuesta[] = [];
  respSelec:preguntaRespuesta
  uploading = false;
  pregDesc:string;
  respDesc:string;
  respCorrecta:boolean;
  errores:any[] = [];

  @Input() preguntaParam:Pregunta;

  constructor(public activeModal:NgbActiveModal,
              private modalService:ModalService,
              public respuestaService:RespuestaService,
              public preguntaService:PreguntaService,
              public lang:LangService,
              ) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading = false;
  }

  async onLoad(){
    try {


      if(this.preguntaParam)
      {
        this.pregDesc= this.preguntaParam.pregDesc
        this.respCorrecta=true;
        const req = await this.respuestaService.getPregResp(this.preguntaParam.pregId);
        this.pregResp = req.data;
        console.log(req);
      }      
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregar()
  {
    
    if (this.preguntaParam) {
      this.pregResp.push({idOrden:0, pregId:null, respId:null, respDesc:this.respDesc, respCorrecta:this.respCorrecta });
      this.limpiarCampos();
      
    } else {
      this.pregResp.push({idOrden:0, pregId:null, respId:null, respDesc:this.respDesc, respCorrecta:this.respCorrecta });
      this.limpiarCampos();
    }

    this.respCorrecta=false;
  }
 

  async remover(resp:preguntaRespuesta)
  {
   /*  let p = this.pregResp.findIndex(el => el.respId == resp.respId);
    this.pregResp.splice(p,1); */

    this.respSelec=resp;
  }

  async guardar()
  {
    this.uploading= true;
     try {
      var request:PregRespReq;
      var respReq:RespReq[]=[];

      if(this.preguntaParam){//modificar  
      
        for (let index = 0; index < this.pregResp.length; index++) {
         respReq.push({RespId:index, RespDesc:this.pregResp[index].respDesc, respCorrecta: this.pregResp[index].respCorrecta})

        }
        request= {
          pregId:this.preguntaParam.pregId, pregDesc:this.pregDesc, Respuestas:respReq
        }

        const req = await this.preguntaService.post(request);
        this.limpiarCampos();
        this.activeModal.close('creado');
      
      }  
      else{//nuevo

        for (let index = 0; index < this.pregResp.length; index++) {
         respReq.push({RespId:index, RespDesc:this.pregResp[index].respDesc, respCorrecta: this.pregResp[index].respCorrecta})

        }
        request= {
          pregId:null, pregDesc:this.pregDesc, Respuestas:respReq
        }

        
        const req = await this.preguntaService.put(request);
        this.limpiarCampos();
        this.activeModal.close('creado');
      }

    } catch (error) {
      await this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    } 
    this.uploading = false;
  }



  limpiarCampos(){
    this.respCorrecta = false;
    //this.pregDesc = '';
    this.respDesc = '';
  }

}
