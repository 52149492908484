import { Component, OnInit } from '@angular/core';
import { User } from 'src/app/models/User';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-usuario-maestro',
  templateUrl: './usuario-maestro.component.html',
  styleUrls: ['./usuario-maestro.component.css']
})
export class UsuarioMaestroComponent implements OnInit {


  loading = true;
  usuarios:User[] = [];
  pagina = 1;
  search_str = null;
  lastPage = 1;
  total = 0;
  constructor(public lang:LangService,private userService:UserService,private helper:ModalService) { }

  ngOnInit(): void {

    this.load();
  }


  prevPage(){
    if(this.pagina>1){
      this.pagina--;
      this.load();
    }
    
  }

  nextPage(){
    if(this.pagina<this.lastPage){
      this.pagina++;
      this.load();
    }
  }

  async load(){
    this.loading = true;
    try {
      const req = await this.userService.getMaster(this.pagina,this.search_str);
      this.usuarios = req.data;
      this.lastPage = req.data[0].paginationLastPage;
      this.total = req.data[0].paginationTotalResults;
    } catch (error) {
      this.helper.showError(error.message,'aceptar');
    }
    this.loading = false;
  }

}
