<div class="modal-alert">
      <div class="modal-body left">
          <h4 *ngIf="!mesocicloParam">{{lang.t('meso.nuevo')}}</h4>
          <h4 *ngIf="mesocicloParam">{{lang.t('meso.editar')}} # {{mesocicloParam.cadoId}}:</h4>
          <form>
            <label class="app-label">{{lang.t('nombre')}}</label>
            <input class="app-input" type="text" [(ngModel)]="cadoNombre" name="cadoNombre">
            <label class="app-label">{{lang.t('descripcion')}}</label>
            <input class="app-input" type="text" [(ngModel)]="cadoDetalle" name="cadoDetalle">
          </form>

          <div *ngIf="loading" class="loader mt-2"></div>
          <div class="row" *ngIf="!loading">
            <div class="col-sm-5">
              <h6>{{lang.t('microciclos')}}</h6>
              <div class="lista">
                <h6 *ngFor="let i of micros" (click)="agregar(i)"> #{{i.catrId}} {{i.catrNombre}}</h6>
              </div>
            </div>
            <div class="col-sm-2">
              <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
            </div>
            <div class="col-sm-5">
              <h6>{{lang.t('meso.en_el')}}</h6>
              <div class="lista">
                <h6 *ngFor="let j of microsSel" (click)="remover(j)"> #{{j.catrId}} {{j.catrNombre}}</h6>
              </div>
            </div>
          </div>
          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div class="loading-bar" *ngIf="uploading"></div>
      <div class="modal-footer">
          <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
      </div>
</div>
