import { HttpEvent, HttpEventType } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Caracteristica } from 'src/app/models/caracteristica';
import { TipoVideo } from 'src/app/models/tipovideo';
import { Video } from 'src/app/models/video';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-video-nuevo',
  templateUrl: './video-nuevo.component.html',
  styleUrls: ['./video-nuevo.component.css']
})
export class VideoNuevoComponent implements OnInit {

  //@Input() id:number;

  videoNombre:string='';
  videoDesc:string='';
  videoDescEjercicio:string = '';
  videoFile:File;
  videoDuracion:string='';
  videoPremium = 0;
  tipoVideoId = null;
  progress:number = 0;
  uploading = false;

  errores:string[] = [];


  loading = true;

  caracteristicas:Caracteristica[] = [];

  tiposVideo:TipoVideo[] = [];

  constructor(public activeModal: NgbActiveModal,
              private videoService:VideoService,
              private modalService:ModalService,
              public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {
      const req = await this.videoService.getAllCaracteristicas();
      this.caracteristicas = req.data;
      const req2 = await this.videoService.getTiposVideo();
      this.tiposVideo = req2.data;



    } catch (error) {
     this.modalService.showError('Ocurrio un error al traer las caracteristicas, se puede agregar el video sin ellas.','Ok');
    }
  }


  async agregarVideo()
  {
    this.uploading = true;
    let video = new Video();
    video.videoDescripcion = this.videoDesc;
    video.videoNombre = this.videoNombre;
    video.videoTiempo = this.videoDuracion;
    video.videoPremium = this.videoPremium;
    video.tipoVideoId = this.tipoVideoId;
    video.videoDescEjercicio = this.videoDescEjercicio;
    for (const iterator of this.caracteristicas) {
      if(iterator.selected)
      {
        video.categorias.push(iterator.caracteristicaId);
      }
    }

    //video.Archivo = this.videoFile;
    const file = (<HTMLInputElement>document.getElementById('archivo')).files.item(0);
    video.Archivo = file;

    this.videoService.post(video).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          console.log('Enviado');
          break;
        case HttpEventType.ResponseHeader:
          console.log('Recibido el header');
          break;
        case HttpEventType.UploadProgress:
          this.progress = Math.round(event.loaded / event.total * 100);
          console.log(`Subiendo ${this.progress}%`);
          break;
        case HttpEventType.Response:
          console.log('Terminado!', event.body);
          setTimeout(() => {
            this.uploading = false;
            this.progress = 0;
            this.activeModal.close();
          }, 5000);//1500);

      }
    }, (error) =>
    {
      this.uploading = false;
      this.modalService.showError(error.message,'Ok');
      this.errores= error.data;
    });


  }

}
