import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/User';
import { ControlClaseService } from 'src/app/services/controlclase';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { SuspensionClasesService } from 'src/app/services/suspension-clases.service';

@Component({
  selector: 'app-agregar-suspension-clases',
  templateUrl: './agregar-suspension-clases.component.html',
  styleUrls: ['./agregar-suspension-clases.component.css']
})
export class AgregarSuspensionClasesComponent implements OnInit {

  @Input() alumno:User;

  razon:string = '';
  fecha1;
  fecha2;

  uploading = false;
  loading = false;
  errores:any[] = [];

  constructor(private modalSrv:ModalService,public activeModal:NgbActiveModal,
    private claseSrv:ControlClaseService,private suspensionService:SuspensionClasesService,public lang:LangService) { }

  ngOnInit(): void {
  }


  async guardar(){
    this.uploading = true;
    try {
      const req = await this.suspensionService.post(this.alumno.id,this.razon,this.fecha1,this.fecha2);
      this.activeModal.dismiss('ok');
    } catch (error) {
      this.modalSrv.showError(error.message,'Ok');
    }
    this.uploading = false;
 }

}
