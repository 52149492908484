import { Injectable } from '@angular/core';

import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { LoaderComponent } from '../modals/loader/loader.component';
import { LangService } from './lang.service';
import { OptionsAlertComponent } from '../components/options-alert/options-alert.component';
import { CustomSelectOption } from '../models/customselectoption';




@Injectable({
  providedIn: 'root'
})

export class ModalService {
  
  

  constructor(private modalService:NgbModal,private lang:LangService) {
  }


  async showLoader(){
    let modalRef = this.modalService.open(LoaderComponent,{windowClass:'loader-modal',backdrop:'static',centered:true});
    return modalRef;
}

  async showError(msg:string,botonText:string, errorDetail = null){
    try {
      let modalRef = this.modalService.open(AlertModalComponentComponent,{windowClass:'app-modal message-modal'});
    modalRef.componentInstance.message = (msg? msg:'error_inesperado');
    if(errorDetail){
      modalRef.componentInstance.errorDetail = errorDetail;
    }
    
    modalRef.componentInstance.button1 = this.lang.text(botonText);
    return await modalRef.result;
    } catch (error) {
      return null;
    }
    
  }

  async showOptionsAlert(items:CustomSelectOption[]){
    try{
      const modalRef = this.modalService.open(OptionsAlertComponent,{windowClass:'app-modal message-modal'});
      modalRef.componentInstance.items = items;
      const item:CustomSelectOption = await modalRef.result;
      return item;
    }
    catch(error){
      return null;
    }
  }

  dismissAll()
  {
    this.modalService.dismissAll();
  }

  async confirm(msg:string,botonText:string,cancelText="Volver")
  {
    try {
      let modalRef = this.modalService.open(AlertModalComponentComponent,{windowClass:'app-modal top-modal',backdrop:'static'});
      modalRef.componentInstance.message = msg;
      modalRef.componentInstance.button1 = this.lang.text(botonText);
      modalRef.componentInstance.button2 = this.lang.text(cancelText);
      await modalRef.result;
      return true;
    } catch (error) {
      return false;
    }
  }

  open(content:any,options:NgbModalOptions = {windowClass:'app-modal'})
  {
    return this.modalService.open(content,options);
  }
}
