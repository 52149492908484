<div class="container view">

    <div class="botones-opciones full ciclos">
        <button class="app-btn" [routerLink]="['/cursos']">{{lang.t('menu.curs')}}</button>
        <button class="app-btn active" [routerLink]="['/modulos']">{{lang.t('menu.modu')}}</button>
        <button class="app-btn" [routerLink]="['/lecciones']">{{lang.t('menu.lecc')}}</button>
        <button class="app-btn" [routerLink]="['/quizes']">{{lang.t('menu.quiz')}}</button>
        <button class="app-btn" [routerLink]="['/preguntas']">{{lang.t('menu.preg')}}</button>
    </div>
    <div class="ciclos-border">
        <div class="botones-opciones mb-3">
            <button class="app-btn" (click)="agregarNuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
            <button class="app-btn" (click)="refresh()" title="{{lang.t('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>
        </div>
        <div *ngIf="!this.loading" class="table-container">
            <table  class="app-table">
                <thead>
                    <th>
                        #
                    </th>
                    <th>
                        {{lang.text('modu.nombre')}}
                    </th>
                    <th>
                        {{lang.text('modu.desc')}}
                    </th>
                    <th>
                        {{lang.text('modu.cod')}}
                    </th>
                    <th>

                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let m of this.modulos;index as idx">
                        <td>{{idx+1}}</td>
                        <td>{{m.moduNombre}}</td>
                        <td>{{m.moduDesc}}</td>
                        <td>{{m.moduCodigo}}</td>

                        <td>
                            <button class="app-btn small" alt="{{lang.text('modu.edit')}}" (click)="modificar(m)" title="{{lang.t('btn.edit')}}"><fa-icon icon="edit"></fa-icon></button>
                            <button class="app-btn small" alt="{{lang.text('modu.addLec')}}" (click)="agregarLeccion(m)" title="{{lang.t('btn.addLecc')}}"><fa-icon icon="cogs"></fa-icon></button>
                            <button class="app-btn small" alt="{{lang.text('modu.addQuiz')}}" (click)="agregarQuiz(m)" title="{{lang.t('btn.addQuiz')}}"><fa-icon icon="cogs"></fa-icon></button>
                             <button class="app-btn small red" (click)="eliminar(m)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt" ></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>

        <div *ngIf="this.loading" class="loader">

        </div>
        <h6 *ngIf="this.modulos.length == 0 && !this.loading">{{lang.t('nada_por_aqui')}}</h6>
    </div>
</div>
