import { Component,Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { quiz } from 'src/app/models/campus/quiz';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { QuizService } from 'src/app/services/campus/quiz.service';

@Component({
  selector: 'app-quizes-nuevo',
  templateUrl: './quizes-nuevo.component.html',
  styleUrls: ['./quizes-nuevo.component.css']
})
export class QuizesNuevoComponent implements OnInit {

  quiz:quiz;
  errores:any[] = [];
  uploading:boolean= false;

  constructor(public activeModal:NgbActiveModal,
              private quizService:QuizService,
              private modalService:ModalService,
              public lang:LangService) {
    this.quiz = new quiz();
   }

   @Input() quizParam:quiz;

  async ngOnInit(): Promise<void> {
    if (this.quizParam) {
      this.quiz=this.quizParam;
    }
  }

  async agregar(){
    this.uploading = true;
    if(this.quizParam)
    {
      try {
        const req = await this.quizService.post(this.quiz);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }else{
      try {
        const req = await this.quizService.put(this.quiz);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
        this.errores = error.data;
      }
    }

    this.uploading=false;
  }
}
