import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'src/app/models/ApiResponse';
import { Curso, CursoModulo } from 'src/app/models/campus/curso';
import { CursoDetalles } from 'src/app/models/campus/cursodetalles';
import { Idioma } from 'src/app/models/campus/idioma';
import { Modulo } from 'src/app/models/campus/modulo';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CursoService {

  constructor(private http:HttpClient) { }


  public async get(quizId?:number)
  {
    const body = Params.toHttpParams({quizId:quizId});
    return this.http.get<ApiResponse<Curso>>(`${environment.urlAPI}campus/curso`,{params:body}).toPromise();
  }


  public async getIdioma()
  {   
    return this.http.get<ApiResponse<Idioma>>(`${environment.urlAPI}campus/curso/idioma`).toPromise();
  }

  public async getCursos(idUser:number)
    {
      return this.http.get<ApiResponse<any>>(`${environment.urlAPI}campus/curso/user?userId=${idUser}`).toPromise();
    }

    async getCursoDetalles(idUser:number,cursoId:number)
    {
        return this.http.get<ApiResponse<CursoDetalles>>(`${environment.urlAPI}campus/curso/user?userId=${idUser}&cursId=${cursoId}`).toPromise();
    }
  //no espera nada de vuelta
  public async put(req:Curso)
  {
    return this.http.put<ApiResponse<Curso>>(`${environment.urlAPI}campus/curso`,req).toPromise();
  }

//este si puede llegar algo de vuelta
  public async post(req:Curso)
  {
    return this.http.post<ApiResponse<Curso>>(`${environment.urlAPI}campus/curso`,req).toPromise();
  }



  ////todo los modulos que tiene un curso
  public async getCursoModulo(cursId:number,moduloId?:number)
  {
    const body = Params.toHttpParams({cursId:cursId,moduloId:moduloId});
    return this.http.get<ApiResponse<Modulo>>(`${environment.urlAPI}campus/modulo/curso`,{params:body}).toPromise();
  }

   // manda la lista de modulos a un curso
  public async postCursoModulo(req:CursoModulo)
  {
    return this.http.put<ApiResponse<CursoModulo>>(`${environment.urlAPI}campus/modulo/curso`,req).toPromise();
  }



  public async DeleteCurso(cursId?:number)
  {
    const body = Params.toHttpParams({cursId:cursId});
    return this.http.delete<ApiResponse<CursoModulo>>(`${environment.urlAPI}campus/curso`,{params:body}).toPromise();
  }








}


export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
}
}