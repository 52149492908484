import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/User';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-alumno-detalle',
  templateUrl: './alumno-detalle.component.html',
  styleUrls: ['./alumno-detalle.component.css']
})
export class AlumnoDetalleComponent implements OnInit {

  @Input() user:User;
  sesiones:any[] = [];
  diasNombres:string[] = ['','Lunes','Martes','Miercoles','Jueves','Viernes'];


  constructor(public activeModal:NgbActiveModal,private evaluacionService:EvaluacionService, public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }

  async load()
  {
    try {
      const req = await this.evaluacionService.getPeriodoSesiones(this.user.id);
      this.sesiones = req.data;
    } catch (error) {

    }
  }

}
