<div class="modal-alert">
    <div class="modal-body">
        <p> {{lang.text(message)}}</p>

        <blockquote *ngIf="errorDetail">
          {{lang.text(errorDetail)}}
        </blockquote>
    </div>
    <div class="modal-footer">
      <button *ngIf="button2" type="button" class="app-btn" (click)="activeModal.dismiss('boton2')"><fa-icon icon="times"></fa-icon> {{button2}}</button>
      <button type="button" class="app-btn" (click)="activeModal.close('boton1')"><fa-icon icon="check"></fa-icon> {{button1}}</button>
    </div>
</div>
