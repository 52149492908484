<div class="container-md" style="max-width: 960px;">
    <div class="botones-opciones full ciclos">
        <button class="app-btn " [routerLink]="['/account']" >{{lang.text('acco.h1')}}</button>
        <button class="app-btn active" [routerLink]="['/calendar']" >{{lang.text('acco.calendar')}}</button>
        <button class="app-btn " [routerLink]="['/prices']" >{{lang.text('acco.payments')}}</button>
        <button class="app-btn " [routerLink]="['/sells']" >{{lang.text('acco.sells')}}</button>

    </div>
    <div class="header g-border p-3">
        <h2>{{lang.text('calendar.header')}}</h2>
        <p>{{lang.text('calendar.text1')}}</p>

        <div class="botones-opciones">
            <button class="app-btn" (click)="agregar()">{{lang.text('calendar.add')}}</button>
        </div>
        <div class="table-container" style="max-height: 50vh;">
            <table class="app-table">
                <thead>
                    
                    <th>
                        {{lang.text('fecha')}}
                    </th>
                    <th>
                        {{lang.text('dia')}}
                    </th>
                    <th>
                        {{lang.text('hora')}}
                    </th>
                    <th>
                        {{lang.text('calendar.reservada')}}
                    </th>
                    <th>
                        {{lang.text('alumno')}}
                    </th>
                    <th>
                        
                    </th>
                </thead>
                <tbody>
                    <tr *ngFor="let i of horas">
                        <td>
                            {{i.agenFh | date:'mediumDate'}} 
                        </td>
                        <td>
                            {{i.agenFh |date :'EEEE'}}
                        </td>
                        <td>
                            {{i.agenFh | date: 'shortTime'}}
                        </td>
                        <td>
                            <span class="pillow" *ngIf="!i.agenReservado"><fa-icon icon="check" ></fa-icon> Disponible</span>
                            <span class="pillow gray" *ngIf="i.agenReservado"><fa-icon icon="dollar-sign" ></fa-icon> Reservada</span>
                        </td>
                        <td>
                            {{i.alumnoNombre}}
                        </td>
                        <td>
                            <button *ngIf="!i.agenReservado" class="app-btn small"  (click)="eliminar(i)" title="{{lang.t('btn.dele')}}"><fa-icon icon="trash-alt"></fa-icon></button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>    
</div>
