<div *ngIf="user" class="container">
      
      <div style="width:100%;display:flex;justify-content:center;">
            <img src="/assets/logo_rosa.png"  height="250">
      </div>
      <h1>{{lang.text('welcome')}} {{user.userNombre}}</h1>

      <ng-container *ngIf="user.rolId == 4">
            <p >
                  {{lang.text('texto_ofrecer_pagos')}}
            </p>
            <a routerLink="/pago" class="app-btn">{{lang.text('btn_pagar')}}</a>
      </ng-container>

      <ng-container *ngIf="user.rolId == 5 || user.rolId == 7">
            <p >
                  {{lang.text('texto_ver_campus')}}
            </p>
            <a href="https://campus.golazoft.com" class="app-btn">{{lang.text('btn_ir_campus')}}</a>
      </ng-container>

      <ng-container *ngIf="user.rolId == 3">
            <div class="row">
                  <div class="col-sm-4">
                        <img src="/assets/app.png" alt="" width="200">
                  </div>
                  <div class="col-sm-8" >
                        <h2>{{lang.text('inicio.descarga_app_h1')}}</h2>
                        <div style="width: 100%;display:flex;justify-content:center;">
                              <img src="/assets/qr.png" alt="Download" width="200">
                        </div>
                        
                  </div>
            </div>
      </ng-container>
      
</div>
