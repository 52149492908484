<div class="container view">
      <div class="botones-opciones mb-3">
          <button class="app-btn" (click)="agregarNuevo()" title="{{lang.t('btn.add')}}"><fa-icon icon="plus"></fa-icon></button>
          <button class="app-btn" (click)="refresh()" title="{{lang.text('btn.actu')}}"><fa-icon icon="retweet"></fa-icon></button>
          <button class="app-btn" routerLink="/sponsor"><fa-icon icon="building"></fa-icon> {{lang.text('patrocinadores')}}</button>
      </div>
      <div *ngIf="!this.loading" class="table-container">
          <table class="app-table" >
                <thead>
                      <th>
                            #
                      </th>
                      <th>
                            {{lang.text('descripcion')}}
                      </th>
                      <th>
                            {{lang.text('videos')}}
                      </th>
                      <th>

                      </th>
                </thead>
                <tbody>
                      <tr *ngFor="let iter of this.grupos;index as idx">
                            <td>{{idx+1}}</td>
                            <td>{{iter.grupoNombre}} <span class="badge badge-pill badge-info" *ngIf="iter.grupoGratis == 1">{{lang.text('gratis')}}</span></td>
                            <td>{{iter.conteoVideos}}</td>

                            <td>
                                  <button class="app-btn small" (click)="administrar(iter)" title="{{lang.text('btn.administrar')}}"><fa-icon icon="cogs"></fa-icon></button>

                                  <button class="app-btn small red" (click)="eliminar(iter)" title="{{lang.text('btn.dele')}}" ><fa-icon icon="trash-alt"></fa-icon></button>
                            </td>
                      </tr>
                </tbody>
          </table>

      </div>

      <div *ngIf="this.loading" class="loader">

      </div>
      <h6 *ngIf="this.grupos.length == 0 && !this.loading">{{lang.text('btn.nada_por_aqui')}}</h6>

  </div>
