import { Component, OnInit } from '@angular/core';
import { Modulo } from 'src/app/models/campus/modulo';
import { ModuloService } from 'src/app/services/campus/modulo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { AgregarQuizComponent } from './agregar-quiz/agregar-quiz.component';
import { EditarModuloComponent } from './editar-modulo/editar-modulo.component';
import { NuevoModuloComponent } from './nuevo-modulo/nuevo-modulo.component';


@Component({
  selector: 'app-modulos',
  templateUrl: './modulos.component.html',
  styleUrls: ['./modulos.component.css']
})
export class ModulosComponent implements OnInit {


  modulos:Modulo[] = [];
  loading:boolean = true;
  uploading:boolean = false;

  constructor(private modalService:ModalService, private moduloService:ModuloService,public lang:LangService) { }

  async ngOnInit(): Promise<void> {
    await this.onLoad();
    this.loading=false;
  }

  async refresh()
  {
    this.loading= true;
    await this.onLoad();
    this.loading=false;
  }

  async onLoad()
  {
    try {
      const req = await this.moduloService.get();
      this.modulos = req.data;
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }

  async agregarNuevo()
  {
     let modalRef = this.modalService.open(NuevoModuloComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    } 
    
  }

  async modificar(quizmodulo?:Modulo)
  {
     let modalRef = this.modalService.open(NuevoModuloComponent,{windowClass:'app-modal top-modal',backdropClass:'no-backdrop'});
     modalRef.componentInstance.moduloParam= quizmodulo;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }  
  }

  async agregarLeccion(modulo:Modulo){
      let modalRef = this.modalService.open(EditarModuloComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
    modalRef.componentInstance.moduParam = modulo;
    try {
      await modalRef.result;
      await this.refresh();
    } catch (error) {
      
    }  
  }


  async agregarQuiz(modulo:Modulo){
    let modalRef = this.modalService.open(AgregarQuizComponent,{windowClass:'app-modal top-modal',size:'lg',backdropClass:'no-backdrop',backdrop:'static'});
  modalRef.componentInstance.moduParam = modulo;
  try {
    await modalRef.result;
    await this.refresh();
  } catch (error) {
    
  }  
}

  async eliminar(modulo:Modulo)
  {
    let confirmar = await this.modalService.confirm('grupo.confirmar_eliminar','Si, borralo','No');
    if(confirmar)
    {
      try {
        const req = await this.moduloService.DeleteModulo(modulo.moduId);
        await this.refresh();
      } catch (error) {
        this.modalService.showError(error.message,'ok');
      }
    }
  }




}


