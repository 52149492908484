import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { User } from 'src/app/models/User';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-profesores-pendientes',
  templateUrl: './profesores-pendientes.component.html',
  styleUrls: ['./profesores-pendientes.component.css']
})
export class ProfesoresPendientesComponent implements OnInit {

  users:User[] = [];
  currentPage = 1;
  lastPage = 1;
  total = 0;
  constructor(public activeModal:NgbActiveModal,private userService:UserService,private modalService:ModalService,public lang:LangService) { }

  ngOnInit(): void {
    this.load();
  }


  prevPage(){
    if(this.currentPage>1){
      this.currentPage--;
      this.load();
    }
    
  }

  nextPage(){
    if(this.currentPage<this.lastPage){
      this.currentPage++;
      this.load();
    }
  }


  async load(){
    try {
      const req = await this.userService.getMaster(this.currentPage,null,4);
      this.users = req.data;
      this.lastPage = req.data[0].paginationLastPage;
      this.total = req.data[0].paginationTotalResults;

    } catch (error) {
      this.modalService.showError(error.message,'Ok');
    }
  }


  async agregar(user:User){
    const loader = await this.modalService.showLoader();
    try {
      const req = await this.userService.putRol(user.id,2);
      this.activeModal.close(user);
      loader.dismiss();
    } catch (error) {
      loader.dismiss();
      this.modalService.showError(error.message,'Ok');
    }
  }
}
