import { Component, OnInit } from '@angular/core';
import { Abono } from 'src/app/models/abono';
import { PaqueteEntrenamiento } from 'src/app/models/paquete';
import { LangService } from 'src/app/services/lang.service';
import { PaqueteService } from 'src/app/services/paquete.service';

@Component({
  selector: 'app-precios',
  templateUrl: './precios.component.html',
  styleUrls: ['./precios.component.css']
})
export class PreciosComponent implements OnInit {

  paquetes:PaqueteEntrenamiento[] = [];

  constructor(public lang:LangService,private paqueteService:PaqueteService) { }

  ngOnInit(): void {
    this.load();
  }


  async load(){
    try {
      const req = await this.paqueteService.getAll();
      this.paquetes = req.data;

      
    } catch (error) {
      
    }
  }

  round(n:number){
    return n.toFixed(2);
  }
}
