
<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{lang.text('videos_sesion')}}</h4>
        <div *ngIf="this.loading" class="loader"></div>
        <div class="video-item" *ngFor="let i of videos" (click)="verVideo(i)">
            <img [src]="previewUrl+i.videoPreview" height="48">
            <h6>{{i.videoDescripcion}}</h6>
            <h6>{{i.videoTiempo}}</h6>
            <p>{{i.videoDescEjercicio}}</p>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn"  (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
    </div>
</div>
