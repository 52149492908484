import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/User';
import { ApiResponse } from '../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlertModalComponentComponent } from '../alert-modal-component/alert-modal-component.component';
import { Video } from '../models/video';
import { Caracteristica } from '../models/caracteristica';
import { Sesion } from '../models/sesion';

import { Microciclo } from '../models/microciclo';
import { Mesociclo } from '../models/mesociclo';
import { Macrociclo } from '../models/Macrociclo';
import { Mesogrupo, MesogrupoCado } from '../models/Mesogrupo';
import { ProgramaDetalle } from '../models/programa.detalle';




@Injectable({
  providedIn: 'root'
})

export class CategoriaService {
  

  constructor(private http:HttpClient) {
  }


  /* Sesiones o Cacu o Categoria cuatro */
  public async getAllSesion():Promise<ApiResponse<Sesion>>{
    return this.http.get<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion`).toPromise();
  }

  public async getSession(id:number):Promise<ApiResponse<Sesion>>{
    return this.http.get<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion/${id}`).toPromise();
  }

  public async postSesion(sesion:Sesion):Promise<ApiResponse<any>>{
    return this.http.post<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion`,sesion).toPromise();
  }

  public async postSesionInfo(sesion:Sesion):Promise<ApiResponse<any>>{
    return this.http.post<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion/info`,sesion).toPromise();
  }

  public async deleteSesion(sesion:Sesion):Promise<ApiResponse<any>>{
    //return this.http.delete<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion/${sesion.cacuId}`).toPromise();
    return this.http.post<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion/eliminar/${sesion.cacuId}`,{}).toPromise();
  }

  public async getSesionByMicro(id:number)
  {
    return this.http.get<ApiResponse<Sesion>>(`${environment.urlAPI}categorias/sesion/microciclo/${id}`).toPromise();
  }

  /* Microciclos o catr o Categoria tres */
  public async getAllMicros(){
    return this.http.get<ApiResponse<Microciclo>>(`${environment.urlAPI}categorias/microciclo`).toPromise();
  }

  public async getMicro(id:number){
    return this.http.get<ApiResponse<Microciclo>>(`${environment.urlAPI}categorias/microciclo/${id}`).toPromise();
  }

  public async postMicro(obj:Microciclo){
    return this.http.post<ApiResponse<Microciclo>>(`${environment.urlAPI}categorias/microciclo`,obj).toPromise();
  }

  public async cambiarTipoMicro(obj:Microciclo,tipo:boolean){
    const body = {
      catrId:obj.catrId,
      catrGratis:tipo
    }
    return this.http.post<ApiResponse<Microciclo>>(`${environment.urlAPI}categorias/microciclo/cambiar-tipo`,body).toPromise();
  }

  public async deleteMicro(obj:Microciclo){
    //return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}categorias/microciclo/${obj.catrId}`).toPromise();
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}categorias/microciclo/eliminar/${obj.catrId}`,{}).toPromise();
  }

  public async getMicroByMeso(id:number)
  {
    return this.http.get<ApiResponse<Microciclo>>(`${environment.urlAPI}categorias/microciclo/mesociclo/${id}`).toPromise();
  }

  /* Mesociclos o cado o Categoria dos */
  public async getAllMesos(){
    return this.http.get<ApiResponse<Mesociclo>>(`${environment.urlAPI}categorias/mesociclo`).toPromise();
  }

  public async getMeso(id:number){
    return this.http.get<ApiResponse<Mesociclo>>(`${environment.urlAPI}categorias/mesociclo/${id}`).toPromise();
  }

  public async postMeso(obj:Mesociclo){
    return this.http.post<ApiResponse<Mesociclo>>(`${environment.urlAPI}categorias/mesociclo`,obj).toPromise();
  }

  public async deleteMeso(obj:Mesociclo){
    //return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}categorias/mesociclo/${obj.cadoId}`).toPromise();
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}categorias/mesociclo/eliminar/${obj.cadoId}`,{}).toPromise();
  }

  public async getMesoByMacro(id:number)
  {
    return this.http.get<ApiResponse<Mesociclo>>(`${environment.urlAPI}categorias/mesociclo/macrociclo/${id}`).toPromise();
  }

  /* Macrociclos o caun o Categoria uno */
  public async getAllMacros(){
    return this.http.get<ApiResponse<Macrociclo>>(`${environment.urlAPI}categorias/macrociclo`).toPromise();
  }

  public async getMacro(id:number){
    return this.http.get<ApiResponse<Macrociclo>>(`${environment.urlAPI}categorias/macrociclo/${id}`).toPromise();
  }

  public async getMacroDetalles(id:number){
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}categorias/macrociclo/${id}`).toPromise();
  }

  public async postMacro(obj:Macrociclo){
    return this.http.post<ApiResponse<Macrociclo>>(`${environment.urlAPI}categorias/macrociclo`,obj).toPromise();
  }

  public async deleteMacro(obj:Macrociclo){
    //return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}categorias/macrociclo/${obj.caunId}`).toPromise();
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}categorias/macrociclo/eliminar/${obj.caunId}`,{}).toPromise();
  }


  //grupos de mesos
  public async getAllMesoGrupos(){
    return this.http.get<ApiResponse<Mesogrupo>>(`${environment.urlAPI}categorias/mesogrupo`).toPromise();
  }

  public async getMesoGrupo(id:number){
    return this.http.get<ApiResponse<MesogrupoCado|Mesociclo>>(`${environment.urlAPI}categorias/mesogrupo/${id}`).toPromise();
  }

  public async postMesoGrupo(obj:Mesogrupo)
  {
    return this.http.post<ApiResponse<Mesogrupo>>(`${environment.urlAPI}categorias/mesogrupo`,obj).toPromise();
  }

  public async deleteMesogrupo(obj:Mesogrupo){
    //return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}categorias/macrociclo/${obj.caunId}`).toPromise();
    return this.http.post<ApiResponse<any>>(`${environment.urlAPI}categorias/mesogrupo/eliminar/${obj.megrId}`,{}).toPromise();
  }

  public async getPartesSesion(){
    return this.http.get<ApiResponse<{paseId:string}>>(`${environment.urlAPI}categorias/sesion/partes`,{}).toPromise();
  }

  public async getPrograma(id:number){
    return this.http.get<ApiResponse<ProgramaDetalle>>(`${environment.urlAPI}categorias/sesion/${id}/programa`,{}).toPromise();
  }
}
