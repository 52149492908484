<div class="modal-alert">
      <div class="modal-body left">
          <h4 *ngIf="!sesionParam">{{lang.t('ses.agregar')}}</h4>
          <h4 *ngIf="sesionParam">{{lang.t('ses.editar')}} # {{sesionParam.cacuId}}:</h4>


          <form>
            <div class="row">
              <div class="col-sm-6">
                <label class="app-label">{{lang.t('nombre')}}:</label>
                <input class="app-input" type="text" [(ngModel)]="cacuNombre" name="cacuNombre">
              </div>
              <div class="col-sm-6">
                <label class="app-label">{{lang.t('nombre_visible')}}:</label>
            <input class="app-input" type="text" [(ngModel)]="cacuNombreApp" name="cacuNombreApp">
              </div>
            </div>


          </form>

          <h5 class="mt-3">{{lang.t('configurar')}}:</h5>
          <div *ngIf="loading" class="loader mt-2"></div>
          <div class="row" *ngIf="!loading">
            <div class="col-sm-3">
              <h6>{{lang.t('grupos_disponibles')}}:</h6>
              <div class="lista">
                <h6 *ngFor="let i of grupos" (click)="abrir(i)"><fa-icon icon="folder-open"></fa-icon> {{i.grupoNombre}}</h6>
              </div>
            </div>
            <div class="col-sm-3">
              <h6>{{lang.t('videos')}}:</h6>
              <div class="lista">
                <h6 *ngFor="let v of videosDisponibles" (click)="agregar(v)"><fa-icon icon="plus"></fa-icon> {{v.videoNombre}}</h6>
                <small *ngIf="videosDisponibles.length < 1" class="info">{{lang.text('ses.click_para_video')}}</small>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="masivos">
                <button type="button" class="app-btn" (click)="copyMultiple()" ><fa-icon icon="copy"></fa-icon> {{lang.text('copiar')}}</button>
                <button type="button" class="app-btn" [disabled]="clipService.isEmpty" (click)="paste()"><fa-icon icon="paste"></fa-icon> {{lang.text('pegar')}}</button>
                <button type="button" class="app-btn" (click)="removeMultiple()"><fa-icon icon="trash"></fa-icon> {{lang.text('eliminar')}}</button>
                <button type="button" class="app-btn" (click)="selectAll()"><fa-icon icon="check-square"></fa-icon> {{lang.text('sel_todo')}}</button>
                <button type="button" class="app-btn" (click)="deselectAll()"><fa-icon icon="square"></fa-icon> {{lang.text('desel_todo')}}</button>
              </div>
              <div class="lista" [class.dragging]="dragClass"  (dragover)="dragOver($event)" (dragstart)="dragClass=true;" (drop)="drop($event,0)">
                <div *ngFor="let j of videosSel;let ind = index" [style.--parte-color]="j.paseId? coloresPartes[j.paseId]:'#ffffff00'" class="row video-item" [class.selected]="j.selected" [class.hovered]="j.hovered"  draggable="true" (dragover)="dragStep($event)"  (drag)="dragClass=true" (dragend)="dropEnd($event,ind)"  [attr.model]="ind">
                  <h6 (click)="j.selected = !j.selected"><fa-icon *ngIf="j.selected" icon="check-square"></fa-icon><fa-icon *ngIf="!j.selected" icon="square"></fa-icon></h6>

                  <h6 class="title">
                    <span  class="badge badge-pill badge-info" style="margin-right: 4px;">{{ind+1}} </span>
                    <fa-icon icon="video"></fa-icon> {{j.videoNombre}} <span class="darker">{{j.videoTiempo}}</span>
                  </h6>
                  <div>
                    <button style="margin-right: 0.5rem;" type="button" class="app-btn md rd outline" (click)="mark(j)" >
                      <fa-icon icon="arrow-down"></fa-icon>
                    </button>
                    <button style="margin-right: 0.5rem;" type="button" class="app-btn md rd outline" (click)="startSerie(j)" >
                      <fa-icon icon="bars" *ngIf="j.esSerie"></fa-icon>
                      <fa-icon icon="bars" *ngIf="!j.esSerie" style="color:#202020;"></fa-icon>
                    </button>
                    <button style="margin-right: 0.5rem;" type="button" class="app-btn md rd outline" (click)="remove(ind)" title="{{lang.t('btn.remover')}}">
                      <fa-icon icon="trash"></fa-icon>
                    </button>
                    <button type="button" class="app-btn md rd outline" (click)="flipContador(j)" title="{{lang.t('btn.contador')}}">
                      <fa-icon icon="stopwatch" *ngIf="j.mostrarContador"></fa-icon>
                      <fa-icon icon="stopwatch" *ngIf="!j.mostrarContador" style="color:#202020;"></fa-icon>
                    </button>
                  </div>

                </div>

                <small *ngIf="videosSel.length < 1" class="info">{{lang.text('ses.sin_videos')}}</small>
              </div>
              <p>
                <span [style.color]="coloresPartes['pa_entrada']">{{lang.text('pa_entrada')}}</span>
                <span [style.color]="coloresPartes['pa_principal']">{{lang.text('pa_principal')}}</span>
                <span [style.color]="coloresPartes['pa_calma']">{{lang.text('pa_calma')}}</span>
              </p>
            </div>
          </div>
          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div class="loading-bar" *ngIf="uploading"></div>
      <div class="modal-footer">
          <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.text('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
      </div>
</div>

