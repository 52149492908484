import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Caracteristica } from 'src/app/models/caracteristica';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { VideoService } from 'src/app/services/video.service';

@Component({
  selector: 'app-agregar-caracteristica',
  templateUrl: './agregar-caracteristica.component.html',
  styleUrls: ['./agregar-caracteristica.component.css']
})
export class AgregarCaracteristicaComponent implements OnInit {
  @Input() caracteristica:Caracteristica = {caracteristicaId:null,caracteristicaNombre:'',selected:false};
  errores:any[] = [];
  uploading:boolean= false;

  constructor(public activeModal:NgbActiveModal, private videoService:VideoService,private modalService:ModalService,public lang:LangService) {
   }

  async ngOnInit(): Promise<void> {
  }

  async agregar(){

    this.uploading = true;
    try {
      const req = await this.videoService.postCaracteristica(this.caracteristica);
      this.activeModal.close('creado');
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading=false;
  }

}
