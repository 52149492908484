<div class="modal-alert">
      <div class="modal-body left">
          <h4 *ngIf="!microcicloParam">{{lang.text('micro.nuevo')}}</h4>
          <h4 *ngIf="microcicloParam">{{lang.text('micro.editar')}} # {{microcicloParam.catrId}}:</h4>
          <form>
            <label class="app-label">{{lang.text('nombre')}}</label>
            <input class="app-input" type="text" [(ngModel)]="catrNombre" name="catrNombre">
            <label class="app-label">{{lang.text('nombre_visible')}}</label>
            <input class="app-input" type="text" [(ngModel)]="catrNombreApp" name="catrNombreApp">
          </form>

          <div *ngIf="loading" class="loader mt-2"></div>
          <div class="row" *ngIf="!loading">
            <div class="col-sm-5">
              <h6>{{lang.t('sesiones')}}</h6>
              <div class="lista">
                <input  class="app-input" type="search" placeholder="{{lang.t('buscar')}}" [(ngModel)]="searchStr" name="buscar" (keyup)="filter()">

                <h6 *ngFor="let i of sesiones" (click)="agregar(i)"> #{{i.cacuId}} {{i.cacuNombre}}</h6>
              </div>
            </div>
            <div class="col-sm-2">
              <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
            </div>
            <div class="col-sm-5">
              <h6>{{lang.t('micro.en_el')}}:</h6>
              <div class="lista">
                <h6 *ngFor="let j of sesionesSel" (click)="remover(j)"> #{{j.cacuId}} {{j.cacuNombre}}</h6>
              </div>
            </div>
          </div>
          <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}
          </div>
      </div>
      <div class="loading-bar" *ngIf="uploading"></div>
      <div class="modal-footer">
          <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times" ></fa-icon></button>
          <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check" ></fa-icon></button>
      </div>
</div>
