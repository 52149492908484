<div class="container view">
    <app-adm-btns>
      
    </app-adm-btns>
    <h1>{{lang.text('logcampanas.header')}}</h1>
    <ng-container *ngIf="status">
        <div class="stats mb-2">

            <div class="stat-box">
                <span class="material-symbols-rounded">
                    ad_units
                </span>
                <div class="info">
                    <h6>{{status.notificacionesPendientes}}</h6>
                    <p>{{lang.text('adm.notis_pending')}}</p>
                </div>
                
            </div>
    
            <div class="stat-box">
                <span class="material-symbols-rounded">
                    forward_to_inbox
                </span>
                <div class="info">
                    <h6>{{status.mailPendientes?? 0}}</h6>
                    <p>{{lang.text('adm.mail_cola')}}</p>
                </div>
                
            </div>
        </div>
    </ng-container>
    <div style="width: 200px;margin-bottom: 12px;">
        <button class="app-btn" [routerLink]="['/adm/campana/nueva']">{{lang.t('campanas.nueva')}}</button>
    </div>
    <app-read-only-table source="reporte/campana">

    </app-read-only-table>
</div>