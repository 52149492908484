import { Component, OnInit } from '@angular/core';
import { Agenda } from 'src/app/models/agenda';
import { AgendaService } from 'src/app/services/agenda.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { AgregarAgendaComponent } from './agregar-agenda/agregar-agenda.component';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  styleUrls: ['./agenda.component.css']
})
export class AgendaComponent implements OnInit {


  horas:Agenda[] = [];
  constructor(public lang:LangService,private agendaService:AgendaService,private modalService:ModalService) { }

  ngOnInit(): void {
    this.load();
  }

  async load(){
    try {
      const req = await this.agendaService.get();
      this.horas = req.data;
    } catch (error) {
      
    }
  }

  async eliminar(ind:Agenda)
  {
    let confirmar = await this.modalService.confirm('confirmar_quitar_agenda','Si, borralo','No');
    if(confirmar)
    {
      const loader = await this.modalService.showLoader();
      try {
        const req = await this.agendaService.delete(ind);
        await this.load();
        loader.dismiss();
      } catch (error) {
        loader.dismiss();
        this.modalService.showError(error.message,'ok');
      }
    }
  }

  async agregar(){
    let modalRef = this.modalService.open(AgregarAgendaComponent,{windowClass:'app-modal top-modal',size:'md',backdropClass:'no-backdrop',backdrop:'static'});

    try {
      await modalRef.result;
      await this.load();
    } catch (error) {

    }
  }

}
