export class Curso{
    cursId:number;
    cursNombre:string;
    cursDesc:string;
    cursCodigo:string;
    cursFhCrea:string;
    cursEstado:number;
    aprobado:number;
    numeroModulos: number;
    modulosCompletados:number;
    sigModulo:number;
    sigLeccion:number;
    idioma:string;
    idiomaId:number = null;
}


export class CursoModulo{
    cursId:number;
    modulos:number[];
}