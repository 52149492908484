import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-adm-btns',
  templateUrl: './adm-btns.component.html',
  styleUrls: ['./adm-btns.component.css']
})
export class AdmBtnsComponent implements OnInit {

  constructor(public lang:LangService) { }

  ngOnInit(): void {
  }

}
