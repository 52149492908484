import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutError } from 'rxjs';
import { reduce } from 'rxjs/operators';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { CategoriaService } from 'src/app/services/categoria.service';
import { ModalService } from 'src/app/services/modal-service';
import { LangService } from 'src/app/services/lang.service';

@Component({
  selector: 'app-macrociclos-detalle',
  templateUrl: './macrociclos-detalle.component.html',
  styleUrls: ['./macrociclos-detalle.component.css']
})
export class MacrociclosDetalleComponent implements OnInit {


  loading = true;
  @Input() macrociclo:Macrociclo;
  detalles:any[] = [];
  table:any[][] = [];
  constructor(public activeModal:NgbActiveModal,private categoriaService:CategoriaService,private modalService:ModalService, public lang:LangService) { }





  async ngOnInit() {
    this.onLoad();
    this.loading = false;
  }

  async onLoad()
  {
    try {

      const req = await this.categoriaService.getMacroDetalles(this.macrociclo.caunId);
      this.detalles = req.data;

      for (let index = 0; index < req.data.length; index++) {

        const row1 = {id:req.data[index].caunId,nombre:req.data[index].caunNombre,rowSpan:1};
        const row2 = {id:req.data[index].cadoId,nombre:req.data[index].cadoNombre,rowSpan:1};
        const row3 = {id:req.data[index].catrId,nombre:req.data[index].catrNombre,rowSpan:1};
        const row4 = {id:req.data[index].cacuId,nombre:req.data[index].cacuNombre,rowSpan:1};
        const el = [row1,row2,row3,row4];
        this.table.push(el);
      }

      for (let cel = 0; cel < 4; cel++) {
        for (let index = 0; index < this.table.length; index++) {
          for (let j = index+1; j < this.table.length; j++) {
            if(this.table[index][cel].id == this.table[j][cel].id && this.table[index][cel].rowSpan > 0)
            {
              this.table[index][cel].rowSpan ++;
              this.table[j][cel].rowSpan = 0;
            }
            else
            {
              break;
            }
          }
        }
      }


      console.log(this.table);

    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
    }
  }



}
