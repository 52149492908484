import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Grupo } from 'src/app/models/grupo';
import { GrupoService } from 'src/app/services/grupo.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';

@Component({
  selector: 'app-nuevo-grupo',
  templateUrl: './nuevo-grupo.component.html',
  styleUrls: ['./nuevo-grupo.component.css']
})
export class NuevoGrupoComponent implements OnInit {

  grupo:Grupo;
  errores:any[] = [];
  uploading:boolean= false;

  constructor(public activeModal:NgbActiveModal, private grupoService:GrupoService,private modalService:ModalService,public lang:LangService) {
    this.grupo = new Grupo();
   }

  async ngOnInit(): Promise<void> {
  }

  async agregar(){
    if(this.grupo.grupoGratis)
    {
      this.grupo.grupoGratis = 1;
    }
    this.uploading = true;
    try {
      const req = await this.grupoService.post(this.grupo);
      this.activeModal.close('creado');
    } catch (error) {
      this.modalService.showError(error.message,'Aceptar');
      this.errores = error.data;
    }
    this.uploading=false;
  }

}
