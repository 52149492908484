import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpRequest } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class LangService {

  langLoaded = false;
  currentLang:Languajes|null = null;
  systemLang:string = null;

  languajes:Languajes[] = [
    {name:'Español',file:'./assets/locale/es.json'},
    {name:'English',file:'./assets/locale/en.json'}
  ];

  constructor(private http:HttpClient) {
  }


  public text(value:string):string{

    if(!this.langLoaded){
      console.error('Lenguaje aun no cargado!');
    }
    const val = this.currentLang?.dict?.dict[value];
    if(val != null){
      return val;
    }
    else{
      return ''+value+'';
    }
  }


  public textEx(value:string){
    if(!this.langLoaded){
      console.error('Lenguaje aun no cargado!');
    }
    for (const key in this.currentLang?.dict?.dict) {
      if (Object.prototype.hasOwnProperty.call(this.currentLang?.dict?.dict, key)) {
        const i:string = this.currentLang?.dict?.dict[key];
        if(key.includes('%s')){
          var regex = key.replace('%s','(.*?)');
          if(value.match(regex) != null){
            var label = key.replace('%s','');
            var words = label.split(' ');
            var realWorlds = value.split(' ');
            var sobras = realWorlds.filter(x => !words.includes(x));
            if(sobras.length > 0){
              var palabra = sobras[0];
              var palabraTraducida = this.text(palabra);
              var etiqueta = this.currentLang?.dict?.dict[key];
              etiqueta = etiqueta.replace('%s',palabraTraducida);
              return etiqueta;
            }
          }
        }
      }
    }
    return this.text(value);
  }

  public t(value:string){
    return this.text(value);
  }

  async loadLang(){
    const headers = new HttpHeaders({
      "Cache-Control": "no-cache",
      "Pragma": "no-cache",
      "Expires": "Sat, 01 Jan 2000 00:00:00 GMT"
    });
    for (const i of this.languajes) {
      const req = await this.http.get<LanguajeDict>(i.file,{headers:headers}).toPromise();
      i.dict = req;
    }
    this.currentLang = this.languajes[0];
    await this.setPreferedLang();

    this.langLoaded = true;
  }

  async setPreferedLang(){
    const langCode = navigator.language.substring(0,2);
    this.systemLang = langCode;
    const langFull = navigator.language.toLocaleLowerCase();
    console.info('Browser lang:'+langFull);
    for (const i of this.languajes) {
      if(i.dict.code.substring(0,2) == langCode){
        this.currentLang = i;
        console.info('Idioma ajustado a '+i.dict.name);
        break;
      }
    }

    for (const i of this.languajes) {
      if(i.dict.code == langFull){
        this.currentLang = i;
        console.info('Idioma ajustado con presición de pais a '+i.dict.name);
        break;
      }
    }
  }
}




export interface LanguajeDict{
  name:string;
  code:string;
  dict:any;
}

export interface Languajes{
  name:string;
  file:string;
  dict?:LanguajeDict;
}
