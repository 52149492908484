import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { GraficoData, WebService } from 'src/app/services/web.service';

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})
export class AdminHomeComponent implements OnInit {

  info:{
coachesTotal: any;
  cantVentas: number;
    ventasTotales: number;
    pagado: number;
    ganancia: number;
    aPagar: number;
    gananciaLiquida?: number;
    totalClases:number;
    totalAlumnos:number;
    totalDaddies:number;
  };


  charts:GraficoData[] = [];

  constructor(public lang:LangService,private admService:WebService,private helper:ModalService) { }

  ngOnInit(): void {
    this.load();
    this.loadGraficos();
  }

  async load(){
    try {
      const req = await this.admService.getResumenAdm();
      this.info = req.data[0];
    } catch (error) {
      this.helper.showError(error.message,'Ok');
    }
  }


  async loadGraficos(){
    try {
      const req = await this.admService.getGrafico();
      this.charts = req.data;
    } catch (error) {
      
    }
  }
}
