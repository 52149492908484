<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!mesogrupo">{{lang.text('mesogrupo.nuevo')}}</h4>
        <h4 *ngIf="mesogrupo">{{lang.text('mesogrupo.editar')}}</h4>
        <form>
          <label class="app-label">{{lang.text('nombre')}}</label>
          <input class="app-input" type="text" [(ngModel)]="megrNombre" name="megrNombre">
        </form>


        <div *ngIf="loading" class="loader mt-2"></div>
        <div class="row" *ngIf="!loading">
          <div class="col-sm-5">
            <h6>{{lang.t('mesociclos')}}</h6>
            <div class="lista">
              <h6 *ngFor="let i of mesos" (click)="agregar(i)"> #{{i.cadoId}} {{i.cadoNombre}}</h6>
            </div>
          </div>
          <div class="col-sm-2">
            <img src="https://www.freeiconspng.com/thumbs/blue-arrow-png/blue-arrow-png-22.png" alt="" class="mt-5">
          </div>
          <div class="col-sm-5">
            <h6>{{lang.text('meso.en_el')}}</h6>
            <div class="lista">
              <h6 *ngFor="let j of mesosSel" (click)="remover(j)"> #{{j.cadoId}} {{j.cadoNombre}}</h6>
            </div>
          </div>
        </div>
        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon> </button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check" ></fa-icon> </button>
    </div>
</div>
