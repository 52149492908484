<div class="modal-alert">
    <div class="modal-body left">
        <h4 *ngIf="!cursParam">{{lang.text('curs.add')}}</h4>
        <h4  *ngIf="cursParam">{{lang.text('curs.edit')}}</h4>
        <form>
            <label class="app-label">{{lang.text('curs.nomb')}}</label>
            <input class="app-input" type="text" [(ngModel)]="cursos.cursNombre" name="moduloNombre">

            <label class="app-label">{{lang.text('curs.desc')}}</label>
            <input class="app-input" type="text" [(ngModel)]="cursos.cursDesc" name="moduloDesc">

            <label class="app-label">{{lang.text('curs.codi')}}</label>
            <input class="app-input" type="text" [(ngModel)]="cursos.cursCodigo" name="moduloCodigo">

            <label class="app-label">{{lang.text('curs.idioma')}}</label>
            <select [(ngModel)]="cursos.idiomaId" name="tipoVideoId" class="app-combo" >
                <option [value]="null" selected>{{lang.text('videos.todos')}}</option>
                <option *ngFor="let i of idioma" value="{{i.idiomaId}}">{{i.idioma}}</option>
            </select>
        </form>
        <div class="alert alert-danger" *ngFor="let error of errores">
            {{lang.textEx(error.message)}}

        </div>
    </div>
    <div *ngIf="uploading" class="loading-bar">

    </div>
    <div class="modal-footer">
        <button type="button" class="app-btn red" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.text('btn.cancel')}}"><fa-icon icon="times" ></fa-icon></button>
        <button type="button" class="app-btn green" [disabled]="uploading" (click)="agregar()" title="{{lang.text('btn.add')}}"><fa-icon icon="check" ></fa-icon></button>
    </div>
</div>
