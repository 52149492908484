import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PeriodoEvaluacion } from 'src/app/models/evaluacion';
import { Macrociclo } from 'src/app/models/Macrociclo';
import { Mesogrupo } from 'src/app/models/Mesogrupo';
import { Plan } from 'src/app/models/plan';
import { User } from 'src/app/models/User';
import { CategoriaService } from 'src/app/services/categoria.service';
import { EvaluacionService } from 'src/app/services/evaluacion.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { PeriodoService } from 'src/app/services/periodo.service';
import { UserService } from 'src/app/services/user.service';



@Component({
  selector: 'app-alumno-crear-evaluacion',
  templateUrl: './alumno-crear-evaluacion.component.html',
  styleUrls: ['./alumno-crear-evaluacion.component.css']
})
export class AlumnoCrearEvaluacionComponent implements OnInit {

  @Input() userModel:User;
  @Input() users:User[] = null;
  errores:any[]=[];
  uploading = false;
  periodos:any[] = [];
  planes:Plan[] = [];
  ciclos:Mesogrupo[] = [];
  macros:Macrociclo[] = [];

  model:PeriodoEvaluacion = {evaluacionInicio:'',caunId:null,planId:null,cicloFiltro:0,alumnoId:0}

  constructor(public activeModal:NgbActiveModal,private modalService:ModalService,private userService:UserService,
    private periodoService:PeriodoService, private evaluacionService:EvaluacionService
    ,private catService:CategoriaService,public lang:LangService) { }

  ngOnInit(): void {
    this.onLoad();
    this.model.alumnoId = this.userModel?.id?? null;
  }

  async onLoad(){
    try {

      const req3 = await this.userService.getPlanes();
      this.planes = req3.data;

      const req4 = await this.catService.getAllMesoGrupos();
      this.ciclos = req4.data;

      const req = await this.catService.getAllMacros();
      this.macros = req.data;

    } catch (error) {
      
    }
  }

  async guardar(){
    this.uploading = true;
    if(this.users){
      try {
        for (const i of this.users) {
          this.model.alumnoId = i.id;
          const req = await this.evaluacionService.crearSemestre(this.model);
        }
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }else{
      try {
        const req = await this.evaluacionService.crearSemestre(this.model);
        this.activeModal.close('creado');
      } catch (error) {
        this.modalService.showError(error.message,'Aceptar');
      }
    }
    
    this.uploading = false;
  }
}
