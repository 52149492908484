import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpRequest } from '@angular/common/http';
import { ApiResponse } from '../../models/ApiResponse';
import { environment } from 'src/environments/environment';
import { Respuesta } from 'src/app/models/campus/respuesta';
import { Pregunta } from 'src/app/models/campus/Pregunta';
import { quizPreguntas } from '../../models/campus/quiz';
import { leccion } from '../../models/campus/leccion';
import { DocumentoLeccion } from '../documento.service';
import { DocumentoLeccionn } from 'src/app/models/campus/LeccionDocumento';

@Injectable({
  providedIn: 'root'
})
export class LeccionService {
  constructor(private http:HttpClient) {
  }

  public async get(leccId?:number)
  {
    const body = Params.toHttpParams({leccId:leccId});
    return this.http.get<ApiResponse<leccion>>(`${environment.urlAPI}campus/leccion`,{params:body}).toPromise();
  }

  public async put(req:leccion)
  {

    return this.http.put<ApiResponse<leccion>>(`${environment.urlAPI}campus/leccion`,req).toPromise();
  }

  public async post(req:leccion)
  {
    return this.http.post<ApiResponse<leccion>>(`${environment.urlAPI}campus/leccion`,req).toPromise();
  }


  public async DeleteLeccion(leccId?:number)
  {
    const body = Params.toHttpParams({leccId:leccId});
    return this.http.delete<ApiResponse<any>>(`${environment.urlAPI}campus/leccion/lecc`,{params:body}).toPromise();
  }



  public async getLeccDocu(leccId:number, docuId:string)
  {
    const body = Params.toHttpParams({leccId:leccId,docuId:docuId});
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}campus/leccion/documento`,{params:body}).toPromise();
  }


  public async postDocuLecc(req:DocumentoLeccionn)
  {
    return this.http.put<ApiResponse<DocumentoLeccionn>>(`${environment.urlAPI}campus/leccion/documento`,req).toPromise();
  }


  public async getLeccDocuPorLeccId(leccId:number)
  {
    const body = Params.toHttpParams({leccId:leccId});
    return this.http.get<ApiResponse<any>>(`${environment.urlAPI}campus/leccion/documentosLeccion`,{params:body}).toPromise();
  }


}

export class Params {
  public static toHttpParams(obj: Object): HttpParams {
    let p = new HttpParams();
    for (const i of Object.getOwnPropertyNames(obj)) {
        if(obj[i]){
            p = p.set(i,obj[i].toString());
        }
    }
    return p;
  }
}
