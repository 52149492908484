import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Compra } from 'src/app/models/compra';
import { Payout } from 'src/app/models/payout';
import { User } from 'src/app/models/User';
import { CompraService } from 'src/app/services/compra.service';
import { LangService } from 'src/app/services/lang.service';
import { ModalService } from 'src/app/services/modal-service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-ventas',
  templateUrl: './ventas.component.html',
  styleUrls: ['./ventas.component.css']
})
export class VentasComponent implements OnInit,AfterViewInit {

  ventas:Compra[] = [];
  user:User;

  page = 1;
  lastPage = 1;
  pageSize = 5;
  constructor(public lang:LangService,private helper:ModalService,private userService:UserService,private ventaService:CompraService) { }
  

  ngOnInit(): void {
    this.load();
  }


  async ngAfterViewInit() {
    const loader = await this.helper.showLoader();
    try {
      const req = await this.userService.me();
      const user = req.data[0];
      this.user = user;
      if(this.user.stripeId != null && !this.user.stripeConfirmado){
        
        const req = await this.userService.linkear();
        if(req.message == "refresh_requested"){
          location.reload();
        }
        
      }
      loader.dismiss();
    } catch (error) {
      console.error(error);
      loader.dismiss();
    }
  }

  update(){
    location.href=this.user.stripeAccountUrl;
  }

  async linkear(){
    const loader = await this.helper.showLoader();
    try {
      const req = await this.userService.linkear();
      location.href = req.url;
      console.log(req); 
    } catch (error) {
      
    }
    loader.dismiss();
  }

  getPage(){
    let array =  this.ventas.slice((this.page - 1) * this.pageSize, this.page * this.pageSize);
    this.lastPage = Math.ceil(this.ventas.length / this.pageSize);
    return array;
  }

  

  async load(){
    try {
      const req = await this.ventaService.getMisVentas();
      this.ventas = req.data;
      
      
    } catch (error) {
      
    }
  }

}
