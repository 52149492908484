import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { LangService } from '../services/lang.service';

@Component({
  selector: 'app-alert-modal-component',
  templateUrl: './alert-modal-component.component.html',
  styleUrls: ['./alert-modal-component.component.css']
})
export class AlertModalComponentComponent implements OnInit {

  @Input() message:string;
  @Input() button1:string;
  @Input() button2:string;
  @Input() errorDetail:string = null;
  constructor(public activeModal: NgbActiveModal,public lang:LangService) { }

  ngOnInit(): void {
  }

}
