<div class="modal-alert">
    <div class="modal-body left">
        <h4>{{lang.text('marcar_asistencia')}}</h4>
        <form>
            <label class="app-label">{{lang.text('marcar_como')}}</label>
            <select [(ngModel)]="estado"  name="estado" class="app-combo">
                <option value="null" disabled>{{lang.text('seleccionar')}}</option>
                <option *ngFor="let e of estados" [ngValue]="e.clesId">{{lang.text(e.clesNombre)}}</option>
            </select>
            <label class="app-label">{{lang.text('fecha')}}</label>
            <input type="date" class="app-combo" [(ngModel)]="fecha" name="fecha" id="fecha">
            <label class="app-label">{{lang.text('observacion')}}</label>
            <input class="app-input" type="text" [(ngModel)]="observacion" name="observacion">

        </form>


        <div class="alert alert-danger" *ngFor="let error of errores">
          {{error.message}}
        </div>
    </div>
    <div class="loading-bar" *ngIf="uploading"></div>
    <div class="modal-footer">
        <button type="button" class="app-btn" [disabled]="uploading" (click)="activeModal.dismiss('cancelar')" title="{{lang.t('btn.cancel')}}"><fa-icon icon="times"></fa-icon></button>
        <button type="button" class="app-btn" [disabled]="uploading" (click)="guardar()" title="{{lang.t('btn.saveChange')}}"><fa-icon icon="check"></fa-icon></button>
    </div>
</div>
